import { DialogContainer } from '@/components/dialog-container/DialogContainer';
import { FieldLocalDate } from '@/components/form/field-date/FieldDate';
import { FieldSelect } from '@/components/form/field-select/FieldSelect';
import { EmployeeRole } from '@/domain/employee-role/EmployeeRole.model';
import { Role } from '@/domain/role/Role.model';
import { isValidDate } from '@/utils/datetime.util';

import { getNull } from '@/utils/object.util';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormControlLabel, Stack } from '@mui/material';
import i18next from 'i18next';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

type Props = {
    selectedEmployeeRole?: EmployeeRole;
    availableRoles: Role[];
    onSave: (roleFormValues: RoleFormValues) => void;
    onClose: () => void;
    employeeId: number;
};

export const RoleDialog: FC<Props> = ({ onClose, onSave, selectedEmployeeRole, availableRoles }) => {
    const { t } = useTranslation();
    const title = t('employee.sections.roles');

    const schema = getRoleSchema();

    const defaultValues: Record<string, unknown> = {};
    if (selectedEmployeeRole?.startDate) {
        defaultValues['startDate'] = selectedEmployeeRole?.startDate;
    }
    defaultValues['role'] = selectedEmployeeRole?.role ?? getNull();

    const { control, handleSubmit } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            selectedEmployeeRoleId: selectedEmployeeRole?.id,
            ...defaultValues,
        },
    });

    const onCloseDialog = () => {
        onClose();
    };

    return (
        <DialogContainer title={title} open={true} onSave={() => handleSubmit(onSave, console.error)()} onClose={onCloseDialog}>
            <Stack direction='column' spacing={2}>
                <FormControlLabel
                    label={t('employee.role.name')}
                    control={
                        <FieldSelect
                            name='role'
                            control={control}
                            options={availableRoles}
                            fullWidth
                            getOptionLabel={option => option.name}
                            isOptionEqualToValue={(option, anotherOption) => option.id === anotherOption.id}
                        />
                    }
                />
                <FormControlLabel label={t('add_correction_dialog.effective_date')} control={<FieldLocalDate control={control} name='startDate' />} />
            </Stack>
        </DialogContainer>
    );
};

const getRoleSchema = () => {
    return yup.object().shape({
        startDate: yup
            .string<LocalDate>()
            .required()
            .test({
                message: i18next.t('general.validations.valid_date'),
                test: isValidDate,
            }),
        role: yup.object().default(getNull()).required().shape({
            id: yup.number().required(),
            name: yup.string().required(),
        }),
        selectedEmployeeRoleId: yup.number().nullable(),
    });
};

export type RoleFormValues = yup.InferType<ReturnType<typeof getRoleSchema>>;
