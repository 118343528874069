import { DialogContainer } from '@/components/dialog-container/DialogContainer';
import { FieldText } from '@/components/form/field-text/FieldText';
import { ShiftType } from '@/domain/shift-type/ShiftType.model';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormControlLabel, Radio, RadioGroup, Stack } from '@mui/material';
import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

type ShiftTypeDialogProps = {
    open: boolean;
    handleSave: (data: ShiftTypeForm) => void;
    closeDialog: () => void;
    shiftType?: ShiftType;
};

export type ShiftTypeForm = {
    name: string;
    onSite: boolean;
};

export const ShiftTypeDialog: FC<ShiftTypeDialogProps> = ({ handleSave, open, closeDialog, shiftType: activeShiftTypeData }) => {
    const { t } = useTranslation();

    const schema = yup.object().shape({
        name: yup.string().trim().required(),
        onSite: yup.boolean().required(),
    });

    const { control, handleSubmit } = useForm<ShiftTypeForm>({
        resolver: yupResolver(schema),
        defaultValues: {
            name: activeShiftTypeData?.name ?? '',
            onSite: activeShiftTypeData?.onSite || false,
        },
    });

    const onClose = () => {
        closeDialog();
    };
    const onSave = (data: ShiftTypeForm) => {
        handleSave(data);
    };

    return (
        <DialogContainer
            title={activeShiftTypeData ? t('planning_setting_page.add_shift_type') : t('planning_setting_page.edit_shift_type')}
            open={open}
            onSave={handleSubmit(onSave, console.error)}
            onClose={onClose}
        >
            <Stack gap={2}>
                <FormControlLabel label={t('planning_setting_page.shift_type_name')} control={<FieldText name='name' control={control} fullWidth />} />

                <Controller
                    name='onSite'
                    control={control}
                    render={({ field }) => (
                        <FormControlLabel
                            label={t('planning_setting_page.behaviour_in_planning')}
                            control={
                                <RadioGroup value={field.value}>
                                    <FormControlLabel
                                        value={true}
                                        control={
                                            <Radio
                                                onChange={() => {
                                                    field.onChange(true);
                                                }}
                                            />
                                        }
                                        label='Normal'
                                        labelPlacement='end'
                                    />
                                    <FormControlLabel
                                        value={false}
                                        control={
                                            <Radio
                                                onChange={() => {
                                                    field.onChange(false);
                                                }}
                                            />
                                        }
                                        label='Ignore'
                                        labelPlacement='end'
                                    />
                                </RadioGroup>
                            }
                        />
                    )}
                />
            </Stack>
        </DialogContainer>
    );
};
