import { leaveTypeApi } from '@/api/leave-type/LeaveType.api';
import { LeaveActivityType, LeaveType, LeaveTypeCreationMutation, LeaveTypePolicy, LeaveTypeUpdateMutation } from '@/domain/leave-type/LeaveType.model';
import i18next, { DurationUnit } from '@/i18n/i18n';
import { UnitType } from '../date/Date.model';
import { AllowanceType } from './LeaveType.model';
import { OrderMutation } from '@/domain/common';
import { addYears, formatToLocalDate, getCurrentLocalDate, MONTHS, subDays } from '@/utils/datetime.util';
import { getCycleStartDate } from '@/domain/timesheet-setting/TimesheetSetting.service';
import { getRoundedNumber, RoundingType } from '@/utils/math.util';

export const getLeaveTypeById = (leaveTypeId: number): Promise<LeaveType> => {
    return leaveTypeApi.getLeaveTypeById(leaveTypeId);
};

export const getLeaveTypes = (): Promise<LeaveType[]> => {
    return leaveTypeApi.getLeaveTypes();
};

export const createNewLeaveType = (mutation: LeaveTypeCreationMutation): Promise<LeaveType> => {
    return leaveTypeApi.createNewLeaveType(mutation);
};

export const updateLeaveType = (mutation: LeaveTypeUpdateMutation, leaveTypeId: number): Promise<LeaveType> => {
    return leaveTypeApi.updateLeaveType(mutation, leaveTypeId);
};

export const updateLeavesTypeOrder = (mutation: OrderMutation[]): Promise<void> => {
    return leaveTypeApi.updateLeavesTypeOrder(mutation);
};

export const deleteLeaveType = (leaveTypeId: number): Promise<void> => {
    return leaveTypeApi.deleteLeaveType(leaveTypeId);
};

export const getAllowanceText = (policy: LeaveTypePolicy, unitType: UnitType, roundingType?: RoundingType): string => {
    if (unitType === UnitType.HOURS) {
        return i18next.t('duration.formatDuration', {
            duration: policy.allowanceInMinutes / 60,
            unit: DurationUnit.HOURS,
        });
    } else if (unitType === UnitType.DAYS || unitType === UnitType.BOTH) {
        return i18next.t('duration.formatDuration', {
            duration: getRoundedNumber(policy.allowanceInMinutes / 1440, roundingType),
            unit: DurationUnit.DAYS,
        });
    }
    throw new Error('Unit type not supported.');
};

export const filterOutCompensationNotUnlimitedLeaveTypes = (leaveTypes: LeaveType[] = []): LeaveType[] => {
    return leaveTypes?.filter(
        leaveType => !(leaveType.leaveActivityType === LeaveActivityType.TIMESHEET_COMPENSATION && leaveType.allowanceType === AllowanceType.NOT_UNLIMITED),
    );
};

export const removeNullsFromLeaveType = (leaveType: LeaveType): LeaveType => {
    //TODO: when we have a better solution for the null issues, we should remove this function
    //right now this function is used to remove all the null values that come from the "api" so that we dont have errors with the schema that doesnt allow null values
    return {
        ...leaveType,
        color: leaveType.color ?? undefined,
        attachmentRequiredAfterDays: leaveType.attachmentRequiredAfterDays ?? undefined,
        maxDurationInMinutes: leaveType.maxDurationInMinutes ?? undefined,
        employeeRequestMessage: leaveType.employeeRequestMessage ?? undefined,
        requestIncrement: leaveType.requestIncrement ?? undefined,
    };
};

export const getLeaveTypeDisplayUnitType = (leaveType: LeaveType, leaveRequestUnitType: UnitType): UnitType => {
    return leaveType?.displayUnitType === UnitType.BOTH ? leaveRequestUnitType : leaveType?.displayUnitType;
};

export const getCycleDates = (
    cycleEndDate: LocalDate,
    newCycleMonth: MONTHS,
    updateEndDate = false,
): {
    startDate: LocalDate;
    endDate: LocalDate;
} => {
    const filterEndDate = updateEndDate ? getCurrentLocalDate() : cycleEndDate;
    const startDate = getCycleStartDate(filterEndDate, newCycleMonth);
    const endDate = formatToLocalDate(subDays(addYears(getCycleStartDate(startDate, newCycleMonth), 1), 1));
    return { startDate, endDate };
};
