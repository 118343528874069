import { EmployeeAvatar } from '@/components/employee-avatar/EmployeeAvatar';
import { StackedAvatars } from '@/components/stacked-avatar/StackedAvatars';
import { EmployeeAvatar as EmployeeAvatarType } from '@/domain/employee/Employee.model';
import { canViewEmployeesDirectory } from '@/domain/permission/Permission.service';
import { useAppSelector } from '@/stores/store';
import { Link, Stack, Typography } from '@mui/material';
import { FC, useEffect, useRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';

export const EmployeeCell: FC<{ value: EmployeeAvatarType; compact: boolean; navDisabled: boolean }> = ({ value, compact, navDisabled }) => {
    const typographyRef = useRef<HTMLSpanElement>();

    // Add a title to the cell if the text is too long
    useEffect(() => {
        const elem = typographyRef.current;
        if (elem) {
            const parentWidth = elem.parentElement?.getBoundingClientRect().width;
            const elemWidth = elem.getBoundingClientRect().width;
            if (
                parentWidth &&
                // size of the avatar + padding
                elemWidth + 40 >= parentWidth
            ) {
                elem.setAttribute('title', elem.textContent ?? '');
            }
        }
    }, [value?.displayName]);

    const grantedPolicies = useAppSelector(state => state.currentEmployee.grantedPolicies);

    const canGoToEmployeeProfile = canViewEmployeesDirectory(grantedPolicies, value?.id);

    if (!value) {
        return;
    }

    return (
        <Link component={!navDisabled && canGoToEmployeeProfile ? RouterLink : 'div'} to={`/profile/${value?.id}/personal-info`} underline='none'>
            <Stack direction='row' gap={1} alignItems='center'>
                <EmployeeAvatar employeeAvatar={value} size={compact ? 'small' : 'medium'} />
                <Stack>
                    <Typography variant='body2'>{value?.displayName}</Typography>
                </Stack>
            </Stack>
        </Link>
    );
};

export const StackedAvatarsCell: FC<{ value: EmployeeAvatarType[]; compact: boolean; cellNavDisabled: boolean }> = ({ value, compact, cellNavDisabled }) => {
    if (value?.length === 1) {
        return <EmployeeCell value={value[0]} compact={compact} navDisabled={cellNavDisabled} />;
    }
    return <StackedAvatars employeeAvatars={value} size={compact ? 'small' : undefined} />;
};

export const AvatarCell: FC<{ value: EmployeeAvatarType }> = ({ value }) => {
    return <EmployeeAvatar employeeAvatar={value} size='medium' />;
};
