import { StateHandler } from '@/components/state-handler/StateHandler';
import { useGetEmployeeFolders } from '@/hooks/document/Document.hook';
import { EmployeeDocuments } from '@/page/employee-profile/employee-profile-document/EmployeeDocuments';
import { useAppSelector } from '@/stores/store';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

export const EmployeeProfileDocumentsPage: FC = () => {
    const params = useParams();
    const currentEmployee = useAppSelector(state => state.currentEmployee.employee);
    const employeeId = params?.employeeId ? Number(params?.employeeId) : currentEmployee?.id;

    const { data: employeeFolders = [], isLoading, error, isError } = useGetEmployeeFolders(employeeId);

    if (!employeeId) {
        throw new Error('Employee id is not defined');
    }

    return (
        <StateHandler isLoading={isLoading} error={error} isError={isError}>
            <EmployeeDocuments employeeId={employeeId} employeeFolders={employeeFolders} />
        </StateHandler>
    );
};
