import { RouteLeavingGuard } from '@/components/route-leaving-guard/RouteLeavingGuard';
import { Footer, FooterActions, FooterActionsProps } from '@/page/layout/Footer';
import { handleError } from '@/utils/api.util';
import { getDayOfWeekTranslationKey, getDayPeriodTranslationKey } from '@/domain/date/Date.service';
import { DayOfWeek, getDurationFromTime } from '@/utils/datetime.util';
import { DayPeriod } from '@/domain/date/Date.model';
import {
    Button,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    IconButton,
    MenuItem,
    Paper,
    PaperProps,
    Select,
    Stack,
    StackProps,
    TextField,
    Typography,
} from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { Controller, FormProvider, useFieldArray, useForm, useFormContext } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { WeeklyWorkingTime } from '@/domain/weekly-working-time/WeeklyWorkingTime.model';
import {
    createWorkingPatternTemplate,
    defaultWorkingPatternDays,
    deleteWorkingPatternTemplate,
    getWorkingPatternTemplateById,
    updateWorkingPatternTemplate,
} from '@/domain/working-pattern-template/WorkingPatternTemplate.service';
import { displayFormRouteLeavingGuard } from '@/components/route-leaving-guard/RouteLeavingGuard.util';
import {
    getAverageDailyWorkingTimeDisplay,
    getAverageWeeklyWorkingTimeDisplay,
    getDaysWorkPerWeekDisplay,
    getTotalDaysPerPattern,
    getTotalMinutesPerPattern,
    mapFormValuesToCreationRequest,
    mapFormValuesToUpdateRequest,
    mapWorkingPatternTemplateToFormValues,
} from '@/page/setting/time-management/working-pattern/WorkingPatternFormPage.util';
import { useSearchWeeklyWorkingTimes } from '@/page/employee-profile/employee-profile-info/EmpoyeeWorkPatternSection/Components/WeeklyWorkingTime.hook';

import {
    WorkingPatternTemplateCreationRequest,
    WorkingPatternTemplateUpdateRequest,
    WorkingPatternType,
} from '@/domain/working-pattern-template/WorkingPatternTemplate.model';
import { Add01Icon, Delete02Icon } from 'hugeicons-react';
import { showSnackbar } from '@/utils/snackbar.util';
import { ContentContainer } from '@/page/layout/ContentContainer';
import { FieldTime } from '@/components/form/field-time/FieldTime';

export const WorkingPatternFormPage: FC = () => {
    const { t } = useTranslation();
    const { workingPatternId } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const isEdit = !!workingPatternId && workingPatternId !== 'new';

    const { data: weeklyWorkingTimes = [] } = useSearchWeeklyWorkingTimes();

    const daySchema: yup.ObjectSchema<WorkingPatternTemplateDayForm> = yup.object().shape({
        dayOfWeek: yup.string().required().oneOf(Object.values(DayOfWeek)),
        amountWorked: yup.string<LocalTime>().required(),
        dayPeriod: yup.string().required().oneOf(Object.values(DayPeriod)),
    });

    const weekSchema: yup.ObjectSchema<WorkingPatternTemplateWeekForm> = yup.object().shape({
        workingPatternTemplateDaysForm: yup.array().of(daySchema).required(),
    });

    const schema: yup.ObjectSchema<WorkingPatternTemplateForm> = yup.object().shape({
        name: yup.string().required(),
        type: yup
            .string()
            .required()
            .oneOf(Object.values(WorkingPatternType) as WorkingPatternType[]),
        workingPatternTemplateWeeksForm: yup.array().required().of(weekSchema),
        weeklyWorkingTimeId: yup.number().required(),
    });

    const formMethods = useForm<WorkingPatternTemplateForm>({
        resolver: yupResolver(schema),
        defaultValues: {
            name: '',
            type: WorkingPatternType.FIXED,
            workingPatternTemplateWeeksForm: [
                {
                    workingPatternTemplateDaysForm: defaultWorkingPatternDays,
                },
            ],
            weeklyWorkingTimeId: undefined,
        },
    });

    useEffect(() => {
        const getWeeklyWorkingTimesId = (weeklyWorkingTimes: WeeklyWorkingTime[]) => {
            if (formMethods.getValues().weeklyWorkingTimeId) {
                return;
            }
            if (weeklyWorkingTimes.length === 1) {
                return weeklyWorkingTimes[0].id;
            }
            return undefined;
        };

        const weeklyWorkingTimesId = getWeeklyWorkingTimesId(weeklyWorkingTimes);
        if (weeklyWorkingTimesId) {
            formMethods.setValue('weeklyWorkingTimeId', weeklyWorkingTimesId);
        }
    }, [weeklyWorkingTimes, formMethods]);

    const fetchWorkingPattern = useCallback(async () => {
        if (isEdit) {
            setLoading(true);
            try {
                const data = await getWorkingPatternTemplateById(Number(workingPatternId));
                const workingPatternTemplateFormValues: WorkingPatternTemplateForm = mapWorkingPatternTemplateToFormValues(data);
                formMethods.reset(workingPatternTemplateFormValues);
            } catch {
                setError(true);
            } finally {
                setLoading(false);
            }
        }
    }, [formMethods, isEdit, workingPatternId]);

    useEffect(() => {
        fetchWorkingPattern().catch(handleError);
    }, [fetchWorkingPattern]);

    const handleCreateWorkingPatternTemplate = async (formValues: WorkingPatternTemplateForm) => {
        const workingPatternTemplateCreationRequest: WorkingPatternTemplateCreationRequest = mapFormValuesToCreationRequest(formValues);
        try {
            await createWorkingPatternTemplate(workingPatternTemplateCreationRequest);
            navigate('/settings/time-management/working-patterns');
        } catch (error) {
            handleError(error);
        }
    };

    const handleUpdateWorkingPatternTemplate = async (formValues: WorkingPatternTemplateForm) => {
        const workingPatternTemplateUpdateRequest: WorkingPatternTemplateUpdateRequest = mapFormValuesToUpdateRequest(formValues);
        try {
            await updateWorkingPatternTemplate(Number(workingPatternId), workingPatternTemplateUpdateRequest);
            navigate('/settings/time-management/working-patterns');
        } catch (error) {
            handleError(error);
        }
    };

    const onSave = (formValues: WorkingPatternTemplateForm) => {
        if (isEdit) {
            handleUpdateWorkingPatternTemplate(formValues).catch(handleError);
        } else {
            handleCreateWorkingPatternTemplate(formValues).catch(handleError);
        }
    };

    const handleDelete = async () => {
        if (!workingPatternId) {
            return;
        }
        try {
            await deleteWorkingPatternTemplate(Number(workingPatternId));
            showSnackbar(t('contract_page.delete_with_success'), 'success');
            navigate('/settings/time-management/working-patterns');
        } catch {
            showSnackbar(t('contract_page.unable_to_delete'), 'error');
        }
    };

    const getFooterActions = () => {
        const actions: FooterActionsProps['actions'] = [];

        if (isEdit && workingPatternId) {
            actions.push({
                name: 'delete',
                children: t('general.delete'),
                variant: 'contained',
                color: 'error',
                onClick: () => {
                    handleDelete().catch(handleError);
                },
            });
        }

        actions.push({
            name: 'save',
            children: t(isEdit ? 'general.update' : 'general.create'),
            variant: 'contained',
            onClick: () => {
                formMethods.handleSubmit(onSave, console.error)();
            },
        });

        return actions;
    };

    const footerActions = getFooterActions();

    return (
        <>
            <ContentContainer>
                <RouteLeavingGuard when={displayFormRouteLeavingGuard(formMethods?.formState)} />

                <StateHandler isLoading={loading} isError={error} error={new Error('Error during loading')}>
                    <Stack gap={2}>
                        <FormProvider {...formMethods}>
                            <About weeklyWorkingTimes={weeklyWorkingTimes} />
                            <WorkingHours />
                        </FormProvider>
                    </Stack>
                </StateHandler>
            </ContentContainer>
            <Footer>
                <FooterActions actions={footerActions} />
            </Footer>
        </>
    );
};

export type AboutProps = {
    weeklyWorkingTimes: WeeklyWorkingTime[];
} & StackProps &
    PaperProps;

export const About: FC<AboutProps> = ({ weeklyWorkingTimes, ...rest }) => {
    const { t } = useTranslation();
    const {
        watch,
        register,
        formState: { errors },
    } = useFormContext<WorkingPatternTemplateForm>();
    const workingPatternTemplateValues = watch();

    return (
        <Stack gap={2} component={Paper} p={3} elevation={1} {...rest}>
            <Typography variant={'h1'}>{t('contract_page.about')}</Typography>

            <FormControlLabel
                label={t('contract_page.contract_name')}
                labelPlacement='top'
                control={<TextField sx={{ width: '24em' }} error={!!errors.name} helperText={errors.name?.message?.toString()} {...register('name')} />}
            />

            {weeklyWorkingTimes.length > 1 && <WeeklyWorkingTimeField weeklyWorkingTimes={weeklyWorkingTimes} />}

            <Stack gap={1}>
                <Typography variant={'h2'}>{t('contract_page.average_working_time')}</Typography>
                <Typography variant={'body2'}>
                    {!!workingPatternTemplateValues?.weeklyWorkingTimeId &&
                        getAverageWeeklyWorkingTimeDisplay(workingPatternTemplateValues, weeklyWorkingTimes, workingPatternTemplateValues?.weeklyWorkingTimeId)}
                </Typography>
                <Typography variant={'body2'}>{getAverageDailyWorkingTimeDisplay(workingPatternTemplateValues)}</Typography>
                <Typography variant={'body2'}>{getDaysWorkPerWeekDisplay(workingPatternTemplateValues)}</Typography>

                <Typography variant={'body2'}>
                    {t('contracts_settings_page.total_rotation', {
                        totalHours: getTotalMinutesPerPattern(workingPatternTemplateValues?.workingPatternTemplateWeeksForm),
                        totalWeeks: workingPatternTemplateValues?.workingPatternTemplateWeeksForm?.length ?? 0,
                        totalDays: getTotalDaysPerPattern(workingPatternTemplateValues?.workingPatternTemplateWeeksForm),
                    })}
                </Typography>
            </Stack>
        </Stack>
    );
};

type WeeklyWorkingTimeFieldProps = {
    weeklyWorkingTimes: WeeklyWorkingTime[];
};

export const WeeklyWorkingTimeField: FC<WeeklyWorkingTimeFieldProps> = ({ weeklyWorkingTimes }) => {
    const {
        control,
        formState: { errors },
    } = useFormContext();
    const formValueName = 'weeklyWorkingTimeId';

    return (
        <FormControl sx={{ width: '24em' }}>
            <Controller
                render={({ field: { ref, value, ...rest } }) => (
                    <Select {...rest} inputRef={ref} error={!!errors[formValueName]} autoFocus={false} value={value ?? ''}>
                        {weeklyWorkingTimes?.map(weeklyWorkingTime => (
                            <MenuItem key={weeklyWorkingTime.id} value={weeklyWorkingTime.id}>
                                {weeklyWorkingTime.name}
                            </MenuItem>
                        ))}
                    </Select>
                )}
                name={formValueName}
                control={control}
            />
            {!!errors[formValueName] && <FormHelperText error={!!errors[formValueName]}>{errors[formValueName].message?.toString()}</FormHelperText>}
        </FormControl>
    );
};

export type WorkingHoursProps = StackProps & PaperProps;

export const WorkingHours: FC<WorkingHoursProps> = ({ ...rest }) => {
    const { t } = useTranslation();

    return (
        <Stack gap={2} component={Paper} p={3} elevation={1} {...rest}>
            <Typography variant={'h1'}>{t('contract_page.working_hours')}</Typography>
            <Weeks />
        </Stack>
    );
};

export const Weeks: FC = () => {
    const { t } = useTranslation();
    const showAddMoreWeekButton = (workingPatternTemplate: WorkingPatternTemplateForm) => {
        return workingPatternTemplate.workingPatternTemplateWeeksForm.length < 10;
    };

    const { control, watch } = useFormContext<WorkingPatternTemplateForm>();

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'workingPatternTemplateWeeksForm',
    });

    const workingPatternTemplateValues = watch();

    const isDeleteButtonDisabled = () => {
        return workingPatternTemplateValues.workingPatternTemplateWeeksForm.length === 1;
    };

    const handleAddMoreWeekClick = () => {
        append({
            workingPatternTemplateDaysForm: defaultWorkingPatternDays,
        });
    };

    const handleDeleteWeekClick = (weekIndex: number) => () => {
        remove(weekIndex);
    };

    return (
        <Stack gap={2} alignItems={'start'}>
            <Stack gap={3}>
                {fields.map((workingPatternTemplateWeeksForm, weekIndex) => {
                    return (
                        <Stack key={workingPatternTemplateWeeksForm.workingPatternTemplateDaysForm[0].dayOfWeek + workingPatternTemplateWeeksForm.id} gap={1}>
                            <Stack gap={0.5}>
                                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                    <Typography>
                                        {t('contract_page.week')} {weekIndex + 1}
                                    </Typography>
                                    <IconButton
                                        name={'delete_icon_button'}
                                        size={'small'}
                                        disabled={isDeleteButtonDisabled()}
                                        onClick={handleDeleteWeekClick(weekIndex)}
                                    >
                                        <Delete02Icon />
                                    </IconButton>
                                </Stack>
                                <Divider />
                            </Stack>
                            <Stack flexWrap={'wrap'} direction={'row'} gap={1}>
                                <Days weekIndex={weekIndex} />
                            </Stack>
                        </Stack>
                    );
                })}
            </Stack>
            {showAddMoreWeekButton(workingPatternTemplateValues) && (
                <Button variant={'text'} startIcon={<Add01Icon width='20' height='20' viewBox='0 0 20 20' />} onClick={handleAddMoreWeekClick}>
                    {t('contract_page.add_more_week')}
                </Button>
            )}
        </Stack>
    );
};

export interface DaysProps {
    weekIndex: number;
}

export const Days: FC<DaysProps> = ({ weekIndex }) => {
    const { control } = useFormContext<WorkingPatternTemplateForm>();

    const { fields } = useFieldArray({
        control,
        name: `workingPatternTemplateWeeksForm.${weekIndex}.workingPatternTemplateDaysForm`,
    });

    return fields?.map((workingPatternTemplateDaysForm, dayIndex) => {
        return (
            <Stack gap={1} key={workingPatternTemplateDaysForm?.dayOfWeek + workingPatternTemplateDaysForm.amountWorked}>
                <WorkingDayComponent weekIndex={weekIndex} dayIndex={dayIndex} dayOfWeek={workingPatternTemplateDaysForm.dayOfWeek} />
            </Stack>
        );
    });
};

type WorkingDayComponentProps = {
    weekIndex: number;
    dayIndex: number;
    dayOfWeek: DayOfWeek;
};

const WorkingDayComponent: FC<WorkingDayComponentProps> = ({ weekIndex, dayIndex, dayOfWeek }) => {
    const { t } = useTranslation();
    const { control, watch } = useFormContext<WorkingPatternTemplateForm>();

    const workingPatternTemplateValues = watch();
    const isSelectedDayPeriodDisabled = (weekIndex: number, dayIndex: number) => {
        const amountWorked = workingPatternTemplateValues.workingPatternTemplateWeeksForm[weekIndex].workingPatternTemplateDaysForm[dayIndex].amountWorked;
        const amountWorkedInMinutes = getDurationFromTime(amountWorked);
        return amountWorkedInMinutes === 0;
    };

    return (
        <FormControlLabel
            label={t(getDayOfWeekTranslationKey(dayOfWeek))}
            labelPlacement='top'
            control={
                <Stack gap={1} width={'12em'}>
                    <FieldTime
                        name={`workingPatternTemplateWeeksForm.${weekIndex}.workingPatternTemplateDaysForm.${dayIndex}.amountWorked`}
                        control={control}
                    />

                    <Controller
                        name={`workingPatternTemplateWeeksForm.${weekIndex}.workingPatternTemplateDaysForm.${dayIndex}.dayPeriod`}
                        control={control}
                        render={({ field }) => (
                            <Select {...field} disabled={isSelectedDayPeriodDisabled(weekIndex, dayIndex)}>
                                <MenuItem value={DayPeriod.ALL_DAY}>{t(getDayPeriodTranslationKey(DayPeriod.ALL_DAY))}</MenuItem>
                                <MenuItem value={DayPeriod.MORNING}>{t(getDayPeriodTranslationKey(DayPeriod.MORNING))}</MenuItem>
                                <MenuItem value={DayPeriod.AFTERNOON}>{t(getDayPeriodTranslationKey(DayPeriod.AFTERNOON))}</MenuItem>
                            </Select>
                        )}
                    />
                </Stack>
            }
        />
    );
};

export type WorkingPatternTemplateForm = {
    name: string;
    type: WorkingPatternType;
    workingPatternTemplateWeeksForm: WorkingPatternTemplateWeekForm[];
    weeklyWorkingTimeId: number;
};

export type WorkingPatternTemplateWeekForm = {
    workingPatternTemplateDaysForm: WorkingPatternTemplateDayForm[];
};

export type WorkingPatternTemplateDayForm = {
    dayOfWeek: DayOfWeek;
    amountWorked: LocalTime;
    dayPeriod: DayPeriod;
};
