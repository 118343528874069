import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogContainer } from '@/components/dialog-container/DialogContainer';
import { Typography } from '@mui/material';
import { getLeaveRequestBalanceAsString } from '@/domain/leave-request/LeaveRequest.service';
import { LeaveType } from '@/domain/leave-type/LeaveType.model';
import { UnitType } from '@/domain/date/Date.model';

export type ConfirmNegativeBalanceDialogProps = {
    onSave: () => void;
    onClose: () => void;
    leaveRequestUnitType: UnitType;
    leaveType: LeaveType;
    availableAmountInDays: number | undefined;
    availableAmountInMinutes: number | undefined;
};

export const ConfirmNegativeBalanceDialog: FC<ConfirmNegativeBalanceDialogProps> = ({
    onSave,
    onClose,
    leaveRequestUnitType,
    leaveType,
    availableAmountInDays = 0,
    availableAmountInMinutes = 0,
}) => {
    const { t } = useTranslation();

    const handleClose = () => {
        onClose();
    };

    const handleSave = () => {
        onSave();
    };

    return (
        <DialogContainer
            open={true}
            onClose={handleClose}
            title={t('confirm_negative_balance_dialog.title')}
            onSave={handleSave}
            saveButtonText={t('confirm_negative_balance_dialog.confirm')}
            cancelButtonText={t('confirm_negative_balance_dialog.cancel')}
            cancelButtonColor={'inherit'}
        >
            <Typography>
                {t('confirm_negative_balance_dialog.message', {
                    duration: getLeaveRequestBalanceAsString(
                        leaveRequestUnitType,
                        leaveType,
                        availableAmountInDays,
                        availableAmountInMinutes,
                        leaveType?.roundingType,
                    ),
                })}
            </Typography>
        </DialogContainer>
    );
};
