import { FieldNumber } from '@/components/form/field-number/FieldNumber';
import { FieldSwitch } from '@/components/form/field-switch/FieldSwitch';
import { TimesheetSettingForm } from '@/page/setting/time-management/time-management-setting-form/TimeManagementSettingForm';
import { Collapse, FormControlLabel, formHelperTextClasses, Paper, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FieldTime } from '@/components/form/field-time/FieldTime';
import { TimesheetMode } from '@/domain/timesheet-setting/TimesheetSetting.model';

export const TimeManagementTimeClock: FC = () => {
    const { t } = useTranslation();
    const { watch } = useFormContext<TimesheetSettingForm>();

    const timesheetMode = watch('timesheetMode');
    const isSimplified = timesheetMode === TimesheetMode.SIMPLIFIED;

    return (
        <Stack component={Paper} p={2} gap={2}>
            <Typography variant={'h1'}>{t('time_management_settings_page.time_management_configuration.timeclock')}</Typography>

            {isSimplified ? <TimeManagementTimeClockSimplified /> : <TimeManagementTimeClockNormal />}
        </Stack>
    );
};

const TimeManagementTimeClockSimplified: FC = () => {
    const { t } = useTranslation();
    const { control } = useFormContext<TimesheetSettingForm>();

    return (
        <Stack direction={'row'} gap={1} alignItems={'center'}>
            <FormControlLabel
                label={t('time_management_settings_page.time_management_configuration.allow_clock_in_out_on_sunday_and_public_holidays')}
                labelPlacement='end'
                control={<FieldSwitch name='allowClockInOutOnSundayAndPublicHolidays' control={control} />}
                sx={{ mr: 0 }}
            />
        </Stack>
    );
};

const TimeManagementTimeClockNormal: FC = () => {
    const { t } = useTranslation();
    const { control, watch } = useFormContext<TimesheetSettingForm>();

    const forceSmallBreakDurationInMinutes = watch('forceSmallBreakDurationInMinutes');
    const forceBigBreakDurationInMinutes = watch('forceBigBreakDurationInMinutes');
    const isForceBreakToBeTakenEnabled = watch('isForceBreakToBeTakenEnabled');
    const isPaidBreaksSundayPublicHolidays = watch('paidBreaksSundayPublicHolidays');
    const isPaidBreaksSaturday = watch('paidBreaksSaturday');
    const isPaidBreaksEnabled = watch('isPaidBreaksEnabled');
    const disableForceBreakRule1 = forceSmallBreakDurationInMinutes === 0;
    const disableForceBreakRule2 = forceBigBreakDurationInMinutes === 0;

    return (
        <Stack gap={2}>
            <Stack direction={'row'} gap={1} alignItems={'center'}>
                <FormControlLabel
                    label={t('time_management_settings_page.time_management_configuration.mobile_clock_in_out')}
                    labelPlacement='end'
                    control={<FieldSwitch name='mobileClockInOut' control={control} />}
                    sx={{ mr: 0 }}
                />
            </Stack>

            <Stack direction={'row'} gap={1} alignItems={'center'}>
                <FormControlLabel
                    label={t('time_management_settings_page.time_management_configuration.allow_clock_in_out_outside_work_hours')}
                    labelPlacement='end'
                    control={<FieldSwitch name='allowClockInOutOutsideWorkHours' control={control} />}
                    sx={{ mr: 0 }}
                />
            </Stack>

            <Stack direction={'row'} gap={1} alignItems={'center'}>
                <FormControlLabel
                    label={t('time_management_settings_page.time_management_configuration.allow_clock_in_out_on_sunday_and_public_holidays')}
                    labelPlacement='end'
                    control={<FieldSwitch name='allowClockInOutOnSundayAndPublicHolidays' control={control} />}
                    sx={{ mr: 0 }}
                />
            </Stack>

            <Stack direction={'column'}>
                <Stack direction={'row'} gap={1} alignItems={'center'}>
                    <FormControlLabel
                        label={t('time_management_settings_page.time_management_configuration.force_break_clock_in_out')}
                        labelPlacement='end'
                        control={<FieldSwitch name='forceBreakClockInOut' control={control} />}
                        sx={{ mr: 0 }}
                    />
                    {watch('forceBreakClockInOut') && (
                        <Typography variant={'body1'}>{t('time_management_settings_page.time_management_configuration.of')}</Typography>
                    )}
                </Stack>

                <Collapse in={watch('forceBreakClockInOut')}>
                    <Stack gap={2} paddingTop={2}>
                        <Stack direction={'row'} gap={1} alignItems={'baseline'}>
                            <Typography width={120} variant={'body1bold'}>
                                {t('time_management_settings_page.time_management_configuration.rule', { ruleNumber: 1 })}
                            </Typography>

                            <FieldNumber name='forceSmallBreakDurationInMinutes' control={control} min={0} step={5} sx={fieldNumberSX} />

                            <Typography variant={'body1'}>{t('time_management_settings_page.time_management_configuration.minutes_after')}</Typography>

                            <FieldNumber
                                name='forceSmallBreakAfterXHours'
                                control={control}
                                min={0}
                                step={0.5}
                                sx={fieldNumberSX}
                                disabled={disableForceBreakRule1}
                                precision={2}
                            />

                            <Typography variant={'body1'}>{t('time_management_settings_page.time_management_configuration.hours_work')}</Typography>
                        </Stack>
                        <Stack direction={'row'} gap={1} alignItems={'baseline'}>
                            <Typography width={120} variant={'body1bold'}>
                                {t('time_management_settings_page.time_management_configuration.rule', { ruleNumber: 2 })}
                            </Typography>

                            <FieldNumber name='forceBigBreakDurationInMinutes' control={control} min={0} step={5} sx={fieldNumberSX} />

                            <Typography variant={'body1'}>{t('time_management_settings_page.time_management_configuration.minutes_after')}</Typography>

                            <FieldNumber
                                name='forceBigBreakAfterXHours'
                                control={control}
                                min={0}
                                step={0.5}
                                sx={fieldNumberSX}
                                disabled={disableForceBreakRule2}
                                precision={2}
                            />

                            <Typography variant={'body1'}>{t('time_management_settings_page.time_management_configuration.hours_work')}</Typography>
                        </Stack>
                        <Stack direction={'row'} gap={1} alignItems={'center'}>
                            <FormControlLabel
                                label={
                                    isForceBreakToBeTakenEnabled
                                        ? t('time_management_settings_page.time_management_configuration.force_break_to_be_taken_at_on')
                                        : t('time_management_settings_page.time_management_configuration.force_break_to_be_taken_at_off')
                                }
                                labelPlacement='end'
                                control={<FieldSwitch name='isForceBreakToBeTakenEnabled' control={control} />}
                                sx={{ mr: 0, pl: '125px' }}
                            />
                            <Collapse in={isForceBreakToBeTakenEnabled}>
                                <Stack direction={'row'} gap={1} alignItems={'center'}>
                                    <Typography variant={'body1'}>{t('general.from')}</Typography>
                                    <FieldTime name={'forceBreakToBeTakenFrom'} control={control} sx={fieldTimeSX} />
                                    <Typography variant={'body1'}>{t('general.to')}</Typography>
                                    <FieldTime name={'forceBreakToBeTakenTo'} control={control} sx={fieldTimeSX} />
                                </Stack>
                            </Collapse>
                        </Stack>
                    </Stack>
                </Collapse>
            </Stack>

            <Stack direction={'row'} gap={1} alignItems={'center'}>
                <FormControlLabel
                    label={
                        isPaidBreaksSundayPublicHolidays
                            ? t('time_management_settings_page.time_management_configuration.paid_breaks_sunday_public_holidays_on')
                            : t('time_management_settings_page.time_management_configuration.paid_breaks_sunday_public_holidays_off')
                    }
                    labelPlacement='end'
                    control={<FieldSwitch name='paidBreaksSundayPublicHolidays' control={control} />}
                    sx={{ mr: 0 }}
                />
                {isPaidBreaksSundayPublicHolidays && (
                    <TimeManagementMaximumDuration maxDurationName={'paidBreaksSundayPublicHolidaysMaximumDurationInMinutes'} />
                )}
            </Stack>

            <Stack direction={'row'} gap={1} alignItems={'center'}>
                <FormControlLabel
                    label={
                        isPaidBreaksSaturday
                            ? t('time_management_settings_page.time_management_configuration.paid_breaks_saturday_on')
                            : t('time_management_settings_page.time_management_configuration.paid_breaks_saturday_off')
                    }
                    labelPlacement='end'
                    control={<FieldSwitch name='paidBreaksSaturday' control={control} />}
                    sx={{ mr: 0 }}
                />
                {isPaidBreaksSaturday && <TimeManagementMaximumDuration maxDurationName={'paidBreaksSaturdayMaximumDurationInMinutes'} />}
            </Stack>

            <Stack direction={'column'} gap={1}>
                <Stack direction={'row'} gap={1} alignItems={'center'}>
                    <FormControlLabel
                        label={
                            isPaidBreaksEnabled
                                ? t('time_management_settings_page.time_management_configuration.paid_breaks_on')
                                : t('time_management_settings_page.time_management_configuration.paid_breaks_off')
                        }
                        labelPlacement='end'
                        control={<FieldSwitch name='isPaidBreaksEnabled' control={control} />}
                        sx={{ mr: 0 }}
                    />
                </Stack>

                <Collapse in={isPaidBreaksEnabled}>
                    <Stack direction={'column'} gap={1} paddingTop={1}>
                        <TimeManagementTimeClockForceBreakRules
                            ruleName={t('time_management_settings_page.time_management_configuration.rule_force_break', { ruleNumber: 1 })}
                            ruleFromName={'rule1PaidBreaksFrom'}
                            ruleToName={'rule1PaidBreaksTo'}
                            ruleMaxDurationName={'rule1PaidBreaksMaximumDurationInMinutes'}
                        />
                        <TimeManagementTimeClockForceBreakRules
                            ruleName={t('time_management_settings_page.time_management_configuration.rule_force_break', { ruleNumber: 2 })}
                            ruleFromName={'rule2PaidBreaksFrom'}
                            ruleToName={'rule2PaidBreaksTo'}
                            ruleMaxDurationName={'rule2PaidBreaksMaximumDurationInMinutes'}
                        />
                    </Stack>
                </Collapse>
            </Stack>
        </Stack>
    );
};

type TimeManagementTimeClockForceBreakRulesProps = {
    ruleName: string;
    ruleFromName: 'rule1PaidBreaksFrom' | 'rule2PaidBreaksFrom';
    ruleToName: 'rule1PaidBreaksTo' | 'rule2PaidBreaksTo';
    ruleMaxDurationName: 'rule1PaidBreaksMaximumDurationInMinutes' | 'rule2PaidBreaksMaximumDurationInMinutes';
};

const TimeManagementTimeClockForceBreakRules: FC<TimeManagementTimeClockForceBreakRulesProps> = ({
    ruleFromName,
    ruleName,
    ruleToName,
    ruleMaxDurationName,
}) => {
    const { t } = useTranslation();
    const { control, watch } = useFormContext<TimesheetSettingForm>();

    const isRuleActive = watch(ruleMaxDurationName) > 0;

    return (
        <Stack direction={'row'} gap={1} alignItems={'baseline'}>
            <Typography width={120} variant={'body1bold'}>
                {ruleName}
            </Typography>
            <Typography variant={'body1'}>{t('general.from')}</Typography>
            <FieldTime name={ruleFromName} control={control} disabled={!isRuleActive} sx={fieldTimeSX} />
            <Typography variant={'body1'}>{t('general.to')}</Typography>
            <FieldTime name={ruleToName} control={control} disabled={!isRuleActive} sx={fieldTimeSX} />
            <TimeManagementMaximumDuration maxDurationName={ruleMaxDurationName} />
        </Stack>
    );
};

type TimeManagementMaximumDurationProps = {
    maxDurationName:
        | 'rule1PaidBreaksMaximumDurationInMinutes'
        | 'rule2PaidBreaksMaximumDurationInMinutes'
        | 'paidBreaksSundayPublicHolidaysMaximumDurationInMinutes'
        | 'paidBreaksSaturdayMaximumDurationInMinutes';
};

const TimeManagementMaximumDuration: FC<TimeManagementMaximumDurationProps> = ({ maxDurationName }) => {
    const { t } = useTranslation();
    const { control } = useFormContext<TimesheetSettingForm>();

    return (
        <>
            <Typography variant={'body1'}>{t('time_management_settings_page.time_management_configuration.maximum')}</Typography>

            <FieldNumber name={maxDurationName} control={control} min={0} step={5} sx={fieldNumberSX} defaultValue={0} precision={2} />

            <Typography variant={'body1'}>{t('time_management_settings_page.time_management_configuration.minutes')}</Typography>
        </>
    );
};

const fieldTimeSX = {
    width: '8em',
    // has is newly supported by major browsers
    // in case of not supported, the error message will be displayed in a small width
    [`&:has(> .${formHelperTextClasses.root})`]: {
        width: 'fit-content',
    },
};

const fieldNumberSX = {
    width: '8em',
    [`& .${formHelperTextClasses.root}`]: {
        whiteSpace: 'nowrap',
    },
};
