import { RealmFeaturesType } from '@/domain/realm/Realm.model';
import { hasRealmFeatureEnabled } from '@/domain/realm/Realm.service';
import { useAppSelector } from '@/stores/store';

/**
 * Checks if a specific feature is enabled in the given realm.
 *
 * @param {RealmFeaturesType} feature - The feature to check for.
 * @returns {boolean} - Returns true if the feature is enabled, otherwise false.
 *
 */
export const useRealmFeatureEnabled = (feature: RealmFeaturesType): boolean => {
    const realm = useAppSelector(state => state.ui.currentRealm);

    return hasRealmFeatureEnabled(realm?.realmFeatures, feature);
};
