import { Paper, Skeleton, Stack, Tab, Tabs } from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { useAppDispatch, useAppSelector } from '@/stores/store';
import { getCurrentEmployee } from '@/domain/employee/Employee.service';
import { employeeAuthenticated } from '@/stores/reducers/currentEmployeeSlice';
import { getProfileTabByLocation, getProfileTabItems, PROFILE_TABS } from '@/page/employee-profile/employeeProfile.util';

export const EmployeeProfileTabs: FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const params = useParams();
    const currentEmployee = useAppSelector(state => state.currentEmployee.employee);
    const employeeId = params?.employeeId ? Number(params?.employeeId) : currentEmployee?.id;
    const policies = useAppSelector(state => state.currentEmployee.grantedPolicies);
    const realm = useAppSelector(state => state.ui.currentRealm);
    const [activeProfileTab, setActiveProfileTab] = useState<PROFILE_TABS>('personal-info');

    /**
     * Fetches the current employee and dispatches the granted policies and permission groups.
     * This prevents incorrect behavior when users navigate without refreshing the page.
     * For instance, if we update a permission and then navigate to the profile page.
     */
    const fetchCurrentEmployee = useCallback(async () => {
        try {
            const data = await getCurrentEmployee();
            dispatch(employeeAuthenticated(data));
        } catch (error) {
            console.error(error);
        }
    }, [dispatch]);

    useEffect(() => {
        fetchCurrentEmployee();
    }, [fetchCurrentEmployee]);

    if (!realm) {
        throw new Error('Realm is not defined');
    }

    if (!employeeId) {
        throw new Error('Employee is not defined');
    }

    const isCurrentEmployee = currentEmployee?.id === employeeId;
    const profileTabsItems = getProfileTabItems(employeeId, realm.realmFeatures, policies);

    useEffect(() => {
        const activeTab = getProfileTabByLocation(location);
        if (activeTab) {
            setActiveProfileTab(activeTab);
        }
    }, [location]);

    return (
        <StateHandler loadingComponent={<TabsSkeleton />} isLoading={!realm} isError={false} error={undefined}>
            <Paper>
                {policies && (
                    <Tabs
                        value={activeProfileTab}
                        variant='scrollable'
                        scrollButtons='auto'
                        onChange={(_, newValue) => {
                            setActiveProfileTab(newValue);

                            if (isCurrentEmployee) {
                                navigate(`/profile/${newValue}`);
                            } else {
                                navigate(`/profile/${employeeId}/${newValue}`);
                            }
                        }}
                    >
                        {profileTabsItems.map(item => (
                            <Tab key={item.path} value={item.path} label={t(item.label)} />
                        ))}
                    </Tabs>
                )}
            </Paper>
        </StateHandler>
    );
};

export const TabsSkeleton: FC = () => {
    return (
        <Paper sx={{ minHeight: 48 }}>
            <Stack direction='row' alignItems={'center'} height={'100%'} gap={2} pl={2}>
                <Skeleton variant='rounded' width={160} height={24} />
                <Skeleton variant='rounded' width={120} height={24} />
                <Skeleton variant='rounded' width={160} height={24} />
                <Skeleton variant='rounded' width={120} height={24} />
                <Skeleton variant='rounded' width={160} height={24} />
                <Skeleton variant='rounded' width={120} height={24} />
            </Stack>
        </Paper>
    );
};
