import i18next from 'i18next';
import * as yup from 'yup';
import { UserLanguage } from '@/utils/language.util';
import { getLabelFormSchema } from '@/domain/label/Label.schema';

export const getReviewSkillSettingFormSchema = (translationLanguage?: UserLanguage) => {
    return yup.object().shape({
        name: getLabelFormSchema(translationLanguage),
        description: getLabelFormSchema(),
        categoryId: yup.number().required(),
        allRolesSelected: yup.boolean().default(false),
        availableForRoles: yup
            .array()
            .of(yup.number().required())
            .default([])
            .when('allRolesSelected', {
                is: false,
                then: schema => schema.min(1, i18next.t('reviews_settings_page.skill_form.missing_roles')).required(),
            }),
    });
};

export type ReviewSkillSettingFormValues = yup.InferType<ReturnType<typeof getReviewSkillSettingFormSchema>>;
