import { Button, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { DialogContainer } from '@/components/dialog-container/DialogContainer';
import { EmployeeDialogHeader } from '../employee-dialog-header/EmployeeDialogHeader';
import { EmployeeReview } from '@/domain/employee-review/EmployeeReview.model';
import { ViewIcon as VisibilityIcon } from 'hugeicons-react';

type EmployeeReviewPreviewDialogProps = {
    open: boolean;
    onClose: () => void;
    activeReview: EmployeeReview;
};

export const EmployeeReviewPreviewDialog: FC<EmployeeReviewPreviewDialogProps> = ({ open, onClose, activeReview }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const onPreview = (contributorType: string) => {
        navigate(`/reviews/${activeReview.id}/feedbacks/preview?role=${contributorType}`);
    };

    return (
        <DialogContainer
            open={open}
            onSave={onClose}
            onClose={onClose}
            title={t('reviews.manage_reviews.update_review')}
            isActionButtonDisabled={true}
            customHeader={<EmployeeDialogHeader employee={activeReview?.employee} />}
        >
            <Stack gap={2}>
                <Typography variant='body2'>{t('reviews.manage_reviews.preview_review_template_for')}</Typography>
                <Stack direction='row'>
                    <Button variant='text' startIcon={<VisibilityIcon />} onClick={() => onPreview('SELF')} sx={{ px: 1.5, mr: 2 }}>
                        {t('reviews.contributor_types.self')}
                    </Button>
                    <Button variant='text' startIcon={<VisibilityIcon />} onClick={() => onPreview('MANAGER')} sx={{ px: 1.5, mr: 2 }}>
                        {t('reviews.contributor_types.manager')}
                    </Button>
                    {activeReview?.review?.contributorTypes?.includes('PEER') && (
                        <Button variant='text' startIcon={<VisibilityIcon />} onClick={() => onPreview('PEER')} sx={{ px: 1.5, mr: 2 }}>
                            {t('reviews.contributor_types.peer')}
                        </Button>
                    )}
                    {activeReview?.review?.contributorTypes?.includes('UPWARD') && (
                        <Button variant='text' startIcon={<VisibilityIcon />} onClick={() => onPreview('UPWARD')} sx={{ px: 1.5, mr: 2 }}>
                            {t('reviews.contributor_types.upward')}
                        </Button>
                    )}
                </Stack>
            </Stack>
        </DialogContainer>
    );
};
