import { EmploymentStatus } from '@/domain/employment/Employment.model';
import { Employee } from '@/domain/employee/Employee.model';
import { useGetEmployees } from '@/hooks/employee/Employee.hook';
import { getNull } from '@/utils/object.util';
import { Autocomplete, TextField } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { filterEmployeesByAdvancedSearch } from '@/domain/employee/Employee.service';

export const EmployeeSearch: FC<{ employeeId?: number }> = ({ employeeId }) => {
    const navigate = useNavigate();

    const { data: employeesOptions = [], isLoading: isLoadingEmployees } = useGetEmployees({
        statuses: [EmploymentStatus.EMPLOYED, EmploymentStatus.HIRED, EmploymentStatus.ON_LONG_LEAVE],
    });

    const { t } = useTranslation();

    const [inputValue, setInputValue] = useState('');

    return (
        <Autocomplete
            value={employeesOptions.find(option => option.id === employeeId) ?? (getNull() as unknown as Employee)}
            options={employeesOptions}
            getOptionKey={option => option.id}
            getOptionLabel={option => `${option.displayName} ${option.employeeCode ? '(' + option.employeeCode + ')' : ''}`}
            filterOptions={(options, params) => {
                return filterEmployeesByAdvancedSearch(options, params.inputValue);
            }}
            isOptionEqualToValue={option => {
                return option.id === employeeId;
            }}
            loading={isLoadingEmployees}
            disableClearable={true}
            autoHighlight
            onChange={(_e, user) => {
                if (user?.id) {
                    navigate(`/profile/${user.id}/personal-info`);
                }
            }}
            inputValue={inputValue}
            onInputChange={(_e, value, reason) => {
                if (reason === 'input') {
                    setInputValue(value);
                }
            }}
            renderInput={({ inputProps, ...restParams }) => {
                return (
                    <TextField
                        {...restParams}
                        placeholder={t('header.user_search')}
                        slotProps={{ htmlInput: { ...inputProps, 'aria-label': t('header.user_search') } }}
                    />
                );
            }}
            onBlur={() => {
                setInputValue('');
            }}
            blurOnSelect={true}
            size='small'
            popupIcon={''}
            ListboxProps={{ style: { maxHeight: '15rem' } }}
            sx={{
                backgroundColor: 'white',
                borderRadius: '4px',
                width: 210,
            }}
        />
    );
};
