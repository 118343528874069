import { ReactElement } from 'react';
import { Box, CircularProgress, Paper, Stack, Typography } from '@mui/material';
import { SectionField } from '../types';
import Button from '@mui/material/Button';
import { EditableSectionFieldComponent } from '../SectionFieldComponent/EditableSectionFieldComponent';
import { FieldValues, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { AnyObjectSchema } from 'yup';

export type EditableStackSectionProps<T = Record<string, unknown>> = {
    sectionTitle: string;
    fields: SectionField[];
    onSave: (formValues: T) => void;
    onCancel: () => void;
    schema: AnyObjectSchema;
};

export function EditableStackSection<T extends FieldValues>({
    sectionTitle,
    fields,
    onSave,
    onCancel,
    schema,
}: Readonly<EditableStackSectionProps<T>>): ReactElement {
    const { t } = useTranslation();

    const formMethods = useForm<T>({
        mode: 'onBlur',
        resolver: yupResolver(schema),
    });

    const onSubmit: SubmitHandler<T> = data => {
        onSave(data);
    };

    if (!sectionTitle || !fields) {
        return (
            <Stack justifyContent='center' alignItems='center' flex={1}>
                <CircularProgress />
            </Stack>
        );
    }

    const { formState } = formMethods;

    return (
        <form onSubmit={formMethods.handleSubmit(onSubmit, console.error)}>
            <FormProvider {...formMethods}>
                <Stack component={Paper} p={3} justifyContent='center' spacing={1} flex={1}>
                    <Stack direction='row' justifyContent='space-between' alignItems='flex-start' spacing={1} flex='1'>
                        <Typography variant='h1'>{sectionTitle}</Typography>
                        <Stack direction='row' gap={1}>
                            <Button color='inherit' onClick={onCancel} variant='text'>
                                {t('general.cancel')}
                            </Button>
                            <Button type='submit' variant='text' disabled={!formState.isDirty}>
                                {t('general.save')}
                            </Button>
                        </Stack>
                    </Stack>
                    {!!fields?.length &&
                        fields.map(field => {
                            return (
                                <Stack
                                    key={field.title}
                                    flex={1}
                                    alignItems='flex-start'
                                    direction={{ xs: 'column', sm: 'row' }}
                                    columnGap={3}
                                    rowGap={1}
                                    minHeight={36}
                                >
                                    {/* When there is an error, the error field misaligned the typography with the EditableSectionFieldComponent  */}
                                    {/* So we add a marginBottom to the typography to align it with the EditableSectionFieldComponent */}
                                    {/* We also add a paddingTop to the typography to align it with the EditableSectionFieldComponent (to cover the document edge case design) */}
                                    <Typography
                                        marginBottom={(formMethods.formState.errors as Record<string, unknown>)?.[field.formValueName ?? ''] ? 2.9 : 0}
                                        paddingTop={1}
                                        variant='body1bold'
                                        noWrap
                                        width={{ sm: 200 }}
                                        title={field.title}
                                    >
                                        {field.title}
                                        {field.required ? '*' : ''}
                                    </Typography>

                                    <Box display={'flex'} flex={1} width='100%'>
                                        <EditableSectionFieldComponent field={field} />
                                    </Box>
                                </Stack>
                            );
                        })}
                </Stack>
            </FormProvider>
        </form>
    );
}
