import { AgGridWrapper, RogerColDef } from '@/components/ag-grid-wrapper/AgGridWrapper';
import { useAgGridWrapper } from '@/components/ag-grid-wrapper/useAgGridWrapper';
import { DatatableAdditionalAction } from '@/components/datatable-additional-action/DatatableAdditionalAction';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { DurationUnit } from '@/i18n/i18n';
import { MonthlyTimesheetReport, TimesheetSearch } from '@/domain/timesheet/Timesheet.model';
import { useGetEmployeeMonthlyTimesheets } from '@/hooks/timesheet/Timesheet.hook';
import { MissingCount } from '@/page/timesheet/missing-count/MissingCount';
import { formatInDefaultDate, getEndOfYear, MONTHS } from '@/utils/datetime.util';

import { Paper, Stack } from '@mui/material';
import i18next from 'i18next';
import { FC, useCallback, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { BalanceTimesheetFilter, useBalanceTimesheetPageFilters } from '@/hooks/timesheet/BalanceTimesheetPageFilters.hook';
import { useFiltersStorage } from '@/components/filters-bar/useFiltersStorage';
import { FiltersBar } from '@/components/filters-bar/FiltersBar';
import { getFilterValueIdsByKey, getSelectFilterStringValuesByKey } from '@/components/filters-bar/FiltersBar.util';
import { Employment } from '@/domain/employment/Employment.model';
import { getLabelTranslation } from '@/utils/language.util';
import { useSearchTimesheetSettings } from '@/page/setting/time-management/TimesheetSettings.hook';
import { TimesheetSetting } from '@/domain/timesheet-setting/TimesheetSetting.model';
import { getCycleStartDate, isDifferentLifeCycleStartMonth } from '@/domain/timesheet-setting/TimesheetSetting.service';
import { LeaveBalanceFilter } from '@/hooks/leave-type-history/LeaveBalancePageFilters.hook';
import { getCycleDates } from '@/domain/leave-type/LeaveType.service';

export const TimesheetsBalancePage: FC = () => {
    const { data: timesheetSettings = [], isLoading: isLoading, isError: isError, error: error } = useSearchTimesheetSettings();
    const defaultTimesheetSetting = timesheetSettings?.[0];

    return (
        <StateHandler isLoading={isLoading} isError={isError} error={error} isEmpty={!defaultTimesheetSetting}>
            <TimesheetsBalanceTable timesheetSettings={timesheetSettings} defaultTimesheetSetting={defaultTimesheetSetting} />
        </StateHandler>
    );
};

type TimesheetsBalanceTableProps = {
    timesheetSettings: TimesheetSetting[];
    defaultTimesheetSetting: TimesheetSetting;
};

const TimesheetsBalanceTable: FC<TimesheetsBalanceTableProps> = ({ timesheetSettings, defaultTimesheetSetting }) => {
    const haveDifferentCycleStartMonth = isDifferentLifeCycleStartMonth(timesheetSettings);
    // FILTERS
    const { filters: availableFilters } = useBalanceTimesheetPageFilters(timesheetSettings, defaultTimesheetSetting, haveDifferentCycleStartMonth);

    const [filters, setFilters] = useFiltersStorage('timesheet-balance-filters', availableFilters);

    const updateFilters = useCallback(() => {
        filters.forEach(filter => {
            if (filter.key === 'endDate') {
                const cycleMonth = (getSelectFilterStringValuesByKey(filters, 'startCycleMonth')?.[0] ?? MONTHS.JANUARY) as MONTHS;
                const endDate = getEndDate(filters);
                filter.filterName = formatInDefaultDate(getCycleStartDate(endDate, cycleMonth));
            }
        });
        setFilters(filters);
    }, [filters, setFilters]);

    //only run this effect on mount after the filters are loaded from storage
    useLayoutEffect(() => {
        updateFilters();
    }, [updateFilters]);

    const mapFiltersToTimesheetSearchRequest = (filters: BalanceTimesheetFilter[]) => {
        //the filters will have an undefined date, and we need to always have an endDate (string Date or undefined)
        const endDate = getEndDate(filters);
        const cycleMonth = (getSelectFilterStringValuesByKey(filters, 'startCycleMonth')?.[0] ?? MONTHS.JANUARY) as MONTHS;
        const search: TimesheetSearch = {
            startDate: getCycleStartDate(endDate, cycleMonth),
            endDate: endDate,
            locationIds: getFilterValueIdsByKey(filters, 'LOCATION_IDS'),
            departmentIds: getFilterValueIdsByKey(filters, 'DEPARTMENT_IDS'),
            jobIds: getFilterValueIdsByKey(filters, 'JOB_IDS'),
            managerIds: getFilterValueIdsByKey(filters, 'MANAGER_IDS'),
            cycleMonth: haveDifferentCycleStartMonth ? cycleMonth : undefined,
        };
        return search;
    };

    const setFilterConditions = (newFilters: LeaveBalanceFilter[]) => {
        const copyFilters = [...newFilters];

        const newCycleMonth = (getSelectFilterStringValuesByKey(newFilters, 'startCycleMonth')?.[0] ?? MONTHS.JANUARY) as MONTHS;
        const oldCycleMonth = (getSelectFilterStringValuesByKey(filters, 'startCycleMonth')?.[0] ?? MONTHS.JANUARY) as MONTHS;
        //if the cycle month changes, we need to update the endDate so that we have the current cycle and not a future one
        const updateEndDate = newCycleMonth !== oldCycleMonth;

        copyFilters.forEach(filter => {
            if (filter.key === 'endDate') {
                const { startDate, endDate } = getCycleDates(getEndDate(copyFilters), newCycleMonth, updateEndDate);
                filter.filterName = formatInDefaultDate(startDate);
                if (updateEndDate) {
                    filter.value = endDate;
                }
            }
        });

        setFilters(copyFilters);
    };

    const {
        data: monthlyTimesheetReports = [],
        isLoading,
        isFetching = false,
        isError,
        error,
    } = useGetEmployeeMonthlyTimesheets({
        timesheetSearch: mapFiltersToTimesheetSearchRequest(filters),
        options: { enabled: !!filters.length },
    });

    const navigate = useNavigate();
    const { gridRef, setGridRef, quickFilter } = useAgGridWrapper<MonthlyTimesheetReport>();

    const columnDefs = getColumnDefs();

    const onBtnExport = () => {
        gridRef.current?.api?.exportDataAsExcel({
            allColumns: true,
        });
    };

    const goToEmployeeTimesheets = (employeeId: number) => navigate(`/profile/${employeeId}/timesheets`);

    return (
        <Stack gap={2} flex={1}>
            <StateHandler isLoading={isLoading} isError={isError} error={error}>
                <Stack flexGrow={1} gap={2}>
                    <Stack component={Paper} p={1} direction={'row'} spacing={2} alignItems={'center'} justifyContent={'space-between'}>
                        <FiltersBar filters={filters} onFiltersChange={setFilterConditions} />
                        <DatatableAdditionalAction quickFilter={quickFilter} onBtnExport={onBtnExport} disabled={isFetching} />
                    </Stack>
                    <StateHandler isLoading={isFetching} isError={false} error={error}>
                        <Stack component={Paper} flex={1}>
                            <AgGridWrapper<MonthlyTimesheetReport>
                                initRef={setGridRef}
                                onRowClicked={({ data }) => (data?.employee.id ? goToEmployeeTimesheets(data.employee.id) : undefined)}
                                rowData={monthlyTimesheetReports}
                                columnDefs={columnDefs}
                            />
                        </Stack>
                    </StateHandler>
                </Stack>
            </StateHandler>
        </Stack>
    );
};

const getColumnDefs = (): RogerColDef<MonthlyTimesheetReport>[] => [
    {
        field: 'employee.email',
        headerName: 'Email',
        hide: true,
    },
    {
        field: 'employee',
        type: 'employee',
        headerName: i18next.t('general.employee'),
    },

    {
        field: 'employee.currentEmployments',
        colId: 'jobTitle',
        headerName: i18next.t('timesheets.table_headers.job_title'),
        valueFormatter: ({ value }: { value: Employment[] }) => value?.flatMap(employment => getLabelTranslation(employment.job.name)).join(', '),
    },
    {
        field: 'employee.currentEmployments',
        colId: 'location',
        headerName: i18next.t('timesheets.table_headers.main_location'),
        valueFormatter: ({ value }: { value: Employment[] }) => value?.flatMap(employment => employment.location.name).join(', '),
    },
    {
        field: 'employee.currentEmployments',
        colId: 'managers',
        headerName: i18next.t('timesheets.table_headers.manager'),
        valueGetter: ({ data }) => data?.employee.currentEmployments?.flatMap(employment => employment.managers),
        type: 'stackedAvatars',
    },
    {
        field: 'totalWorkedCount',
        headerName: i18next.t('timesheets.table_headers.totalWorkedCount'),
        type: 'minutesToHours',
    },
    {
        field: 'totalLeaveCount',
        headerName: i18next.t('timesheets.table_headers.totalLeaveCount'),
        valueGetter: data => ((data.data?.totalForecastedLeaveCount ?? 0) + (data.data?.totalPublicHolidayCount ?? 0)) / 60,
        valueFormatter: params =>
            params.value
                ? i18next.t('duration.formatDuration', {
                      duration: params.value,
                      unit: DurationUnit.HOURS,
                  })
                : '-',
    },
    {
        field: 'totalContractCount',
        headerName: i18next.t('timesheets.table_headers.totalContractCount'),
        valueGetter: data => ((data.data?.totalContractCount ?? 0) + (data.data?.totalPublicHolidayCount ?? 0)) / 60,
        valueFormatter: params =>
            i18next.t('duration.formatDuration', {
                duration: params.value ?? 0,
                unit: DurationUnit.HOURS,
            }),
    },
    {
        field: 'previousCarryover',
        headerName: i18next.t('timesheets.table_headers.carryoverPreviousYear'),
        type: 'minutesToHours',
    },
    {
        field: 'totalForecastedDifference',
        headerName: i18next.t('timesheets.table_headers.totalDifference'),
        type: 'minutesToHours',
    },
    {
        field: 'totalForecastedCompensationCount',
        headerName: i18next.t('timesheets.table_headers.totalCompensation'),
        valueGetter: data => (data.data ? -(data.data.totalForecastedCompensationCount ?? 0) : 0) / 60,
        valueFormatter: params =>
            params.value
                ? i18next.t('duration.formatDuration', {
                      duration: params.value,
                      unit: DurationUnit.HOURS,
                  })
                : '-',
    },
    {
        field: 'totalForecastedPaymentCount',
        headerName: i18next.t('timesheets.payments'),
        valueGetter: data => (data.data?.totalPaymentCount ? -data.data?.totalPaymentCount : 0) / 60,
        valueFormatter: params =>
            params.value
                ? i18next.t('duration.formatDuration', {
                      duration: params.value,
                      unit: DurationUnit.HOURS,
                  })
                : '-',
    },
    {
        field: 'totalForecastedAdjustmentCount',
        headerName: i18next.t('timesheets.adjustments'),
        type: 'minutesToHours',
    },
    {
        field: 'forecastedBalance',
        headerName: i18next.t('timesheets.table_headers.balance'),
        type: 'minutesToHours',
    },
    {
        headerName: i18next.t('timesheets.table_headers.missing'),
        field: 'missingCount',
        cellClass: ['display-flex'],
        cellRenderer: ({ data }) => <MissingCount missingCount={data?.missingCount} />,
    },
    {
        field: 'employee.employeeCode',
        headerName: i18next.t('payroll.id'),
        hide: true,
    },
];

const getEndDate = (filters: BalanceTimesheetFilter[]): LocalDate => {
    const filterEndDate = filters.find(filter => filter.key === 'endDate')?.value as LocalDate | undefined;
    return filterEndDate ? filterEndDate : getEndOfYear();
};
