import { ArrowDown01Icon, ArrowUp01Icon } from 'hugeicons-react';
import { RichTreeView } from '@mui/x-tree-view';
import { RichTreeViewProps } from '@mui/x-tree-view/RichTreeView/RichTreeView.types';
import { RecursiveValue } from '@/components/tree-select/TreeSelect';

export type TreeViewItem = {
    id: number;
    label: string;
    children: RecursiveValue[];
    disabled?: boolean;
};

type TreeViewProps<Multiple extends boolean | undefined = undefined> = RichTreeViewProps<RecursiveValue, Multiple>;

export const TreeView = <Multiple extends boolean | undefined = undefined>(props: TreeViewProps<Multiple>): JSX.Element => {
    const { items, ...restProps } = props;
    if (items.length === 0) {
        return <></>;
    }

    return (
        <RichTreeView
            items={items}
            getItemId={item => item.id.toString()}
            {...restProps}
            isItemDisabled={item => Boolean(item?.disabled)}
            slots={{
                expandIcon: props.slots?.expandIcon ?? ArrowDown01Icon,
                collapseIcon: props.slots?.collapseIcon ?? ArrowUp01Icon,
                ...props.slots,
            }}
        />
    );
};
