import { API_BASE_URL, client } from '@/api/common';
import { AxiosResponse } from 'axios';
import {
    TimesheetRecurringAdjustment,
    TimesheetRecurringAdjustmentCreateMutation,
    TimesheetRecurringAdjustmentUpdateMutation,
} from '@/domain/timesheet-recurring-adjustment/TimesheetRecurringAdjustment.model';

const createTimesheetRecurringAdjustment = async (request: TimesheetRecurringAdjustmentCreateMutation): Promise<TimesheetRecurringAdjustment> => {
    const requestDTO = {
        ...request,
        employeeIds: [request.employeeId],
    };

    const url = API_BASE_URL + '/timesheet-recurring-adjustments';
    return (
        await client.post<TimesheetRecurringAdjustmentDTO[], AxiosResponse<TimesheetRecurringAdjustmentDTO[]>, TimesheetRecurringAdjustmentCreateMutationDTO>(
            url,
            requestDTO,
        )
    ).data[0];
};

const updateTimesheetRecurringAdjustment = async (request: TimesheetRecurringAdjustmentUpdateMutation, id: number): Promise<TimesheetRecurringAdjustment> => {
    const url = API_BASE_URL + `/timesheet-recurring-adjustments/${id}`;
    return (
        await client.put<TimesheetRecurringAdjustmentDTO, AxiosResponse<TimesheetRecurringAdjustmentDTO>, TimesheetRecurringAdjustmentUpdateMutationDTO>(
            url,
            request,
        )
    ).data;
};

const deleteTimesheetRecurringAdjustment = async (id: number): Promise<void> => {
    const url = API_BASE_URL + `/timesheet-recurring-adjustments/${id}`;
    return (await client.delete(url)).data;
};

const getTimesheetRecurringAdjustment = async (id: number): Promise<TimesheetRecurringAdjustment> => {
    const url = API_BASE_URL + `/timesheet-recurring-adjustments/${id}`;
    return (await client.get<TimesheetRecurringAdjustmentDTO>(url)).data;
};

export const timesheetRecurringAdjustmentApi = {
    createTimesheetRecurringAdjustment,
    updateTimesheetRecurringAdjustment,
    deleteTimesheetRecurringAdjustment,
    getTimesheetRecurringAdjustment,
};

type TimesheetRecurringAdjustmentDTO = TimesheetRecurringAdjustment;
type TimesheetRecurringAdjustmentUpdateMutationDTO = TimesheetRecurringAdjustmentUpdateMutation;
type TimesheetRecurringAdjustmentCreateMutationDTO = Omit<TimesheetRecurringAdjustmentCreateMutation, 'employeeId'> & {
    employeeIds: number[];
};
