import { Stack } from '@mui/material';
import { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Footer, FooterActions, FooterActionsProps } from '@/page/layout/Footer';
import {
    isEmployeeReviewCancelled,
    isEmployeeReviewClosed,
    isEmployeeReviewEmployeeApproved,
    isEmployeeReviewInputNeeded,
    isEmployeeReviewStarted,
    isEmployeeReviewSubmitted,
} from '@/domain/employee-review/EmployeeReview.service';
import { isReviewClosed } from '@/domain/review/Review.service';
import { EmployeeReviewStatus } from '@/domain/employee-review-feedback/EmployeeReviewFeedback.model';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { useGetEmployeeReview, useGetEmployeeReviewManagerSummary } from '@/hooks/employee-review/EmployeeReview.hook';
import { WizardBreadcrumbs } from '@/components/wizard-breadcrumbs/WizardBreadcrumbs';
import { EmployeeReviewSummaryFeedbackForm } from '@/page/review/employee-review-summary-feedback-form/EmployeeReviewSummaryFeedbackForm';
import { useGetObjectives } from '@/hooks/objective/Objective.hook';

type LoadingButtonProps = FooterActionsProps['actions'][number];

export const EmployeeReviewManagerSummaryFeedbackFormPage: FC = () => {
    const [isCorrectionSummaryMode, setIsCorrectionSummaryMode] = useState(false);
    const { employeeReviewId } = useParams();
    const {
        data: employeeReview,
        isLoading: isEmployeeReviewLoading,
        isError: isEmployeeReviewError,
        error: employeeReviewError,
    } = useGetEmployeeReview(Number(employeeReviewId));
    const {
        data: employeeReviewManagerSummary,
        isLoading: isEmployeeReviewManagerSummaryLoading,
        isError: isEmployeeReviewManagerSummaryError,
        error: employeeReviewManagerSummaryError,
    } = useGetEmployeeReviewManagerSummary(Number(employeeReviewId));

    const employeeId = employeeReview?.employee?.id;

    const {
        data: objectives,
        error: objectivesError,
        isError: isObjectivesError,
        isLoading: objectivesLoading,
        refetch: refetchObjectives,
    } = useGetObjectives(employeeId ? { employeeIds: [employeeId] } : undefined);

    const { t } = useTranslation();
    const navigate = useNavigate();
    const scrollRef = useRef<HTMLDivElement>();

    const readingFeedbackByManager = employeeReview?.status === EmployeeReviewStatus.INPUT_NEEDED;

    const breadcrumbs = readingFeedbackByManager
        ? [t('reviews.review_summary.read_feedback')]
        : [t('reviews.review_summary.discussion'), t('reviews.review_summary.validation')];

    const isFormDisabled = () => {
        return (
            (!employeeReview ||
                isEmployeeReviewInputNeeded(employeeReview.status) ||
                isEmployeeReviewClosed(employeeReview.status) ||
                isEmployeeReviewCancelled(employeeReview.status) ||
                isEmployeeReviewEmployeeApproved(employeeReview.status) ||
                isEmployeeReviewSubmitted(employeeReview.status)) &&
            !isCorrectionSummaryMode
        );
    };

    const getBreadCrumbStep = (employeeReviewStatus: EmployeeReviewStatus | undefined): number => {
        switch (employeeReviewStatus) {
            case EmployeeReviewStatus.DISCUSSION_STARTED:
                return 0;
            case EmployeeReviewStatus.DISCUSSION_SUBMITTED:
                return 1;
            case EmployeeReviewStatus.EMPLOYEE_APPROVED:
                return 1;
            case EmployeeReviewStatus.INPUT_NEEDED:
            case EmployeeReviewStatus.CANCELLED:
            default:
                return 0;
        }
    };

    const getPrimaryActionLabel = (employeeReviewStatus: EmployeeReviewStatus | undefined): string => {
        const validationLabel = t('reviews.review_summary.validation');
        const submitDiscussionLabel = t('reviews.review_summary.submit_discussion');
        const passToValidationLabel = t('reviews.review_summary.pass_to_validation');
        const closeLabel = t('reviews.review_summary.close');

        let validateLabel = '';
        if (employeeReview?.review?.includeValidationStep) {
            validateLabel = passToValidationLabel;
        } else if (employeeReview?.review?.type === 'ONE_SHOT') {
            validateLabel = submitDiscussionLabel;
        } else {
            validateLabel = validationLabel;
        }

        switch (employeeReviewStatus) {
            case EmployeeReviewStatus.DISCUSSION_STARTED:
                return validateLabel;
            case EmployeeReviewStatus.DISCUSSION_SUBMITTED:
                if (isCorrectionSummaryMode) {
                    return validateLabel;
                }
                return closeLabel;
            case EmployeeReviewStatus.EMPLOYEE_APPROVED:
            case EmployeeReviewStatus.INPUT_NEEDED:
            case EmployeeReviewStatus.CANCELLED:
            default:
                return closeLabel;
        }
    };

    const correctSummaryButton: LoadingButtonProps = {
        name: 'correct',
        onClick: () => {
            setIsCorrectionSummaryMode(true);
        },
        children: t('reviews.review_summary.correct_summary'),
        variant: 'text',
    };

    const getPrimaryAction = (): LoadingButtonProps => {
        const primaryActionLabel = getPrimaryActionLabel(employeeReview?.status);
        if (!employeeReview) {
            return {
                onClick: () => {
                    navigate(-1);
                },
                children: primaryActionLabel,
                variant: 'contained',
                name: 'close',
            };
        }

        if (employeeReview?.review && (isReviewClosed(employeeReview?.review) || readingFeedbackByManager) && !isCorrectionSummaryMode) {
            return {
                onClick: () => {
                    navigate(-1);
                },
                children: primaryActionLabel,
                variant: 'contained',
                name: 'close',
            };
        }
        if (isEmployeeReviewStarted(employeeReview.status) || isCorrectionSummaryMode) {
            return {
                type: 'submit',
                form: 'employee-review-summary-feedback-form',
                children: primaryActionLabel,
                variant: 'contained',
                name: 'submit',
            };
        } else {
            return {
                onClick: () => navigate('/reviews/team-reviews'),
                children: primaryActionLabel,
                variant: 'contained',
                name: 'close',
            };
        }
    };

    const secondaryAction: LoadingButtonProps = {
        onClick: () => {
            navigate('/reviews/team-reviews');
        },
        children: t('reviews.write_feedback.continue_later'),
        variant: 'outlined',
        name: 'continue-later',
    };

    const getFooterActions = (): LoadingButtonProps[] => {
        const primaryAction = getPrimaryAction();
        const breadcrumbStep = getBreadCrumbStep(employeeReview?.status);
        const isCorrectionButtonVisible = !!employeeReview?.status && isEmployeeReviewSubmitted(employeeReview?.status) && !isCorrectionSummaryMode;

        if (breadcrumbStep === 1 || readingFeedbackByManager) {
            return isCorrectionButtonVisible ? [correctSummaryButton, primaryAction] : [primaryAction];
        }

        const actions = [secondaryAction, primaryAction];
        return isCorrectionButtonVisible ? [correctSummaryButton, ...actions] : actions;
    };
    return (
        <StateHandler
            isLoading={isEmployeeReviewLoading || isEmployeeReviewManagerSummaryLoading || objectivesLoading}
            isError={isEmployeeReviewError || isEmployeeReviewManagerSummaryError || !employeeId || isObjectivesError}
            error={objectivesError || employeeReviewError || employeeReviewManagerSummaryError}
        >
            <>
                {employeeReviewManagerSummary && employeeReview && (
                    <EmployeeReviewSummaryFeedbackForm
                        employeeReviewManagerSummary={employeeReviewManagerSummary}
                        isFormDisabled={isFormDisabled()}
                        employeeId={Number(employeeId)}
                        employeeReview={employeeReview}
                        scrollRef={scrollRef}
                        objectives={objectives || []}
                        refetchObjectives={refetchObjectives}
                    />
                )}
                <Footer justifyContent='space-between'>
                    {breadcrumbs && <WizardBreadcrumbs steps={breadcrumbs} activeStep={getBreadCrumbStep(employeeReview?.status)} />}

                    <Stack direction={'row'} gap={1}>
                        <FooterActions actions={getFooterActions()} />
                    </Stack>
                </Footer>
            </>
        </StateHandler>
    );
};
