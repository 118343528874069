import { SectionDefinition } from '@/domain/section-setting/Section.model';
import { getLocalDateTestConfig } from '@/utils/datetime.util';

import { isNumberOrEmpty, MAX_NUMBER_VALUE } from '@/utils/math.util';
import { getNull } from '@/utils/object.util';
import { isValidIBAN } from 'ibantools';
import * as yup from 'yup';
import { AnyObject } from 'yup';
import i18next from 'i18next';
import { isValidAvs } from '@/utils/validator.util';

export const getSectionDefinitionSchema = ({ sectionDefinition }: { sectionDefinition: SectionDefinition }): yup.ObjectSchema<AnyObject> => {
    const shape = sectionDefinition.fields.reduce((acc, curr) => {
        const sectionFieldDefinition = curr;

        let fieldSchema;

        switch (sectionFieldDefinition.valueType) {
            case 'STRING':
                fieldSchema = yup.string();
                break;
            case 'CUSTOM_MULTI_LIST_ITEM':
                fieldSchema = yup
                    .array()
                    .nullable()
                    .transform((_, val) => {
                        return val?.length ? val : undefined;
                    });
                break;
            case 'CUSTOM_LIST_ITEM':
                fieldSchema = yup
                    .number()
                    .nullable()
                    .transform((_, val) => {
                        if (val.length === 0) {
                            return undefined;
                        }
                        return val || val === 0 ? Number(val) : undefined;
                    });
                break;
            case 'NUMBER':
                fieldSchema = yup
                    .number()
                    .nullable()
                    .max(MAX_NUMBER_VALUE, i18next.t('general.validations.valid_number_max'))
                    .min(0, i18next.t('general.validations.valid_number_min'))
                    .transform((_, val) => {
                        return isNumberOrEmpty(val);
                    })
                    .typeError(i18next.t('general.validations.not_type_number'));
                break;
            case 'EMPLOYEE':
                fieldSchema = yup
                    .number()
                    .nullable()
                    .transform((_, val) => {
                        return val || val === 0 ? Number(val) : undefined;
                    });
                break;
            case 'DATE':
                fieldSchema = yup.string<LocalDate>().nullable().test(getLocalDateTestConfig());
                break;
            case 'SECTION_FIELD_DOCUMENT':
                fieldSchema = yup
                    .array()
                    .of(
                        yup.object().nullable().default(getNull()).shape({
                            id: yup.number().nullable(),
                            itemId: yup.number().nullable(),
                            url: yup.string().nullable(),
                            key: yup.string().required(),
                            mimetype: yup.string().required(),
                        }),
                    )

                    .nullable()
                    .test({
                        message: i18next.t('general.validations.required'),
                        test: val => {
                            if (sectionFieldDefinition.mandatory) {
                                return !!val && val.length > 0;
                            }
                            return true;
                        },
                    });
                break;
            case 'ENUM':
                fieldSchema = yup.string().nullable();
                break;
            case 'COUNTRY':
                fieldSchema = yup
                    .object()
                    .shape({
                        label: yup.string(),
                        value: yup.string(),
                    })
                    .nullable()
                    .test({
                        message: i18next.t('general.validations.required'),
                        test: val => {
                            if (sectionFieldDefinition.mandatory) {
                                return !!val?.value;
                            }
                            return true;
                        },
                    });
                break;
            case 'IBAN_NUMBER':
                fieldSchema = yup
                    .string()
                    .nullable()
                    .transform((_, val: string) => (val ? val.replace(new RegExp('\\s', 'g'), '') : val))
                    .test({
                        message: i18next.t('general.validations.not_typ_iban_number'),
                        test: val => (!val ? !sectionFieldDefinition.mandatory : isValidIBAN(val)),
                    });
                break;
            case 'AVS_NUMBER':
                fieldSchema = yup
                    .string()
                    .nullable()
                    .test({
                        message: i18next.t('employee.avs_number_not_valid'),
                        test: avsNumber => (avsNumber ? isValidAvs(avsNumber) : true),
                    });
                break;
            case 'EMAIL':
                fieldSchema = yup.string().email();
                break;
            default:
                fieldSchema = yup.string();
                break;
        }

        if (sectionFieldDefinition.mandatory) {
            fieldSchema = fieldSchema.required();
        }

        return { ...acc, [curr.formId]: fieldSchema };
    }, {});

    return yup.object().shape(shape);
};

export type SectionDefinitionFormValues = yup.InferType<ReturnType<typeof getSectionDefinitionSchema>>;
