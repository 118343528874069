import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Stack, Typography } from '@mui/material';
import { SectionFieldDefinitionDraggable } from '@/page/setting/section/SectionFieldDefinitionDraggable';
import { TranslatableLabelTypography } from '@/components/translatable-label-typography/TranslatableLabelTypography';
import { FC, SyntheticEvent, useState } from 'react';
import { DragDropContext, Draggable, DraggableProvided, Droppable, OnDragEndResponder } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

import { SectionDefinition, SectionFieldDefinition, SectionType } from '@/domain/section-setting/Section.model';
import { ArrowDown01Icon, ArrowUp01Icon, DragDropHorizontalIcon } from 'hugeicons-react';
import Chip from '@mui/material/Chip';
import { BasicMenu, BasicMenuItem } from '@/components/basic-menu/BasicMenu';
import i18next from 'i18next';
import { MoreVerticalIcon } from '@/assets/icons/Icons';

type SectionDefinitionDraggableProps = {
    section: SectionDefinition;
    provided: DraggableProvided;
    onDelete: () => void;
    onUpdate: () => void;
    onArchive: () => void;
    onUnarchive: () => void;
    onFieldAdd: () => void;
    onFieldUpdate: (field: SectionFieldDefinition) => void;
    onFieldDelete: (field: SectionFieldDefinition) => void;
    onFieldsOrderChange: (fields: SectionFieldDefinition[]) => void;
    onFieldArchive: (field: SectionFieldDefinition) => void;
    onFieldUnarchive: (field: SectionFieldDefinition) => void;
};

export const SectionDefinitionDraggable: FC<SectionDefinitionDraggableProps> = ({
    section,
    provided,
    onDelete = () => {},
    onUpdate = () => {},
    onArchive = () => {},
    onUnarchive = () => {},
    onFieldAdd = () => {},
    onFieldUpdate = () => {},
    onFieldDelete = () => {},
    onFieldsOrderChange = () => {},
    onFieldArchive = () => {},
    onFieldUnarchive = () => {},
}) => {
    const { t } = useTranslation();

    // We control the expanded state of the accordion ourselves
    // to manage icon position
    const [expanded, setExpanded] = useState<boolean>(false);
    const expandable = !section.archived && !section.unmodifiable;

    if (!section) {
        return undefined;
    }

    const handleDragEnd: OnDragEndResponder = result => {
        const { destination, source } = result;

        if (!destination) {
            return;
        }

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        // the new order of the fields
        let fieldsWithNewOrder = [...section.fields];
        fieldsWithNewOrder.splice(source.index, 1);
        fieldsWithNewOrder.splice(destination.index, 0, section.fields[source.index]);
        fieldsWithNewOrder = fieldsWithNewOrder.map((item, index) => ({
            ...item,
            order: index,
        }));

        // We update the order of the fields in the section
        onFieldsOrderChange(fieldsWithNewOrder);
    };

    const isCustomSection = section.type === SectionType.CUSTOM_SINGLE_ROW || section.type === SectionType.CUSTOM_MULTI_ROW;

    const handleExpandedChange = (_: SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded);
    };

    const sectionMenuItems: BasicMenuItem[] = section.unmodifiable
        ? []
        : [
              {
                  title: i18next.t('general.edit'),
                  onClick: onUpdate,
                  hide: section.archived,
              },
              {
                  title: i18next.t('general.delete'),
                  onClick: onDelete,
                  hide: !isCustomSection || !section.archived,
              },
              {
                  title: i18next.t('general.archive'),
                  onClick: () => {
                      onArchive();
                      if (expanded) {
                          setExpanded(false);
                      }
                  },
                  hide: !isCustomSection || section.archived,
                  confirmationRequired: true,
              },
              {
                  title: i18next.t('general.unarchive'),
                  onClick: onUnarchive,
                  hide: !isCustomSection || !section.archived,
              },
          ];

    return (
        <Accordion disableGutters onChange={expandable ? handleExpandedChange : undefined} expanded={expanded}>
            <AccordionSummary>
                <Stack flex='1' direction='row' gap={2} alignItems='center' justifyContent='space-between'>
                    <Box display='flex' {...provided.dragHandleProps} alignSelf='center'>
                        <DragDropHorizontalIcon />
                    </Box>
                    <Box flex='1'>
                        {/* section definition are only display with default language */}
                        <Stack flexDirection={'row'} gap={1} alignItems='center'>
                            <TranslatableLabelTypography variant='h2' label={section?.name} />
                            {section.archived && <Chip color={'warning'} label={t('general.archived')} />}
                        </Stack>
                        {isCustomSection && (
                            <Typography variant='body2bold' color='primary'>
                                {t(`employee_fields_page.${section.type}`)}
                            </Typography>
                        )}
                    </Box>
                    {expandable && (expanded ? <ArrowUp01Icon /> : <ArrowDown01Icon />)}
                    {!section.unmodifiable && <BasicMenu items={sectionMenuItems} endIcon={<MoreVerticalIcon />} />}
                </Stack>
            </AccordionSummary>
            <AccordionDetails>
                <Stack gap={2}>
                    {isCustomSection && (
                        <Stack direction='row-reverse'>
                            <Button variant='text' onClick={onFieldAdd}>
                                {t('employee_fields_page.new_field')}
                            </Button>
                        </Stack>
                    )}

                    {/* Display fields */}
                    <DragDropContext onDragEnd={handleDragEnd}>
                        <Droppable droppableId='section-field-definition-order'>
                            {provided => (
                                <Stack ref={provided.innerRef} {...provided.droppableProps}>
                                    {section?.fields.map((field, index) => (
                                        <Draggable draggableId={field.formId} index={index} key={field.formId}>
                                            {(provided, snapshot) => (
                                                // Gap is not supported by DND yet, we need to add margin to the draggable element
                                                // see issue https://github.com/hello-pangea/dnd/issues/432
                                                <Box
                                                    paddingY={1}
                                                    {...provided.draggableProps}
                                                    ref={provided.innerRef}
                                                    borderBottom={1}
                                                    borderColor={
                                                        // We don't want to display the divider when the field is being dragged
                                                        snapshot.isDragging ||
                                                        // Or when the field is the last one
                                                        index + 1 === section.fields.length
                                                            ? 'transparent'
                                                            : 'divider'
                                                    }
                                                >
                                                    <SectionFieldDefinitionDraggable
                                                        sectionField={field}
                                                        provided={provided}
                                                        snapshot={snapshot}
                                                        onFieldUpdate={onFieldUpdate}
                                                        onFieldDelete={onFieldDelete}
                                                        onFieldArchive={onFieldArchive}
                                                        onFieldUnarchive={onFieldUnarchive}
                                                    />
                                                </Box>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </Stack>
                            )}
                        </Droppable>
                    </DragDropContext>
                </Stack>
            </AccordionDetails>
        </Accordion>
    );
};
