import { EmployeeWorkingPatternType } from '@/domain/employee-working-pattern/EmployeeWorkingPattern.model';
import { RealmFeature, RealmFeaturesType } from '@/domain/realm/Realm.model';
import { hasRealmFeatureEnabled } from '@/domain/realm/Realm.service';
import { WeeklyWorkingTime } from '@/domain/weekly-working-time/WeeklyWorkingTime.model';
import { useAppSelector } from '@/stores/store';
import { DayOfWeek, getLocalDateTestConfig } from '@/utils/datetime.util';

import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
import { FormProvider, SubmitErrorHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { BasicInfosSection } from './Components/BasicInfosSection';
import { ContractSection } from './Components/ContractSection';
import { EmployeeWorkingPatternSection } from './Components/EmployeeWorkingPatternSection';
import { EmploymentSection } from './Components/EmploymentSection';
import { PersonalInfosSection } from './Components/PersonalInfosSection';

import { Calendar } from '@/domain/calendar/Calendar.model';
import { getEmploymentItemSchema, getEmploymentSchema } from '@/page/employee-profile/employee-profile-info/EmploymentSection/employment.schema';
import { getWorkPatternSchema } from '@/page/employee-profile/employee-profile-info/EmpoyeeWorkPatternSection/EmployeeWorkPattern.schema';
import { getNull } from '@/utils/object.util';
import {
    SectionDefinitionFormValues,
    getSectionDefinitionSchema,
} from '@/page/employee-profile/employee-profile-info/EmployeeCustomSectionRowDialog/EmployeeSectionDefinition.schema';
import { SectionDefinition, SectionType } from '@/domain/section-setting/Section.model';
import { getFormValueByFieldType } from '@/domain/section-setting/Section.service';

type BaseProfileProps = {
    onSubmitBaseProfileForm: (baseProfileFormValuesData: OnboardingProfileStepFormValues) => void;
    baseProfileFormValues: Partial<OnboardingProfileStepFormValues>;
    weeklyWorkingTimes: WeeklyWorkingTime[];
    calendars: Calendar[];
    sectionDefinitions: SectionDefinition[];
};

export const OnboardingEmployeeInformationForm: FC<BaseProfileProps> = ({
    onSubmitBaseProfileForm,
    baseProfileFormValues,
    weeklyWorkingTimes,
    calendars,
    sectionDefinitions,
}) => {
    const realm = useAppSelector(state => state.ui.currentRealm);

    const hasTimesheetLeavesRealmFeature =
        hasRealmFeatureEnabled(realm?.realmFeatures, RealmFeaturesType.TIMESHEET) || hasRealmFeatureEnabled(realm?.realmFeatures, RealmFeaturesType.LEAVES);

    const personalInfoSection = sectionDefinitions.find(section => section.type === SectionType.PERSONAL_INFO);

    const getDefaultValues = useCallback((): Partial<OnboardingProfileStepFormValues> => {
        return {
            // Contract
            contractStartDate: baseProfileFormValues?.contractStartDate,
            probationEndDate: baseProfileFormValues?.probationEndDate,
            contractType: baseProfileFormValues?.contractType,
            contractEndDate: baseProfileFormValues?.contractEndDate,
            // Personal info
            ...personalInfoSection?.fields.reduce((acc, field) => {
                return {
                    ...acc,
                    [field.formId]: getFormValueByFieldType(personalInfoSection, field.fieldType, baseProfileFormValues),
                };
            }, {}),
            // Employment
            location: baseProfileFormValues?.location,
            job: baseProfileFormValues?.job,
            jobFamily: baseProfileFormValues?.jobFamily,
            department: baseProfileFormValues?.department,
            managers: baseProfileFormValues?.managers ?? [],
            employmentCostCenters: baseProfileFormValues?.employmentCostCenters ?? [],
            timesheetSettingId: baseProfileFormValues?.timesheetSettingId,
            //  Working pattern
            ...getDefaultEmployeeWorkingPatternValues(baseProfileFormValues, hasTimesheetLeavesRealmFeature, calendars),
        };
    }, [baseProfileFormValues, personalInfoSection, hasTimesheetLeavesRealmFeature, calendars]);

    const defaultValues = getDefaultValues();

    const schema = getSchema(realm?.realmFeatures ?? [], sectionDefinitions);

    const formMethods = useForm<OnboardingProfileStepFormValues>({
        resolver: yupResolver(schema),
        defaultValues,
        shouldFocusError: false,
    });

    const [canFocus, setCanFocus] = useState(true);

    const onError: SubmitErrorHandler<OnboardingProfileStepFormValues> = errors => {
        console.error(errors);
        setCanFocus(true);
    };

    useEffect(() => {
        if (formMethods.formState.errors && canFocus) {
            const elementsOnError = Object.keys(formMethods.formState.errors)
                .map(name => document.getElementsByName(name)[0])
                .filter(el => !!el);
            elementsOnError.sort((a, b) => a.getBoundingClientRect().top - b.getBoundingClientRect().top);
            if (elementsOnError.length > 0) {
                const errorElement = elementsOnError[0];
                errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                setCanFocus(false);
            }
        }
    }, [formMethods.formState, canFocus]);

    useEffect(() => {
        formMethods.reset({
            ...getDefaultValues(),
            weeklyWorkingTimeId: weeklyWorkingTimes.length === 1 ? weeklyWorkingTimes[0].id : undefined,
        });
    }, [weeklyWorkingTimes, getDefaultValues, formMethods]);

    if (!realm) {
        throw new Error('Realm is not defined');
    }

    return (
        <form id='base-profile-form' onSubmit={formMethods.handleSubmit(onSubmitBaseProfileForm, onError)}>
            <Stack direction='column' spacing={2}>
                <FormProvider {...formMethods}>
                    {sectionDefinitions.map(sectionDefinition => {
                        if (sectionDefinition.type === SectionType.BASIC_INFO) {
                            return (
                                <BasicInfosSection
                                    key={sectionDefinition.id}
                                    baseProfileFormValues={baseProfileFormValues}
                                    sectionDefinition={sectionDefinition}
                                />
                            );
                        }
                        if (
                            sectionDefinition.type === SectionType.PERSONAL_INFO &&
                            hasRealmFeatureEnabled(realm.realmFeatures, RealmFeaturesType.ADVANCED_EMPLOYEE_PROFILE)
                        ) {
                            return (
                                <PersonalInfosSection
                                    key={sectionDefinition.id}
                                    baseProfileFormValues={baseProfileFormValues}
                                    sectionDefinition={sectionDefinition}
                                />
                            );
                        }
                    })}
                    <ContractSection />
                    <EmploymentSection />
                    <EmployeeWorkingPatternSection weeklyWorkingTimes={weeklyWorkingTimes} calendars={calendars} />
                </FormProvider>
            </Stack>
        </form>
    );
};

const getContractSchema = () => {
    return yup
        .object()
        .shape({
            contractStartDate: yup.string<LocalDate>().required().test(getLocalDateTestConfig()),
            contractEndDate: yup.string<LocalDate>().default(getNull()).nullable().test(getLocalDateTestConfig()),
        })
        .concat(getEmploymentSchema().pick(['probationEndDate', 'contractType']));
};

const getSchema = (realmFeatures: RealmFeature[], sectionDefinitions: SectionDefinition[]) => {
    // Employment update reason and start date are not required are defined in contract section
    const onboardingEmploymentSchema = getEmploymentSchema()
        .omit(['employmentUpdateReason', 'startDate', 'items'])
        // For now onboarding does not support multiple employments
        .concat(getEmploymentItemSchema());
    const onboardingWorkPatternSchema = getWorkPatternSchema(realmFeatures).omit(['startDate']);
    const onboardingContractSchema = getContractSchema();

    const basicInfoSectionDefinition = sectionDefinitions.find(section => section.type === SectionType.BASIC_INFO);
    const basicInfoSchema = basicInfoSectionDefinition ? getSectionDefinitionSchema({ sectionDefinition: basicInfoSectionDefinition }) : undefined;

    const personalInfoSectionDefinition = sectionDefinitions.find(section => section.type === SectionType.PERSONAL_INFO);
    const personalInfoSchema = personalInfoSectionDefinition ? getSectionDefinitionSchema({ sectionDefinition: personalInfoSectionDefinition }) : undefined;

    if (!basicInfoSchema || !personalInfoSchema) {
        throw new Error('Basic info and personal info section definitions are required');
    }

    return yup
        .object()
        .concat(basicInfoSchema)
        .concat(personalInfoSchema)
        .concat(onboardingContractSchema)
        .concat(onboardingEmploymentSchema)
        .concat(onboardingWorkPatternSchema);
};

// workaround to help inference because part of the schema is dynamic( from SectionDefinitionFormValues)
export type OnboardingProfileStepFormValues = SectionDefinitionFormValues & yup.InferType<ReturnType<typeof getSchema>>;

const getDefaultEmployeeWorkingPatternValues = (
    baseProfileFormValues: Partial<OnboardingProfileStepFormValues>,
    hasTimesheetLeavesRealmFeature: boolean,
    calendars: Calendar[],
) => {
    const defaultDays: DayOfWeek[] = Object.keys(DayOfWeek)
        .map((k: string) => DayOfWeek[k as keyof typeof DayOfWeek])
        .filter(dayOfWeek => dayOfWeek !== DayOfWeek.SATURDAY && dayOfWeek !== DayOfWeek.SUNDAY);

    const defaultEmployeeWorkingPatternType = hasTimesheetLeavesRealmFeature ? EmployeeWorkingPatternType.FIXED : EmployeeWorkingPatternType.RATE;

    return {
        type: baseProfileFormValues?.type ?? defaultEmployeeWorkingPatternType,
        rate: baseProfileFormValues?.rate ?? 100,
        workingDays: baseProfileFormValues?.workingDays ?? { morningWorkingDays: defaultDays, afternoonWorkingDays: defaultDays },
        workingPatternTemplate: baseProfileFormValues?.workingPatternTemplate,
        weeklyWorkingTimeId: baseProfileFormValues?.weeklyWorkingTimeId,
        calendarId: calendars.length === 1 ? calendars[0].id : baseProfileFormValues?.calendarId,
    };
};
