import { DialogContainerProps } from '@/components/dialog-container/DialogContainer';
import { DialogWrapper } from '@/components/dialog-wrapper/DialogWrapper';
import { FieldSelect } from '@/components/form/field-select/FieldSelect';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { TranslationLanguageSelector } from '@/components/translation-language-selector/TranslationLanguageSelector';
import { Employee } from '@/domain/employee/Employee.model';
import { getLabelFormSchema } from '@/domain/label/Label.schema';
import { ReviewTemplate } from '@/domain/review-template/ReviewTemplate.model';
import { ReviewOneShotCreationMutation } from '@/domain/review/Review.model';
import { createOneShotReview } from '@/domain/review/Review.service';
import { useGetEmployees } from '@/hooks/employee/Employee.hook';
import { useSearchReviewTemplates } from '@/hooks/review-template/ReviewTemplate.hook';
import { useAppSelector } from '@/stores/store';
import { handleError } from '@/utils/api.util';
import { getLabelTranslation, getRealmLanguage, UserLanguage } from '@/utils/language.util';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, DialogActions, DialogContent, FormControlLabel, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { getLocalDateMinTestConfig, getLocalDateTestConfig } from '@/utils/datetime.util';
import { FieldLocalDate } from '@/components/form/field-date/FieldDate';
import { FieldLabel } from '@/components/form/field-label/FieldLabel';

type OneShotReviewDialogProps = Omit<DialogContainerProps, 'onSave'> & {
    onSuccess: (
        employeeReviewId: number | undefined,
        managerId: number,
        options: {
            includePreparationStep: boolean;
        },
    ) => void;
    /** The employee to review */
    employeeId: number | undefined;
};

const getSchema = (userLanguage: UserLanguage, reviewTemplates: ReviewTemplate[]) => {
    return yup.object().shape({
        name: getLabelFormSchema(userLanguage),
        reviewTemplate: yup.object().default(undefined).required().shape({
            label: yup.string().required(),
            value: yup.number().required(),
        }),
        manager: yup.object().default(undefined).required().shape({
            label: yup.string().required(),
            value: yup.number().required(),
        }),
        employee: yup
            .object()
            .shape({
                label: yup.string().required(),
                value: yup.number().required(),
            })
            .required(),
        feedbackDeadlineDate: yup.string<LocalDate>().when('reviewTemplate', {
            is: (reviewTemplate: { label: string; value: number } | undefined) => {
                const template = reviewTemplates.find(template => template.id === reviewTemplate?.value);
                return template?.includePreparationStep;
            },
            then: schema => schema.required().test(getLocalDateTestConfig()),
            otherwise: schema => schema.nullable(),
        }),
        endDate: yup.string<LocalDate>().when(['feedbackDeadlineDate', 'reviewTemplate'], ([feedbackDeadlineDate, reviewTemplate], schema) => {
            const template = reviewTemplates.find(template => template.id === reviewTemplate?.value);
            return template?.includePreparationStep
                ? schema
                      .test(getLocalDateMinTestConfig(feedbackDeadlineDate, t('reviews.new_review_one_shot.end_date_after_feedback_deadline_date')))
                      .required()
                : schema;
        }),
    });
};

type OneShotReviewFormValues = yup.InferType<ReturnType<typeof getSchema>>;

export const OneShotReviewDialog: FC<OneShotReviewDialogProps> = ({ onSuccess, employeeId, onClose, ...rest }) => {
    const currentEmployee = useAppSelector(state => state.currentEmployee.employee);
    const {
        data: reviewTemplates,
        isError: isReviewTemplatesError,
        error: reviewTemplatesError,
        isLoading: isReviewTemplatesLoading,
    } = useSearchReviewTemplates({ reviewType: 'ONE_SHOT' });
    const [translationLanguage, setTranslationLanguage] = useState<UserLanguage>(getRealmLanguage());
    const { data: employees, error: employeesError, isError: isEmployeesError, isLoading: isEmployeesLoading } = useGetEmployees();
    const employeeToReview = employees?.find(employee => employee.id === employeeId);

    const error = reviewTemplatesError || employeesError;
    const isLoading = isReviewTemplatesLoading || isEmployeesLoading;
    const isError = isReviewTemplatesError || isEmployeesError;
    const header = (
        <Stack direction='row' justifyContent='space-between' alignItems='center' flex={1}>
            <Typography variant={'body1bold'}>{t('reviews.new_review_one_shot.dialog_title')}</Typography>
            <TranslationLanguageSelector translationLanguage={translationLanguage} onLanguageChange={setTranslationLanguage} />
        </Stack>
    );

    return (
        <DialogWrapper {...rest} onClose={() => onClose()} open={true} header={header} maxWidth='sm'>
            <DialogContent>
                <StateHandler isLoading={isLoading} isError={isError} error={error}>
                    <OneShotReviewDialogForm
                        reviewTemplates={reviewTemplates ?? []}
                        employees={employees ?? []}
                        onSuccess={onSuccess}
                        manager={currentEmployee}
                        employeeToReview={employeeToReview}
                        translationLanguage={translationLanguage}
                    />
                </StateHandler>
            </DialogContent>
            <DialogActions>
                <Button type='submit' form={'one-shot-review-form'}>
                    {t('general.save')}
                </Button>
            </DialogActions>
        </DialogWrapper>
    );
};

type OneShotReviewDialogFormProps = {
    employees: Employee[];
    reviewTemplates: ReviewTemplate[];
    onSuccess: (
        employeeReviewId: number | undefined,
        managerId: number,
        options: {
            includePreparationStep: boolean;
        },
    ) => void;
    employeeToReview: Employee | undefined;
    manager: Employee | undefined;
    translationLanguage: UserLanguage;
};

const OneShotReviewDialogForm: FC<OneShotReviewDialogFormProps> = ({
    employees,
    reviewTemplates,
    manager,
    onSuccess,
    employeeToReview,
    translationLanguage,
}) => {
    const reviewTemplateOptions = reviewTemplates.map(template => ({
        label: getLabelTranslation(template.name),
        value: template.id,
    }));
    const employeeOptions = employees
        .map(employee => ({
            label: employee.displayName,
            value: employee.id,
        }))
        .filter(employee => employee.value !== manager?.id);

    const getDefaultValues = (): Partial<OneShotReviewFormValues> => ({
        employee: employeeToReview ? { label: employeeToReview.displayName, value: employeeToReview.id } : undefined,
        manager: manager ? { label: manager.displayName, value: manager.id } : undefined,
    });

    const { handleSubmit, control, watch } = useForm<OneShotReviewFormValues>({
        resolver: yupResolver(getSchema(translationLanguage, reviewTemplates)),
        defaultValues: getDefaultValues(),
    });

    const feedbackDeadLineDate = watch('feedbackDeadlineDate');
    const reviewTemplate = watch('reviewTemplate');
    const includePreparationStep = !!reviewTemplates.find(template => template.id === reviewTemplate?.value)?.includePreparationStep;

    const handleSave = async (values: OneShotReviewFormValues) => {
        const request: ReviewOneShotCreationMutation = {
            name: values.name,
            reviewTemplateId: values.reviewTemplate.value,
            managerIds: [values.manager.value],
            employeeId: values.employee.value,
            endDate: includePreparationStep ? values.endDate : undefined,
            feedbackDeadlineDate: includePreparationStep ? values.feedbackDeadlineDate : undefined,
        };

        try {
            const review = await createOneShotReview(request);
            onSuccess(review?.employeeReviews?.[0]?.id, values.manager.value, { includePreparationStep: includePreparationStep ?? false });
        } catch (error) {
            handleError(error);
        }
    };

    return (
        <Stack gap={1} component='form' id='one-shot-review-form' onSubmit={handleSubmit(handleSave, console.error)}>
            <FormControlLabel
                label={t('general.employee')}
                control={
                    <FieldSelect
                        name='employee'
                        control={control}
                        disabled={!!employeeToReview}
                        fullWidth
                        options={employeeOptions}
                        getOptionLabel={option => option.label}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                    />
                }
            />

            <FieldLabel control={control} name='name' fullWidth language={translationLanguage} label={t('reviews.new_review_one_shot.name')} />

            <FormControlLabel
                label={t('reviews.new_review_one_shot.template')}
                control={
                    <FieldSelect
                        name='reviewTemplate'
                        control={control}
                        fullWidth
                        options={reviewTemplateOptions}
                        getOptionLabel={option => option.label}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                    />
                }
            />
            <FormControlLabel
                label={t('reviews.new_review_one_shot.manager')}
                control={
                    <FieldSelect
                        name='manager'
                        control={control}
                        fullWidth
                        options={employeeOptions}
                        getOptionLabel={option => option.label}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                    />
                }
            />
            {includePreparationStep && (
                <>
                    <FormControlLabel
                        label={t('reviews.new_review_one_shot.feedback_deadline_date')}
                        control={<FieldLocalDate name='feedbackDeadlineDate' control={control} />}
                    />

                    <FormControlLabel
                        label={t('reviews.new_review_one_shot.end_date')}
                        control={<FieldLocalDate name='endDate' control={control} minDate={feedbackDeadLineDate} />}
                    />
                </>
            )}
        </Stack>
    );
};
