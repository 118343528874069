import { UnitType } from '@/domain/date/Date.model';
import { convertLeavesMinutesToUnit } from '@/domain/leave-request/LeaveRequest.service';
import { AllowanceType, LeaveActivityType, LeaveType } from '@/domain/leave-type/LeaveType.model';
import { shadows } from '@/theme/shadows';
import { Card, CardContent, Divider, LinearProgress, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { ColorDot } from '@/components/color-dot/ColorDot';
import { calculatePercentage } from '@/utils/math.util';
import { useTranslation } from 'react-i18next';
import { getLabelTranslation } from '@/utils/language.util';
import { DurationUnit } from '@/i18n/i18n';

type LeaveTypeCardProps = {
    employeeId: number;
    leaveType: LeaveType;
    availableAmountInMinutes: number;
    usedAmountInMinutes: number;
    allowanceInMinutes: number;
    availableAmountInDays: number;
    usedAmountInDays: number;
    allowanceInDays: number;
};
export const LeaveTypeCard: FC<LeaveTypeCardProps> = ({
    employeeId,
    leaveType,
    availableAmountInMinutes,
    usedAmountInMinutes,
    allowanceInMinutes,
    availableAmountInDays,
    usedAmountInDays,
    allowanceInDays,
}) => {
    const navigate = useNavigate();

    const availableAmountDays = convertLeavesMinutesToUnit({
        input: availableAmountInDays,
        outputUnit: UnitType.DAYS,
        roundingType: leaveType.roundingType,
    });

    const allowanceDays = convertLeavesMinutesToUnit({
        input: allowanceInDays,
        outputUnit: UnitType.DAYS,
        roundingType: leaveType.roundingType,
    });

    const availableAmountHours = convertLeavesMinutesToUnit({
        input: availableAmountInMinutes,
        outputUnit: UnitType.HOURS,
        roundingType: leaveType.roundingType,
    });

    const allowanceHours = convertLeavesMinutesToUnit({
        input: allowanceInMinutes,
        outputUnit: UnitType.HOURS,
        roundingType: leaveType.roundingType,
    });

    const isUnlimited = leaveType.allowanceType === AllowanceType.UNLIMITED || leaveType.leaveActivityType === LeaveActivityType.TIMESHEET_COMPENSATION;
    const total = leaveType.displayUnitType === UnitType.HOURS ? allowanceHours : allowanceDays;
    const taken = leaveType.displayUnitType === UnitType.HOURS ? availableAmountHours : availableAmountDays;

    const percentage = calculatePercentage(taken, total);

    return (
        <Card
            sx={{
                cursor: 'pointer',
                width: '260px',
                minWidth: '260px',
                height: '128px',
                '&:hover': {
                    boxShadow: shadows[2],
                },
            }}
            onClick={() => {
                navigate(`/profile/${employeeId}/leaves/history/${leaveType.id}`);
            }}
        >
            <CardContent>
                <Stack direction='column' spacing={1}>
                    <Stack direction={'row'} spacing={1} alignItems={'center'}>
                        <Stack>
                            <ColorDot color={leaveType.color} />
                        </Stack>
                        <Typography variant='body1' noWrap>
                            {getLabelTranslation(leaveType.name)}
                        </Typography>
                    </Stack>

                    <Divider />
                    {!isUnlimited && (
                        <LimitedAmount
                            leaveType={leaveType}
                            allowanceDays={allowanceDays}
                            allowanceHours={allowanceHours}
                            availableAmountDays={availableAmountDays}
                            availableAmountHours={availableAmountHours}
                        />
                    )}
                    {isUnlimited && (
                        <UnlimitedAmount
                            leaveType={leaveType}
                            availableAmountDays={availableAmountDays}
                            availableAmountHours={availableAmountHours}
                            usedAmountInDays={usedAmountInDays}
                            usedAmountInMinutes={usedAmountInMinutes}
                        />
                    )}
                    {!isUnlimited && (
                        <Stack>
                            <LinearProgress variant='determinate' value={percentage} />
                        </Stack>
                    )}
                </Stack>
            </CardContent>
        </Card>
    );
};

type LimitedAmountProps = {
    availableAmountHours: number | undefined;
    availableAmountDays: number | undefined;
    allowanceHours: number | undefined;
    allowanceDays: number | undefined;
    leaveType: LeaveType;
};

const LimitedAmount: FC<LimitedAmountProps> = ({ availableAmountHours, availableAmountDays, allowanceHours, allowanceDays, leaveType }) => {
    const { t } = useTranslation();
    const getDaysRemainingText = () => {
        if (leaveType.displayUnitType === UnitType.DAYS || leaveType.displayUnitType === UnitType.BOTH) {
            return t('my_leaves.leave_type_card.days', {
                remaining: availableAmountDays,
                total: allowanceDays,
            });
        }
        return undefined;
    };

    const getHoursRemainingText = () => {
        if (leaveType.displayUnitType === UnitType.HOURS || leaveType.displayUnitType === UnitType.BOTH) {
            const available = t('duration.formatDuration', {
                duration: availableAmountHours ?? 0,
                unit: DurationUnit.HOURS,
            });
            const allowance = t('duration.formatDuration', {
                duration: allowanceHours ?? 0,
                unit: DurationUnit.HOURS,
            });
            return t('my_leaves.leave_type_card.hours', {
                remaining: available,
                total: allowance,
            });
        }
        return undefined;
    };

    const daysText = getDaysRemainingText();
    const hoursText = getHoursRemainingText();
    const displayBoth = leaveType.displayUnitType === UnitType.BOTH;

    return DisplayAmountTypographies({ daysText, hoursText, displayBoth });
};

type UnlimitedAmountProps = {
    availableAmountHours: number | undefined;
    availableAmountDays: number | undefined;
    usedAmountInMinutes: number | undefined;
    usedAmountInDays: number | undefined;
    leaveType: LeaveType;
};

const UnlimitedAmount: FC<UnlimitedAmountProps> = ({ availableAmountHours, availableAmountDays, usedAmountInMinutes, usedAmountInDays, leaveType }) => {
    const { t } = useTranslation();
    const isCompensation = leaveType.leaveActivityType === LeaveActivityType.TIMESHEET_COMPENSATION;

    const usedAmountDays = convertLeavesMinutesToUnit({
        input: usedAmountInDays ?? 0,
        outputUnit: UnitType.DAYS,
        roundingType: leaveType.roundingType,
    });

    const usedAmountHours = convertLeavesMinutesToUnit({
        input: usedAmountInMinutes ?? 0,
        outputUnit: UnitType.HOURS,
        roundingType: leaveType.roundingType,
    });

    const amountInMinutes = leaveType.leaveActivityType === LeaveActivityType.TIMESHEET_COMPENSATION ? availableAmountHours : usedAmountHours;
    const amountInDays = leaveType.leaveActivityType === LeaveActivityType.TIMESHEET_COMPENSATION ? availableAmountDays : usedAmountDays;

    const getDaysRemainingText = () => {
        if (leaveType.displayUnitType === UnitType.DAYS || leaveType.displayUnitType === UnitType.BOTH) {
            return t('my_leaves.leave_type_card.days', {
                context: isCompensation ? 'remaining' : 'unlimited',
                total: amountInDays,
            });
        }
        return undefined;
    };

    const getHoursRemainingText = () => {
        if (leaveType.displayUnitType === UnitType.HOURS || leaveType.displayUnitType === UnitType.BOTH) {
            const amount = t('duration.formatDuration', {
                duration: amountInMinutes ?? 0,
                unit: DurationUnit.HOURS,
            });
            return t('my_leaves.leave_type_card.hours', {
                context: isCompensation ? 'remaining' : 'unlimited',
                total: amount,
            });
        }
        return undefined;
    };

    const daysText = getDaysRemainingText();
    const hoursText = getHoursRemainingText();
    const displayBoth = leaveType.displayUnitType === UnitType.BOTH;

    return DisplayAmountTypographies({ daysText, hoursText, displayBoth });
};

const DisplayAmountTypographies: FC<{
    daysText: string | undefined;
    hoursText: string | undefined;
    displayBoth: boolean;
}> = ({ daysText, hoursText, displayBoth }) => {
    const emptyText = '‎ '; //used so that we can have the exact same height with or without the text

    return (
        <Stack spacing={0.5}>
            <Typography variant='body1bold' noWrap>
                {daysText ?? hoursText}
            </Typography>
            <Typography variant='body3' noWrap>
                {displayBoth ? hoursText : emptyText}
            </Typography>
        </Stack>
    );
};
