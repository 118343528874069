import { Button, Stack } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AgGridWrapper, RogerColDef } from '@/components/ag-grid-wrapper/AgGridWrapper';
import { SurveyTemplatesActionButtons } from '@/page/survey/survey/SurveyTemplatesActionButtons';
import { handleError } from '@/utils/api.util';
import { showSnackbar } from '@/utils/snackbar.util';
import { useAppSelector } from '@/stores/store';
import { useNavigate } from 'react-router-dom';
import { canManageSurveys } from '@/domain/permission/Permission.service';
import { Add01Icon } from 'hugeicons-react';
import { SurveyTemplate } from '@/domain/survey-template/Survey-template.model';
import { cloneSurveyTemplate, deleteSurveyTemplate, getSurveyTemplates } from '@/domain/survey-template/Survey-template.service';
import { ICellRendererParams } from '@ag-grid-community/core';

export const SurveyTemplatesPage: FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const policies = useAppSelector(state => state.currentEmployee.grantedPolicies);
    const [surveyTemplates, setSurveyTemplates] = useState<SurveyTemplate[]>();
    const [refetch, setRefetch] = useState(false);

    useEffect(() => {
        getSurveyTemplates()
            .then(data => {
                setSurveyTemplates(data);
            })
            .catch(handleError);
    }, [refetch]);

    if (!surveyTemplates) {
        return <></>;
    }

    if (!canManageSurveys(policies)) {
        return;
    }

    const cellActionRenderer = (params: ICellRendererParams<SurveyTemplate>) => {
        if (!params.data?.id) {
            return;
        }
        const surveyTemplateId = params.data.id;
        return (
            <SurveyTemplatesActionButtons
                onEditClicked={() => {
                    navigate(`/manage-surveys/templates/${surveyTemplateId}`);
                }}
                onDeleteClicked={() => {
                    deleteSurveyTemplate(surveyTemplateId)
                        .then(() => {
                            showSnackbar(t('survey_templates_page.survey_deleted'), 'success');
                            setRefetch(true);
                        })
                        .catch(handleError);
                }}
                onDuplicateClicked={() => {
                    cloneSurveyTemplate(surveyTemplateId)
                        .then(() => {
                            setRefetch(true);
                        })
                        .catch(handleError);
                }}
            />
        );
    };

    const columnDefs: RogerColDef<SurveyTemplate>[] = [
        {
            field: 'name',
            headerName: t('survey_templates_page.surveys_table.form_name'),
            type: 'label',
        },
        {
            field: 'description',
            headerName: t('survey_templates_page.surveys_table.description'),
            type: 'label',
        },
        {
            field: 'createdBy',
            headerName: t('survey_templates_page.surveys_table.created_by'),
            type: 'employee',
        },
        {
            type: 'actionMenu',
            cellRenderer: cellActionRenderer,
        },
    ];

    return (
        <Stack spacing={2} flex={1}>
            <Stack direction='row' justifyContent='flex-end' spacing={2}>
                <Button
                    startIcon={<Add01Icon />}
                    onClick={() => {
                        navigate('/manage-surveys/templates/new');
                    }}
                >
                    Template
                </Button>
            </Stack>
            <AgGridWrapper<SurveyTemplate>
                rowData={surveyTemplates}
                columnDefs={columnDefs}
                onRowClicked={row => {
                    if (!row.event?.defaultPrevented && row.data?.id) {
                        navigate(`/manage-surveys/templates/${row.data.id}`);
                    }
                }}
            />
        </Stack>
    );
};
