import { FileMetadata, FilePickerWrapper } from '@/components/file-picker-wrapper/FilePickerWrapper';
import { DialogContainerProps } from '@/components/dialog-container/DialogContainer';
import { DialogWrapper } from '@/components/dialog-wrapper/DialogWrapper';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Checkbox, DialogActions, DialogContent, FormControlLabel, FormGroup, FormLabel, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

type MassDocumentImportDialogProps = Omit<DialogContainerProps, 'onSave'> & {
    onSave: (values: ImportFormValues) => void;
};

type ImportFormValues = {
    checkByName: boolean;
    checkByEmployeeCode: boolean;
    documents: FileMetadata[];
};

const schema = yup.object().shape({
    documents: yup.array().required().min(1),
    checkByName: yup.boolean().default(true),
    checkByEmployeeCode: yup.boolean().default(true),
});

export const MassDocumentImportDialog: FC<MassDocumentImportDialogProps> = ({ onSave, ...rest }) => {
    const { t } = useTranslation();

    const form = useForm<ImportFormValues>({
        resolver: yupResolver(schema),
        defaultValues: {
            documents: [],
            checkByName: true,
            checkByEmployeeCode: true,
        },
    });

    const { handleSubmit, control, watch } = form;

    const checkByName = watch('checkByName');
    const checkByEmployeeCode = watch('checkByEmployeeCode');

    const hasAtLeastOneMatchingSetting = checkByName || checkByEmployeeCode;

    return (
        <FormProvider {...form}>
            <DialogWrapper header={t('import_page.mass_document_import_dialog.title')} {...rest}>
                <DialogContent>
                    <Stack gap={2}>
                        <FormGroup>
                            <FormLabel>{t('import_page.mass_document_import_dialog.check_by')}</FormLabel>
                            <Controller
                                name='checkByName'
                                control={control}
                                render={({ field: { value, ...field } }) => (
                                    <FormControlLabel
                                        labelPlacement='end'
                                        control={<Checkbox checked={value} {...field} size='small' />}
                                        label={t('import_page.mass_document_import_dialog.check_by_name')}
                                    />
                                )}
                            />
                            <Controller
                                name='checkByEmployeeCode'
                                control={control}
                                render={({ field: { value, ...field } }) => (
                                    <FormControlLabel
                                        labelPlacement='end'
                                        control={<Checkbox checked={value} {...field} size='small' />}
                                        label={t('import_page.mass_document_import_dialog.check_by_employee_code')}
                                    />
                                )}
                            />
                        </FormGroup>
                        <Controller
                            name='documents'
                            control={control}
                            render={({ field, fieldState }) => {
                                return (
                                    <>
                                        <FilePickerWrapper
                                            containerId='mass-document-import-dialog'
                                            filesMetadata={field.value}
                                            onCancel={() => {}}
                                            onFileUploaded={fileToAdd => {
                                                // JBA : I don't understand why but we have to use getValues instead of field
                                                const files = [...form.getValues('documents'), fileToAdd];
                                                field.onChange(files);
                                            }}
                                            onFileRemoved={fileToRemove => {
                                                field.onChange([...field.value.filter(file => file.key !== fileToRemove.key)]);
                                            }}
                                            onFileRenamed={fileToReplace => {
                                                field.onChange([...field.value.map(file => (file.key === fileToReplace.key ? fileToReplace : file))]);
                                            }}
                                            maxFiles={MAX_UPLOAD_FILES}
                                            fetchDocumentUrl={undefined}
                                        />

                                        <Typography color='error'>{fieldState.error?.message}</Typography>
                                    </>
                                );
                            }}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleSubmit(onSave, console.error)()} fullWidth disabled={!hasAtLeastOneMatchingSetting}>
                        {t('general.save')}
                    </Button>
                </DialogActions>
            </DialogWrapper>
        </FormProvider>
    );
};

const MAX_UPLOAD_FILES = 500;
