import { TranslatableLabelInput } from '@/components/translatable-label-input/TranslatableLabelInput';
import { Label, LabelRequest } from '@/domain/label/Label.model';
import { createDefaultLabel, isLabelUnique } from '@/domain/label/Label.service';
import { handleError } from '@/utils/api.util';
import { getLabelTranslation, getRealmLanguage, UserLanguage } from '@/utils/language.util';
import { Box, Button, IconButton, Stack } from '@mui/material';
import { Delete02Icon, DragDropHorizontalIcon } from 'hugeicons-react';
import { FC, KeyboardEvent, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable, OnDragEndResponder } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

export type LabelListItemFormValues = {
    id?: number;
    label: Label;
    order: number;
};

type LabelInputListProps = {
    list: LabelListItemFormValues[];
    onChange?: (labels: LabelListItemFormValues[]) => void;
    onLabelAdd?: (label: LabelListItemFormValues) => Promise<void>;
    onLabelRemove?: (label: LabelListItemFormValues) => Promise<void>;
    onLabelUpdate?: (label: LabelListItemFormValues, error: unknown) => Promise<void>;
    translationLanguage: UserLanguage;
};

enum Key {
    ENTER = 'Enter',
    ESCAPE = 'Escape',
    ARROW_UP = 'ArrowUp',
    ARROW_DOWN = 'ArrowDown',
    ARROW_LEFT = 'ArrowLeft',
    ARROW_RIGHT = 'ArrowRight',
}

export const LabelInputList: FC<LabelInputListProps> = ({ translationLanguage, list: originalList, onChange, onLabelAdd, onLabelRemove, onLabelUpdate }) => {
    const list = originalList.map((item, index) => ({ ...item, order: index }));
    const { t } = useTranslation();

    const itemsRefs = useRef<HTMLDivElement[]>([]);

    const defaultLanguage = getRealmLanguage();

    const defaultLabel = createDefaultLabel();

    const [newLabel, setNewLabel] = useState<Label>(defaultLabel);

    // Errors are stored in an array of objects with the error message and the index based on order of the item in the list
    const [errors, setErrors] = useState<{ errorMessage: string; index: number }[]>([]);

    const validateLabel = (label: Label, item?: LabelListItemFormValues): string | undefined => {
        const labelList = list.map(item => item.label).filter(label => label.id !== item?.label.id);
        if (!isLabelUnique(label, labelList)) {
            setErrors(errors => {
                // the error to create a new label is stored with an index of -1 because it doesn't have an order
                if (errors?.some(e => e.index === (item?.order ?? -1))) {
                    return errors;
                }
                return [
                    ...(errors ?? []),
                    {
                        errorMessage: t('custom_list.unique_label_error'),
                        index: item?.order ?? -1,
                    },
                ];
            });
            return t('custom_list.unique_label_error');
        }
        setErrors(errors => errors?.filter(e => e.index !== (item?.order ?? -1)));
    };

    const handleLabelChange = (item: LabelListItemFormValues) => (label: Label) => {
        const updatedItem = {
            ...item,
            label,
        };

        const labelError = validateLabel(label, item);

        if (onLabelUpdate) {
            onLabelUpdate(updatedItem, labelError).catch(handleError);
        } else {
            // we use the order to find the index of the item in the list
            const index = list.findIndex(i => i.order === item.order);
            handleListChange([...list.slice(0, index), updatedItem, ...list.slice(index + 1)]);
        }
    };

    const handleLabelAdd = async (label: LabelRequest) => {
        if (getLabelTranslation(label, defaultLanguage)) {
            const item = {
                label,
                order: 0,
            };
            const labelError = validateLabel(label);
            if (labelError) {
                return;
            }

            if (onLabelAdd) {
                try {
                    await onLabelAdd(item);
                    setNewLabel(defaultLabel);
                } catch {
                    return;
                }
            } else {
                // If there is an error, we don't add the label
                const newList = [item, ...(list ?? [])];
                setNewLabel(defaultLabel);
                handleListChange(newList);
            }
        }
    };

    const isDefaultLanguage = translationLanguage === defaultLanguage;

    const handleDragEnd: OnDragEndResponder = result => {
        if (!result.destination) {
            return;
        }

        const items = [...list];
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        handleListChange(items);

        // if the item was focusing, focus it again
        if (itemsRefs.current[result.source.index]?.contains(document.activeElement)) {
            itemsRefs.current[result.destination.index].focus();
        }
    };

    const handleLabelRemove = (item: LabelListItemFormValues) => async () => {
        if (onLabelRemove) {
            try {
                await onLabelRemove(item);
            } catch {
                return;
            }
        } else {
            // focus the previous item or the next one if there is no previous one
            const index = list.findIndex(i => i.order === item.order);
            if (index > 0) {
                itemsRefs.current[index - 1].focus();
            } else {
                itemsRefs.current?.[1]?.focus();
            }

            handleListChange(list.filter(i => i.order !== item.order));
        }
    };

    const handleNewLabelKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === Key.ENTER) {
            handleLabelAdd(newLabel).catch(handleError);
        }
    };

    const handleListChange = (newList: LabelListItemFormValues[]) => {
        onChange?.(newList.map((item, index) => ({ ...item, order: index })));
    };

    return (
        <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId='custom-list-order'>
                {provided => (
                    <Stack ref={provided.innerRef} {...provided.droppableProps}>
                        {isDefaultLanguage && (
                            <Stack direction='row' justifyContent='space-between' alignItems='center' gap={1}>
                                <Stack flex='1'>
                                    <TranslatableLabelInput
                                        fullWidth
                                        translationLanguage={translationLanguage}
                                        onChange={setNewLabel}
                                        value={newLabel}
                                        InputProps={{
                                            onKeyUp: handleNewLabelKeyUp,
                                            placeholder: t('custom_list.add_item_placeholder'),
                                        }}
                                        // Error to create a new label is stored with an index of -1 because it doesn't have an order
                                        error={!!errors.find(e => e.index === -1)}
                                        helperText={errors.find(e => e.index === -1)?.errorMessage}
                                    />
                                </Stack>
                                <Button
                                    onClick={() => handleLabelAdd(newLabel)}
                                    // Hack to align the button with the input even when input has an error
                                    sx={{ alignSelf: 'baseline' }}
                                >
                                    {t('general.add')}
                                </Button>
                            </Stack>
                        )}
                        <Stack>
                            {list?.map((item, index) => (
                                <DraggableLabelInput
                                    key={`id_${item?.id}_order_${item.order}`}
                                    item={item}
                                    index={index}
                                    error={!!errors.find(e => e.index === item.order)}
                                    helperText={errors.find(e => e.index === item.order)?.errorMessage}
                                    isDefaultLanguage={isDefaultLanguage}
                                    translationLanguage={translationLanguage}
                                    onLabelRemove={handleLabelRemove(item)}
                                    onLabelChanged={handleLabelChange(item)}
                                    isDragDisabled={!onChange}
                                    itemsRefs={itemsRefs}
                                />
                            ))}
                        </Stack>
                        {provided.placeholder}
                    </Stack>
                )}
            </Droppable>
        </DragDropContext>
    );
};

const DraggableLabelInput: FC<{
    index: number;
    error: boolean;
    helperText?: string;
    isDefaultLanguage: boolean;
    translationLanguage: UserLanguage;
    onLabelRemove: () => Promise<void>;
    onLabelChanged: (label: Label) => void;
    item: LabelListItemFormValues;
    isDragDisabled?: boolean;
    itemsRefs: React.MutableRefObject<HTMLDivElement[]>;
}> = ({ index, error, helperText, isDefaultLanguage, translationLanguage, onLabelRemove, onLabelChanged, isDragDisabled, item, itemsRefs }) => {
    const { t } = useTranslation();

    const linkInputRef = (index: number) => (el: HTMLDivElement) => {
        itemsRefs.current[index] = el;
    };
    return (
        <Draggable draggableId={item.order.toString()} index={index} isDragDisabled={!isDefaultLanguage || isDragDisabled}>
            {provided => (
                // Gap is not supported by DND yet, we need to add margin to the draggable element
                // see issue https://github.com/hello-pangea/dnd/issues/432
                <Stack paddingTop={2} {...provided.draggableProps} ref={provided.innerRef}>
                    <Box display='none' {...provided.dragHandleProps} />
                    <TranslatableLabelInput
                        translationLanguage={translationLanguage}
                        fullWidth
                        inputRef={linkInputRef(index)}
                        value={item.label}
                        onChange={onLabelChanged}
                        InputProps={{
                            startAdornment:
                                isDefaultLanguage && !isDragDisabled ? (
                                    <Box display='inline-flex' {...provided.dragHandleProps} paddingRight={1}>
                                        <DragDropHorizontalIcon />
                                    </Box>
                                ) : undefined,
                            endAdornment: isDefaultLanguage ? (
                                <IconButton onClick={() => onLabelRemove()} aria-label={t('general.delete')}>
                                    <Delete02Icon />
                                </IconButton>
                            ) : undefined,
                            placeholder: t('custom_list.translate_item_placeholder'),
                        }}
                        error={error}
                        helperText={helperText}
                    />
                </Stack>
            )}
        </Draggable>
    );
};
