import { AsyncSelectFilter, DateFilter, SelectFilter } from '@/components/filters-bar/FiltersBar';
import { reportApi } from '@/api/report/Report.api';
import { EmployeeAvatar } from '@/domain/employee/Employee.model';
import { SectionDefinition, SectionType } from '@/domain/section-setting/Section.model';
import { getSectionDefinitions } from '@/domain/section-setting/Section.service';
import { ReportColumn } from '@/page/report/report-columns-selector/ReportColumnsSelector';
import { GroupingFilters, ReportFilterItemBar } from '@/page/report/report-editor-bar/ReportEditorBar';

import { ColDef } from '@ag-grid-community/core';
import { StackProps } from '@mui/material';
import { Label } from '../label/Label.model';
import {
    AvailableReportField,
    AvailableReportGroupedField,
    Report,
    ReportCategory,
    ReportCommonRow,
    ReportCreateMutation,
    ReportEmployeeRow,
    ReportField,
    ReportFieldDefinition,
    ReportFieldDefinitionMutation,
    ReportFieldType,
    ReportFilterMutation,
    ReportFilterRule,
    ReportGroupBy,
    ReportPreview,
    ReportRow,
    ReportSort,
    ReportType,
    ReportUpdateMutation,
    ReportUpdateViewerMutation,
} from './Report.model';

export const searchReports = (category: ReportCategory): Promise<Report[]> => {
    return reportApi.searchReports(category);
};

export const createReport = (mutation: ReportCreateMutation): Promise<Report> => {
    return reportApi.createReport(mutation);
};

export function getReport(reportId: number, reportType: ReportType): Promise<Report> {
    return reportApi.getReport(reportType, reportId);
}

export const getReportCustomTables = async (): Promise<SectionDefinition[]> => {
    const data = await getSectionDefinitions();
    return data?.filter(sd => sd.type === SectionType.CUSTOM_MULTI_ROW) ?? [];
};

export const renameReport = (reportId: number, reportType: ReportType, title: Label): Promise<void> => {
    return reportApi.updateReportTitle(reportId, reportType, title);
};

export const deleteReport = (reportId: number, reportType: ReportType): Promise<void> => {
    return reportApi.deleteReport(reportId, reportType);
};

function getReportRowsByType(
    reportType: ReportType,
    previewParams: ReportPreview | Pick<Report, 'id'>,
    { signal }: { signal?: AbortSignal } = {},
): Promise<ReportRow[]> {
    const isNotPreview = 'id' in previewParams;

    switch (reportType) {
        case 'EMPLOYEE_PERSONAL_REPORT':
        case 'EMPLOYEE_SECTION_REPORT':
        case 'EMPLOYEE_ADDRESS_REPORT':
        case 'EMPLOYEE_DOCUMENT_REPORT':
        case 'EMPLOYMENT_REPORT':
        case 'EMPLOYEE_ROLE_REPORT':
        case 'WORKING_PATTERN_REPORT':
        case 'REVIEW_FEEDBACK_REPORT':
        case 'LEAVE_TYPE_POLICY_REPORT':
        case 'LEAVE_CORRECTION_REPORT':
        case 'TIMESHEET_ADJUSTMENT_REPORT':
        case 'TIMESHEET_PAYMENT_REPORT':
        case 'OBJECTIVES_REPORT':
        case 'LONG_LEAVE_REPORT':
            // todo: RP-3017 - convert to have resourceId different than employeeId
            // https://rogerhr.atlassian.net/browse/RP-3017
            return (
                isNotPreview ? reportApi.getReportEmployeeRows(previewParams.id, reportType) : reportApi.getReportEmployeesPreview(previewParams, { signal })
            ).then(convertEmployeeRows);
        default:
            return Promise.reject(Error('Unknown report type'));
    }
}

const convertEmployeeRows = (data: ReportEmployeeRow[]): ReportRow[] =>
    data.map(row => ({
        // we set resourceId to be able to use the same component as for each resource
        resourceId: row.employeeId,
        employeeId: row.employeeId,
        fields: row.fields.map(convertRowFieldToReportField),
    }));

const convertRowFieldToReportField = (field: ReportCommonRow['fields'][number]): ReportField => {
    return {
        ...field,
        type: field.fieldDefinition.valueType,
    };
};

export const getReportRowsPreview = (
    previewParams: ReportPreview,
    {
        signal,
    }: {
        signal?: AbortSignal;
    } = {},
): Promise<ReportRow[]> => {
    return getReportRowsByType(previewParams.reportType, previewParams, { signal });
};

export const getReportRows = (id: number, reportType: ReportType): Promise<ReportRow[]> => {
    return getReportRowsByType(reportType, { id });
};

export const updateReport = (mutation: ReportUpdateMutation): Promise<void> => {
    return reportApi.updateReport(mutation);
};

export const updateReportViewers = async (mutation: ReportUpdateViewerMutation): Promise<EmployeeAvatar[]> => {
    await reportApi.updateReportViewers(mutation);
    const { viewers } = await reportApi.getReport(mutation.reportType, mutation.id);
    return viewers;
};

export const getCustomFieldName = (fieldType: ReportFieldDefinition['fieldType'], customFieldId: number | undefined): string => {
    return `${fieldType}_${customFieldId}`;
};

export const getCustomFieldId = (fieldName: string): number => {
    return Number(fieldName.split('_').pop());
};

export const getAvailableReportGroupedFields = async (reportType: ReportType, sectionId: number | undefined): Promise<AvailableReportGroupedField[]> => {
    return reportApi.getAvailableReportGroupedFields(reportType, sectionId);
};
export const getReviewReportView = (view: string | null): 'MATRIX' | 'QUESTION' => {
    const defaultView = 'MATRIX';
    const isAvailableView = (view: string): view is 'MATRIX' | 'QUESTION' => ['MATRIX', 'QUESTION'].includes(view);
    return view && isAvailableView(view) ? view : defaultView;
};
export const convertAvailableFieldToColumn = (field: AvailableReportField, order: number): ReportColumn => {
    return {
        fieldType: field.fieldType,
        valueType: field.valueType,
        sectionFieldDefinition: field.sectionFieldDefinition,
        order,
        visible: true,
    };
};
export const convertReportToUpdateMutation = (
    {
        filters,
        columns,
        historyRange,
        groupByFields,
        sorts = [],
    }: {
        filters: ReportFilterItemBar[];
        columns: ReportColumn[];
        historyRange: [DateFilter, DateFilter] | [];
        groupByFields: ReportGroupBy[];
        sorts?: ReportSort[];
    },
    { id, title, reportType, includeHistory }: Pick<Report, 'id' | 'title' | 'reportType' | 'includeHistory'>,
): ReportUpdateMutation => {
    const fields: ReportUpdateMutation['fields'] = columns?.filter(c => c.visible)?.map(convertReportColumnToFieldDefinitionMutation) ?? [];

    return {
        id,
        reportType,
        title,
        includeHistory,
        historyRange: historyRange.length ? (historyRange.map(h => h.value) as [LocalDate, LocalDate]) : [],
        fields,
        filters: filters?.filter(f => !!f.value).map(convertReportFilterToReportFilterMutation) ?? [],
        groupByFields,
        // For now only table mode is available
        displayMode: 'TABLE',
        sorts,
    };
};

export const convertReportColumnToFieldDefinitionMutation = ({ visible, sectionFieldDefinition, ...field }: ReportColumn): ReportFieldDefinitionMutation => ({
    ...field,
    hidden: !visible,
    size: 0,
    sectionFieldDefinitionId: sectionFieldDefinition?.id,
});
export const convertReportFilterToReportFilterMutation = (filter: ReportFilterItemBar): ReportFilterMutation => {
    const getKey = () => {
        switch (filter.fieldFilterType) {
            case 'LABEL':
            case 'NUMBER':
                return 'numberValue';
            case 'REFERENCE':
            case 'CUSTOM_LIST_ITEM':
            case 'CUSTOM_MULTI_LIST_ITEM':
                return 'referenceId';
            case 'ENUM':
            default:
                return 'stringValue';
        }
    };

    const getDateReportFilterRule = (dateType: DateFilter['dateType']): ReportFilterRule => {
        switch (dateType) {
            case 'WITHIN_THE_LAST':
                return ReportFilterRule.GREATER_THAN_EQUALS;
            case 'MORE_THAN':
                return ReportFilterRule.LOWER_THAN_EQUALS;
            case 'BETWEEN':
                return ReportFilterRule.BETWEEN;
            default:
                return ReportFilterRule.LOWER_THAN_EQUALS;
        }
    };

    const getSelectReportFilterRule = (filter: SelectFilter | AsyncSelectFilter): ReportFilterRule => {
        const isEquals = filter.rule === 'EQUALS';

        if (filter.type === 'multi-select') {
            return isEquals ? ReportFilterRule.IN : ReportFilterRule.NOT_IN;
        }
        return isEquals ? ReportFilterRule.EQUALS : ReportFilterRule.NOT_EQUALS;
    };

    switch (filter.type) {
        case 'select':
        case 'multi-select':
            return {
                fieldType: filter.fieldType,
                sectionFieldDefinitionId: filter.sectionFieldDefinition?.id,
                rule: getSelectReportFilterRule(filter),
                values: filter.value?.map(v => ({ [getKey()]: v.value })) ?? [],
            };
        case 'date': {
            const mutation = {
                fieldType: filter.fieldType,
                sectionFieldDefinitionId: filter.sectionFieldDefinition?.id,
                rule: getDateReportFilterRule(filter.dateType),
            };

            if (filter.dateType === 'BETWEEN') {
                return { ...mutation, values: [{ dateValue: filter.value?.[0] }, { dateValue: filter.value?.[1] }] };
            }

            if (filter.dateType === 'WITHIN_THE_LAST' || filter.dateType === 'MORE_THAN') {
                return { ...mutation, values: [{ dateValue: filter.value }] };
            }
            break;
        }
        case 'text':
            if (filter.fieldValueType === 'NUMBER') {
                const numberValue = isNaN(Number(filter.value)) ? 0 : Number(filter.value);
                return {
                    fieldType: filter.fieldType,
                    sectionFieldDefinitionId: filter.sectionFieldDefinition?.id,
                    rule: ReportFilterRule.EQUALS,
                    values: [
                        {
                            numberValue,
                        },
                    ],
                };
            }
            return {
                fieldType: filter.fieldType,
                sectionFieldDefinitionId: filter.sectionFieldDefinition?.id,
                rule: ReportFilterRule.CONTAINS,
                values: [{ stringValue: filter.value?.toString() }],
            };
    }

    throw new Error(`Filter type ${filter.type} not supported`);
};

/**
 * Determines if the given column matches the given column definition.
 * @param {ReportColumn} column - The column to match.
 * @param {ColDef} c - The column definition to match against.
 * @returns {boolean} True if the column matches the definition, false otherwise.
 */
export const matchCustomFieldColumn = (column: { fieldType: ReportFieldType }, c: ColDef): boolean =>
    column.fieldType === c.field ||
    // When it's a custom field, the column name is EMPLOYEE_CUSTOM_FIELD + sectionFieldDefinition.id
    (column.fieldType === 'EMPLOYEE_CUSTOM_FIELD' && c.field === getFieldName(column));
/**
 * Returns the name of the given field.
 * @param {ReportColumn | ReportFieldDefinition} field - The field to get the name of.
 * @returns {string} The name of the field.
 */
export const getFieldName = (field: { fieldType: ReportFieldType; sectionFieldDefinition?: { id: number | undefined } }): string => {
    return field.fieldType === 'EMPLOYEE_CUSTOM_FIELD' ? getCustomFieldName(field.fieldType, field.sectionFieldDefinition?.id) : field.fieldType;
};
export const isGroupingFilter = (filter: SelectFilter[]): filter is GroupingFilters => filter.length === 2;
export const getPropsForMatrixMode = (mode: 'STACK' | 'MATRIX'): StackProps => {
    if (mode === 'STACK') {
        return {
            gap: 1,
            direction: 'column',
            flexWrap: undefined,
            alignContent: undefined,
        };
    }
    return {
        gap: 1,
        width: '116px',
        height: '70px',
        direction: 'row',
        flexWrap: 'wrap',
        alignContent: 'flex-start',
    };
};

export const AVAILABLE_REPORT_CATEGORY: ReportCategory[] = [
    'REPORT_EMPLOYEE',
    'REPORT_REVIEW',
    'REPORT_LEAVE',
    'REPORT_TIMESHEET',
    'REPORT_OBJECTIVE',
    'REPORT_LONG_LEAVE',
];
