import { LeavesActionType } from '@/stores/reducers/leavesActions';
import { Stack } from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EmployeeLeaves } from './EmployeeLeaves';
import { MyAllowance } from './MyAllowance';

import { EmptyState } from '@/components/empty-state/EmptyState';
import { LeavesEmptyStateIcon } from '@/components/empty-state/EmptyStateIcons';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { getEmployeeLeaveTypePolicies } from '@/domain/employee-leave-type/EmployeeLeaveType.service';
import { LeaveRequest } from '@/domain/leave-request/LeaveRequest.model';
import { getUserLeaveRequests } from '@/domain/leave-request/LeaveRequest.service';
import { useGetLeaveTypeHistories } from '@/hooks/leave-type-history/LeaveTypeHistory.hook';
import { useAppDispatch, useAppSelector } from '@/stores/store';
import { handleError } from '@/utils/api.util';

type LeavesOverviewProps = {
    employeeId: number;
};

export const LeavesOverview: FC<LeavesOverviewProps> = ({ employeeId }) => {
    const { t } = useTranslation();
    const currentEmployee = useAppSelector(state => state.currentEmployee.employee);
    const userLeaveTypePolicies = useAppSelector(state => state.leaves.userLeaveTypePolicies) ?? [];

    const dispatch = useAppDispatch();
    const {
        data: leaveTypeHistories = [],
        isLoading: isLeaveTypeHistoriesLoading,
        refetch: refetchLeaveTypeHistories,
        isError: isLeaveTypeHistoriesError,
    } = useGetLeaveTypeHistories(employeeId);

    const callUserLeaveTypePolicies = useCallback(() => {
        getEmployeeLeaveTypePolicies(employeeId)
            .then(userLeaveTypePolicies => {
                dispatch({
                    type: LeavesActionType.USER_LEAVE_TYPE_POLICY_LOADED,
                    userLeaveTypePolicies,
                });
            })
            .catch(handleError);
    }, [dispatch, employeeId]);

    useEffect(() => {
        callUserLeaveTypePolicies();
    }, [employeeId, callUserLeaveTypePolicies]);

    const [leaveRequests, setLeaveRequests] = useState<LeaveRequest[]>([]);
    const [loading, setLoading] = useState(true);
    const [isError, setIsError] = useState(false);

    const fetchLeaveRequest = useCallback(async () => {
        try {
            const data = await getUserLeaveRequests(employeeId);
            setLeaveRequests(data);
        } catch {
            setIsError(true);
        } finally {
            setLoading(false);
        }
    }, [employeeId]);

    useEffect(() => {
        fetchLeaveRequest().catch(handleError);
    }, [employeeId, fetchLeaveRequest]);

    if (!userLeaveTypePolicies) {
        return <></>;
    }

    const handleChange = () => {
        fetchLeaveRequest().catch(handleError);
        refetchLeaveTypeHistories().catch(handleError);
    };

    const emptyState = <EmptyState icon={<LeavesEmptyStateIcon />} flex={1} title={t('my_leaves.no_leaves')} />;

    if (!currentEmployee) {
        throw new Error('Current employee is not  defined');
    }

    return (
        <Stack direction='column' gap={2} alignItems='stretch' flex={1}>
            <MyAllowance
                employeeId={employeeId}
                userLeaveTypePolicies={userLeaveTypePolicies}
                onPoliciesUpdated={() => {
                    callUserLeaveTypePolicies();
                }}
                onChange={handleChange}
                leaveTypeHistories={leaveTypeHistories}
            />

            <StateHandler
                isLoading={loading || isLeaveTypeHistoriesLoading}
                isError={isError || isLeaveTypeHistoriesError}
                error={new Error('Error to load leave types')}
                isEmpty={!leaveRequests?.length}
                emptyStateComponent={emptyState}
            >
                <EmployeeLeaves employeeId={employeeId} leaveRequests={leaveRequests ?? []} onChange={handleChange} />
            </StateHandler>
        </Stack>
    );
};
