import { WeeklyWorkingTime } from '@/domain/weekly-working-time/WeeklyWorkingTime.model';
import { createWeeklyWorkingTime, deleteWeeklyWorkingTime, updateWeeklyWorkingTime } from '@/domain/weekly-working-time/WeeklyWorkingTime.service';
import { EmployeeFieldMoreButton } from '@/page/employee-profile/employee-profile-info/EmployeeFieldMoreButton/EmployeeFieldMoreButton';
import { useSearchWeeklyWorkingTimes } from '@/page/employee-profile/employee-profile-info/EmpoyeeWorkPatternSection/Components/WeeklyWorkingTime.hook';
import { WeeklyWorkingTimeDialog, WeeklyWorkingTimeFormValues } from '@/page/setting/time-management/working-pattern/WeeklyWorkingTimeDialog';
import { clearSeconds, formatDuration, getMinutesFromHoursMinutes } from '@/utils/datetime.util';
import { showSnackbar } from '@/utils/snackbar.util';
import { Button, Divider, IconButton, Paper, Stack, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MoreVerticalCircle01Icon } from 'hugeicons-react';
import { ConfirmDialog } from '@/components/confirmation-dialog/ConfirmDialog';

export const WeeklyWorkingTimesPage: FC = () => {
    const { t } = useTranslation();

    const [isNewWeeklyWorkingTimeDialogOpen, setIsNewWeeklyWorkingTimeDialogOpen] = useState(false);
    const [weeklyWorkingTimeToUpdate, setWeeklyWorkingTimeToUpdate] = useState<WeeklyWorkingTime>();
    const [weeklyWorkingTimeToDelete, setWeeklyWorkingTimeToDelete] = useState<WeeklyWorkingTime>();

    const { data: weeklyWorkingTimes = [], setData: setWeeklyWorkingTimes } = useSearchWeeklyWorkingTimes();

    const handleCreate = async (newWeeklyWorkingTime: WeeklyWorkingTimeFormValues) => {
        try {
            const data = await createWeeklyWorkingTime({
                ...newWeeklyWorkingTime,
                weeklyAmountWorked: getMinutesFromHoursMinutes(newWeeklyWorkingTime.weeklyAmountWorked),
            });
            // we add the new weekly to the list
            setWeeklyWorkingTimes([...(weeklyWorkingTimes ?? []), data]);
            setIsNewWeeklyWorkingTimeDialogOpen(false);
        } catch {
            showSnackbar(t('settings_time_management.weekly_working_times.create_error'), 'error');
        }
    };
    const handleUpdate =
        ({ id }: WeeklyWorkingTime) =>
        async ({ weeklyAmountWorked, ...rest }: WeeklyWorkingTimeFormValues) => {
            try {
                const data = await updateWeeklyWorkingTime(id, {
                    ...rest,
                    weeklyAmountWorked: getMinutesFromHoursMinutes(weeklyAmountWorked),
                });
                // we update the weekly in the list
                setWeeklyWorkingTimes(weeklyWorkingTimes.map(weeklyWorkingTime => (weeklyWorkingTime.id === id ? data : weeklyWorkingTime)));
                setWeeklyWorkingTimeToUpdate(undefined);
            } catch {
                showSnackbar(t('settings_time_management.weekly_working_times.update_error'), 'error');
            }
        };

    const handleDelete = async ({ id }: WeeklyWorkingTime) => {
        try {
            await deleteWeeklyWorkingTime(id);
            // we remove the weekly from the list
            setWeeklyWorkingTimes(weeklyWorkingTimes.filter(weeklyWorkingTime => weeklyWorkingTime.id !== id));
            setWeeklyWorkingTimeToDelete(undefined);
        } catch {
            showSnackbar(t('settings_time_management.weekly_working_times.delete_error'), 'error');
        }
    };

    const keyPrefix = 'settings_time_management.weekly_working_times.';

    return (
        // This is a hack to fix the layout on mobile
        <Stack maxWidth='100vw' minHeight='100vh' gap={2}>
            <Stack alignItems='flex-end'>
                <Button onClick={() => setIsNewWeeklyWorkingTimeDialogOpen(true)}>
                    {t('settings_time_management.weekly_working_times.add_weekly_working_time')}
                </Button>
            </Stack>
            <Paper sx={{ p: 2 }}>
                <Stack gap={1} divider={<Divider />}>
                    <Stack direction='row' justifyContent='space-between' paddingX={2}>
                        <Typography flex='1' variant='body1bold'>
                            {t(`${keyPrefix}fields.name`)}
                        </Typography>
                        <Typography flex='1' variant='body1bold'>
                            {t(`${keyPrefix}fields.weeklyAmountWorked`)}
                        </Typography>
                        <Typography flex='1' variant='body1bold'>
                            {t(`${keyPrefix}base_schedule`)}
                        </Typography>
                        {/* this is a hack to align header */}
                        <IconButton>
                            <MoreVerticalCircle01Icon visibility='hidden' />
                        </IconButton>
                    </Stack>

                    {weeklyWorkingTimes.map(weeklyWorkingTime => (
                        <Stack key={weeklyWorkingTime.id} direction='row' justifyContent='space-between' paddingX={2}>
                            <Typography flex='1'>{weeklyWorkingTime.name}</Typography>
                            <Typography flex='1'>{formatDuration(weeklyWorkingTime.weeklyAmountWorked)}</Typography>
                            <Typography flex='1'>
                                {clearSeconds(weeklyWorkingTime.startTime)} - {clearSeconds(weeklyWorkingTime.endTime)} ({weeklyWorkingTime.breakDuration})
                            </Typography>

                            <EmployeeFieldMoreButton
                                onEditClicked={() => setWeeklyWorkingTimeToUpdate(weeklyWorkingTime)}
                                onDeleteClicked={() => setWeeklyWorkingTimeToDelete(weeklyWorkingTime)}
                            />
                        </Stack>
                    ))}
                </Stack>
            </Paper>
            {isNewWeeklyWorkingTimeDialogOpen && <WeeklyWorkingTimeDialog onSave={handleCreate} onClose={() => setIsNewWeeklyWorkingTimeDialogOpen(false)} />}
            {weeklyWorkingTimeToUpdate && (
                <WeeklyWorkingTimeDialog
                    onSave={handleUpdate(weeklyWorkingTimeToUpdate)}
                    onClose={() => setWeeklyWorkingTimeToUpdate(undefined)}
                    defaultWeeklyWorkingTime={weeklyWorkingTimeToUpdate}
                />
            )}
            {weeklyWorkingTimeToDelete && (
                <ConfirmDialog
                    open
                    title={t('settings_time_management.weekly_working_times.delete_confirmation_dialog.title')}
                    content={t('settings_time_management.weekly_working_times.delete_confirmation_dialog.content_text')}
                    onConfirm={() => handleDelete(weeklyWorkingTimeToDelete)}
                    onClose={() => setWeeklyWorkingTimeToDelete(undefined)}
                />
            )}
        </Stack>
    );
};
