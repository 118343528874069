import { CompanySettingsLayout } from '../../CompanySettingsLayout';
import { FC, useState } from 'react';
import { AddCategoryDialog, CategoryForm } from './AddCategoryDialog';
import { useTranslation } from 'react-i18next';
import { showSnackbar } from '@/utils/snackbar.util';
import { ConfigType } from '../../types';
import { handleError } from '@/utils/api.util';
import { ICellRendererParams, RowClickedEvent } from '@ag-grid-community/core';
import { ObjectiveCategory } from '@/domain/objective/Objective.model';
import { createObjectiveCategory, deleteObjectiveCategory, updateObjectiveCategory } from '@/domain/objective-category/ObjectiveCategory.service';
import { BasicMenu } from '@/components/basic-menu/BasicMenu';
import { MoreVerticalIcon } from '@/assets/icons/Icons';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { useGetObjectivesCategory } from '@/hooks/objectiveCategory/Objective.hook';
import { getLabelTranslation } from '@/utils/language.util';

export const ObjectiveCategories: FC = () => {
    const { t } = useTranslation();
    const [activeCategoryData, setActiveCategoryData] = useState<
        | {
              objectiveCategory: ObjectiveCategory | undefined;
          }
        | undefined
    >(undefined);

    const {
        data: objectiveCategories = [],
        isLoading: isObjectivesCategoryLoading,
        error: objectivesCategoryError,
        isError: isObjectivesCategoryError,
        refetch: refetchObjectivesCategory,
    } = useGetObjectivesCategory();

    const onClickCategoriesRow = (category: ObjectiveCategory) => {
        setActiveCategoryData({
            objectiveCategory: category,
        });
    };

    const resetAndRefetch = async () => {
        await refetchObjectivesCategory();
        setActiveCategoryData(undefined);
    };

    const onDeleteCategory = async (categoryId: number) => {
        try {
            await deleteObjectiveCategory(categoryId);
            showSnackbar(t('objectives_settings_page.messages.objective_category_deleted'), 'success');
        } catch (error) {
            handleError(error);
        } finally {
            await resetAndRefetch();
        }
    };

    const handleCreateCategory = async (category: CategoryForm) => {
        const request = {
            name: category.name,
            order: objectiveCategories.length,
        };

        try {
            await createObjectiveCategory(request);
            showSnackbar(t('objectives_settings_page.messages.objective_category_created'), 'success');
        } catch (error) {
            handleError(error);
        } finally {
            await resetAndRefetch();
        }
    };

    const handleEditCategory = async (category: CategoryForm, id: number) => {
        const request = {
            name: category.name,
            order: objectiveCategories.length,
        };

        try {
            await updateObjectiveCategory(id, request);
            showSnackbar(t('objectives_settings_page.messages.objective_category_updated'), 'success');
        } catch (error) {
            handleError(error);
        } finally {
            await resetAndRefetch();
        }
    };

    const onSaveCategory = (category: CategoryForm) => {
        if (activeCategoryData?.objectiveCategory?.id) {
            return handleEditCategory(category, activeCategoryData.objectiveCategory.id);
        }

        return handleCreateCategory(category);
    };

    const primaryAction = () => {
        setActiveCategoryData({
            objectiveCategory: undefined,
        });
    };

    const cellActionRenderer = (params: ICellRendererParams<ObjectiveCategory>) => {
        return (
            <BasicMenu
                items={[
                    {
                        title: t('general.edit'),
                        onClick: () => (params.data ? onClickCategoriesRow(params.data) : undefined),
                    },
                    {
                        title: t('general.delete'),
                        onClick: () => (params.data ? onDeleteCategory(params.data.id) : undefined),
                    },
                ]}
                endIcon={<MoreVerticalIcon />}
            />
        );
    };

    const config: ConfigType<ObjectiveCategory> = {
        type: 'table',
        isOnRowClickActive: true,
        header: {
            primaryButtonCaption: t('objectives_settings_page.category'),
            primaryButtonAction: primaryAction,
        },
        table: {
            agGridProps: {
                onRowClicked: (params: RowClickedEvent<ObjectiveCategory>) => (params.data ? onClickCategoriesRow(params.data) : undefined),
            },
            columns: [
                {
                    field: 'name',
                    valueFormatter: ({ value }) => getLabelTranslation(value),
                    headerName: t('objectives_settings_page.categories'),
                },
                {
                    type: 'actionMenu',
                    cellRenderer: cellActionRenderer,
                },
            ],
        },
    };

    return (
        <StateHandler isLoading={isObjectivesCategoryLoading} isError={isObjectivesCategoryError} error={objectivesCategoryError}>
            {objectiveCategories && (
                <>
                    <CompanySettingsLayout options={config} data={objectiveCategories} isSearchAvailable />
                    {!!activeCategoryData && (
                        <AddCategoryDialog
                            open={true}
                            handleSave={onSaveCategory}
                            closeDialog={() => setActiveCategoryData(undefined)}
                            activeCategoryData={activeCategoryData.objectiveCategory}
                            mode={activeCategoryData.objectiveCategory?.id ? 'edit' : 'create'}
                        />
                    )}
                </>
            )}
        </StateHandler>
    );
};
