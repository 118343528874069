import { RogerColDef } from '@/components/ag-grid-wrapper/AgGridWrapper';
import { useAgGridWrapper } from '@/components/ag-grid-wrapper/useAgGridWrapper';
import { EmptyState } from '@/components/empty-state/EmptyState';
import { EmptyStateIcon } from '@/components/empty-state/icons/EmptyStateIcon';
import { Footer, FooterActions } from '@/page/layout/Footer';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { ThirdPartyMappingValue, ThirdPartyMappingValueRequest } from '@/domain/third-party-mapping/ThirdPartyMapping.model';
import { saveThirdPartyMappingValues } from '@/domain/third-party-mapping/ThirdPartyMapping.service.';
import { useGetThirdPartyMappingValues } from '@/hooks/third-party-mapping/ThirdPartyMapping.hook';
import { CompanySettingsLayout } from '@/page/setting/CompanySettingsLayout';
import { ConfigType } from '@/page/setting/types';
import { substringAfterLast } from '@/utils/strings.util';
import { CellValueChangedEvent, IRowNode, ModuleRegistry, NestedFieldPaths } from '@ag-grid-community/core';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ContentContainer } from '@/page/layout/ContentContainer';

ModuleRegistry.registerModules([ClipboardModule]);

export const ThirdPartyMappingSettingPage: FC = () => {
    const { t } = useTranslation();
    const params = useParams();
    const mappingId = Number(params.mappingId) || undefined;
    const agGridWrapper = useAgGridWrapper<ThirdPartyMappingValue>();

    const { data: thirdPartyMappingValues = [], isLoading, isError, error, setData } = useGetThirdPartyMappingValues(mappingId);

    const columns: RogerColDef<ThirdPartyMappingValue>[] = [
        {
            field: 'referenceId',
            headerName: t('third_party_mapping_setting_page.table_headers.referenceId'),
            valueFormatter: ({ value }) => substringAfterLast(value, '_'),
            cellStyle: ({ data }) => {
                // this is a hack to differentiate custom list and items
                // custom list and sections have referenceId with underscore, ex: CUSTOM_LIST_123
                if (data?.referenceId?.includes('_')) {
                    return { fontWeight: 'bold' };
                }
                return undefined;
            },
        },
        {
            field: 'label',
            headerName: t('third_party_mapping_setting_page.table_headers.label'),
            type: 'label',
            cellStyle: ({ data }) => {
                // this is a hack to differentiate custom list and items
                // custom list and sections have referenceId with underscore, ex: CUSTOM_LIST_123
                if (data?.referenceId?.includes('_')) {
                    return { fontWeight: 'bold' };
                }
                return undefined;
            },
        },
    ];

    // This version doesn't allow duplicate third party names
    const thirdPartyNames = new Set(thirdPartyMappingValues.flatMap(v => v.thirdPartyIdentifiers).map(v => v.thirdPartyName));

    [...thirdPartyNames].forEach((name, index) => {
        columns.push({
            headerName: name,
            // ColId is used to identify the column for cell editing
            colId: name,
            // AG grid seems not handle double nested field paths
            // Its ok to do `thirdPartyIdentifiers.${index}` but not `thirdPartyIdentifiers.${index}.value`
            field: `thirdPartyIdentifiers.${index}.value` as NestedFieldPaths<ThirdPartyMappingValue>,
            editable: true,
            cellDataType: false,
        });
    });

    const handleCellValueChange = ({ data, newValue, column }: CellValueChangedEvent<ThirdPartyMappingValue, string>) => {
        const currentRowReferenceId = data.referenceId;
        const colId = column?.getColId();

        const replaceCellValue = (row: ThirdPartyMappingValue, colId: string, newValue: string): ThirdPartyMappingValue => {
            // We are using thirdPartyName as colId
            const thirdPartyIdentifiers = row.thirdPartyIdentifiers.map(c =>
                c.thirdPartyName === colId
                    ? {
                          ...c,
                          value: newValue,
                      }
                    : c,
            );
            return { ...row, thirdPartyIdentifiers };
        };

        setData(rows => rows?.map(row => (row.referenceId === currentRowReferenceId ? replaceCellValue(row, colId, newValue ?? '') : row)));
    };

    const config: ConfigType<ThirdPartyMappingValue> = {
        type: 'table',
        isOnRowClickActive: false,
        table: {
            columns: columns,
            agGridProps: {
                initRef: agGridWrapper.setGridRef,
                // Needed for cell editing
                suppressCellFocus: false,
                onCellValueChanged: handleCellValueChange,
                getRowId: params => params.data.referenceId,
            },
        },
    };

    const emptyState = <EmptyState icon={<EmptyStateIcon />} flex={1} title={t('third_party_mapping_setting_page.empty')} />;

    // useCallback is used to update function reference when AgGrid ref is defined
    const handleSave = useCallback(() => {
        if (!mappingId) {
            return;
        }

        // extract all cells from thirdPartyMappingValues
        type Cell = ThirdPartyMappingValueRequest;

        const getCell = (rowId: string, colId: string): Cell => {
            const rowNode: IRowNode<ThirdPartyMappingValue> | undefined = agGridWrapper.gridRef.current?.api?.getRowNode(rowId);
            if (!rowNode) {
                throw new Error(`Row with id ${rowId} not found`);
            }
            const thirdPartyId = rowNode.data?.thirdPartyIdentifiers.find(({ thirdPartyName }) => thirdPartyName === colId)?.thirdPartyId;

            if (!thirdPartyId) {
                throw new Error(`Third party identifier with name ${colId} not found`);
            }

            // We are using colId as thirdPartyName
            const value = agGridWrapper.gridRef.current?.api?.getCellValue({
                rowNode,
                colKey: colId,
            });

            return {
                referenceId: rowId,
                thirdPartyId: thirdPartyId,
                value: value ?? '',
            };
        };

        const cells: Cell[] = thirdPartyMappingValues.flatMap(v => v.thirdPartyIdentifiers.map(({ thirdPartyName: colId }) => getCell(v.referenceId, colId)));

        saveThirdPartyMappingValues(mappingId, cells);
    }, [agGridWrapper.gridRef, mappingId, thirdPartyMappingValues]);

    const handleExport = useCallback(() => {
        agGridWrapper.gridRef.current?.api?.exportDataAsExcel();
    }, [agGridWrapper.gridRef]);

    if (!mappingId) {
        return;
    }

    return (
        <StateHandler isLoading={isLoading} isError={isError} error={error} isEmpty={!thirdPartyMappingValues.length} emptyStateComponent={emptyState}>
            <ContentContainer>
                <CompanySettingsLayout
                    options={config}
                    data={thirdPartyMappingValues}
                    isSearchAvailable={true}
                    onExport={handleExport}
                    onQuickFilter={agGridWrapper.quickFilter}
                />
            </ContentContainer>
            <Footer>
                <FooterActions
                    actions={[
                        {
                            name: 'save',
                            onClick: handleSave,
                            children: t('general.save'),
                            variant: 'contained',
                        },
                    ]}
                />
            </Footer>
        </StateHandler>
    );
};
