import { FilterType } from '@/components/filters-bar/FiltersBar';
import { useLocalStorage, useSessionStorage } from '@/hooks/Storage.hook';
import { useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';

export const useFiltersStorage = <T extends FilterType>(storageKey: string, availableFilters: T[]): [T[], (filters: T[]) => void] => {
    // TODO to be removed after transition to local storage https://rogerhr.atlassian.net/browse/RP-5511
    const [filterInSessionStorage] = useSessionStorage<FilterType[]>(storageKey, []);
    // TODO challenge typing because we get strings from the session storage
    const [filterInLocalStorage, setFilterInLocalStorage] = useLocalStorage<FilterType[]>(storageKey, []);
    const [filters, setFilters] = useState<T[]>([]);

    const handleFilterChange = (filters: T[]) => {
        setFilterInLocalStorage(filters);
        setFilters(filters);
    };

    // get the filters from the local storage
    useDeepCompareEffect(() => {
        if (filters?.length) {
            return;
        }

        // set the filters from the session storage to the local storage for the transition period
        if (filterInSessionStorage.length) {
            setFilterInLocalStorage(filterInSessionStorage);
        }

        const filterInStorage = filterInLocalStorage.length ? filterInLocalStorage : filterInSessionStorage;
        if (filterInStorage.length) {
            const currentFilters = availableFilters.map(filter => {
                const filterFromStorage = filterInStorage.find(filterFromStorage => filterFromStorage.key === filter.key);
                return { ...filter, value: filterFromStorage?.value };
            });

            setFilters(currentFilters);
            return;
        }
        setFilters(availableFilters);
    }, [availableFilters, filters]);

    return [filters, handleFilterChange];
};
