import { InputNumber } from '@/components/form/field-number/InputNumber';
import { UnitType } from '@/domain/date/Date.model';
import { createDefaultLeaveTypePolicy } from '@/domain/leave-request/LeaveRequest.service';
import { LeaveTypePolicy } from '@/domain/leave-type/LeaveType.model';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    IconButton,
    Stack,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { Cancel01Icon } from 'hugeicons-react';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getNull } from '@/utils/object.util';

type Props = {
    open: boolean;
    unitType: UnitType;
    defaultPolicy?: LeaveTypePolicy;
    onSave: (policy: LeaveTypePolicy) => void;
    onDelete?: (policy: LeaveTypePolicy) => void;
    onClose: () => void;
};

export const LeavePolicyDialog: FC<Props> = ({ open, unitType, defaultPolicy, onSave, onDelete, onClose }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [policy, setPolicy] = useState<LeaveTypePolicy>(createDefaultLeaveTypePolicy());

    // This is a hack to check if the policy is being edited or created
    const isEdit = !!defaultPolicy?.name;

    useEffect(() => {
        if (open && defaultPolicy) {
            setPolicy(defaultPolicy);
        }
    }, [open, defaultPolicy]);

    const isFormValid = () => {
        return policy.name;
    };

    const handleSave = () => {
        onSave(policy);
        onClose();
    };

    const getAllowanceNumberRounded = (number: number) => {
        return Math.round(number * 10) / 10;
    };
    return (
        <Dialog open={open} fullScreen={fullScreen} onClose={onClose}>
            <DialogTitle>
                {isEdit ? t('add_leave_policy_dialog.title_edit_policy') : t('add_leave_policy_dialog.title_create_policy')}
                <IconButton aria-label='close' onClick={onClose} size='large'>
                    <Cancel01Icon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Stack spacing={3}>
                    <Stack spacing={2}>
                        <Typography variant='body1bold'>{t('add_leave_policy_dialog.about_policy')}</Typography>
                        <FormControlLabel
                            label={t('add_leave_policy_dialog.policy_name')}
                            control={
                                <TextField
                                    autoFocus
                                    value={policy.name}
                                    fullWidth
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setPolicy({ ...policy, name: event.target.value });
                                    }}
                                />
                            }
                        />
                    </Stack>

                    <Stack spacing={2}>
                        <Typography variant='body1bold'>{t('add_leave_policy_dialog.balance')}</Typography>
                        {unitType !== UnitType.HOURS && (
                            <FormControlLabel
                                label={t('add_leave_policy_dialog.allowance_days')}
                                control={
                                    <InputNumber
                                        value={getAllowanceNumberRounded(policy.allowanceInMinutes / 1440)}
                                        fullWidth
                                        onChange={value => {
                                            /* allow 0 */
                                            if (value == getNull()) {
                                                return;
                                            }
                                            setPolicy({
                                                ...policy,
                                                allowanceInMinutes: value * 1440,
                                            });
                                        }}
                                    />
                                }
                            />
                        )}
                        {unitType === UnitType.HOURS && (
                            <FormControlLabel
                                label={t('add_leave_policy_dialog.allowance_hours')}
                                control={
                                    <InputNumber
                                        value={getAllowanceNumberRounded(policy.allowanceInMinutes / 60)}
                                        fullWidth
                                        onChange={value => {
                                            /* allow 0 */
                                            if (value == getNull()) {
                                                return;
                                            }
                                            setPolicy({
                                                ...policy,
                                                allowanceInMinutes: value * 60,
                                            });
                                        }}
                                    />
                                }
                            />
                        )}
                    </Stack>
                </Stack>
            </DialogContent>
            <DialogActions>
                {isEdit && (
                    <Button
                        variant='text'
                        color='error'
                        onClick={() => {
                            onDelete?.(policy);
                            onClose();
                        }}
                    >
                        {t('add_leave_policy_dialog.delete_policy')}
                    </Button>
                )}
                <Button variant='contained' color='primary' disabled={!isFormValid()} onClick={() => handleSave()}>
                    {t('general.save')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
