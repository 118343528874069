import { MuiAlert, MuiAlertTitle } from '@/theme/MuiAlert';
import { MuiAutocomplete } from '@/theme/MuiAutocomplete';
import { MuiAvatar } from '@/theme/MuiAvatar';
import { MuiButtonGroup } from '@/theme/MuiButtonGroup';
import { MuiCheckbox } from '@/theme/MuiCheckbox';
import { MuiChip } from '@/theme/MuiChip';
import { MuiDialog, MuiDialogActions, MuiDialogContent, MuiDialogTitle } from '@/theme/MuiDialog';
import { MuiDivider } from '@/theme/MuiDivider';
import { MuiInputBase, MuiOutlinedInput } from '@/theme/MuiInputBase';
import { MuiLink } from '@/theme/MuiLink';
import { MuiPopper } from '@/theme/MuiPopper';
import { MuiRadio } from '@/theme/MuiRadio';
import { MuiSwitch } from '@/theme/MuiSwitch';
import { MuiToggleButton } from '@/theme/MuiToggleButton';
import { MuiToolbar } from '@/theme/MuiToolbar';
import { MuiTooltip } from '@/theme/MuiTooltip';
import { createTheme, CustomTheme, Theme, ThemeOptions } from '@mui/material/styles';
import { MuiButton } from './MuiButton';
import { MuiFormControlLabel } from './MuiFormControlLabel';
import { MuiMenu, MuiMenuItem } from './MuiMenu';
import { MuiPaper } from './MuiPaper';
import { MuiTabs } from './MuiTabs';
import { MuiTextField } from './MuiTextField';
import { MuiToggleButtonGroup } from './MuiToggleButtonGroup';
import { palette } from './palette';
import { shadows } from './shadows';
import { shape } from './shape';
import { typography } from './typography';
import { CSSProperties } from 'react';
import { MuiLinearProgress } from '@/theme/MuiLinearProgress';

declare module '@mui/material/styles' {
    interface DefaultTheme extends CustomTheme {
        toolbarHeight: number;
        logo: CSSProperties;
    }
}

declare module '@mui/material/styles' {
    interface CustomTheme extends Theme {
        toolbarHeight: number;
        logo: CSSProperties;
    }

    // allow configuration using `createTheme`
    interface CustomThemeOptions extends ThemeOptions {
        toolbarHeight?: number;
        logo?: CSSProperties;
    }

    export function createTheme(options?: CustomThemeOptions): CustomTheme;
}

declare module '@mui/material/styles' {
    interface TypographyVariants {
        body1bold: CSSProperties;
        body2bold: CSSProperties;
        body3: CSSProperties;
        body3bold: CSSProperties;
        body4: CSSProperties;
        body4bold: CSSProperties;
    }

    // allow configuration using `createMuiTheme`
    interface TypographyVariantsOptions {
        body1bold: CSSProperties;
        body2bold: CSSProperties;
        body3: CSSProperties;
        body3bold: CSSProperties;
        body4: CSSProperties;
        body4bold: CSSProperties;
    }
}

declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        h1: true;
        h2: true;
        h3: true;
        body1bold: true;
        body2bold: true;
        body3: true;
        body3bold: true;
        body4: true;
        h4: false;
        h5: false;
        h6: false;
        body4bold: true;
        caption: false;
        overline: false;
        subtitle1: false;
        subtitle2: false;
    }
}

declare module '@mui/material/Chip' {
    interface ChipPropsVariantOverrides {
        hight: true;
    }
}

export const createApplicationTheme = (): CustomTheme => {
    return createTheme({
        components: {
            MuiInputBase,
            MuiOutlinedInput,
            MuiTextField,
            MuiAlert,
            MuiAlertTitle,
            MuiLink,
            MuiButton,
            MuiButtonGroup,
            MuiChip,
            MuiFormControlLabel,
            MuiAutocomplete,
            MuiToggleButton,
            MuiToggleButtonGroup,
            MuiPaper,
            MuiMenu,
            MuiMenuItem,
            MuiDialog,
            MuiDialogTitle,
            MuiDialogActions,
            MuiDialogContent,
            MuiTooltip,
            MuiPopper,
            MuiAvatar,
            MuiSwitch,
            MuiCheckbox,
            MuiRadio,
            MuiTabs,
            MuiToolbar,
            MuiLinearProgress,
            MuiDivider,
            MuiTypography: {
                defaultProps: {
                    fontStyle: 'normal',
                    color: 'textPrimary',
                    variantMapping: {
                        h1: 'h1',
                        h2: 'h2',
                        h3: 'h3',
                        body1bold: 'p',
                        body2bold: 'p',
                        body3bold: 'p',
                        body4bold: 'p',
                        button: 'p',
                    },
                },
                styleOverrides: {
                    root: {
                        '& .rp-underline': {
                            textDecoration: 'underline dashed',
                            textUnderlineOffset: '4px',
                        },
                    },
                },
            },
        },
        logo: {
            fontFamily: 'Inter',
            fontWeight: 900,
            fontSize: 20,
        },
        typography,
        palette,
        shape,
        shadows,
    });
};
