import { Label, LabelRequest } from '../label/Label.model';
import { ReportFieldType } from '@/domain/report/Report.model';
import { EmployeeSectionFieldDocument } from '@/domain/employee-section/EmployeeSection.model';
import { EmployeeAvatar } from '@/domain/employee/Employee.model';
import { CustomList } from '@/domain/custom-list/CustomList.model';
import { EmployeeFieldType } from '@/domain/employee/EmployeeFields.model';

export enum SectionType {
    BASIC_INFO = 'BASIC_INFO',
    PERSONAL_INFO = 'PERSONAL_INFO',
    CONTRACT = 'CONTRACT',
    EMPLOYMENT = 'EMPLOYMENT',
    WORK_PATTERN = 'WORK_PATTERN',
    ADDRESS = 'ADDRESS',
    LONG_LEAVE = 'LONG_LEAVE',
    CUSTOM_SINGLE_ROW = 'CUSTOM_SINGLE_ROW',
    CUSTOM_MULTI_ROW = 'CUSTOM_MULTI_ROW',
}

export const SECTION_FIELD_VALUE_TYPES = [
    'STRING',
    'NUMBER',
    'DATE',
    'REFERENCE',
    'CUSTOM_LIST_ITEM',
    'CUSTOM_MULTI_LIST_ITEM',
    'DOCUMENT',
    'SECTION_FIELD_DOCUMENT',
    'ENUM',
    'LABEL',
    'EMPLOYEE',
    'COUNTRY',
    'PHONE_NUMBER',
    'IBAN_NUMBER',
    'AVS_NUMBER',
    'EMAIL',
] as const;
export type SectionFieldValueType = (typeof SECTION_FIELD_VALUE_TYPES)[number];

type SectionTab = 'PROFILE' | 'JOB';

export type SectionDefinition = {
    id: number;
    name: Label;
    type: SectionType;
    order: number;
    includeInPayroll: boolean;
    fields: SectionFieldDefinition[];
    tab: SectionTab;
    archived: boolean;
    unmodifiable: boolean;
};

export enum SectionRowStatus {
    PENDING = 'PENDING',
    APPROVED = 'APPROVED',
}

type SectionFieldType = EmployeeFieldType | ReportFieldType;
/**
 * Field definition used to define a custom field in a section
 * Fields are defined in DB for :
 * - custom fields created by admins in real settings page (/settings/employees/fields)
 * - fields in Basic info, personal info and address sections
 * Field are not defined in DB for section in job tab : Contract, Employment, Work pattern, Long leave
 */
export type SectionFieldDefinition = {
    id: number | undefined;
    // formId does not exist in the BE
    // formId is the id when it's defined or the fieldType. It's used to identify the field in the frontend
    // (string & {}) is a tips to have autocompletion but needs to disable eslint rule
    // eslint-disable-next-line sonarjs/no-useless-intersection
    formId: SectionFieldType | (string & {});
    name: Label;
    fieldType: SectionFieldType;
    valueType: SectionFieldValueType;
    order: number;
    mandatory: boolean;
    customList?: CustomList;
    archived: boolean;
    unmodifiable: boolean;
};

export type SectionDefinitionIncludeInPayrollMutation = {
    includeInPayroll: boolean;
};

/**
 * Common field definition used to handle non custom fields and custom fields
 * Non custom fields are fields existing in the system by default
 * Custom fields are fields created by admins in real settings page (/settings/employees/fields)
 */
export type FieldDefinition = {
    id?: number;
    name?: Label;
    // TODO move reportFieldType enum to this file
    fieldType: ReportFieldType;
    valueType: SectionFieldValueType;
};

/**
 * Field value that can be used to display a field value in a table
 * TODO: Improve typing by using a union type for the value
 */
export type SectionFieldValue = {
    sectionFieldDefinition: FieldDefinition;
    dateValue?: LocalDate;
    numberValue?: number;
    stringValue?: string;
    documents: EmployeeSectionFieldDocument[];
    labelValue?: Label;
    customListItemReferences: { id: number; label: Label }[];
    // TODO: Back end should use the same type employeeReferences or employeeValues and not both
    employeeValues: EmployeeAvatar[];
    employeeReferences: EmployeeAvatar[];
};

export type SectionDefinitionCreateRequest = {
    name: LabelRequest;
    type: SectionType;
    order: number;
    includeInPayroll: boolean;
    // not used by the frontend to create a section definition
    fields?: SectionFieldDefinitionRequest[];
    tab: SectionTab;
};

export type SectionDefinitionIncludeInPayrollRequest = {
    includeInPayroll: boolean;
};

export type SectionFieldDefinitionRequest = {
    name: LabelRequest;
    fieldType: SectionFieldDefinition['fieldType'];
    valueType: SectionFieldValueType;
    order: number;
    mandatory: boolean;
    customListId?: number;
};

export type SectionDefinitionUpdateRequest = {
    name: LabelRequest;
    order: number;
    includeInPayroll: boolean;
    fields?: SectionFieldDefinitionRequest[];
};

export type SectionDefinitionSearchRequest = {
    withArchived?: boolean;
};
