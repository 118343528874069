import { DialogContainer } from '@/components/dialog-container/DialogContainer';
import { FieldLocalDate } from '@/components/form/field-date/FieldDate';
import { LongLeave } from '@/domain/long-leave/LongLeave.model';
import { formatToLocalDate, getLocalDateMinTestConfig, getLocalDateTestConfig } from '@/utils/datetime.util';

import { FieldText } from '@/components/form/field-text/FieldText';
import { Employment } from '@/domain/employment/Employment.model';
import { getContracts, isDateInAllowedRanges } from '@/domain/employment/Employment.service';
import { getNull } from '@/utils/object.util';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormControlLabel, Stack } from '@mui/material';
import i18next, { t } from 'i18next';
import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';

type LongLeaveFormProps = {
    setLongLeaveDialogOpen: (open: boolean) => void;
    onSaveLongLeave: (longLeaveRequestForm: LongLeaveFormValues) => void;
    longLeaveDialogOpen: boolean;
    employeeId: number;
    currentLongLeave?: LongLeave;
    employments: Employment[];
};

export const LongLeaveDialog: FC<LongLeaveFormProps> = ({
    setLongLeaveDialogOpen,
    onSaveLongLeave,
    employeeId,
    currentLongLeave,
    longLeaveDialogOpen,
    employments,
}) => {
    // all existing contract
    const allContracts = getContracts(employments);

    // startDate and enDate for the long leave should be within the range of the existing contracts
    const allowedRanges: [LocalDate, LocalDate | undefined][] = allContracts.map(contract => [contract.startDate, contract.endDate]);

    const schema = getLongLeaveSchema({ allowedRanges });

    const formMethods = useForm<LongLeaveFormValues>({
        resolver: yupResolver(schema),
        defaultValues: {
            employeeId: employeeId,
            startDate: currentLongLeave?.startDate,
            endDate: currentLongLeave?.endDate,
            comment: currentLongLeave?.comment ?? '',
        },
    });

    const { control, handleSubmit } = formMethods;

    const getLongLeaveDialogTitle = () => {
        if (currentLongLeave?.id) {
            return t('employee.employment.long_leave.modify_long_leave');
        }
        return t('employee.employment.long_leave.put_on_long_leave');
    };

    return (
        <DialogContainer
            open={longLeaveDialogOpen}
            onClose={() => setLongLeaveDialogOpen(false)}
            onSave={handleSubmit(longLeaveCreateRequest => onSaveLongLeave(longLeaveCreateRequest), console.error)}
            title={getLongLeaveDialogTitle()}
        >
            <FormProvider {...formMethods}>
                <Stack direction='column' spacing={2}>
                    <FormControlLabel
                        label={t('general.start')}
                        control={
                            <FieldLocalDate
                                control={control}
                                name='startDate'
                                datePickerProps={{
                                    shouldDisableDate: date => !isDateInAllowedRanges(formatToLocalDate(date), allowedRanges),
                                }}
                            />
                        }
                    />
                    <FormControlLabel
                        label={t('general.end')}
                        control={
                            <FieldLocalDate
                                control={control}
                                name='endDate'
                                datePickerProps={{
                                    shouldDisableDate: date => !isDateInAllowedRanges(formatToLocalDate(date), allowedRanges),
                                }}
                            />
                        }
                    />

                    <FormControlLabel label={t('general.comment')} control={<FieldText name={'comment'} control={control} fullWidth />} />
                </Stack>
            </FormProvider>
        </DialogContainer>
    );
};

const getLongLeaveSchema = ({ allowedRanges }: { allowedRanges: [LocalDate, LocalDate | undefined][] }) => {
    return yup.object().shape({
        employeeId: yup.number().required().default(getNull()),
        startDate: yup
            .string<LocalDate>()
            .test(getLocalDateTestConfig())
            .test({
                name: 'start-date-outside-contract',
                message: i18next.t('employee.employment.long_leave.no_long_leave_outside_contract'),
                test: startDate => {
                    return !startDate || isDateInAllowedRanges(startDate, allowedRanges);
                },
            })
            .required(),
        endDate: yup
            .string<LocalDate>()
            .test(getLocalDateTestConfig())
            .when('startDate', ([startDate], schema) => schema.test(getLocalDateMinTestConfig(startDate)))
            .test({
                name: 'end-date-outside-contract',
                message: i18next.t('employee.employment.long_leave.no_long_leave_outside_contract'),
                test: endDate => {
                    return !endDate || isDateInAllowedRanges(endDate, allowedRanges);
                },
            })
            .required(),
        comment: yup.string().required().default(''),
    });
};

export type LongLeaveFormValues = yup.InferType<ReturnType<typeof getLongLeaveSchema>>;
