import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FC, useState } from 'react';
import { CloseObjectiveDialog } from './CloseObjectiveDialog';
import { ObjectiveItem } from './ObjectiveItem';

import { ObjectiveHistoryDialog } from '@/page/objective/objectives-list/ObjectiveHistoryDialog';
import { useTranslation } from 'react-i18next';
import { handleError } from '@/utils/api.util';
import { archiveObjective, closeObjective, deleteObjective, reopenObjective, unarchiveObjective } from '@/domain/objective/Objective.service';
import { showSnackbar } from '@/utils/snackbar.util';
import { Objective, ObjectiveCloseMutation } from '@/domain/objective/Objective.model';
import { ConfirmDialog } from '@/components/confirmation-dialog/ConfirmDialog';
import Divider from '@mui/material/Divider';

type Props = {
    objectives: Objective[];
    displayWeight: boolean;
    onSuccess: () => void;
    onEditObjective: (id: number) => void;
    editable?: boolean;
    disabled?: boolean;
    displayAvatarsMobile: boolean;
};

export const ObjectivesList: FC<Props> = ({
    objectives = [],
    displayWeight,
    onSuccess,
    onEditObjective,
    displayAvatarsMobile,
    editable = true,
    disabled = false,
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { t } = useTranslation();
    const [closeObjectiveDialogOpen, setCloseObjectiveDialogOpen] = useState<boolean>(false);
    const [activeObjective, setActiveObjective] = useState<Objective>();
    const [objectiveToOpenInDetailView, setObjectiveToOpenInDetailView] = useState<Objective>();
    const [currentObjectiveToDeleteId, setCurrentObjectiveToDeleteId] = useState<number>();

    const handleDeleteConfirmed = async (id: number) => {
        try {
            await deleteObjective(id);
        } catch (e) {
            handleError(e);
        } finally {
            onSuccess();
            setCurrentObjectiveToDeleteId(undefined);
        }
    };

    const onCompleteObjective = (id: number) => (data: ObjectiveCloseMutation) => {
        closeObjective(id, data)
            .then(() => {
                showSnackbar(t('objectives.messages.closed'), 'success');
                setCloseObjectiveDialogOpen(false);
                onSuccess();
            })
            .catch(handleError);
    };

    const onReopenObjective = (id: number) => {
        reopenObjective(id)
            .then(() => {
                showSnackbar(t('objectives.messages.reopened'), 'success');
                onSuccess();
            })
            .catch(handleError);
    };

    const onArchiveObjective = async (id: number) => {
        try {
            await archiveObjective(id);
            showSnackbar(t('objectives.messages.archived'), 'success');
            onSuccess();
        } catch (error) {
            handleError(error);
        }
    };

    const onUnarchiveObjective = async (id: number) => {
        try {
            await unarchiveObjective(id);
            showSnackbar(t('objectives.messages.unarchived'), 'success');
            onSuccess();
        } catch (error) {
            handleError(error);
        }
    };

    return (
        <>
            <Stack direction='column' p={2} pb={1}>
                {!isMobile && (
                    <>
                        <Stack gap={1} marginLeft={0} marginRight={0} pb={0} pl={1} direction={'row'}>
                            <Stack style={{ minWidth: '200px' }} flex={1}>
                                <Typography variant='body1bold'>{t('objectives.table.objective')}</Typography>
                            </Stack>
                            <Stack
                                style={{
                                    width: '120px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <Typography variant='body1bold' alignItems={'center'}>
                                    {t('objectives.table.assignee')}
                                </Typography>
                            </Stack>
                            <Stack
                                style={{
                                    minWidth: '140px',
                                    width: '140px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <Typography variant='body1bold'>{t('objectives.table.deadline')}</Typography>
                            </Stack>
                            <Stack
                                style={{
                                    minWidth: '120px',
                                    width: '120px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <Typography variant='body1bold'>{t('objectives.table.updated_on')}</Typography>
                            </Stack>
                            <Stack
                                style={{
                                    minWidth: '60px',
                                    width: '60px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <Typography variant='body1bold'>{t('objectives.table.status')}</Typography>
                            </Stack>
                            <Stack
                                style={{
                                    minWidth: '40px',
                                    width: '40px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            />
                        </Stack>
                        <Divider sx={{ width: '100%', marginTop: '8px' }} />
                    </>
                )}
                <Stack divider={<Divider />}>
                    {objectives.map(objective => {
                        return (
                            <ObjectiveItem
                                key={`objective_${objective.id}_${objective.name}_${objective.completedAt}`}
                                objective={objective}
                                displayWeight={displayWeight}
                                onCloseObjective={() => {
                                    setActiveObjective(objective);
                                    setCloseObjectiveDialogOpen(true);
                                }}
                                onDelete={() => setCurrentObjectiveToDeleteId(objective.id)}
                                onEdit={onEditObjective}
                                onReopen={onReopenObjective}
                                onArchive={onArchiveObjective}
                                onUnarchive={onUnarchiveObjective}
                                editable={editable}
                                onOpenDetailClick={() => setObjectiveToOpenInDetailView(objective)}
                                showUsersMobile={!displayAvatarsMobile}
                                disabled={disabled}
                            />
                        );
                    })}
                </Stack>
            </Stack>
            {closeObjectiveDialogOpen && activeObjective?.id && (
                <CloseObjectiveDialog
                    onComplete={onCompleteObjective(activeObjective.id)}
                    onClose={() => {
                        setActiveObjective(undefined);
                        setCloseObjectiveDialogOpen(false);
                    }}
                />
            )}

            {objectiveToOpenInDetailView && (
                <ObjectiveHistoryDialog
                    open={true}
                    objectiveId={objectiveToOpenInDetailView.id}
                    displayWeight={displayWeight}
                    onClose={() => {
                        setObjectiveToOpenInDetailView(undefined);
                        onSuccess();
                    }}
                />
            )}

            {!!currentObjectiveToDeleteId && (
                <ConfirmDialog
                    open={true}
                    title={t('objectives.are_you_sure_delete_objective')}
                    onClose={() => setCurrentObjectiveToDeleteId(undefined)}
                    onConfirm={() => handleDeleteConfirmed(currentObjectiveToDeleteId)}
                />
            )}
        </>
    );
};
