import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetOrganizationBilling } from '@/hooks/organization_billing/OrganizationBilling.hook';
import { Stack } from '@mui/system';
import { Paper } from '@mui/material';
import { DateRangePicker } from '@/components/date-range-picker/DateRangePicker';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { AgGridWrapper } from '@/components/ag-grid-wrapper/AgGridWrapper';
import { ContentContainer } from '@/page/layout/ContentContainer';
import { useDateRangeStorage } from '@/components/date-range-picker/DateRangePicker.hook';
import { ColDef } from '@ag-grid-community/core';
import { OrganizationBillingSearchRequest } from '@/domain/organization_billing/OrganizationBilling.model';
import { average } from '@/utils/math.util';

export const OrganizationBillingSettingsPage: FC = () => {
    const { t } = useTranslation();

    const { dateRangeViewType, dateRange, onDateRangeChange } = useDateRangeStorage({
        storageKey: 'organization-billing-date-range',
        initialViewType: 'RANGE',
    });

    const search: OrganizationBillingSearchRequest = {
        startDate: dateRange[0],
        endDate: dateRange[1],
        employeeIds: [],
    };
    const { data: organizationBilling, isLoading, isError, error } = useGetOrganizationBilling(search);

    // get all yearMonths from all employeeBillings [2024-08]
    const yearMonths = new Set(organizationBilling?.employeeBillings.flatMap(billing => billing.yearMonths));

    // transform the array into an object to get one line that represents the realm with the total days for each yearMonth
    const totalDaysByYearMonth = [...yearMonths].reduce<Record<string, number>>((acc, curr) => {
        const employeesBillingByYearMonth = organizationBilling?.employeeBillings.filter(billing => billing.yearMonths.includes(curr)) ?? [];
        acc[curr] = employeesBillingByYearMonth.length;
        return acc;
    }, {});

    // one column for each yearMonth
    const yearMonthsColumns: ColDef<Record<string, number>>[] = [...yearMonths].map(yearMonth => ({
        field: yearMonth,
        headerName: yearMonth,
        type: 'string',
    }));

    const columnDefs: ColDef<Record<string, number>>[] = [
        ...yearMonthsColumns,
        {
            headerName: t('organization_billing_page.average_column'),
            type: 'string',
            valueGetter: ({ data }) => {
                if (!data) {
                    return 0;
                }
                // get the average of the line
                return average(...Object.values(data));
            },
        },
    ];

    return (
        <ContentContainer gap={2}>
            <Stack component={Paper} direction={'row'} p={1}>
                <DateRangePicker dates={dateRange} onDatesChanged={onDateRangeChange} defaultViewType={dateRangeViewType} availableViews={['RANGE']} />
            </Stack>

            <Stack flex={1}>
                <StateHandler isLoading={isLoading} isError={isError} error={error} isEmpty={!yearMonths.size}>
                    <AgGridWrapper columnDefs={columnDefs} rowData={[totalDaysByYearMonth]} compact />
                </StateHandler>
            </Stack>
        </ContentContainer>
    );
};
