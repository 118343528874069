import { AgGridWrapper, RogerColDef } from '@/components/ag-grid-wrapper/AgGridWrapper';
import { getFieldDefinitionTranslation } from '@/components/ag-grid-wrapper/column-types/columnTypes';
import { useAgGridWrapper } from '@/components/ag-grid-wrapper/useAgGridWrapper';
import { DatatableAdditionalAction } from '@/components/datatable-additional-action/DatatableAdditionalAction';
import { DateRangePicker } from '@/components/date-range-picker/DateRangePicker';
import { useDateRangeStorage } from '@/components/date-range-picker/DateRangePicker.hook';
import { AsyncSelectFilter, FiltersBar, FilterType, SelectFilterOption } from '@/components/filters-bar/FiltersBar';
import { useFiltersStorage } from '@/components/filters-bar/useFiltersStorage';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { getDepartments } from '@/domain/department/Department.service';
import { isEqualSectionFieldValue } from '@/domain/employee-pending-change/EmployeePendingChange.service';
import { EmployeeSectionField } from '@/domain/employee-section/EmployeeSection.model';
import { EmployeeAvatar } from '@/domain/employee/Employee.model';
import { searchEmployees } from '@/domain/employee/Employee.service';
import { getLocations } from '@/domain/location/Location.service';
import { EmployeePayrollProfileChange, PayrollSection, PayrollSectionRow } from '@/domain/payroll/Payroll.model';
import { markAsReviewed, unmarkAsReviewed } from '@/domain/payroll/Payroll.service';
import { SectionFieldDefinition } from '@/domain/section-setting/Section.model';
import { isSameFieldDefinition } from '@/domain/section-setting/Section.service';
import { useGetPayrollProfileChanges } from '@/hooks/payroll/Payroll.hook';
import { handleError } from '@/utils/api.util';
import { formatToLocalDate } from '@/utils/datetime.util';
import { getLabelTranslation } from '@/utils/language.util';
import { CellClassParams, RowSpanParams } from '@ag-grid-community/core';
import { Button, Chip, Paper, Stack, Tooltip } from '@mui/material';
import i18next from 'i18next';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useDeepCompareEffect from 'use-deep-compare-effect';

export const PayrollProfileChangesPage: FC = () => {
    const { t } = useTranslation();
    const agGridWrapper = useAgGridWrapper<EmployeePayrollProfileChangeRow>();

    const availableFilters = getAvailableFilters();
    const [filters, setFilters] = useFiltersStorage('payroll-changes-filters', availableFilters);

    const getFilter = (key: string) => filters.find(filter => filter.key === key);

    const getSelectFilterValues = (key: string) => (getFilter(key) as AsyncSelectFilter)?.value?.flatMap((option: SelectFilterOption) => option.value);
    const getSelectFilterValuesIds = (key: string) => getSelectFilterValues(key)?.map(v => Number(v));

    const { dateRange, dateRangeViewType, onDateRangeChange } = useDateRangeStorage({
        storageKey: 'payroll-changes-date-range',
    });

    const showAllFields = getSelectFilterValues('FIELD_VISIBILITY')?.[0] === 'SHOW_ALL_FIELDS';

    const search = {
        startDate: dateRange[0],
        endDate: dateRange[1],
        departmentIds: getSelectFilterValuesIds('departmentIds') ?? [],
        employeeIds: getSelectFilterValuesIds('employeeIds') ?? [],
        locationIds: getSelectFilterValuesIds('locationIds') ?? [],
    };
    const { data: changes = [], isLoading, isError, error, refetch } = useGetPayrollProfileChanges(search, { enabled: !!filters.length });

    const columnDefs = getColumnDefs();

    const { rows, isLoading: isLoadingRows } = useRows(changes, { excludeIdenticalFieldValues: !showAllFields });

    const onBtnExport = () =>
        agGridWrapper.gridRef.current?.api.exportDataAsExcel({
            // If we don't provide columnKeys, it will  add an empty column for row selection (I suppose)
            columnKeys: columnDefs.map(columnDef => columnDef.colId ?? columnDef.field).filter(c => c !== undefined),
        });

    const selectedRows = agGridWrapper?.selectedRows;

    const handleMarkAsReviewed = async (selectedRows: EmployeePayrollProfileChangeRow[]) => {
        try {
            const rows = selectedRows.filter(row => !row.reviewedAt);
            if (rows.length) {
                const markedReviewPromises = rows.map(row => {
                    return markAsReviewed({
                        employeeId: row.employee.id,
                        sectionType: row.section.sectionDefinition.type,
                        rowId: row.id,
                    });
                });
                await Promise.all(markedReviewPromises);
                refetch();
            }
            agGridWrapper?.gridRef.current?.api.deselectAll();
        } catch (error) {
            handleError(error);
        }
    };

    const handleUnmarkAsReviewed = async (selectedRows: EmployeePayrollProfileChangeRow[]) => {
        try {
            const rows = selectedRows.filter(row => row.reviewedAt);
            if (rows.length) {
                const unmarkedReviewPromises = rows.map(row => {
                    return unmarkAsReviewed({
                        employeeId: row.employee.id,
                        sectionType: row.section.sectionDefinition.type,
                        rowId: row.id,
                    });
                });

                await Promise.all(unmarkedReviewPromises);
                refetch();
            }
            agGridWrapper?.gridRef.current?.api.deselectAll();
        } catch (error) {
            handleError(error);
        }
    };

    return (
        <Stack flex={1} gap={2}>
            <Stack component={Paper} direction='row' gap={1} alignItems='center' justifyContent='space-between' p={1}>
                <Stack direction='row' alignItems='flex-start' gap={1} flexWrap={'wrap'}>
                    <DateRangePicker
                        dates={dateRange}
                        onDatesChanged={onDateRangeChange}
                        defaultViewType={dateRangeViewType}
                        availableViews={['MONTH', 'RANGE']}
                    />
                    <FiltersBar
                        filters={filters}
                        onFiltersChange={filters => {
                            setFilters(filters);
                        }}
                        readOnly={true}
                        flex={1}
                    />
                </Stack>
                <DatatableAdditionalAction quickFilter={agGridWrapper?.quickFilter} onBtnExport={onBtnExport} />
            </Stack>
            <StateHandler isLoading={isLoading || isLoadingRows} isError={isError} isEmpty={!changes?.length} error={error}>
                <Stack flex={1}>
                    <AgGridWrapper
                        initRef={agGridWrapper?.setGridRef}
                        gridId={'payroll-profile-changes'}
                        columnDefs={columnDefs}
                        defaultColDef={{ autoHeight: false }}
                        rowData={rows}
                        getRowId={({ data }) => getEmployeeProfileChangeRowId(data)}
                        disableAutoSize
                        rowSelection={{
                            mode: 'multiRow',
                            hideDisabledCheckboxes: true,
                            isRowSelectable: rowNode => rowNode.data?.rowSpan !== 0,
                        }}
                        selectionColumnDef={{
                            cellClass: ({ data }: CellClassParams<EmployeePayrollProfileChangeRow>) =>
                                data?.isLastRowInGroup ? ['last-spanning-cell', 'span-selection-cell'] : ['span-selection-cell'],
                        }}
                        toolbarActions={
                            <Stack direction='row' gap={1}>
                                <Button onClick={() => handleMarkAsReviewed(selectedRows)}>{t('payroll_profile_changes_page.mark_as_reviewed')}</Button>
                                <Button color='error' onClick={() => handleUnmarkAsReviewed(selectedRows)}>
                                    {t('payroll_profile_changes_page.unmark_as_reviewed')}
                                </Button>
                            </Stack>
                        }
                    />
                </Stack>
            </StateHandler>
        </Stack>
    );
};

type RowValue = {
    previousValue?: EmployeeSectionField;
    currentValue: EmployeeSectionField;
} & Omit<PayrollSectionRow, 'fields'>;

type EmployeePayrollProfileChangeRow = {
    employee: EmployeeAvatar;
    section: PayrollSection;
    sectionFieldDefinition: SectionFieldDefinition;
    rowSpan: number;
    isLastRowInGroup: boolean;
} & RowValue;

const getColumnDefs = (): RogerColDef<EmployeePayrollProfileChangeRow>[] => {
    const rowSpan = (params: RowSpanParams<EmployeePayrollProfileChangeRow, unknown>) => params.data?.rowSpan ?? 1;

    return [
        {
            flex: 1,
            field: 'employee',
            type: 'employee',
            sort: 'asc',
            cellClass: ({ data }: CellClassParams<EmployeePayrollProfileChangeRow>) => (data?.rowSpan ? ['span-cell', 'flex-spanning', 'full-width'] : []),
            cellClassRules: {
                'last-spanning-cell': ({ data }: CellClassParams<EmployeePayrollProfileChangeRow>) => (data?.rowSpan ?? 0) > 1,
            },
            rowSpan,
        },
        {
            flex: 1,
            field: 'section.sectionDefinition.name',
            headerName: i18next.t('payroll_profile_changes_page.table_headers.section'),
            type: 'label',
            cellClassRules: {
                'span-cell': ({ data }: CellClassParams<EmployeePayrollProfileChangeRow>) => !!data?.rowSpan,
                'last-spanning-cell': ({ data }: CellClassParams<EmployeePayrollProfileChangeRow>) => (data?.rowSpan ?? 0) > 1,
            },
            rowSpan,
        },
        {
            flex: 1.5,
            field: 'sectionFieldDefinition',
            headerName: i18next.t('payroll_profile_changes_page.table_headers.field'),
            type: 'fieldDefinition',
            cellClass: ['span-cell'],
            sortable: false,
        },
        {
            flex: 1.5,
            field: 'previousValue',
            headerName: i18next.t('payroll_profile_changes_page.table_headers.old_value'),
            type: 'fieldValue',
            cellClass: ['span-cell'],
            sortable: false,
        },
        {
            flex: 1.5,
            field: 'currentValue',
            headerName: i18next.t('payroll_profile_changes_page.table_headers.new_value'),
            type: 'fieldValue',
            cellClass: ['span-cell'],
            sortable: false,
        },
        {
            flex: 1,
            headerName: i18next.t('payroll_profile_changes_page.table_headers.changed_by'),
            field: 'updatedBy.displayName',
            cellClassRules: {
                'span-cell': ({ data }: CellClassParams<EmployeePayrollProfileChangeRow>) => !!data?.rowSpan,
                'last-spanning-cell': ({ data }: CellClassParams<EmployeePayrollProfileChangeRow>) => (data?.rowSpan ?? 0) > 1,
            },
            rowSpan,
        },
        {
            flex: 1,
            headerName: i18next.t('payroll_profile_changes_page.table_headers.changed_at'),
            field: 'updatedAt',
            // Needed for export
            valueGetter: ({ data }) => formatToLocalDate(data?.updatedAt),
            type: 'date',
            cellClassRules: {
                'span-cell': ({ data }: CellClassParams<EmployeePayrollProfileChangeRow>) => !!data?.rowSpan,
                'last-spanning-cell': ({ data }: CellClassParams<EmployeePayrollProfileChangeRow>) => (data?.rowSpan ?? 0) > 1,
            },
            rowSpan,
        },
        {
            flex: 1,
            headerName: i18next.t('payroll_profile_changes_page.table_headers.status'),
            field: 'reviewedAt',
            cellClassRules: {
                'flex-spanning': ({ data }: CellClassParams<EmployeePayrollProfileChangeRow>) => !!data?.rowSpan,
                'span-cell': ({ data }: CellClassParams<EmployeePayrollProfileChangeRow>) => !!data?.rowSpan,
                'last-spanning-cell': ({ data }: CellClassParams<EmployeePayrollProfileChangeRow>) => (data?.rowSpan ?? 0) > 1,
            },
            valueGetter: ({ data }) => (data?.reviewedAt ? i18next.t('payroll_profile_changes_page.reviewed') : ''),
            cellRenderer: ({ value, data }) =>
                value ? (
                    <Tooltip
                        title={i18next.t('payroll_profile_changes_page.reviewed_tracking', {
                            reviewedBy: data?.reviewedBy?.displayName,
                            reviewedAt: data?.reviewedAt,
                        })}
                    >
                        <Chip color='primary' label={value} />
                    </Tooltip>
                ) : undefined,
            rowSpan,
        },
    ];
};

type FIELD_VISIBILITY_OPTION = 'SHOW_ALL_FIELDS' | 'HIDE_IDENTICAL_FIELDS';

const getAvailableFilters = (): FilterType[] => {
    const fieldVisibilityOptions = [
        { label: i18next.t('payroll_profile_changes_page.filter_show_all_fields'), value: 'SHOW_ALL_FIELDS' as FIELD_VISIBILITY_OPTION },
        { label: i18next.t('payroll_profile_changes_page.filter_hide_identical_fields'), value: 'HIDE_IDENTICAL_FIELDS' as FIELD_VISIBILITY_OPTION },
    ];
    return [
        {
            key: 'FIELD_VISIBILITY',
            filterName: i18next.t('payroll_profile_changes_page.filter_field_visibility'),
            type: 'select',
            selectMode: 'SYNC',
            options: fieldVisibilityOptions,
            rule: 'EQUALS',
            availableRules: [],
            value: [fieldVisibilityOptions[1]],
        },
        {
            key: 'departmentIds',
            filterName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_DEPARTMENT' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                const departments = await getDepartments();
                return departments?.map(department => ({
                    label: getLabelTranslation(department.name),
                    value: department.id,
                }));
            },
            rule: 'EQUALS',
            availableRules: [],
        },
        {
            key: 'locationIds',
            filterName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_LOCATION' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                const locations = await getLocations();
                return locations?.map(location => ({
                    label: location.name,
                    value: location.id,
                }));
            },
            rule: 'EQUALS',
            availableRules: [],
        },
        {
            key: 'employeeIds',
            filterName: getFieldDefinitionTranslation({ fieldType: 'EMPLOYEE' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                const employees = await searchEmployees();
                return employees?.map(employee => ({
                    label: employee.displayName,
                    value: employee.id,
                }));
            },
            rule: 'EQUALS',
            availableRules: [],
        },
    ];
};

const getEmployeeProfileChangeRowId = (row: EmployeePayrollProfileChangeRow) => {
    return `${row.id}-${row.sectionFieldDefinition.id ?? row.sectionFieldDefinition.fieldType}-${row.currentValue.id}`;
};

const getEmployeePayrollProfileChangeRows = (
    changes: EmployeePayrollProfileChange[],
    { excludeIdenticalFieldValues }: { excludeIdenticalFieldValues: boolean },
): EmployeePayrollProfileChangeRow[] => {
    // Flatten sections
    const flattenedEmployeeSections = changes.flatMap(change =>
        change.sections.map(section => ({
            employee: change.employee,
            section,
        })),
    );

    // Flatten row values (case of table section with multiple rows)
    const flattenedEmployeeFieldChanges = flattenedEmployeeSections.flatMap(row =>
        row.section.values.map(({ previousValue, currentValue }) => {
            return {
                ...row,
                previousValue,
                currentValue,
            };
        }),
    );

    const filteredFlattenedEmployeeFieldChanges = flattenedEmployeeFieldChanges.map(({ previousValue, currentValue, ...rest }) => {
        // Remove fields with no changes
        const filteredCurrentValue = {
            ...currentValue,
            fields: currentValue.fields.filter(({ sectionFieldDefinition, ...restCurrent }) => {
                // previousValue could be null when it is a new employee without changing any field
                if (!previousValue) {
                    // check if the current value is also empty
                    return !isEqualSectionFieldValue(
                        {},
                        {
                            ...restCurrent,
                            valueType: sectionFieldDefinition.valueType,
                        },
                    );
                }
                return previousValue?.fields.some(
                    ({ sectionFieldDefinition: prevSectionFieldDefinition, ...restPrev }) =>
                        isSameFieldDefinition(sectionFieldDefinition, prevSectionFieldDefinition) &&
                        !isEqualSectionFieldValue(restPrev, {
                            ...restCurrent,
                            valueType: sectionFieldDefinition.valueType,
                        }),
                );
            }),
        };

        return {
            ...rest,
            previousValue,
            currentValue: excludeIdenticalFieldValues ? filteredCurrentValue : currentValue,
        };
    });

    // Flatten fields
    const flattenedEmployeeFields = filteredFlattenedEmployeeFieldChanges.flatMap(row =>
        row.currentValue.fields.map(({ sectionFieldDefinition }, index) => ({
            ...row,
            sectionFieldDefinition,
            // We are force to use fields length from current value because section definition can have differences
            // for example in case of adding field after row creation
            rowSpan: index === 0 ? row.currentValue.fields.length : 0,
            isLastRowInGroup: index === row.currentValue.fields.length - 1,
        })),
    );

    // Map previous and current
    const rowsWithPossibleUndefined = flattenedEmployeeFields.map(({ previousValue, currentValue: { fields, ...restCurrentValue }, ...rest }) => {
        const current = fields.find(({ sectionFieldDefinition }) => isSameFieldDefinition(sectionFieldDefinition, rest.sectionFieldDefinition));
        const previous = previousValue?.fields.find(({ sectionFieldDefinition }) => isSameFieldDefinition(sectionFieldDefinition, rest.sectionFieldDefinition));
        if (!current) {
            return;
        }
        return {
            ...rest,
            ...restCurrentValue,
            previousValue: previous,
            currentValue: current,
        };
    });

    // Prevent undefined values from being added to the array
    return rowsWithPossibleUndefined.filter(row => row !== undefined);
};

const useRows = (
    changes: EmployeePayrollProfileChange[],
    {
        excludeIdenticalFieldValues,
    }: {
        excludeIdenticalFieldValues: boolean;
    },
) => {
    const [isLoading, setIsLoading] = useState(false);
    const [rows, setRows] = useState<EmployeePayrollProfileChangeRow[]>([]);

    useDeepCompareEffect(() => {
        const updateRows = async () => {
            setIsLoading(true);
            // We need to wait a bit to avoid flickering when rows are filtered in FE side
            setTimeout(() => {
                const rows = getEmployeePayrollProfileChangeRows(changes, { excludeIdenticalFieldValues });
                setRows(rows);
                setIsLoading(false);
            }, 50);
        };

        updateRows();
    }, [changes, excludeIdenticalFieldValues]);

    return { rows, isLoading };
};
