import { BreadCrumbItem } from '@/routes/PageContainer';
import { Realm } from '@/domain/realm/Realm.model';
import { createSlice } from '@reduxjs/toolkit';
import { LeaveRequest } from '@/domain/leave-request/LeaveRequest.model';

export type UiState = {
    declineLeaveRequestDialogLeaveRequest: LeaveRequest | undefined;

    snackbar: SnackbarState;

    assignLeaveTypeDialogOpen: boolean;
    assignLeaveTypeDialogUsersIdList: number[];
    assignLeaveTypeDialogUserId: number | undefined;

    unassignLeaveTypePolicyDialogOpen: boolean;
    unassignLeaveTypeDialogUsersIdList: number[];
    unassignLeaveTypePolicyDialogUserId: number | undefined;

    currentRealm: Realm | undefined;
    expandMenu: boolean;

    breadCrumb: BreadCrumbItem[];
};

const initialState: UiState = {
    declineLeaveRequestDialogLeaveRequest: undefined,

    snackbar: { open: false, message: '', severity: 'success' },

    assignLeaveTypeDialogOpen: false,
    assignLeaveTypeDialogUsersIdList: [],
    assignLeaveTypeDialogUserId: undefined,

    unassignLeaveTypePolicyDialogOpen: false,
    unassignLeaveTypeDialogUsersIdList: [],
    unassignLeaveTypePolicyDialogUserId: undefined,

    currentRealm: undefined,
    expandMenu: true,

    breadCrumb: [],
};

/**
 * @deprecated remove this from the store
 */
export enum UiActionType {
    SNACKBAR_CHANGED = 'SNACKBAR_CHANGED',
    ASSIGN_LEAVE_TYPE_DIALOG_OPEN = 'ASSIGN_LEAVE_TYPE_DIALOG_OPEN',
    UNASSIGN_LEAVE_TYPE_DIALOG_OPEN = 'UNASSIGN_LEAVE_TYPE_DIALOG_OPEN',
    REALM_LOADED = 'REALM_LOADED',
    EXPAND_MENU = 'EXPAND_MENU',
    BREADCRUMB = 'BREADCRUMB',
}

export interface Breadcrumb {
    type: UiActionType.BREADCRUMB;
    breadCrumb: BreadCrumbItem[];
}

export interface SnackbarChanged {
    type: UiActionType.SNACKBAR_CHANGED;
    snackbar: SnackbarState;
}

export interface AssignLeaveTypeDialogOpen {
    type: UiActionType.ASSIGN_LEAVE_TYPE_DIALOG_OPEN;
    open: boolean;
    userId: number;
    usersIdList: number[];
}

export interface UnassignLeaveTypeDialogOpen {
    type: UiActionType.UNASSIGN_LEAVE_TYPE_DIALOG_OPEN;
    open: boolean;
    userId: number;
    usersIdList: number[];
}

export interface RealmLoaded {
    type: UiActionType.REALM_LOADED;
    realm?: Realm;
}

export interface ExpandMenu {
    type: UiActionType.EXPAND_MENU;
    expand: boolean;
}

export const uiSlice = createSlice({
    name: 'ui',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {},
    extraReducers: builder => {
        // This is a workaround to be able to use the old reducer in the new slice
        // to avoid having to change all dispatch places
        builder.addDefaultCase((state, action) => {
            return oldReducer(state, action as UiActions);
        });
    },
});

export const uiReducer = uiSlice.reducer;

export type UiActions = SnackbarChanged | AssignLeaveTypeDialogOpen | UnassignLeaveTypeDialogOpen | RealmLoaded | ExpandMenu | Breadcrumb;

/**
 * @deprecated
 * @returns
 */
function oldReducer(state = initialState, action: UiActions): UiState {
    switch (action.type) {
        case UiActionType.BREADCRUMB:
            return {
                ...state,
                breadCrumb: action.breadCrumb,
            };
        case UiActionType.SNACKBAR_CHANGED:
            return {
                ...state,
                snackbar: action.snackbar,
            };
        case UiActionType.ASSIGN_LEAVE_TYPE_DIALOG_OPEN:
            return {
                ...state,
                assignLeaveTypeDialogOpen: action.open,
                assignLeaveTypeDialogUsersIdList: action.usersIdList,
                assignLeaveTypeDialogUserId: action.open ? action.userId : undefined,
            };
        case UiActionType.UNASSIGN_LEAVE_TYPE_DIALOG_OPEN:
            return {
                ...state,
                unassignLeaveTypePolicyDialogOpen: action.open,
                unassignLeaveTypeDialogUsersIdList: action.usersIdList,
                unassignLeaveTypePolicyDialogUserId: action.open ? action.userId : undefined,
            };
        case UiActionType.REALM_LOADED:
            return {
                ...state,
                currentRealm: action.realm,
            };
        case UiActionType.EXPAND_MENU:
            return {
                ...state,
                expandMenu: action.expand,
            };
        default:
            return state;
    }
}

interface SnackbarState {
    message: string;
    open: boolean;
    severity: 'success' | 'error';
}
