import { AxiosResponse } from 'axios';
import { API_BASE_URL, client } from '@/api/common';
import { SearchSkill, Skill, SkillMutation, SkillRole } from '@/domain/skill/Skill.model';
import { SkillCategoryDTO } from '@/api/skill-category/SkillCategory.api';
import { OrderMutation } from '@/domain/common';
import { ImportRequest, ImportResult } from '@/domain/import/Import.model';

export type SkillDTO = Omit<Skill, 'category' | 'roles'> & {
    category: SkillCategoryDTO;
    roles: SkillRoleDTO[];
};

export type SkillRoleDTO = SkillRole;

export type UpdateSkillRequest = SkillMutation;
export type CreateSkillRequest = SkillMutation;

const getSkill = async (skillId: number): Promise<Skill> => {
    return (await client.get<SkillDTO, AxiosResponse<SkillDTO>, number>(API_BASE_URL + `/skills/${skillId}`)).data;
};

const updateSkill = async (skillId: number, request: SkillMutation): Promise<Skill> => {
    return (await client.put<SkillDTO, AxiosResponse<SkillDTO>, UpdateSkillRequest>(API_BASE_URL + `/skills/${skillId}`, request)).data;
};

const deleteSkill = async (skillId: number): Promise<void> => {
    await client.delete(API_BASE_URL + `/skills/${skillId}`);
};

const createSkill = async (request: SkillMutation): Promise<Skill> => {
    return (await client.post<Skill, AxiosResponse<Skill>, CreateSkillRequest>(API_BASE_URL + `/skills`, request)).data;
};

const searchSkills = async (request: SearchSkill = {}): Promise<Skill[]> => {
    return (await client.post<Skill[], AxiosResponse<Skill[]>, SearchSkill>(API_BASE_URL + `/skills/search`, request)).data;
};

const reorderSkillsRequest = async (request: OrderMutation[]): Promise<Skill[]> => {
    return (await client.put<Skill[], AxiosResponse<Skill[]>, OrderMutation[]>(API_BASE_URL + `/skills/order`, request)).data;
};

const importSkills = async (request: ImportRequest): Promise<ImportResult> => {
    return (await client.post<ImportResult, AxiosResponse<ImportResult>, ImportRequest>(API_BASE_URL + `/skills/import`, request)).data;
};

export const skillApi = {
    getSkill,
    updateSkill,
    deleteSkill,
    createSkill,
    searchSkills,
    reorderSkillsRequest,
    importSkills,
};
