import { getApiBaseUrl, getAppConfig } from '@/config/config';
import { Auth } from 'aws-amplify';
import axios, { AxiosError, InternalAxiosRequestConfig } from 'axios';

const API_VERSION = 'v1';
export const API_BASE_URL = getApiBaseUrl() + '/' + API_VERSION;
export const client = axios.create();

// Spring security doesn't allow to send the Authorization header in public routes
// see: https://github.com/spring-projects/spring-security/issues/12599
const UNSECURE_ROUTES = ['/v1/realms/', '/v1/realms/search', '/v1/surveys/auth', '/v1/employees/login-method'];

const onRequest = async (requestConfig: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig> => {
    try {
        const session = await Auth.currentSession();
        const jwt = session.getIdToken().getJwtToken();
        if (!UNSECURE_ROUTES.some(route => requestConfig.url?.includes(route))) {
            requestConfig.headers['Authorization'] = `Bearer ${jwt}`;
        }
        requestConfig.headers['Content-Type'] = getAppConfig().MIME_TYPES.JSON;
    } catch (error) {
        // if the user is not authenticated don't block the client call, just log the error
        console.error('Authentication error => ', error);
    }
    return requestConfig;
};

client.interceptors.request.use(onRequest, (error: AxiosError) => Promise.reject(error));
