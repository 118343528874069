import { UnitType } from '@/domain/date/Date.model';
import { AllowanceType, EmployeeLeaveTypePolicy, LeaveActivityType } from '@/domain/leave-type/LeaveType.model';
import { useAppSelector } from '@/stores/store';
import { Box, Button, Paper, Stack, Tooltip, Typography } from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { StateHandler } from '@/components/state-handler/StateHandler';
import { canApproveRejectLeaveRequests, canCorrectEmployeeBalance, hasManageShiftsPolicy, hasViewShiftsPolicy } from '@/domain/permission/Permission.service';
import { DeclineLeaveRequestDialog } from '@/page/leave/DeclineLeaveRequestDialog';
import { LeaveCorrectionDialog, LeaveCorrectionValues } from '@/page/leave/leave-correction-dialog/LeaveCorrectionDialog';
import { LeaveCancellationConfirmationDialog } from '@/page/leave/LeaveCancellationConfirmationDialog';
import { LeavesConflictsDialog } from '@/page/leave/leaves-conflicts-dialog/LeavesConflictsDialog';
import { LeaveTransactionsTable } from '@/page/leave/LeaveTransactionsTable';
import { handleError } from '@/utils/api.util';
import { showSnackbar } from '@/utils/snackbar.util';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useTranslation } from 'react-i18next';

import { getEmployeeLeaveTypePolicies } from '@/domain/employee-leave-type/EmployeeLeaveType.service';
import { LeaveCorrectionCreateMutation } from '@/domain/leave-correction/LeaveCorrection.model';
import { leaveCorrectionService } from '@/domain/leave-correction/LeaveCorrection.service';
import { LeaveRequest } from '@/domain/leave-request/LeaveRequest.model';
import {
    approvePendingLeaveRequest,
    checkConflictsLeaveRequest,
    isLeaveMedicalTypeEndDateEmpty,
    shiftAPISearchRequest,
} from '@/domain/leave-request/LeaveRequest.service';
import { LeaveTypeCycleHistory, LeaveTypeHistory } from '@/domain/leave-type-history/LeaveTypeHistory.model';
import { getLeaveTypeHistory } from '@/domain/leave-type-history/LeaveTypeHistory.service';
import { Shift, ShiftReleaseRequest, ShiftStatus } from '@/domain/shift/Shift.model';

import { getEmployeeShifts, shiftRelease } from '@/domain/shift/Shift.service';
import { useGetEmployeeById } from '@/hooks/employee/Employee.hook';
import { formatInDefaultDate, getCurrentLocalDate, isAfterDate, isBeforeDate, toDate } from '@/utils/datetime.util';

import { LeaveHistoryHeader } from '@/page/employee-profile/employee-profile-leave/LeavesHistory/LeavesHistoryHeader';

type Props = {
    employeeId: number;
};

export const LeavesHistory: FC<Props> = ({ employeeId }) => {
    const { t } = useTranslation();
    const params = useParams();
    const userLeaveTypeId = Number(params.userLeaveTypeId) || undefined;

    const policies = useAppSelector(state => state.currentEmployee.grantedPolicies);

    const [selectedCycle, setSelectedCycle] = useState<LeaveTypeCycleHistory>();
    const [leaveTypeHistory, setLeaveTypeHistory] = useState<LeaveTypeHistory>();
    const [error, setError] = useState<string>();
    const [userLeaveTypePolicies, setUserLeaveTypePolicies] = useState<EmployeeLeaveTypePolicy[]>();
    const [loading, setLoading] = useState<boolean>(true);
    const [changeId, setChangeId] = useState<number>(0);
    const [value, setValue] = useState<number>(0);
    const [leaveRequestToCancel, setLeaveRequestToCancel] = useState<LeaveRequest>();
    const [isLeaveConflictsDialogOpen, setIsLeaveConflictsDialogOpen] = useState<boolean>(false);
    const [shiftReleaseRequest, setShiftReleaseRequest] = useState<ShiftReleaseRequest>();
    const [activeLeaveRequest, setActiveLeaveRequest] = useState<LeaveRequest>();
    const [conflictingShifts, setConflictingShifts] = useState<Shift[]>();
    const [leaveCorrectionDialogIsOpen, setLeaveCorrectionDialogIsOpen] = useState<boolean>();
    const [currentDeclineLeaveRequest, setCurrentDeclineLeaveRequest] = useState<LeaveRequest>();

    const { data: activeUser, isError: isEmployeeError, isLoading: isEmployeeLoading, error: employeeError } = useGetEmployeeById(employeeId);

    const getCycleYear = (leaveTypeCycleHistory: LeaveTypeCycleHistory): number => {
        return toDate(leaveTypeCycleHistory.leaveCycle.cycleStartDate).getFullYear();
    };

    const isCurrentCycle = (startDate: LocalDate, endDate: LocalDate): boolean => {
        const now = getCurrentLocalDate();
        const isBeforeOrEqual = isBeforeDate(startDate, now) || startDate === now;
        const isAfterOrEqual = isAfterDate(endDate, now) || endDate === now;
        return isBeforeOrEqual && isAfterOrEqual;
    };

    const fetchLeaveTypeHistory = useCallback(() => {
        if (userLeaveTypeId) {
            getLeaveTypeHistory(employeeId, userLeaveTypeId)
                .then(response => {
                    setLeaveTypeHistory(response);
                    if (response?.cycles?.length) {
                        const currentCycle = response.cycles.find(cycle => isCurrentCycle(cycle.leaveCycle.cycleStartDate, cycle.leaveCycle.cycleEndDate));
                        setSelectedCycle(currentCycle);
                    }
                    // Set the tab to the correct one if the leave type is compensation
                    if (response.leaveType.leaveActivityType === LeaveActivityType.TIMESHEET_COMPENSATION) {
                        setValue(1);
                    }
                    setLoading(false);
                })
                .catch(error => {
                    setError(error.message);
                });
        }
    }, [employeeId, userLeaveTypeId]);

    useEffect(() => {
        fetchLeaveTypeHistory();
    }, [fetchLeaveTypeHistory]);

    useEffect(() => {
        getEmployeeLeaveTypePolicies(employeeId)
            .then(data => {
                setUserLeaveTypePolicies(data);
            })
            .catch(handleError);
    }, [employeeId]);

    const leaveTransactions = selectedCycle?.transactions?.filter(item => item.leaveTransactionType === 'LEAVE_REQUEST') ?? [];
    const corrections = selectedCycle?.transactions?.filter(item => item.leaveTransactionType === 'CORRECTION') ?? [];
    const cycleAccrued =
        selectedCycle?.transactions?.filter(item => item.leaveTransactionType === 'CYCLE_ACCRUED' || item.leaveTransactionType === 'CYCLE_CARRYOVER') ?? [];

    const updateLeaveRequest = () => {
        fetchLeaveTypeHistory();
        showSnackbar(t('leaves_page.messages.leave_request_approved'), 'success');
    };

    const canSeeOrManageShifts = () => {
        return hasManageShiftsPolicy(policies) || hasViewShiftsPolicy(policies);
    };

    const handleLeaveCorrectionCreate = async (values: LeaveCorrectionValues) => {
        const { leaveType, ...rest } = values;
        const mutation: LeaveCorrectionCreateMutation = {
            ...rest,
            employeeId,
            leaveTypeId: leaveType.id,
        };
        try {
            await leaveCorrectionService.createLeaveCorrection(mutation);
            setChangeId(changeId + 1);
            setLeaveCorrectionDialogIsOpen(false);
            showSnackbar(t('add_correction_dialog.messages.correction_added'), 'success');
            fetchLeaveTypeHistory();
        } catch (error) {
            handleError(error);
        }
    };

    const handleDeleteCorrection = async (leaveCorrectionId: number) => {
        try {
            await leaveCorrectionService.deleteLeaveCorrection(leaveCorrectionId);
            showSnackbar(t('add_correction_dialog.messages.correction_deleted'), 'success');
            fetchLeaveTypeHistory();
        } catch (error) {
            showSnackbar(t('add_correction_dialog.messages.correction_error'), 'error');
            console.error(error);
        }
    };

    const onLeaveApprovedClicked = async (leaveRequest: LeaveRequest) => {
        const updatedLeaveRequest = leaveRequest;
        if (!updatedLeaveRequest.startDate || !updatedLeaveRequest.endDate) {
            return;
        }
        if (
            !isLeaveMedicalTypeEndDateEmpty(leaveRequest.leaveType, toDate(leaveRequest.endDate)) &&
            (hasManageShiftsPolicy(policies) || hasViewShiftsPolicy(policies))
        ) {
            const conflicts = await checkConflictsLeaveRequest(policies, leaveRequest.employee.id, leaveRequest);
            if (conflicts?.shifts?.length) {
                const { shifts, shiftReleaseRequest } = conflicts;
                setConflictingShifts(shifts);
                setIsLeaveConflictsDialogOpen(true);
                setShiftReleaseRequest(shiftReleaseRequest);
                setActiveLeaveRequest(updatedLeaveRequest);
            } else {
                approveLeaveRequest(updatedLeaveRequest);
            }
        } else {
            approveLeaveRequest(updatedLeaveRequest);
        }
    };

    const approveLeaveRequest = async (leaveRequest: LeaveRequest) => {
        if (!leaveRequest.id) {
            return;
        }
        try {
            await approvePendingLeaveRequest(leaveRequest.id);
            updateLeaveRequest();
        } catch (error) {
            handleError(error);
        }
    };

    const allowanceTypeIsNotUnlimited = leaveTypeHistory?.leaveType.allowanceType === AllowanceType.NOT_UNLIMITED;
    const isCompensationActivityType = leaveTypeHistory?.leaveType.leaveActivityType === LeaveActivityType.TIMESHEET_COMPENSATION;
    const canAdjustBalance = allowanceTypeIsNotUnlimited && canCorrectEmployeeBalance(policies, employeeId) && !isCompensationActivityType;

    //TODO: RP-5562: provide something that the user can change this default behaviour from BOTH so that he can see in DAYS or HOURS
    const displayUnitType = leaveTypeHistory?.leaveType.displayUnitType === UnitType.BOTH ? UnitType.DAYS : leaveTypeHistory?.leaveType.displayUnitType;

    return (
        <StateHandler
            isError={!!error || isEmployeeError}
            isLoading={loading || isEmployeeLoading}
            error={employeeError || new Error('Error to load leave types')}
        >
            {!!leaveTypeHistory && displayUnitType && (
                <>
                    <Stack direction='column' spacing={2} justifyContent='center' flex={1}>
                        {!!activeUser && selectedCycle && (
                            <LeaveHistoryHeader
                                getCycleYear={getCycleYear}
                                selectedCycle={selectedCycle}
                                setSelectedCycle={setSelectedCycle}
                                leaveTypeHistory={leaveTypeHistory}
                                employee={activeUser}
                                displayUnitType={displayUnitType}
                            />
                        )}

                        <Stack gap={1} flex={1}>
                            {leaveTypeHistory.leaveType.allowanceType === AllowanceType.NOT_UNLIMITED ? (
                                <>
                                    {!isCompensationActivityType && (
                                        <Stack component={Paper} direction='row' alignItems='center'>
                                            <Stack component={Tabs} value={value} onChange={(_, newValue) => setValue(newValue)} flex={1}>
                                                <Tab
                                                    label={
                                                        displayUnitType === UnitType.DAYS
                                                            ? t('my_leaves_history.granted_days')
                                                            : t('my_leaves_history.granted_hours')
                                                    }
                                                    {...a11yProps(0)}
                                                />
                                                <Tab label={t('my_leaves_history.tabs.requests')} {...a11yProps(1)} />
                                                <Tab label={t('my_leaves_history.tabs.adjustments')} {...a11yProps(2)} />
                                            </Stack>
                                            {canAdjustBalance && (
                                                <Stack>
                                                    <Tooltip title={!activeUser?.currentWorkingPattern ? t('my_leaves_history.no_working_pattern') : ''}>
                                                        <Box
                                                            component='span'
                                                            sx={{
                                                                width: '9em',
                                                                alignSelf: 'flex-end',
                                                            }}
                                                        >
                                                            <Button
                                                                color='primary'
                                                                variant='contained'
                                                                size='small'
                                                                onClick={() => setLeaveCorrectionDialogIsOpen(true)}
                                                                disabled={!activeUser?.currentWorkingPattern}
                                                            >
                                                                {t('my_leaves_history.add_correction')}
                                                            </Button>
                                                        </Box>
                                                    </Tooltip>
                                                </Stack>
                                            )}
                                        </Stack>
                                    )}
                                    {!!leaveTransactions && !!cycleAccrued && !!corrections && displayUnitType && (
                                        <>
                                            {value === 0 && (
                                                <LeaveTransactionsTable
                                                    employeeId={employeeId}
                                                    historyType='daysGranted'
                                                    leaveType={leaveTypeHistory.leaveType}
                                                    transactions={cycleAccrued}
                                                    loadHistory={fetchLeaveTypeHistory}
                                                    displayUnitType={displayUnitType}
                                                />
                                            )}

                                            {value === 1 && (
                                                <LeaveTransactionsTable
                                                    employeeId={employeeId}
                                                    historyType='requests'
                                                    loadHistory={fetchLeaveTypeHistory}
                                                    onApproveClicked={onLeaveApprovedClicked}
                                                    onDeclineClicked={(leaveRequest: LeaveRequest) => {
                                                        setCurrentDeclineLeaveRequest(leaveRequest);
                                                    }}
                                                    onCancelClicked={(leaveRequest: LeaveRequest) => {
                                                        setLeaveRequestToCancel(leaveRequest);
                                                    }}
                                                    approveEnabled={canApproveRejectLeaveRequests(policies, employeeId)}
                                                    declineEnabled={canApproveRejectLeaveRequests(policies, employeeId)}
                                                    policies={policies}
                                                    leaveType={leaveTypeHistory.leaveType}
                                                    transactions={leaveTransactions}
                                                    displayUnitType={displayUnitType}
                                                />
                                            )}
                                            {value === 2 && (
                                                <LeaveTransactionsTable
                                                    employeeId={employeeId}
                                                    workingPattern={activeUser?.currentWorkingPattern}
                                                    historyType='adjustments'
                                                    leaveType={leaveTypeHistory.leaveType}
                                                    transactions={corrections}
                                                    policies={policies}
                                                    loadHistory={fetchLeaveTypeHistory}
                                                    onDeleteClicked={handleDeleteCorrection}
                                                    displayUnitType={displayUnitType}
                                                />
                                            )}
                                            <DeclineLeaveRequestDialog
                                                leaveRequestId={currentDeclineLeaveRequest?.id}
                                                onCancel={() => setCurrentDeclineLeaveRequest(undefined)}
                                                onDeclineRequest={() => {
                                                    setCurrentDeclineLeaveRequest(undefined);
                                                    fetchLeaveTypeHistory();
                                                }}
                                            />
                                        </>
                                    )}
                                </>
                            ) : (
                                <LeaveTransactionsTable
                                    employeeId={employeeId}
                                    historyType='requests'
                                    onApproveClicked={(leaveRequest: LeaveRequest) => {
                                        const updatedLeaveRequest = leaveRequest;
                                        if (!updatedLeaveRequest.startDate || !updatedLeaveRequest.endDate) {
                                            return;
                                        }

                                        updatedLeaveRequest.requestStatus = 'APPROVED';
                                        const shiftStatus = [ShiftStatus.SHIFT_DRAFT, ShiftStatus.SHIFT_PUBLISHED];
                                        const shiftSearchRequest = shiftAPISearchRequest(
                                            toDate(updatedLeaveRequest.startDate),
                                            toDate(updatedLeaveRequest.endDate),
                                            updatedLeaveRequest.employee.id,
                                            shiftStatus,
                                        );
                                        if (canSeeOrManageShifts()) {
                                            getEmployeeShifts(
                                                shiftAPISearchRequest(
                                                    toDate(updatedLeaveRequest.startDate),
                                                    toDate(updatedLeaveRequest.endDate),
                                                    updatedLeaveRequest.employee.id,
                                                    shiftStatus,
                                                ),
                                            ).then(res => {
                                                if (res[0]?.shifts?.length) {
                                                    setConflictingShifts(res[0].shifts);
                                                    setIsLeaveConflictsDialogOpen(true);
                                                    const shiftReleaseObj: ShiftReleaseRequest = {
                                                        employeeId: shiftSearchRequest.employeeIds?.[0] ?? 0,
                                                        rangeDates: shiftSearchRequest.rangeDates,
                                                    };
                                                    setShiftReleaseRequest(shiftReleaseObj);
                                                    setActiveLeaveRequest(updatedLeaveRequest);
                                                } else {
                                                    if (!updatedLeaveRequest.id) {
                                                        return;
                                                    }
                                                    approvePendingLeaveRequest(updatedLeaveRequest.id)
                                                        .then(updateLeaveRequest)
                                                        .catch(error => {
                                                            handleError(error);
                                                        });
                                                }
                                            });
                                        } else {
                                            if (!updatedLeaveRequest.id) {
                                                return;
                                            }
                                            approvePendingLeaveRequest(updatedLeaveRequest.id)
                                                .then(updateLeaveRequest)
                                                .catch(error => {
                                                    handleError(error);
                                                });
                                        }
                                    }}
                                    onDeclineClicked={(leaveRequest: LeaveRequest) => {
                                        setCurrentDeclineLeaveRequest(leaveRequest);
                                    }}
                                    onCancelClicked={(leaveRequest: LeaveRequest) => {
                                        setLeaveRequestToCancel(leaveRequest);
                                    }}
                                    approveEnabled={canApproveRejectLeaveRequests(policies, employeeId)}
                                    declineEnabled={canApproveRejectLeaveRequests(policies, employeeId)}
                                    policies={policies}
                                    leaveType={leaveTypeHistory.leaveType}
                                    transactions={leaveTransactions}
                                    loadHistory={fetchLeaveTypeHistory}
                                    displayUnitType={displayUnitType}
                                />
                            )}
                        </Stack>
                        {!!userLeaveTypePolicies?.filter(ult => !!leaveTypeHistory.leaveType && ult.leaveType?.id === leaveTypeHistory.leaveType?.id)
                            ?.length && (
                            <Stack padding={2} direction='column' spacing={1} bgcolor='primary.light'>
                                {userLeaveTypePolicies
                                    .filter(ult => ult.leaveType.id === leaveTypeHistory.leaveType.id)
                                    .sort((ult1, ult2) => (ult1.applyFrom < ult2.applyFrom ? -1 : 1))
                                    .map(ult => (
                                        <Stack key={ult.id} gap={0.5}>
                                            <Typography color='primary.main' variant='body2'>
                                                {ult.leaveTypePolicy.name}{' '}
                                                <Typography component='span' variant='body2bold' color='primary.main'>
                                                    {t('general.assigned')}
                                                </Typography>{' '}
                                                {t('general.from')} {formatInDefaultDate(ult.applyFrom)}
                                            </Typography>
                                            {ult.endDate && (
                                                <Typography color='primary.main' variant='body2'>
                                                    {ult.leaveTypePolicy.name}{' '}
                                                    <Typography component='span' variant='body2bold' color='primary.main'>
                                                        {t('general.unassigned')}
                                                    </Typography>{' '}
                                                    {t('general.from')} {formatInDefaultDate(ult.endDate)}
                                                </Typography>
                                            )}
                                        </Stack>
                                    ))}
                            </Stack>
                        )}
                    </Stack>

                    {/* Dialogs */}
                    <LeaveCancellationConfirmationDialog
                        leaveRequest={leaveRequestToCancel}
                        onSuccess={() => {
                            fetchLeaveTypeHistory();
                            setLeaveRequestToCancel(undefined);
                        }}
                        onClose={() => setLeaveRequestToCancel(undefined)}
                    />
                    {leaveCorrectionDialogIsOpen && activeUser?.currentWorkingPattern && (
                        <LeaveCorrectionDialog
                            open={true}
                            leaveType={leaveTypeHistory.leaveType}
                            workingPattern={activeUser.currentWorkingPattern}
                            onSave={handleLeaveCorrectionCreate}
                            onClose={() => {
                                setLeaveCorrectionDialogIsOpen(false);
                            }}
                        />
                    )}

                    {isLeaveConflictsDialogOpen && (
                        <LeavesConflictsDialog
                            onClose={() => setIsLeaveConflictsDialogOpen(false)}
                            open={isLeaveConflictsDialogOpen}
                            onSave={() => {
                                if (!shiftReleaseRequest) {
                                    return;
                                }
                                shiftRelease(shiftReleaseRequest).then(() => {
                                    if (!activeLeaveRequest?.id) {
                                        return;
                                    }
                                    approvePendingLeaveRequest(activeLeaveRequest?.id).then(updateLeaveRequest);
                                    setIsLeaveConflictsDialogOpen(false);
                                });
                            }}
                            saveAndKeepConflicts={() => {
                                if (!activeLeaveRequest?.id) {
                                    return;
                                }
                                approvePendingLeaveRequest(activeLeaveRequest?.id).then(updateLeaveRequest);
                            }}
                            shifts={conflictingShifts ?? []}
                        />
                    )}

                    <DeclineLeaveRequestDialog
                        leaveRequestId={currentDeclineLeaveRequest?.id}
                        onCancel={() => setCurrentDeclineLeaveRequest(undefined)}
                        onDeclineRequest={() => {
                            fetchLeaveTypeHistory();
                            setCurrentDeclineLeaveRequest(undefined);
                        }}
                    />
                </>
            )}
        </StateHandler>
    );
};

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
