import { DialogContainer } from '@/components/dialog-container/DialogContainer';
import { SectionRow } from '@/components/section/types';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { getEmployeeProfilePendingRowsBySectionDefinition } from '@/domain/employee-pending-change/EmployeePendingChange.service';
import { Employee } from '@/domain/employee/Employee.model';
import { Employment, EmploymentStatus } from '@/domain/employment/Employment.model';
import { getCurrentEmploymentStatusLabelDisplay, getCurrentPrincipalEmployment } from '@/domain/employment/Employment.service';
import { LongLeave, LongLeaveCreateMutation, LongLeaveUpdateMutation } from '@/domain/long-leave/LongLeave.model';
import { createLongLeave, deleteLongLeave, updateLongLeave } from '@/domain/long-leave/LongLeave.service';
import { canViewCustomSections, canViewEmployeeContracts, canViewEmployeesBasicInfo } from '@/domain/permission/Permission.service';
import { SectionType } from '@/domain/section-setting/Section.model';
import { useGetEmployeeProfilePendingChanges } from '@/hooks/employee-pending-change/EmployeePendingChanges.hook';
import { useGetEmployeeById } from '@/hooks/employee/Employee.hook';
import { useGetEmployeeSection } from '@/hooks/employee/EmployeeSection.hook';
import { useGetEmployments } from '@/hooks/employment/Employment.hook';
import { useGetLongLeaves } from '@/hooks/long-leave/LongLeave.hook';
import { EmployeeContractSection } from '@/page/employee-profile/employee-profile-info/EmployeeContractSection/EmployeeContractSection';
import { EmployeeCustomSection } from '@/page/employee-profile/employee-profile-info/EmployeeCustomSection/EmployeeCustomSection';
import { EmployeeLongLeaveSection } from '@/page/employee-profile/employee-profile-info/EmployeeLongLeaveSection/EmployeeLongLeaveSection';
import { LongLeaveDialog, LongLeaveFormValues } from '@/page/employee-profile/employee-profile-info/EmployeeLongLeaveSection/LongLeaveDialog/LongLeaveDialog';
import { EmploymentSection } from '@/page/employee-profile/employee-profile-info/EmploymentSection/EmploymentSection';
import { EmployeeWorkPatternSection } from '@/page/employee-profile/employee-profile-info/EmpoyeeWorkPatternSection/EmployeeWorkPatternSection';
import { PERSONAL_SECTIONS } from '@/page/employee-profile/employeeProfile.util';
import { PersonalInfoPageSkeleton } from '@/page/employee-profile/EmployeeProfileInfoPage';
import { EmployeeProfileActionType } from '@/stores/reducers/employeeProfileActions';
import { useAppDispatch, useAppSelector } from '@/stores/store';
import { handleError } from '@/utils/api.util';
import { Alert, Stack, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useGetSectionDefinitions } from '@/hooks/section-definition/SectionDefinition.hook';

export const EmployeeProfileJobInfoPage: FC = () => {
    const params = useParams();
    const currentEmployee = useAppSelector(state => state.currentEmployee.employee);
    const employeeId = params?.employeeId ? Number(params?.employeeId) : currentEmployee?.id;

    if (!employeeId) {
        throw new Error('Employee ID is not defined');
    }
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const realm = useAppSelector(state => state.ui.currentRealm);
    // For now we are using the store and the refetchEmployee flag to refetch the pending changes after a mutation
    // This should be changed to use the useQuery hook and queryClient.invalidateQueries
    const mustRefetchPendingChanges = useAppSelector(state => state.employeeProfile.refetchEmployee);
    const policies = useAppSelector(state => state.currentEmployee.grantedPolicies);

    const [cancelLongLeaveDialogOpen, setCancelLongLeaveDialogOpen] = useState(false);
    const [currentLongLeave, setCurrentLongLeave] = useState<LongLeave>();
    const [longLeaveDialogOpen, setLongLeaveDialogOpen] = useState(false);

    const { data: longLeaves = [], refetch: refetchLongLeaves } = useGetLongLeaves(employeeId, canViewEmployeesBasicInfo(policies, employeeId));
    // TODO: create a custom hook to load all the data with one hook
    const {
        data: employee,
        refetch: refetchEmployeeById,
        isLoading: isEmployeeByIdLoading,
        isError: isEmployeeByIdError,
        error: employeeError,
    } = useGetEmployeeById(employeeId);

    const {
        data: sectionDefinitions = [],
        isLoading: isSectionDefinitionsLoading,
        isError: isSectionDefinitionsError,
        error: sectionDefinitionsError,
    } = useGetSectionDefinitions();

    const {
        data: employments = [],
        refetch: refetchEmployments,
        isLoading: isEmploymentsLoading,
        isError: isEmploymentsError,
        error: employmentError,
    } = useGetEmployments({ employeeId: employeeId });

    const {
        data: employeeSection,
        refetch: refetchEmployeeSection,
        isLoading: isEmployeeSectionLoading,
        isError: isEmployeeSectionError,
        error: employeeSectionError,
    } = useGetEmployeeSection({ employeeId });

    const handleEmployeeUpdate = () => {
        refetchEmployeeById().catch(handleError);
        dispatch({
            type: EmployeeProfileActionType.REFETCH_EMPLOYEE_PROFILE,
            refetchEmployee: true,
        });
    };

    const handleEmploymentUpdate = () => {
        handleEmployeeUpdate();
        refetchEmployments().catch(handleError);
    };

    const handleCustomSectionChange = () => {
        refetchEmployeeSection().catch(handleError);
    };

    const handleLongLeaveButton = () => {
        setLongLeaveDialogOpen(!longLeaveDialogOpen);
    };

    const onCancelLongLeave = async (longLeaveId: number) => {
        try {
            await deleteLongLeave(longLeaveId);
            await refetchLongLeaves();
        } catch (error) {
            handleError(error);
        } finally {
            resetDialogsAndRefreshData();
        }
    };

    const onSaveLongLeave = (longLeaveForm: LongLeaveFormValues) => {
        if (currentLongLeave?.id) {
            onUpdateLongLeave(longLeaveForm, currentLongLeave.id).catch(handleError);
            return;
        }
        onCreateLongLeave(longLeaveForm).catch(handleError);
    };

    const onUpdateLongLeave = async (longLeaveForm: LongLeaveFormValues, longLeaveId: number) => {
        const longLeaveUpdateMutation: LongLeaveUpdateMutation = {
            startDate: longLeaveForm.startDate,
            endDate: longLeaveForm.endDate,
            comment: longLeaveForm.comment,
        };

        try {
            await updateLongLeave(longLeaveId, longLeaveUpdateMutation);
            await refetchLongLeaves();
        } catch (error) {
            handleError(error);
        } finally {
            resetDialogsAndRefreshData();
        }
    };

    const resetDialogsAndRefreshData = () => {
        setLongLeaveDialogOpen(false);
        setCancelLongLeaveDialogOpen(false);
        setCurrentLongLeave(undefined);
        handleEmployeeUpdate();
    };

    const findSelectedLongLeave = (sectionRow: SectionRow) => {
        return longLeaves?.find(longLeave => longLeave.id === sectionRow.id);
    };

    const handleLongLeaveEditClick = (sectionRow: SectionRow) => {
        setLongLeaveDialogOpen(!longLeaveDialogOpen);
        setCurrentLongLeave(findSelectedLongLeave(sectionRow));
    };

    const handleLongLeaveCancelClick = (sectionRow: SectionRow) => {
        setCancelLongLeaveDialogOpen(!cancelLongLeaveDialogOpen);
        setCurrentLongLeave(findSelectedLongLeave(sectionRow));
    };

    const onCreateLongLeave = async (longLeaveForm: LongLeaveFormValues) => {
        const longLeaveCreateMutation: LongLeaveCreateMutation = {
            employeeId: employeeId,
            startDate: longLeaveForm.startDate,
            endDate: longLeaveForm.endDate,
            comment: longLeaveForm.comment,
        };

        try {
            await createLongLeave(longLeaveCreateMutation);
            await refetchLongLeaves();
        } catch (error) {
            handleError(error);
        } finally {
            resetDialogsAndRefreshData();
        }
    };

    const { data: employeeProfileChanges = [], refetch: refetchPendingChanges } = useGetEmployeeProfilePendingChanges(employeeId);

    useEffect(() => {
        if (mustRefetchPendingChanges) {
            refetchPendingChanges().catch(handleError);
        }
    }, [mustRefetchPendingChanges, refetchPendingChanges]);

    if (!realm) {
        throw new Error('Realm is not defined');
    }

    const CUSTOM_SECTION_TYPES = [SectionType.CUSTOM_MULTI_ROW, SectionType.CUSTOM_SINGLE_ROW];

    // Filter out personal sections
    const visibleSections = sectionDefinitions?.filter(
        ({ type, tab }) =>
            (CUSTOM_SECTION_TYPES.includes(type) && tab === 'JOB') || (!PERSONAL_SECTIONS.includes(type) && !CUSTOM_SECTION_TYPES.includes(type)),
    );

    return (
        <StateHandler
            loadingComponent={<PersonalInfoPageSkeleton />}
            isLoading={isEmployeeByIdLoading || isSectionDefinitionsLoading || isEmployeeSectionLoading}
            isError={isEmployeeByIdError || isSectionDefinitionsError || isEmployeeSectionError}
            error={employeeError || sectionDefinitionsError || employeeSectionError}
        >
            {!!employee && (
                <Stack gap={2} overflow='auto'>
                    <EmploymentStatusInfo employee={employee} employments={employments} longLeave={longLeaves[0]} />
                    {visibleSections?.map(sectionDefinition => {
                        const customSection = employeeSection?.find(section => section.sectionDefinition?.id === sectionDefinition.id);

                        switch (sectionDefinition.type) {
                            case SectionType.CONTRACT:
                                return (
                                    canViewEmployeeContracts(policies, employeeId) && (
                                        <EmployeeContractSection
                                            key={SectionType.CONTRACT}
                                            employee={employee}
                                            onUpdateEmployeeContract={handleEmploymentUpdate}
                                            handleLongLeaveButton={handleLongLeaveButton}
                                            employments={employments ?? []}
                                        />
                                    )
                                );
                            case SectionType.EMPLOYMENT:
                                return (
                                    canViewEmployeeContracts(policies, employeeId) && (
                                        <StateHandler isLoading={isEmploymentsLoading} isError={isEmploymentsError} error={employmentError}>
                                            <EmploymentSection key={SectionType.EMPLOYMENT} employee={employee} onUpdateEmployments={handleEmploymentUpdate} />
                                        </StateHandler>
                                    )
                                );
                            case SectionType.WORK_PATTERN:
                                return (
                                    canViewEmployeeContracts(policies, employeeId) && (
                                        <EmployeeWorkPatternSection
                                            key={SectionType.WORK_PATTERN}
                                            employee={employee}
                                            onUpdateWorkPattern={handleEmployeeUpdate}
                                        />
                                    )
                                );
                            case SectionType.LONG_LEAVE:
                                return (
                                    canViewEmployeeContracts(policies, employeeId) && (
                                        <EmployeeLongLeaveSection
                                            key={SectionType.LONG_LEAVE}
                                            employeeId={employeeId}
                                            longLeaves={longLeaves}
                                            handleLongLeaveEditClick={handleLongLeaveEditClick}
                                            handleLongLeaveCancelClick={handleLongLeaveCancelClick}
                                        />
                                    )
                                );
                            case SectionType.CUSTOM_SINGLE_ROW:
                            case SectionType.CUSTOM_MULTI_ROW:
                                return (
                                    customSection &&
                                    canViewCustomSections(policies, employeeId, customSection.sectionDefinition.id) && (
                                        <EmployeeCustomSection
                                            key={sectionDefinition.id}
                                            employeeSection={customSection}
                                            onChange={handleCustomSectionChange}
                                            pendingRows={getEmployeeProfilePendingRowsBySectionDefinition(employeeProfileChanges, sectionDefinition.id)}
                                        />
                                    )
                                );
                            default:
                                return undefined;
                        }
                    })}

                    {cancelLongLeaveDialogOpen && currentLongLeave && (
                        <DialogContainer
                            open={cancelLongLeaveDialogOpen}
                            onClose={() => setCancelLongLeaveDialogOpen(false)}
                            onSave={() => onCancelLongLeave(currentLongLeave.id)}
                            title={t('employee.employment.are_you_sure')}
                            saveButtonText={t('general.yes')}
                            cancelButtonText={t('general.no')}
                        />
                    )}
                    {longLeaveDialogOpen && (
                        <LongLeaveDialog
                            currentLongLeave={currentLongLeave}
                            longLeaveDialogOpen={longLeaveDialogOpen}
                            onSaveLongLeave={onSaveLongLeave}
                            setLongLeaveDialogOpen={setLongLeaveDialogOpen}
                            employeeId={employeeId}
                            employments={employments}
                        />
                    )}
                </Stack>
            )}
        </StateHandler>
    );
};

type EmploymentStatusInfoProps = {
    employee: Employee;
    employments: Employment[];
    longLeave: LongLeave | undefined;
};

export const EmploymentStatusInfo: FC<EmploymentStatusInfoProps> = ({ employee, employments, longLeave }) => {
    const { employmentStatus } = employee;
    const policies = useAppSelector(state => state.currentEmployee.grantedPolicies);

    const currentEmployment = getCurrentPrincipalEmployment(employee);
    const noEmployment = !currentEmployment || !employmentStatus;
    const isEmployed = employmentStatus === EmploymentStatus.EMPLOYED;
    const canViewContractInfo = employmentStatus === EmploymentStatus.ON_LONG_LEAVE || canViewEmployeeContracts(policies, employee.id);

    if (noEmployment || isEmployed || !canViewContractInfo) {
        return;
    }

    const getSeverity = (employmentStatus: EmploymentStatus) => {
        switch (employmentStatus) {
            case EmploymentStatus.HIRED:
                return 'info';
            case EmploymentStatus.ON_LONG_LEAVE:
                return 'warning';
            case EmploymentStatus.TERMINATED:
                return 'error';
        }
    };

    return (
        <Alert severity={getSeverity(employmentStatus)} elevation={0} sx={{ alignItems: 'center' }}>
            <Typography> {getCurrentEmploymentStatusLabelDisplay(employmentStatus, employments, longLeave)}</Typography>
        </Alert>
    );
};
