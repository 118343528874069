import {
    TimesheetAdjustment,
    TimesheetAdjustmentCreateMutation,
    TimesheetAdjustmentUpdateMutation,
} from '@/domain/timesheet-adjustment/TimesheetAdjustment.model';
import { API_BASE_URL, client } from '@/api/common';
import { AxiosResponse } from 'axios';
import { ImportRequest, ImportResult } from '@/domain/import/Import.model';

const timesheetAdjustmentRequest = async (request: TimesheetAdjustmentCreateMutation): Promise<TimesheetAdjustment> => {
    const url = API_BASE_URL + '/timesheet-adjustments';
    return (await client.post<TimesheetAdjustmentDTO, AxiosResponse<TimesheetAdjustmentDTO>, TimesheetAdjustmentCreateMutationDTO>(url, request)).data;
};

const timesheetAdjustmentUpdateRequest = async (request: TimesheetAdjustmentUpdateMutation, id: number): Promise<TimesheetAdjustment> => {
    const url = API_BASE_URL + `/timesheet-adjustments/${id}`;
    return (await client.put<TimesheetAdjustmentDTO, AxiosResponse<TimesheetAdjustmentDTO>, TimesheetAdjustmentUpdateMutationDTO>(url, request)).data;
};

const getTimesheetAdjustment = async (id: number): Promise<TimesheetAdjustment> => {
    const url = API_BASE_URL + `/timesheet-adjustments/${id}`;
    return (await client.get<TimesheetAdjustmentDTO>(url)).data;
};

const deleteTimesheetAdjustment = async (id: number): Promise<void> => {
    const url = API_BASE_URL + `/timesheet-adjustments/${id}`;
    return (await client.delete(url)).data;
};

const importTimesheetsAdjustments = async (request: ImportRequest): Promise<ImportResult> => {
    return (await client.post<ImportResult, AxiosResponse<ImportResult>, ImportRequest>(API_BASE_URL + `/timesheet-adjustments/import`, request)).data;
};

export const timesheetAdjustmentAPI = {
    timesheetAdjustmentRequest,
    timesheetAdjustmentUpdateRequest,
    deleteTimesheetAdjustment,
    importTimesheetsAdjustments,
    getTimesheetAdjustment,
};

type TimesheetAdjustmentCreateMutationDTO = TimesheetAdjustmentCreateMutation;
type TimesheetAdjustmentUpdateMutationDTO = TimesheetAdjustmentUpdateMutation;
type TimesheetAdjustmentDTO = TimesheetAdjustment;
