import { FieldLabel } from '@/components/form/field-label/FieldLabel';
import { FieldSelect } from '@/components/form/field-select/FieldSelect';
import { FieldSwitch } from '@/components/form/field-switch/FieldSwitch';
import { TranslatableRichTextEditor } from '@/components/translatable-rich-text-editor/TranslatableRichTextEditor';
import { TranslationLanguageSelector } from '@/components/translation-language-selector/TranslationLanguageSelector';
import { createDefaultLabel } from '@/domain/label/Label.service';
import { Skill } from '@/domain/skill/Skill.model';
import { useGetReviewsCategories } from '@/hooks/review-category/ReviewCategory.hook';
import { useGetRoles } from '@/hooks/role/Role.hook';
import { ContentContainer } from '@/page/layout/ContentContainer';
import { Footer, FooterActions, FooterActionsProps } from '@/page/layout/Footer';
import { getReviewSkillSettingFormSchema, ReviewSkillSettingFormValues } from '@/page/setting/review/skill/ReviewSkillSettingForm.schema';
import { getLabelTranslation, getLocalizedErrorMessage, getRealmLanguage, UserLanguage } from '@/utils/language.util';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormControlLabel, Paper, Stack, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const ReviewSkillSettingForm: FC<{
    reviewSkill?: Skill;
    onDelete: (id: number) => void;
    onSave: (data: ReviewSkillSettingFormValues) => void;
}> = ({ reviewSkill, onDelete, onSave }) => {
    const { t } = useTranslation();
    const [translationLanguage, setTranslationLanguage] = useState<UserLanguage>(getRealmLanguage());

    const formMethods = useForm<ReviewSkillSettingFormValues>({
        resolver: yupResolver(getReviewSkillSettingFormSchema(translationLanguage)),
        defaultValues: {
            name: reviewSkill?.name ?? createDefaultLabel(),
            description: reviewSkill?.description ?? createDefaultLabel(),
            categoryId: reviewSkill?.category.id,
            allRolesSelected: reviewSkill?.allRolesSelected ?? true,
            availableForRoles: reviewSkill?.roles.map(role => role.id) ?? [],
        },
    });

    const { data: reviewCategories = [], isLoading: isLoadingReviewCategoriesOptions } = useGetReviewsCategories();
    const { data: roles = [], isLoading: isLoadingRoles } = useGetRoles();

    const {
        handleSubmit,
        control,
        watch,
        formState: { isDirty },
    } = formMethods;

    const footerButtons: FooterActionsProps['actions'] = [];

    if (reviewSkill) {
        footerButtons.push({
            key: 'delete',
            name: 'delete',
            onClick: () => onDelete(reviewSkill.id),
            children: t('general.delete'),
            color: 'error',
            variant: 'contained',
        });
    }

    footerButtons.push({
        key: 'save',
        name: 'save',
        onClick: () => {
            handleSubmit(onSave, console.error)();
        },
        disabled: !isDirty,
        children: reviewSkill ? t('general.update') : t('general.create'),
        variant: 'contained',
    });

    const allRolesSelected = watch('allRolesSelected');

    return (
        <FormProvider {...formMethods}>
            <ContentContainer>
                <Stack component={Paper} p={2} direction='column' flex='1'>
                    <Stack sx={{ width: '100%' }} gap={2} direction='column'>
                        <Stack direction='row' justifyContent='space-between' alignItems={'center'}>
                            <Typography variant='body1bold'>{t('reviews_settings_page.skill_form.about')}</Typography>
                            <TranslationLanguageSelector translationLanguage={translationLanguage} onLanguageChange={setTranslationLanguage} />
                        </Stack>
                        <Stack gap={1} sx={{ width: '40%' }}>
                            <FieldLabel
                                control={control}
                                name='name'
                                fullWidth
                                language={translationLanguage}
                                label={t('reviews_settings_page.skill_form.skill_name')}
                            />

                            <Typography variant='body1bold'>{t('reviews_settings_page.skill_form.about')}</Typography>
                            <Controller
                                name='description'
                                control={control}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <TranslatableRichTextEditor
                                        value={value}
                                        onUpdate={onChange}
                                        minHeight='100px'
                                        translationLanguage={translationLanguage}
                                        name={'description'}
                                        errorMessage={getLocalizedErrorMessage(error, translationLanguage)}
                                    />
                                )}
                            />

                            <FormControlLabel
                                label={t('reviews_settings_page.skill_form.category')}
                                control={
                                    <FieldSelect
                                        name='categoryId'
                                        control={control}
                                        fullWidth
                                        options={reviewCategories?.flatMap(r => r.id)}
                                        loading={isLoadingReviewCategoriesOptions}
                                        getOptionLabel={option => getLabelTranslation(reviewCategories?.find(category => category.id === option)?.name)}
                                    />
                                }
                            />
                        </Stack>
                        <Stack>
                            <Typography variant='body1bold'>{t('reviews_settings_page.skill_form.availability')}</Typography>
                        </Stack>
                        <Stack>
                            <FormControlLabel
                                sx={{ mr: 0 }}
                                label={t('reviews_settings_page.skill_form.all_roles_selected')}
                                labelPlacement='end'
                                control={<FieldSwitch control={control} name='allRolesSelected' />}
                            />

                            {!allRolesSelected && (
                                <FormControlLabel
                                    label={t('reviews_settings_page.skill_form.available_for')}
                                    control={
                                        <FieldSelect
                                            name='availableForRoles'
                                            control={control}
                                            fullWidth
                                            multiple
                                            loading={isLoadingRoles}
                                            options={roles.flatMap(role => role.id)}
                                            getOptionLabel={option => roles.find(role => role.id === option)?.name ?? ''}
                                        />
                                    }
                                />
                            )}
                        </Stack>
                    </Stack>
                </Stack>
            </ContentContainer>

            <Footer>
                <FooterActions actions={footerButtons} />
            </Footer>
        </FormProvider>
    );
};
