import { useAgGridWrapper } from '@/components/ag-grid-wrapper/useAgGridWrapper';
import { Footer } from '@/page/layout/Footer';
import { RouteLeavingGuard } from '@/components/route-leaving-guard/RouteLeavingGuard';
import { displayFormRouteLeavingGuard } from '@/components/route-leaving-guard/RouteLeavingGuard.util';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { Calendar, CalendarDay } from '@/domain/calendar/Calendar.model';
import { createCalendar, deleteCalendar, updateCalendar } from '@/domain/calendar/Calendar.service';
import { useGetCalendar } from '@/hooks/calendar/Calendar.hook';
import { CalendarDaysTable } from '@/page/setting/calendar/calendar-days-table/CalendarDaysTable';
import { handleError } from '@/utils/api.util';
import { showSnackbar } from '@/utils/snackbar.util';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, FormControlLabel, Paper, Stack, TextField, Typography } from '@mui/material';
import { Add01Icon, Download02Icon } from 'hugeicons-react';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { CalendarDayDialog } from './calendar-day-dialog/CalendarDayDialog';
import { ContentContainer } from '@/page/layout/ContentContainer';

type CalendarFormValues = Omit<Calendar, 'id' | 'template'> & {
    // days is at least an empty array
    days: CalendarDay[];
};

export const CalendarSettingPage: FC = () => {
    const params = useParams();
    const calendarId = params.calendarId ? Number(params.calendarId) : undefined;

    const { data: calendar, isLoading, isError, error } = useGetCalendar(calendarId);

    return (
        <StateHandler isLoading={isLoading} isError={isError} error={error}>
            <CalendarSettingForm calendar={calendar} />
        </StateHandler>
    );
};

export const CalendarSettingForm: FC<{ calendar: Calendar | undefined }> = ({ calendar }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const agGridWrapper = useAgGridWrapper<CalendarDay>();
    const [createDayDialogOpen, setCreateDayDialogOpen] = useState(false);
    const [dayToUpdate, setDayToUpdate] = useState<CalendarDay>();

    const schema: yup.ObjectSchema<CalendarFormValues> = yup.object().shape({
        name: yup.string().trim().required(),
        days: yup.array().required().min(1),
    });

    const { register, handleSubmit, reset, watch, setValue, formState } = useForm<CalendarFormValues>({
        resolver: yupResolver(schema),
        defaultValues: {
            name: calendar?.name ?? '',
            days: calendar?.days ?? [],
        },
    });

    const { errors } = formState;

    const watchCalendarDays = watch('days');

    const isEdit = !!calendar;

    const handleSave = (data: CalendarFormValues) => {
        if (isEdit) {
            handleEdit(data);
        } else {
            handleCreate(data);
        }
    };

    const handleCreate = async (data: CalendarFormValues) => {
        try {
            const newCalendar = await createCalendar(data);
            reset(newCalendar);
            showSnackbar(t('calendar_dialog.messages.new_calendar_created'), 'success');
            navigate('/settings/time-management/calendars');
        } catch (error) {
            handleError(error);
        }
    };

    const handleEdit = async (data: CalendarFormValues) => {
        if (calendar?.id) {
            try {
                const editedCalendar = await updateCalendar(calendar.id, data);
                reset(editedCalendar);
                showSnackbar(t('calendar_dialog.messages.calendar_updated'), 'success');
                navigate('/settings/time-management/calendars');
            } catch (error) {
                handleError(error);
            }
        }
    };

    const handleDelete = async (calendarID: number) => {
        if (isEdit) {
            try {
                await deleteCalendar(calendarID);
                navigate('/settings/time-management/calendars');
            } catch (e) {
                handleError(e);
            }
        }
    };

    const handleImport = () => {
        navigate(`/settings/import/calendar_days`);
    };

    const handleExportClick = () => {
        agGridWrapper.gridRef.current?.api?.exportDataAsExcel();
    };

    return (
        <>
            <RouteLeavingGuard when={displayFormRouteLeavingGuard(formState)} />

            {(createDayDialogOpen || !!dayToUpdate) && (
                <CalendarDayDialog
                    open={true}
                    onClose={() => {
                        setCreateDayDialogOpen(false);
                        setDayToUpdate(undefined);
                    }}
                    onSave={day => {
                        if (createDayDialogOpen) {
                            setValue('days', [...watchCalendarDays, day], { shouldDirty: true });
                        } else {
                            const dayToUpdate = watchCalendarDays.findIndex(d => d.id === day.id);
                            if (dayToUpdate !== -1) {
                                const newDays = [...watchCalendarDays];
                                newDays[dayToUpdate] = day;
                                setValue('days', [...newDays], { shouldDirty: true });
                            }
                        }
                        setCreateDayDialogOpen(false);
                        setDayToUpdate(undefined);
                    }}
                    onDelete={(day: CalendarDay) => {
                        const newDays = watchCalendarDays.filter(d => d.id !== day.id);
                        setValue('days', newDays, { shouldDirty: true });
                        if (isEdit) {
                            handleSubmit(handleSave)();
                        }
                        setCreateDayDialogOpen(false);
                        setDayToUpdate(undefined);
                    }}
                    day={dayToUpdate}
                />
            )}
            <Stack component={ContentContainer} flex={1}>
                <Stack component={Paper} flex={1} gap={2} p={3}>
                    <Typography variant='body1bold'>{t('calendars_settings_page.about_calendar')}</Typography>

                    <Stack direction='row' gap={1} justifyContent='space-between' alignItems='center'>
                        <FormControlLabel
                            label={t('calendar_dialog.calendar_name')}
                            labelPlacement='top'
                            control={<TextField fullWidth error={!!errors.name} helperText={errors.name?.message} {...register('name')} />}
                        />
                        <Stack direction='row' gap={1}>
                            <Button
                                color='primary'
                                variant='text'
                                onClick={() => {
                                    handleImport();
                                }}
                            >
                                {t('calendar_dialog.import_days')}
                            </Button>
                            <Button
                                color='primary'
                                startIcon={<Add01Icon />}
                                variant='contained'
                                onClick={() => {
                                    setCreateDayDialogOpen(true);
                                }}
                            >
                                {t('calendar_dialog.add_day')}
                            </Button>
                            <Button onClick={handleExportClick} sx={{ paddingX: 0.8, minWidth: 'auto' }}>
                                <Download02Icon />
                            </Button>
                        </Stack>
                    </Stack>

                    <CalendarDaysTable days={watchCalendarDays} onClick={setDayToUpdate} initRef={agGridWrapper.setGridRef} />

                    {errors.days && <Typography color='error'>{t('calendars_settings_page.messages.days_required')}</Typography>}
                </Stack>
            </Stack>
            <Footer>
                {isEdit && (
                    <Button color='error' variant={'contained'} aria-label={'delete_calendar'} onClick={() => handleDelete(calendar.id)}>
                        {t('general.delete')}
                    </Button>
                )}
                <Button onClick={handleSubmit(handleSave, console.error)} variant='contained' color='primary'>
                    {t(isEdit ? 'general.update' : 'general.create')}
                </Button>
            </Footer>
        </>
    );
};
