import { FormControlLabel, FormHelperText, Stack, TextField, TextFieldProps } from '@mui/material';
import { forwardRef } from 'react';
import { Label } from '@/domain/label/Label.model';
import { getHelperText, getLabelInUserLanguage, getLabelPropertyName, getRealmLanguage, UserLanguage } from '@/utils/language.util';

type Props = {
    value: Label;
    onChange: (value: Label) => void;
    translationLanguage: UserLanguage;
} & Omit<TextFieldProps, 'value' | 'onChange'>;

export const TranslatableLabelInput = forwardRef<HTMLDivElement, Props>((props, ref) => {
    const { label, value, onChange, translationLanguage, helperText, error, ...textFieldProps } = props;
    const defaultLanguage = getRealmLanguage();

    return (
        <Stack flex={props.fullWidth ? 1 : undefined}>
            <FormControlLabel
                ref={ref}
                label={label}
                control={
                    <TextField
                        autoComplete='off'
                        value={getLabelInUserLanguage(value, translationLanguage) ?? ''}
                        onChange={event => {
                            const property = getLabelPropertyName(translationLanguage) as keyof Label;
                            onChange({ ...value, [property]: event.target.value });
                        }}
                        error={!!error}
                        helperText={helperText}
                        {...textFieldProps}
                    />
                }
            />
            {value && <FormHelperText color={'text.secondary'}>{getHelperText(translationLanguage, value, defaultLanguage)}</FormHelperText>}
        </Stack>
    );
});
TranslatableLabelInput.displayName = 'TranslatableLabelInput';
