import { EmployeeAvatarWithDetails } from '@/components/employee-avatar/EmployeeAvatarWithDetails';
import { getCurrentPrincipalEmployment } from '@/domain/employment/Employment.service';
import { useGetEmployeeById } from '@/hooks/employee/Employee.hook';
import { useAppSelector } from '@/stores/store';
import { getLabelTranslation } from '@/utils/language.util';
import { Paper, Stack, StackProps, Typography } from '@mui/material';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

export const EmployeeProfileCardMobile: FC<StackProps> = props => {
    const params = useParams();
    const currentEmployee = useAppSelector(state => state.currentEmployee.employee);
    const employeeId = params?.employeeId ? Number(params?.employeeId) : currentEmployee?.id;

    const isCurrentEmployee = employeeId === currentEmployee?.id;
    const { data: employee } = useGetEmployeeById(employeeId, !isCurrentEmployee);

    const employeeToDisplay = isCurrentEmployee ? currentEmployee : employee;

    const employment = employee ? getCurrentPrincipalEmployment(employee) : undefined;
    return (
        <Stack component={Paper} direction={'row'} p={2} {...props}>
            <EmployeeAvatarWithDetails employee={employeeToDisplay} avatarProps={{ size: 'large' }}>
                <Typography variant='body2'>{getLabelTranslation(employment?.job?.name)}</Typography>
            </EmployeeAvatarWithDetails>
        </Stack>
    );
};
