import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { FC, useCallback, useEffect, useState } from 'react';
import { showSnackbar } from '@/utils/snackbar.util';
import { handleError } from '@/utils/api.util';
import { ICellRendererParams } from '@ag-grid-community/core';
import { ReviewRatingScale } from '@/domain/review-rating-scale/ReviewRatingScale.model';
import { deleteReviewRatingScale, searchReviewRatingScales } from '@/domain/review-rating-scale/ReviewRatingScale.service';
import { CompanySettingsLayout } from '@/page/setting/CompanySettingsLayout';
import { ConfigType } from '@/page/setting/types';
import { BasicMenu } from '@/components/basic-menu/BasicMenu';
import { MoreVerticalIcon } from '@/assets/icons/Icons';

export const ReviewRatingScaleSettingPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const route = useLocation();

    const [ratingScales, setRatingScales] = useState<ReviewRatingScale[]>([]);

    const fetchReviewRatingScales = useCallback(async () => {
        try {
            const reviewRatingScales = await searchReviewRatingScales({});
            setRatingScales(reviewRatingScales);
        } catch (error) {
            handleError(error);
        }
    }, []);

    useEffect(() => {
        fetchReviewRatingScales().catch(handleError);
    }, [fetchReviewRatingScales]);

    const onClickRatingRow = (ratingId: number) => {
        if (ratingId) {
            navigate(`${route.pathname}/${ratingId}`);
        }
    };

    const onDeleteRating = (ratingId: number) => {
        try {
            deleteReviewRatingScale(ratingId).catch(handleError);
            showSnackbar(t('reviews_settings_page.messages.rating_scale_deleted'), 'success');
            const allRatings = [...ratingScales];
            const newRatings = allRatings.filter(item => item.id !== ratingId);
            setRatingScales(newRatings);
        } catch (error) {
            handleError(error);
        }
    };

    const cellActionRenderer = (rating: ICellRendererParams<ReviewRatingScale>) => {
        return (
            <BasicMenu
                items={[
                    {
                        title: t('general.edit'),
                        onClick: () => rating.data?.id && onClickRatingRow(rating.data.id),
                    },
                    {
                        title: t('general.delete'),
                        onClick: () => rating.data?.id && onDeleteRating(rating.data.id),
                    },
                ]}
                endIcon={<MoreVerticalIcon />}
            />
        );
    };

    const config: ConfigType<ReviewRatingScale> = {
        type: 'table',
        isOnRowClickActive: true,
        header: {
            primaryButtonCaption: t('reviews_settings_page.scale'),
        },
        table: {
            agGridProps: {
                onRowClicked: row => (row.data?.id ? onClickRatingRow(row.data.id) : undefined),
            },
            columns: [
                {
                    field: 'name',
                    type: 'label',
                    headerName: t('reviews_settings_page.rating_scale_form.scale_name'),
                    onCellClicked: ratingScale => {
                        const ratingScaleId = ratingScale.data?.id;
                        if (ratingScaleId) {
                            onClickRatingRow(ratingScaleId);
                        }
                    },
                },
                {
                    type: 'actionMenu',
                    cellRenderer: cellActionRenderer,
                },
            ],
        },
    };

    return <CompanySettingsLayout options={config} data={ratingScales} isSearchAvailable />;
};
