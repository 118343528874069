import { capitalize, Paper, Stack } from '@mui/material';

import { AgGridWrapper, RogerColDef } from '@/components/ag-grid-wrapper/AgGridWrapper';
import { useAgGridWrapper } from '@/components/ag-grid-wrapper/useAgGridWrapper';
import { BasicMenu, BasicMenuItem } from '@/components/basic-menu/BasicMenu';
import { DatatableAdditionalAction } from '@/components/datatable-additional-action/DatatableAdditionalAction';
import { RequestStatusChip } from '@/components/request-status-chip/RequestStatusChip';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { UnitType } from '@/domain/date/Date.model';
import { LeaveRequest } from '@/domain/leave-request/LeaveRequest.model';
import {
    approvePendingLeaveRequest,
    canCancelLeaveRequest,
    canDeclineLeaveRequest,
    canLeaveRequestBeApproved,
    convertLeavesMinutesToUnit,
    createShiftSearchRequestFromLeaveRequest,
    deleteLeaveRequest,
    getLeaveRequestDisplayUnitType,
    getRequestStatusTranslationKey,
    isLeaveMedicalTypeEndDateEmpty,
} from '@/domain/leave-request/LeaveRequest.service';
import { LeaveActivityType } from '@/domain/leave-type/LeaveType.model';
import { canApproveRejectLeaveRequests, canEditLeaveRequest, hasManageShiftsPolicy, hasViewShiftsPolicy } from '@/domain/permission/Permission.service';
import { Shift, ShiftReleaseRequest, ShiftStatus } from '@/domain/shift/Shift.model';
import { DeclineLeaveRequestDialog } from '@/page/leave/DeclineLeaveRequestDialog';
import { LeaveCancellationConfirmationDialog } from '@/page/leave/LeaveCancellationConfirmationDialog';
import { LeavesConflictsDialog } from '@/page/leave/leaves-conflicts-dialog/LeavesConflictsDialog';
import { useAppSelector } from '@/stores/store';
import { handleError } from '@/utils/api.util';
import { showSnackbar } from '@/utils/snackbar.util';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { MoreVerticalIcon } from '@/assets/icons/Icons';
import { getFieldDefinitionTranslation } from '@/components/ag-grid-wrapper/column-types/columnTypes';
import { DateRangePicker } from '@/components/date-range-picker/DateRangePicker';
import { useDateRangeStorage } from '@/components/date-range-picker/DateRangePicker.hook';
import { AsyncSelectFilter, FiltersBar, SelectFilter } from '@/components/filters-bar/FiltersBar';
import { getNestedValueByPath, getSelectFilterNumberValues } from '@/components/filters-bar/FiltersBar.util';
import { useFiltersStorage } from '@/components/filters-bar/useFiltersStorage';
import { doesEmployeeMatchFilter, EmployeeFilterType, isValidEmployeeFilterType, searchEmployees } from '@/domain/employee/Employee.service';
import { EMPLOYEE_STATUS_TYPES, Employment } from '@/domain/employment/Employment.model';
import { getJobs } from '@/domain/job/Job.service';
import { getLeaveTypes } from '@/domain/leave-type/LeaveType.service';
import { getLocations } from '@/domain/location/Location.service';
import { getEmployeeShifts, shiftRelease } from '@/domain/shift/Shift.service';
import { useGetLeaveRequests } from '@/hooks/leave-request/LeaveRequest.hook';
import { toDate } from '@/utils/datetime.util';
import { getLabelTranslation } from '@/utils/language.util';
import { ICellRendererParams } from '@ag-grid-community/core';
import { getDayPeriodTranslationKey } from '@/domain/date/Date.service';

export const AllLeaveRequestsPage: FC = () => {
    const { t } = useTranslation();

    const grantedPolicies = useAppSelector(state => state.currentEmployee.grantedPolicies);

    const [isLeaveConflictsDialogOpen, setIsLeaveConflictsDialogOpen] = useState<boolean>(false);
    const [leaveRequestToCancel, setLeaveRequestToCancel] = useState<LeaveRequest>();
    const [shiftReleaseRequest, setShiftReleaseRequest] = useState<ShiftReleaseRequest>();
    const [activeLeaveRequest, setActiveLeaveRequest] = useState<LeaveRequest>();
    const [conflictingShifts, setConflictingShifts] = useState<Shift[]>();

    const [currentDeclineLeaveRequest, setCurrentDeclineLeaveRequest] = useState<LeaveRequest>();

    const { dateRange, dateRangeViewType, onDateRangeChange } = useDateRangeStorage({
        storageKey: 'manage-all-leaves-date-range',
    });

    const { filters: availableFilters } = useAllLeaveRequestsFilters();
    const [filters, setFilters] = useFiltersStorage('all-leave-requests', availableFilters);

    const {
        data: leaveRequests = [],
        refetch: fetchLeaveRequests,
        isLoading,
        isError,
        error,
    } = useGetLeaveRequests({
        startDate: dateRange[0],
        endDate: dateRange[1],
    });

    const getLeaveRequestsFiltered = () => {
        // if no filters are applied, we return the original data
        const filtersFilled = filters?.filter(filter => !!filter.value?.length);
        return !filtersFilled?.length
            ? leaveRequests
            : leaveRequests.filter(row =>
                  // if one of the filter is not matching, we don't want to display the row
                  filtersFilled.every(filter => isFilterMatched(filter, row)),
              );
    };

    const isFilterMatched = (filter: SelectFilter | AsyncSelectFilter, request: LeaveRequest): boolean => {
        const key = filter.key;

        if (isValidEmployeeFilterType(key)) {
            const ids = getSelectFilterNumberValues(filter);
            return !!request?.employee && doesEmployeeMatchFilter(request.employee, ids, key);
        } else {
            const nestedValue = getNestedValueByPath(request, key);
            return !!filter.value?.find(option => option.value === nestedValue);
        }
    };

    const leaveRequestsFiltered = getLeaveRequestsFiltered();

    const agGridWrapper = useAgGridWrapper<LeaveRequest>();

    const onBtnExport = () => {
        agGridWrapper.gridRef.current?.api?.exportDataAsExcel({
            allColumns: true,
        });
    };

    const fetchLeaveRequestsAndShowSuccess = () => {
        fetchLeaveRequests().catch(handleError);
        showSnackbar(t('leaves_page.messages.leave_request_approved'), 'success');
    };

    const handleApproveLeaveRequest = (leaveRequestId: number) => {
        approvePendingLeaveRequest(leaveRequestId).then(() => {
            fetchLeaveRequestsAndShowSuccess();
        });
    };
    const getApproveMenuItem = (leaveRequest: LeaveRequest): BasicMenuItem => {
        return {
            title: t('leaves_page.approve'),
            onClick: () => {
                const leaveRequestId = leaveRequest.id;
                if (!leaveRequestId) {
                    return;
                }
                const shiftStatus = [ShiftStatus.SHIFT_DRAFT, ShiftStatus.SHIFT_PUBLISHED];
                const shiftSearchRequest = createShiftSearchRequestFromLeaveRequest(leaveRequest.employee.id, shiftStatus, leaveRequest);
                if (
                    !isLeaveMedicalTypeEndDateEmpty(leaveRequest.leaveType, toDate(leaveRequest.endDate)) &&
                    (hasManageShiftsPolicy(grantedPolicies) || hasViewShiftsPolicy(grantedPolicies))
                ) {
                    getEmployeeShifts(shiftSearchRequest).then(res => {
                        if (res?.[0]?.shifts?.length) {
                            setConflictingShifts(res[0].shifts);
                            setIsLeaveConflictsDialogOpen(true);
                            const employeeId = shiftSearchRequest.employeeIds?.[0];
                            if (!employeeId) {
                                return;
                            }
                            const shiftReleaseObj: ShiftReleaseRequest = {
                                rangeDates: shiftSearchRequest.rangeDates,
                                employeeId,
                            };
                            setShiftReleaseRequest(shiftReleaseObj);
                            setActiveLeaveRequest(leaveRequest);
                        } else {
                            handleApproveLeaveRequest(leaveRequestId);
                        }
                    });
                } else {
                    approvePendingLeaveRequest(leaveRequestId).then(fetchLeaveRequestsAndShowSuccess).catch(handleError);
                }
            },
        };
    };

    const getDeclineMenuItem = (leaveRequest: LeaveRequest): BasicMenuItem => {
        return {
            title: t('leaves_page.decline'),
            onClick: event => {
                event.stopPropagation();
                setCurrentDeclineLeaveRequest(leaveRequest);
            },
        };
    };
    const getCancelMenuItem = (leaveRequest: LeaveRequest): BasicMenuItem => {
        return {
            title: t('general.cancel'),
            onClick: event => {
                event.stopPropagation();
                setLeaveRequestToCancel(leaveRequest);
            },
        };
    };

    const getDeleteMenuItem = (leaveRequestId: number): BasicMenuItem => {
        return {
            title: t('general.delete'),
            onClick: event => {
                event.stopPropagation();
                if (!leaveRequestId) {
                    return;
                }
                deleteLeaveRequest(leaveRequestId)
                    .then(() => {
                        fetchLeaveRequests().catch(handleError);
                    })
                    .catch(handleError);
            },
        };
    };

    const getRowActionMenu = (leaveRequest: LeaveRequest) => {
        const menuItems: BasicMenuItem[] = [];
        if (!leaveRequest) {
            return;
        }
        if (canApproveRejectLeaveRequests(grantedPolicies, leaveRequest.employee?.id)) {
            if (canLeaveRequestBeApproved(leaveRequest)) {
                menuItems.push(getApproveMenuItem(leaveRequest));
            }
            if (canDeclineLeaveRequest(leaveRequest) && leaveRequest.leaveType?.leaveActivityType !== LeaveActivityType.MEDICAL) {
                menuItems.push(getDeclineMenuItem(leaveRequest));
            }
        }

        if (canEditLeaveRequest(leaveRequest.requestStatus, grantedPolicies, leaveRequest.employee?.id)) {
            if (canCancelLeaveRequest(leaveRequest)) {
                menuItems.push(getCancelMenuItem(leaveRequest));
            }

            if (leaveRequest.requestStatus === 'CANCELLED' && leaveRequest.id) {
                menuItems.push(getDeleteMenuItem(leaveRequest.id));
            }
        }
        return menuItems;
    };

    const leaveRequestCancelled = () => {
        showSnackbar(t('request_leave_dialog.messages.leave_request_cancelled'), 'success');
    };

    const cellStatusRenderer = ({ value }: { value: string }) => <RequestStatusChip status={value} />;

    const cellActionRenderer = (params: ICellRendererParams<LeaveRequest>) => {
        if (!params.data) {
            return;
        }
        const items = getRowActionMenu(params.data);
        if (!items?.length) {
            return;
        }
        return <BasicMenu items={items} endIcon={<MoreVerticalIcon />} />;
    };

    const columnDefs: RogerColDef<LeaveRequest>[] = [
        {
            field: 'employee.email',
            headerName: t('leaves_page.table_headers.email'),
            hide: true,
        },
        {
            field: 'employee',
            type: 'employee',
            headerName: t('general.employee'),
        },
        {
            field: 'employee.currentEmployments',
            colId: 'mainLocation',
            headerName: t('leaves_page.table_headers.main_location'),
            valueFormatter: ({ value }: { value: Employment[] }) => value.flatMap(employment => employment.location.name).join(', '),
        },
        {
            field: 'employee.currentEmployments',
            colId: 'jobTitle',
            headerName: t('leaves_page.table_headers.job_title'),
            valueFormatter: ({ value }: { value: Employment[] }) => value.flatMap(employment => getLabelTranslation(employment.job.name)).join(', '),
        },
        {
            field: 'leaveType.name',
            headerName: t('leaves_page.table_headers.type'),
            type: 'label',
        },
        {
            field: 'requestStatus',
            cellRenderer: cellStatusRenderer,
            valueFormatter: param => t(getRequestStatusTranslationKey(param?.value)),
            headerName: t('leaves_page.table_headers.status'),
            cellClass: ['display-flex'],
        },
        {
            field: 'startTimePeriod',
            headerName: t('leaves_page.table_headers.start_time_period'),
            valueGetter: ({ data }) => {
                if (data?.unitType === UnitType.DAYS) {
                    return t(getDayPeriodTranslationKey(data?.startTimePeriod));
                }
            },
        },
        {
            field: 'endTimePeriod',
            headerName: t('leaves_page.table_headers.end_time_period'),
            valueGetter: ({ data }) => {
                if (data?.unitType === UnitType.DAYS) {
                    return t(getDayPeriodTranslationKey(data?.endTimePeriod));
                }
            },
        },
        {
            field: 'startDate',
            type: 'date',
            headerName: t('leaves_page.table_headers.start_date'),
        },
        {
            field: 'endDate',
            type: 'date',
            headerName: t('leaves_page.table_headers.end_date'),
        },
        {
            field: 'comment',
            cellRendererParams: params => {
                if (params?.data?.requestStatus) {
                    return { status: params.value };
                } else {
                    return {};
                }
            },
            headerName: t('leaves_page.table_headers.comment'),
        },
        {
            headerName: t('general.duration'),
            colId: 'duration',
            valueGetter: params => {
                if (!params?.data?.durationInMinutes) {
                    // can be empty in case of medical leave type
                    return '';
                }
                const unitType = getLeaveRequestDisplayUnitType(params.data);
                return convertLeavesMinutesToUnit({
                    input: (unitType ? params.data.durationInDays : params.data.durationInMinutes) ?? 0,
                    outputUnit: unitType,
                    roundingType: params.data.leaveType.roundingType,
                    useAbsoluteValue: true,
                });
            },
        },
        {
            field: 'usedAmountInMinutes',
            valueGetter: params => {
                if (!params?.data?.usedAmountInMinutes) {
                    // can be empty in case of medical leave type
                    return '';
                }
                const unitType = getLeaveRequestDisplayUnitType(params.data);
                return convertLeavesMinutesToUnit({
                    input: (unitType ? params.data.usedAmountInDays : params.data.usedAmountInMinutes) ?? 0,
                    outputUnit: unitType,
                    roundingType: params.data.leaveType.roundingType,
                    useAbsoluteValue: true,
                });
            },
            headerName: t('payroll.used_days'),
        },
        {
            field: 'leavePercentage',
            headerName: t('general.incapacity'),
            valueFormatter: params => {
                const value = params?.data?.leavePercentage;
                if (!value) {
                    return '';
                }
                return `${value}%`;
            },
        },
        {
            headerName: t('general.unit'),
            colId: 'displayUnitType',
            valueGetter: params => {
                return params?.data?.unitType === UnitType.DAYS ? capitalize(t('general.days_plural')) : capitalize(t('general.hours_plural'));
            },
        },
        {
            field: 'employee.employeeCode',
            headerName: t('payroll.id'),
            hide: true,
        },
        {
            type: 'actionMenu',
            cellRenderer: cellActionRenderer,
        },
    ];
    return (
        <StateHandler isLoading={isLoading} isError={isError} error={error}>
            <Stack gap={2} flex={1}>
                <Stack component={Paper} direction='row' gap={2} alignItems='center' justifyContent='space-between' p={1}>
                    <Stack direction='row' alignItems={'flex-start'} gap={1} flexWrap={'wrap'}>
                        <DateRangePicker
                            dates={dateRange}
                            onDatesChanged={onDateRangeChange}
                            defaultViewType={dateRangeViewType}
                            availableViews={['MONTH', 'RANGE']}
                        />
                        <FiltersBar filters={filters} onFiltersChange={setFilters} flex={1} />
                    </Stack>
                    <DatatableAdditionalAction quickFilter={agGridWrapper.quickFilter} onBtnExport={onBtnExport} />
                </Stack>
                <Stack flex={1}>
                    <AgGridWrapper<LeaveRequest> initRef={agGridWrapper.setGridRef} rowData={leaveRequestsFiltered} columnDefs={columnDefs} />
                </Stack>
                {isLeaveConflictsDialogOpen && !!shiftReleaseRequest && !!activeLeaveRequest && (
                    <LeavesConflictsDialog
                        open={isLeaveConflictsDialogOpen}
                        onClose={() => setIsLeaveConflictsDialogOpen(false)}
                        saveAndKeepConflicts={() => {
                            if (!activeLeaveRequest.id) {
                                return;
                            }
                            approvePendingLeaveRequest(activeLeaveRequest?.id).then(() => {
                                setIsLeaveConflictsDialogOpen(false);
                                fetchLeaveRequestsAndShowSuccess();
                            });
                        }}
                        onSave={() => {
                            shiftRelease(shiftReleaseRequest).then(() => {
                                if (!activeLeaveRequest.id) {
                                    return;
                                }
                                approvePendingLeaveRequest(activeLeaveRequest?.id).then(fetchLeaveRequestsAndShowSuccess);
                                setIsLeaveConflictsDialogOpen(false);
                            });
                        }}
                        shifts={conflictingShifts ?? []}
                    />
                )}

                {!!leaveRequestToCancel && (
                    <LeaveCancellationConfirmationDialog
                        leaveRequest={leaveRequestToCancel}
                        onSuccess={() => {
                            leaveRequestCancelled();
                            setLeaveRequestToCancel(undefined);
                            fetchLeaveRequests().catch(handleError);
                        }}
                        onClose={() => setLeaveRequestToCancel(undefined)}
                    />
                )}

                {!!currentDeclineLeaveRequest && (
                    <DeclineLeaveRequestDialog
                        leaveRequestId={currentDeclineLeaveRequest.id}
                        onCancel={() => setCurrentDeclineLeaveRequest(undefined)}
                        onDeclineRequest={() => {
                            setCurrentDeclineLeaveRequest(undefined);
                            fetchLeaveRequests().catch(handleError);
                        }}
                    />
                )}
            </Stack>
        </StateHandler>
    );
};

const useAllLeaveRequestsFilters = () => {
    const { t } = useTranslation();
    const filters: (AsyncSelectFilter | SelectFilter)[] = [
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'LEAVE_TYPE_POLICY_LEAVE_TYPE_TITLE' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                const leaveTypes = await getLeaveTypes();
                return leaveTypes?.map(leaveType => ({
                    label: getLabelTranslation(leaveType.name),
                    value: leaveType.id,
                }));
            },
            key: 'leaveType.id',
            rule: 'EQUALS',
            availableRules: [],
        },
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_LOCATION' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                const locations = await getLocations();
                return locations?.map(location => ({
                    label: location.name,
                    value: location.id,
                }));
            },
            key: 'LOCATION_IDS' satisfies EmployeeFilterType,
            rule: 'EQUALS',
            availableRules: [],
        },

        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_JOB' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                const jobs = await getJobs();
                return jobs?.map(job => ({
                    label: getLabelTranslation(job.name),
                    value: job.id,
                }));
            },
            key: 'JOB_IDS' satisfies EmployeeFilterType,
            rule: 'EQUALS',
            availableRules: [],
        },
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'LEAVE_REQUEST_STATUS' }),
            type: 'multi-select',
            selectMode: 'SYNC',
            key: 'requestStatus',
            rule: 'EQUALS',
            availableRules: [],
            options: EMPLOYEE_STATUS_TYPES.map(status => ({
                label: t('leaves_page.status.' + status),
                value: status,
            })),
        },
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_MANAGER' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                const employeesData = await searchEmployees();
                return employeesData.map(employee => ({
                    label: employee.displayName,
                    value: employee.id,
                }));
            },
            key: 'MANAGER_IDS' satisfies EmployeeFilterType,
            rule: 'EQUALS',
            availableRules: [],
        },
    ];

    return {
        filters,
    };
};
