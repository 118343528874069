import { EmployeeAvatar } from '@/components/employee-avatar/EmployeeAvatar';
import { Grid } from '@mui/material';
import { FC } from 'react';

import { Employee } from '@/domain/employee/Employee.model';

type Props = {
    employee: Employee;
};

export const EmployeeDialogHeader: FC<Props> = ({ employee }) => {
    const name = `${employee?.firstName} ${employee?.lastName}`;

    return (
        <Grid
            container
            sx={{
                display: 'flex',
                alignItems: 'center',
                '& > div': {
                    display: 'flex',
                    flexDirection: 'column',
                    fontSize: 13,
                    '> b': {
                        fontSize: 14,
                    },
                },
            }}
        >
            {employee && (
                <>
                    <Grid item sx={{ mr: 1 }}>
                        <EmployeeAvatar employeeAvatar={employee} />
                    </Grid>
                    <Grid item>
                        {name}
                        <b>{employee.planningPositions?.[0]?.name}</b>
                    </Grid>
                </>
            )}
        </Grid>
    );
};
