import { getFieldValueProperty } from '@/components/section/SectionFieldComponent/SectionField.util';
import { isEqualSectionFieldValue } from '@/domain/employee-pending-change/EmployeePendingChange.service';
import { CircularProgress, Divider, Paper, Stack, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { ArrowDown01Icon } from 'hugeicons-react';
import { FC } from 'react';
import { BasicMenu, BasicMenuItem } from '../../basic-menu/BasicMenu';
import { SectionFieldComponent } from '../SectionFieldComponent/SectionFieldComponent';
import { SectionActionButton, SectionField } from '../types';

export type StackSectionProps = {
    sectionTitle: string;
    fields: SectionField[];
    pendingFields?: SectionField[];
    actionButtons?: SectionActionButton[];
    dropdownActionButtons?: BasicMenuItem[];
    dropdownTitle?: string;
};

export const StackSection: FC<StackSectionProps> = ({ sectionTitle, fields, pendingFields, actionButtons, dropdownActionButtons, dropdownTitle }) => {
    if (!sectionTitle || !fields) {
        return (
            <Stack justifyContent='center' alignItems='center'>
                <CircularProgress />
            </Stack>
        );
    }

    return (
        <Paper elevation={0} sx={{ p: 3 }}>
            <Stack justifyContent='center' alignItems='flex-start' gap={1}>
                <Stack direction='row' justifyContent='space-between' alignItems='flex-start' gap={1} sx={{ width: '100%' }}>
                    <Typography variant='h1'>{sectionTitle}</Typography>
                    <Stack direction={'row'} gap={1}>
                        {!!actionButtons?.length &&
                            actionButtons.map(actionButton => (
                                <Button key={actionButton.title} variant='text' {...actionButton}>
                                    {actionButton.title}
                                </Button>
                            ))}
                        {!!dropdownActionButtons?.length && (
                            <BasicMenu items={dropdownActionButtons} dropdownTitle={dropdownTitle} endIcon={<ArrowDown01Icon />} />
                        )}
                    </Stack>
                </Stack>
                {!!fields?.length &&
                    fields.map(field => {
                        const pendingField = pendingFields?.find(pendingField => {
                            // For EMPLOYEE_CUSTOM_FIELD, we use formValueName to compare
                            // formValueName is based on sectionFieldId
                            return pendingField.formValueName === field.formValueName && !isEqualSectionFieldValue(field, pendingField);
                        });
                        return (
                            <Stack key={field.title} width='100%'>
                                <Stack
                                    direction={{ xs: 'column', md: 'row' }}
                                    justifyContent='flex-start'
                                    alignItems={{ md: 'flex-start' }}
                                    columnGap={3}
                                    rowGap={{ xs: 1, md: 0 }}
                                    py={1}
                                >
                                    <Typography width={{ md: 200 }} variant='body1bold' noWrap>
                                        {field.title}
                                    </Typography>
                                    <Stack px={{ md: 2 }} minWidth={200} flex={1}>
                                        <SectionFieldComponent field={field} />
                                        {!!pendingField && <PendingField field={pendingField} />}
                                    </Stack>
                                </Stack>
                                <Divider flexItem sx={{ opacity: 0.6 }} />
                            </Stack>
                        );
                    })}
            </Stack>
        </Paper>
    );
};

const PendingField: FC<{ field: SectionField }> = ({ field }) => {
    if (!field) {
        return;
    }

    const property = getFieldValueProperty(field.valueType);
    const value = field[property];

    return value ? (
        <SectionFieldComponent field={field} isPending />
    ) : (
        <Typography variant='body1bold' color='warning'>
            -
        </Typography>
    );
};
