import { EmployeeSettingsUpdateMutation } from '@/domain/employee/Employee.model';
import { updateEmployeeSettings } from '@/domain/employee/Employee.service';
import { RealmFeaturesType } from '@/domain/realm/Realm.model';
import { hasRealmFeatureEnabled } from '@/domain/realm/Realm.service';
import { useLocalStorage } from '@/hooks/Storage.hook';
import { employeeSettingsUpdated } from '@/stores/reducers/currentEmployeeSlice';
import { useAppDispatch, useAppSelector } from '@/stores/store';
import { handleError } from '@/utils/api.util';
import { getTodayDate } from '@/utils/datetime.util';
import { getLanguageTranslationKey, setUserLanguage, UserLanguage } from '@/utils/language.util';
import { showSnackbar } from '@/utils/snackbar.util';
import { Button, Checkbox, FormControl, FormControlLabel, Paper, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { authApi } from '@/api/auth/AuthApi';

export const EmployeeSettingsPage: FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const { t } = useTranslation();

    const currentEmployee = useAppSelector(state => state.currentEmployee.employee);
    const realm = useAppSelector(state => state.ui.currentRealm);
    const dispatch = useAppDispatch();

    const [lastResetTime, setLastResetTime] = useLocalStorage<string>('RESET_PASSWORD_TIME', '');

    if (!currentEmployee || !realm) {
        return undefined;
    }

    const resetPasswordDisabled: boolean = lastResetTime != '' && getTodayDate().getTime() - parseInt(lastResetTime) < 60 * 1000;

    const resetPassword = () => {
        setLoading(true);
        authApi
            .resetPassword(realm.tenantId, currentEmployee?.email)
            .then(() => {
                setLastResetTime(getTodayDate().getTime().toFixed());
            })
            .catch(error => {
                handleError(error);
            })
            .finally(() => {
                showSnackbar(t('user_settings.messages.password_reset_email_sent'), 'success');
                setLoading(false);
            });
    };

    const handleLanguageChange = (event: ChangeEvent<HTMLInputElement>) => {
        const newSettings: EmployeeSettingsUpdateMutation = {
            language: event.target.value as UserLanguage,
            leaveNotificationsEnabled: currentEmployee.leaveNotificationsEnabled,
            leaveAttachmentNotificationsEnabled: currentEmployee.leaveAttachmentNotificationsEnabled,
            planningUpdatesNotificationsEnabled: currentEmployee.planningUpdatesNotificationsEnabled,
            documentNotificationsEnabled: currentEmployee.documentNotificationsEnabled,
            profileChangeNotificationsEnabled: currentEmployee.profileChangeNotificationsEnabled,
            timesheetUpdatesNotificationsEnabled: currentEmployee.timesheetUpdatesNotificationsEnabled,
            announcementNotificationsEnabled: currentEmployee.announcementNotificationsEnabled,
        };
        if (newSettings.language !== currentEmployee?.language) {
            setUserLanguage(newSettings.language).catch(handleError);
        }
        updateEmployeeSettings(newSettings)
            .then(employeeSettings => {
                const employee = { ...currentEmployee, ...employeeSettings };
                dispatch(employeeSettingsUpdated(employee));
            })
            .catch(handleError);
    };

    const handleNotificationChange = (setting: Partial<EmployeeSettingsUpdateMutation>) => {
        const newSettings: EmployeeSettingsUpdateMutation = {
            language: currentEmployee.language,
            planningUpdatesNotificationsEnabled: currentEmployee.planningUpdatesNotificationsEnabled,
            leaveNotificationsEnabled: currentEmployee.leaveNotificationsEnabled,
            leaveAttachmentNotificationsEnabled: currentEmployee.leaveAttachmentNotificationsEnabled,
            documentNotificationsEnabled: currentEmployee.documentNotificationsEnabled,
            profileChangeNotificationsEnabled: currentEmployee.profileChangeNotificationsEnabled,
            timesheetUpdatesNotificationsEnabled: currentEmployee.timesheetUpdatesNotificationsEnabled,
            announcementNotificationsEnabled: currentEmployee.announcementNotificationsEnabled,
            ...setting,
        };
        updateEmployeeSettings(newSettings)
            .then(employeeSettings => {
                const employee = { ...currentEmployee, ...employeeSettings };
                dispatch(employeeSettingsUpdated(employee));
            })
            .catch(handleError);
    };

    const hasLeaveFeatureEnabled = hasRealmFeatureEnabled(realm.realmFeatures, RealmFeaturesType.LEAVES);
    const hasPlanningFeatureEnabled = hasRealmFeatureEnabled(realm.realmFeatures, RealmFeaturesType.PLANNING);
    const mustDisplayNotifications = hasLeaveFeatureEnabled || hasPlanningFeatureEnabled;
    const isStandardLogin = realm?.availableLoginMethods?.length === 1 && realm.availableLoginMethods?.[0].type === 'STANDARD';

    return (
        <Stack direction='column' flexWrap={'nowrap'} gap={2} bgcolor={({ palette }) => palette.background.default} flex='1'>
            <Paper sx={{ p: 2 }}>
                <Typography variant='body2bold'>{t('user_settings.user_info')}</Typography>
                <Stack direction='column' gap={2} pt={2}>
                    <Stack>
                        <Typography variant='body2'>{t('user_settings.username')}</Typography>
                        <Typography variant='body2'>{currentEmployee?.email}</Typography>
                    </Stack>
                    {isStandardLogin && (
                        <Stack>
                            <Typography variant='body2'>{t('user_settings.password')}</Typography>
                            <Stack direction='row'>
                                <Button disabled={loading || resetPasswordDisabled} onClick={() => resetPassword()}>
                                    {t(
                                        resetPasswordDisabled
                                            ? 'user_settings.send_reset_password_email_link_email_sent'
                                            : 'user_settings.send_reset_password_email',
                                    )}
                                </Button>
                            </Stack>
                        </Stack>
                    )}
                </Stack>
            </Paper>
            <Paper sx={{ p: 2 }}>
                <Typography variant='body2bold'>{t('user_settings.interface')}</Typography>
                <Stack direction='column' gap={2} pt={2}>
                    <FormControl component='fieldset'>
                        <Typography color='textSecondary' variant='body2'>
                            {t('user_settings.language')}
                        </Typography>

                        <RadioGroup row value={currentEmployee?.language} onChange={handleLanguageChange}>
                            <FormControlLabel
                                value={UserLanguage.FR}
                                labelPlacement='end'
                                control={<Radio />}
                                label={t(getLanguageTranslationKey(UserLanguage.FR))}
                            />
                            <FormControlLabel
                                value={UserLanguage.EN}
                                labelPlacement='end'
                                control={<Radio />}
                                label={t(getLanguageTranslationKey(UserLanguage.EN))}
                            />
                            <FormControlLabel
                                value={UserLanguage.DE}
                                labelPlacement='end'
                                control={<Radio />}
                                label={t(getLanguageTranslationKey(UserLanguage.DE))}
                            />
                        </RadioGroup>
                    </FormControl>
                </Stack>
            </Paper>
            {mustDisplayNotifications && (
                <Paper sx={{ p: 2 }}>
                    <Typography variant='body2bold'>{t('user_settings.notifications')}</Typography>
                    <Stack direction='column' pt={2}>
                        {hasLeaveFeatureEnabled && (
                            <>
                                <FormControlLabel
                                    label={t('user_settings.leave_updates')}
                                    labelPlacement={'end'}
                                    control={
                                        <Checkbox
                                            checked={currentEmployee?.leaveNotificationsEnabled}
                                            onChange={event => handleNotificationChange({ leaveNotificationsEnabled: event.target.checked })}
                                            size='small'
                                        />
                                    }
                                />
                                <FormControlLabel
                                    label={t('user_settings.leave_attachments_updates')}
                                    labelPlacement={'end'}
                                    control={
                                        <Checkbox
                                            checked={currentEmployee?.leaveAttachmentNotificationsEnabled}
                                            onChange={event => handleNotificationChange({ leaveAttachmentNotificationsEnabled: event.target.checked })}
                                            size='small'
                                        />
                                    }
                                />
                            </>
                        )}
                        {hasPlanningFeatureEnabled && (
                            <FormControlLabel
                                label={t('user_settings.planning_updates')}
                                labelPlacement={'end'}
                                control={
                                    <Checkbox
                                        checked={currentEmployee?.planningUpdatesNotificationsEnabled}
                                        onChange={event => handleNotificationChange({ planningUpdatesNotificationsEnabled: event.target.checked })}
                                        size='small'
                                    />
                                }
                            />
                        )}
                        <FormControlLabel
                            label={t('user_settings.documents_updates')}
                            labelPlacement={'end'}
                            control={
                                <Checkbox
                                    checked={currentEmployee?.documentNotificationsEnabled}
                                    onChange={event => handleNotificationChange({ documentNotificationsEnabled: event.target.checked })}
                                    size='small'
                                />
                            }
                        />
                        <FormControlLabel
                            label={t('user_settings.profile_pending_updates')}
                            labelPlacement={'end'}
                            control={
                                <Checkbox
                                    checked={currentEmployee?.profileChangeNotificationsEnabled}
                                    onChange={event => handleNotificationChange({ profileChangeNotificationsEnabled: event.target.checked })}
                                    size='small'
                                />
                            }
                        />
                        <FormControlLabel
                            label={t('user_settings.timesheets_updates')}
                            labelPlacement={'end'}
                            control={
                                <Checkbox
                                    checked={currentEmployee?.timesheetUpdatesNotificationsEnabled}
                                    onChange={event => handleNotificationChange({ timesheetUpdatesNotificationsEnabled: event.target.checked })}
                                    size='small'
                                />
                            }
                        />
                        <FormControlLabel
                            label={t('user_settings.announcements')}
                            labelPlacement={'end'}
                            control={
                                <Checkbox
                                    checked={currentEmployee.announcementNotificationsEnabled}
                                    onChange={event => handleNotificationChange({ announcementNotificationsEnabled: event.target.checked })}
                                    size='small'
                                />
                            }
                        />
                    </Stack>
                </Paper>
            )}
        </Stack>
    );
};
