import { EmployeeAvatarWithDetails } from '@/components/employee-avatar/EmployeeAvatarWithDetails';
import { EditableSectionFieldComponent } from '@/components/section/SectionFieldComponent/EditableSectionFieldComponent';
import { getFieldValueProperty } from '@/components/section/SectionFieldComponent/SectionField.util';
import { SectionFieldComponent } from '@/components/section/SectionFieldComponent/SectionFieldComponent';
import { SectionField } from '@/components/section/types';
import { DialogContainer, DialogContainerProps } from '@/components/dialog-container/DialogContainer';
import { EmployeeProfileChangeRow } from '@/domain/employee-pending-change/EmployeePendingChange.model';
import {
    approvePendingChanges,
    cancelPendingChanges,
    convertPendingFieldToSectionField,
    isEqualSectionFieldValue,
} from '@/domain/employee-pending-change/EmployeePendingChange.service';
import { EmployeeSection } from '@/domain/employee-section/EmployeeSection.model';
import { EmployeeAvatar as EmployeeAvatarType } from '@/domain/employee/Employee.model';
import {
    convertEmployeeSectionFieldsToSectionFields,
    getEmployeePersonalInfo,
    mapFieldFormValuesToAddressMutation,
    mapFieldFormValuesToPersonalInfoMutation,
    updateEmployeeAddressPendingRequest,
    updateEmployeePersonalInfoPendingRequest,
} from '@/domain/employee/Employee.service';
import { EmployeeAddressUpdateMutation } from '@/domain/employee/EmployeeAddress.model';
import { EmployeePersonalInfo, EmployeePersonalInfoMutation } from '@/domain/employee/EmployeePersonalInfo.model';
import { SectionDefinition, SectionFieldDefinition, SectionType } from '@/domain/section-setting/Section.model';
import { convertFormValuesToFields } from '@/page/employee-profile/employee-profile-info/EmployeeCustomSection/EmployeeCustomSection.util';
import {
    getSectionDefinitionSchema,
    SectionDefinitionFormValues,
} from '@/page/employee-profile/employee-profile-info/EmployeeCustomSectionRowDialog/EmployeeSectionDefinition.schema';
import { useEmployeePersonalInfoSectionFields } from '@/page/employee-profile/employee-profile-info/EmployeePersonalInfoSection/EmployeePersonalInfoSection.hook';
import { useGetEmployeeSection } from '@/hooks/employee/EmployeeSection.hook';
import { handleError } from '@/utils/api.util';
import { getCountry } from '@/utils/countries.util';
import { getLabelTranslation } from '@/utils/language.util';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormControlLabel, Stack, StackProps, Typography, useTheme } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ArrowRight02Icon } from 'hugeicons-react';
import { updateEmployeeSectionRowPendingRequest } from '@/domain/employee-section/EmployeeSection.service';
import { toSorted } from '@/utils/collections.util';
import { EmployeeFieldType } from '@/domain/employee/EmployeeFields.model';
import formControlLabelClasses from '@mui/material/FormControlLabel/formControlLabelClasses';

type EmployeeProfilePendingRequestDialogProps = Omit<DialogContainerProps, 'onSave'> & {
    employee: EmployeeAvatarType;
    employeeSection: EmployeeSection;
    pendingRow: EmployeeProfileChangeRow;
    onSuccess: () => void;
};

/**
 * Displays a diff between the previous and the next value
 * Previous (the current value) is load by the dialog base on the section definition type
 */
export const EmployeeProfilePendingRequestDialog: FC<EmployeeProfilePendingRequestDialogProps> = ({
    open,
    onClose,
    onSuccess,
    employee,
    employeeSection,
    pendingRow,
    ...rest
}) => {
    const { t } = useTranslation();
    const sectionDefinition = employeeSection.sectionDefinition;

    const schema = getSectionDefinitionSchema({ sectionDefinition });

    const addressCountry = pendingRow.fields.find(f => f.sectionFieldDefinition.fieldType === 'ADDRESS_COUNTRY')?.stringValue;

    const formMethods = useForm<SectionDefinitionFormValues>({
        resolver: yupResolver<SectionDefinitionFormValues>(schema),
        // Workaround to set the default values for the address section
        defaultValues: addressCountry
            ? {
                  ADDRESS_COUNTRY: getCountry(addressCountry),
              }
            : undefined,
    });

    const {
        formState: { isDirty },
        handleSubmit,
    } = formMethods;

    const isTableSection = [SectionType.ADDRESS, SectionType.CUSTOM_MULTI_ROW, SectionType.WORK_PATTERN, SectionType.EMPLOYMENT].includes(
        sectionDefinition.type,
    );

    const getPendingId = () => {
        // For personal info we need to use the employee id
        if (sectionDefinition.type === 'PERSONAL_INFO') {
            return employee.id;
        }
        // For the rest of the sections we need to use the pending row id
        return pendingRow.id;
    };

    const handleConfirmClick = async (formValues: SectionDefinitionFormValues) => {
        const updatePersonalInfo = async (formValues: SectionDefinitionFormValues) => {
            const personalInfo: EmployeePersonalInfoMutation = mapFieldFormValuesToPersonalInfoMutation(formValues, sectionDefinition);

            await updateEmployeePersonalInfoPendingRequest(employee.id, personalInfo);
        };

        const updateAddress = async (formValues: SectionDefinitionFormValues, sectionDefinition: SectionDefinition) => {
            const address: EmployeeAddressUpdateMutation = mapFieldFormValuesToAddressMutation(formValues, sectionDefinition, employee.id);

            await updateEmployeeAddressPendingRequest(getPendingId(), address);
        };

        const updateSectionRow = async (formValues: SectionDefinitionFormValues) => {
            const row = {
                fields: convertFormValuesToFields(formValues, sectionDefinition),
                order: 0,
            };
            await updateEmployeeSectionRowPendingRequest(employeeSection.id, pendingRow.id, row);
        };

        const updatePendingRow = async (formValues: SectionDefinitionFormValues) => {
            if (sectionDefinition.type === 'PERSONAL_INFO') {
                await updatePersonalInfo(formValues);
            } else if (sectionDefinition.type === 'ADDRESS') {
                await updateAddress(formValues, sectionDefinition);
            } else {
                await updateSectionRow(formValues);
            }
        };

        try {
            // if the pending row is updated we have to edit the pending row before approving it
            if (isDirty) {
                await updatePendingRow(formValues);
            }

            await approvePendingChanges(getPendingId(), employeeSection);
            onSuccess();
        } catch (e) {
            handleError(e);
        }
    };

    const handleCancelClick = async () => {
        try {
            await cancelPendingChanges(getPendingId(), employeeSection);
            onSuccess();
        } catch (e) {
            handleError(e);
        }
    };

    return (
        <FormProvider {...formMethods}>
            <DialogContainer
                maxWidth={'md'}
                title={t('manage_people_pending_request_page.title')}
                open={open}
                onClose={onClose}
                onSave={handleSubmit(handleConfirmClick, console.error)}
                onCancel={handleCancelClick}
                saveButtonText={t('manage_people_pending_request_page.approve')}
                cancelButtonText={t('manage_people_pending_request_page.cancel')}
                {...rest}
            >
                <Stack gap={3}>
                    <EmployeeAvatarWithDetails employee={employee} />

                    {/* pending fields from table section are not compatible with the diff */}
                    {isTableSection ? (
                        <PendingFieldsStack pendingRow={pendingRow} sectionDefinition={sectionDefinition} />
                    ) : (
                        <DiffPendingFieldsStack pendingRow={pendingRow} employeeId={employee.id} sectionDefinition={sectionDefinition} />
                    )}
                </Stack>
            </DialogContainer>
        </FormProvider>
    );
};

const PendingFieldsStack: FC<{
    pendingRow: EmployeeProfileChangeRow;
    sectionDefinition: SectionDefinition;
}> = ({ pendingRow, sectionDefinition }) => {
    const getPendingFieldFromDefinition = (sectionFieldDefinition: SectionFieldDefinition) => {
        const title = getLabelTranslation(sectionFieldDefinition?.name);
        const formValueName = sectionFieldDefinition?.formId;

        const pendingField = pendingRow.fields.find(pR => formValueName === pR.sectionFieldDefinition.formId);

        return {
            ...convertPendingFieldToSectionField(sectionFieldDefinition, pendingField),
            formValueName,
            title,
        };
    };

    const sectionFieldsDefinitionSorted = toSorted(sectionDefinition.fields);

    return (
        <Stack gap={2}>
            {sectionFieldsDefinitionSorted.map(sectionFieldDefinition => {
                const pendingField = getPendingFieldFromDefinition(sectionFieldDefinition);
                return (
                    <FormControlLabel
                        key={pendingField.formValueName}
                        componentsProps={{ typography: { variant: 'body2bold' } }}
                        label={pendingField.title}
                        sx={{ flex: 1, alignItems: 'stretch' }}
                        htmlFor={pendingField.formValueName}
                        control={<EditableSectionFieldComponent field={pendingField} />}
                    />
                );
            })}
        </Stack>
    );
};

const DiffPendingFieldsStack: FC<{
    pendingRow: EmployeeProfileChangeRow;
    sectionDefinition: SectionDefinition;
    employeeId: number;
}> = ({ pendingRow, employeeId, sectionDefinition }) => {
    const previousFields = useGetPreviousFields(employeeId, sectionDefinition);

    const hasPreviousFieldsLoad = !!previousFields.length;

    const getPendingFieldFromDefinition = (sectionFieldDefinition: SectionFieldDefinition) => {
        const previous = previousFields?.find(
            p =>
                p.fieldType === sectionFieldDefinition.fieldType ||
                // TODO workaround to get the previous field when the field is a custom section field
                (sectionFieldDefinition.fieldType === ('CUSTOM_SECTION_FIELD' as EmployeeFieldType) &&
                    p.fieldType === 'EMPLOYEE_CUSTOM_FIELD' &&
                    p.formValueName === sectionFieldDefinition?.formId),
        );

        if (!previous) {
            throw new Error('Previous field not found');
        }

        const formValueName = sectionFieldDefinition.formId;
        const title = sectionFieldDefinition.id ? getLabelTranslation(sectionFieldDefinition.name) : previous.title;

        // get field from in row from the definition
        const pendingField = pendingRow.fields.find(f => f.sectionFieldDefinition.id === sectionFieldDefinition.id);
        const sectionFieldWithoutFormValueName = convertPendingFieldToSectionField(sectionFieldDefinition, pendingField);
        // TODO workaround to get the enum list when the field is an enum list item
        const enumList = sectionFieldWithoutFormValueName.valueType === 'ENUM' ? previous.enumList : undefined;

        return {
            ...sectionFieldWithoutFormValueName,
            formValueName,
            title,
            enumList,
        };
    };

    const sectionFieldsDefinitionSorted = toSorted(sectionDefinition.fields);

    return (
        <Stack gap={2}>
            {hasPreviousFieldsLoad &&
                sectionFieldsDefinitionSorted.map(sectionFieldDefinition => {
                    const pendingField = getPendingFieldFromDefinition(sectionFieldDefinition);
                    const previousField = previousFields?.find(p => p.formValueName === pendingField.formValueName);
                    const isPending = !isEqualSectionFieldValue(previousField ?? {}, pendingField);

                    return (
                        <FormControlLabel
                            key={pendingField.formValueName}
                            slotProps={{
                                typography: {
                                    variant: 'body2bold',
                                    color: isPending ? 'warning' : undefined,
                                },
                            }}
                            label={pendingField.title}
                            sx={{
                                flex: 1,
                                alignItems: 'stretch',
                                [`& .${formControlLabelClasses.label}`]: { paddingLeft: 0 },
                            }}
                            htmlFor={pendingField.formValueName}
                            control={
                                <DiffField next={pendingField} previous={previousField} direction='row' gap={2} alignItems='center' isPending={isPending} />
                            }
                        />
                    );
                })}
        </Stack>
    );
};

const UNkNOWN_VALUE_SYMBOL = '-';
const DiffField: FC<
    {
        previous: SectionField | undefined;
        next: SectionField & { formValueName: string };
        isPending?: boolean;
    } & StackProps
> = ({ previous, next, isPending, ...rest }) => {
    const { palette } = useTheme();
    const pendingColor = isPending ? palette.warning.main : undefined;

    return (
        <Stack {...rest}>
            <Stack flex={1}>
                {previous?.[getFieldValueProperty(previous.valueType)] ? (
                    <SectionFieldComponent field={previous} isPending={isPending} />
                ) : (
                    <Typography variant='body2bold' color={pendingColor}>
                        {UNkNOWN_VALUE_SYMBOL}
                    </Typography>
                )}
            </Stack>
            <ArrowRight02Icon color={pendingColor} />
            <Stack flex={1}>
                {next?.formValueName ? (
                    <EditableSectionFieldComponent
                        field={{
                            ...next,
                        }}
                    />
                ) : (
                    <Typography variant='body2bold'>{UNkNOWN_VALUE_SYMBOL}</Typography>
                )}
            </Stack>
        </Stack>
    );
};

/**
 * Hook to keep the dialog generic
 */
const useGetPreviousFields = (employeeId: number, sectionDefinition: SectionDefinition): SectionField[] => {
    const [personalInfo, setPersonalInfo] = useState<EmployeePersonalInfo>();
    const type = sectionDefinition.type;

    useEffect(() => {
        if (type === SectionType.PERSONAL_INFO) {
            // We need to call the API to get the current employee personal info
            getEmployeePersonalInfo(employeeId).then(data => {
                setPersonalInfo(data);
            });
        }
    }, [employeeId, type]);

    // TODO disable load if not needed
    const { data: employeeSections } = useGetEmployeeSection({ employeeId });

    const previousEmployeePersonalInfoFields = useEmployeePersonalInfoSectionFields(sectionDefinition, personalInfo);

    if (type === SectionType.PERSONAL_INFO) {
        return previousEmployeePersonalInfoFields;
    }

    if (type === SectionType.CUSTOM_SINGLE_ROW) {
        const employeeSection = employeeSections?.find(s => s.sectionDefinition.id === sectionDefinition.id);
        return employeeSection
            ? convertEmployeeSectionFieldsToSectionFields(employeeSection.id, sectionDefinition?.fields, employeeSection?.rows[0]?.fields)
            : [];
    }

    return [];
};
