import {
    EmployeeSection as EmployeeSectionDomain,
    EmployeeSectionFieldDocument as EmployeeSectionFieldDocumentDomain,
    EmployeeSectionImportRequest,
    EmployeeSectionRow as EmployeeSectionRowDomain,
    EmployeeSectionRowCreationMutation,
    EmployeeSectionRowUpdateMutation,
    EmployeeSectionSearch,
} from '@/domain/employee-section/EmployeeSection.model';
import { AxiosResponse } from 'axios';
import { EmployeeSectionFieldProfileChange } from '@/domain/employee-pending-change/EmployeePendingChange.model';
import { DocumentContentDisposition } from '@/domain/document/Document.model';
import { API_BASE_URL, client } from '@/api/common';
import { ImportResult } from '@/domain/import/Import.model';
import {
    mapSectionDefinitionDTO,
    mapSectionFieldDefinitionDTO,
    SectionDefinitionDTO,
    SectionFieldDefinitionDTO,
} from '@/api/section-definition/SectionDefinition.api';

export type EmployeeSectionDTO = Overwrite<
    EmployeeSectionDomain,
    {
        sectionDefinition: SectionDefinitionDTO;
        rows: EmployeeSectionRowDTO[];
    }
>;
export type EmployeeSectionFieldDTO = Overwrite<
    EmployeeSectionFieldProfileChange,
    {
        sectionFieldDefinition: SectionFieldDefinitionDTO;
    }
>;
export type EmployeeSectionRowDTO = Overwrite<
    EmployeeSectionRowDomain,
    {
        fields: EmployeeSectionFieldDTO[];
    }
>;
export type EmployeeSectionFieldDocumentDTO = EmployeeSectionFieldDocumentDomain;
type EmployeeSectionRowCreationRequestDTO = EmployeeSectionRowCreationMutation;
type EmployeeSectionSearchRequestDTO = EmployeeSectionSearch;
type EmployeeSectionRowUpdateRequestDTO = EmployeeSectionRowUpdateMutation;

const searchEmployeeSections = async (request: EmployeeSectionSearch): Promise<EmployeeSectionDomain[]> => {
    const { data } = await client.post<EmployeeSectionDTO[], AxiosResponse<EmployeeSectionDTO[]>, EmployeeSectionSearchRequestDTO>(
        API_BASE_URL + '/employee/sections/search',
        request,
    );
    return data.map(mapEmployeeSectionDTO);
};

const addEmployeeSectionRow = async (employeeSectionId: number, request: EmployeeSectionRowCreationMutation): Promise<EmployeeSectionRowDomain> => {
    const { data } = await client.post<EmployeeSectionRowDTO, AxiosResponse<EmployeeSectionRowDTO>, EmployeeSectionRowCreationRequestDTO>(
        API_BASE_URL + `/employee/sections/${employeeSectionId}/row`,
        request,
    );
    return mapEmployeeSectionRowDTO(data);
};

const createEmployeeSectionRowPendingRequest = async (
    employeeSectionId: number,
    request: EmployeeSectionRowCreationMutation,
): Promise<EmployeeSectionRowDomain> => {
    const { data } = await client.post<EmployeeSectionRowDTO, AxiosResponse<EmployeeSectionRowDTO>, EmployeeSectionRowCreationRequestDTO>(
        API_BASE_URL + `/employee/sections/${employeeSectionId}/row/pending`,
        request,
    );
    return mapEmployeeSectionRowDTO(data);
};

const updateEmployeeSectionRowPendingRequest = async (
    employeeSectionId: number,
    pendingRowId: number,
    request: EmployeeSectionRowUpdateMutation,
): Promise<EmployeeSectionRowDomain> => {
    const { data } = await client.put<EmployeeSectionRowDTO, AxiosResponse<EmployeeSectionRowDTO>, EmployeeSectionRowUpdateMutation>(
        API_BASE_URL + `/employee/sections/${employeeSectionId}/row/${pendingRowId}/pending`,
        request,
    );
    return mapEmployeeSectionRowDTO(data);
};

const updateEmployeeSectionRow = async (
    employeeSectionId: number,
    employeeSectionRowId: number,
    request: EmployeeSectionRowUpdateRequestDTO,
): Promise<EmployeeSectionRowDomain> => {
    const { data } = await client.put<EmployeeSectionRowDTO, AxiosResponse<EmployeeSectionRowDTO>, EmployeeSectionRowUpdateRequestDTO>(
        API_BASE_URL + `/employee/sections/${employeeSectionId}/row/${employeeSectionRowId}`,
        request,
    );
    return mapEmployeeSectionRowDTO(data);
};

const deleteEmployeeSectionRow = async (employeeSectionId: number, employeeSectionRowId: number): Promise<void> => {
    await client.delete(API_BASE_URL + `/employee/sections/${employeeSectionId}/row/${employeeSectionRowId}`);
};

const deleteEmployeeSectionRowPendingRequest = async (employeeSectionId: number, employeeSectionRowId: number): Promise<void> => {
    await client.delete(API_BASE_URL + `/employee/sections/${employeeSectionId}/row/${employeeSectionRowId}/cancel`);
};

const approveEmployeeCustomSectionRowPendingRequest = async (employeeSectionId: number, pendingRowId: number): Promise<void> => {
    await client.post(API_BASE_URL + `/employee/sections/${employeeSectionId}/row/${pendingRowId}/approve`);
};

const getEmployeeSectionFieldDocumentUrl = async (
    employeeSectionFieldDocumentId: number,
    documentContentDisposition: DocumentContentDisposition,
): Promise<string> => {
    return (await client.get<string>(API_BASE_URL + `/employee/sections/document/${employeeSectionFieldDocumentId}/url/${documentContentDisposition}`)).data;
};

const importEmployeeSection = async (employeeSectionImportRequest: EmployeeSectionImportRequest, sectionDefinitionId: number): Promise<ImportResult> => {
    return (await client.post(API_BASE_URL + `/employee/sections/${sectionDefinitionId}/import`, employeeSectionImportRequest)).data;
};

export const employeeSectionApi = {
    searchEmployeeSections,
    addEmployeeSectionRow,
    updateEmployeeSectionRow,
    deleteEmployeeSectionRow,
    getEmployeeSectionFieldDocumentUrl,
    createEmployeeSectionRowPendingRequest,
    updateEmployeeSectionRowPendingRequest,
    deleteEmployeeSectionRowPendingRequest,
    approveEmployeeCustomSectionRowPendingRequest,
    importEmployeeSection,
};

export const mapEmployeeSectionDTO = (employeeSectionDTO: EmployeeSectionDTO): EmployeeSectionDomain => {
    return {
        ...employeeSectionDTO,
        sectionDefinition: mapSectionDefinitionDTO(employeeSectionDTO.sectionDefinition),
        rows: employeeSectionDTO.rows.map(mapEmployeeSectionRowDTO),
    };
};

export const mapEmployeeSectionRowDTO = (employeeSectionRowDTO: EmployeeSectionRowDTO): EmployeeSectionRowDomain => {
    return {
        ...employeeSectionRowDTO,
        fields: employeeSectionRowDTO.fields.map(employeeSectionField => ({
            ...employeeSectionField,
            sectionFieldDefinition: mapSectionFieldDefinitionDTO(employeeSectionField.sectionFieldDefinition),
        })),
    };
};

export const mapEmployeeSectionFieldDTO = (employeeSectionFieldDTO: EmployeeSectionFieldDTO): EmployeeSectionFieldProfileChange => {
    return {
        ...employeeSectionFieldDTO,
        sectionFieldDefinition: mapSectionFieldDefinitionDTO(employeeSectionFieldDTO.sectionFieldDefinition),
    };
};
