import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import { EmployeeFolder } from '@/domain/document/Document.model';
import { Paper, Stack, useMediaQuery, useTheme } from '@mui/material';
import { Folder01Icon } from 'hugeicons-react';
import { FC, SyntheticEvent, useState } from 'react';
import { EmployeeDocumentDetails } from './EmployeeDocumentDetails';
import { getLabelTranslation, useUserLanguage } from '@/utils/language.util';

type Props = {
    employeeFolders: EmployeeFolder[];
    employeeId: number;
};

export const EmployeeDocuments: FC<Props> = ({ employeeFolders, employeeId }) => {
    const [folderIndex, setFolderIndex] = useState<number>(0);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const userLanguage = useUserLanguage();

    const handleChange = (_event: SyntheticEvent, newValue: number) => {
        setFolderIndex(newValue);
    };

    const folder = employeeFolders[folderIndex];

    return (
        <Stack direction={{ sx: 'column', sm: 'row' }} gap={2} justifyContent='center' flex={1}>
            <Paper sx={{ minWidth: '280px' }}>
                <Tabs
                    orientation={isMobile ? 'horizontal' : 'vertical'}
                    variant='scrollable'
                    value={folderIndex}
                    onChange={handleChange}
                    aria-label='Documents tabs'
                    scrollButtons={isMobile ? 'auto' : false}
                >
                    {employeeFolders?.map((folder, index) => (
                        <Tab
                            key={folder.folder.id}
                            label={getLabelTranslation(folder.folder.name, userLanguage)}
                            icon={<Folder01Icon />}
                            iconPosition={'start'}
                            wrapped={true}
                            sx={{
                                justifyContent: 'flex-start',
                                p: 2,
                                margin: 0,
                                fontSize: 14,
                                minHeight: 64,
                                minWidth: '100%',
                                textAlign: 'left',
                                '&.Mui-selected': {
                                    fontWeight: 400,
                                    color: theme.palette.primary.main,
                                },
                            }}
                            {...a11yProps(index)}
                        />
                    ))}
                </Tabs>
            </Paper>

            <EmployeeDocumentDetails folder={folder?.folder} employeeId={employeeId} />
        </Stack>
    );
};

const a11yProps = (index: number) => {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
};
