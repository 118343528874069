import { Employee } from '@/domain/employee/Employee.model';
import { Area } from '@/domain/area/Area.model';
import { PlanningPosition } from '@/domain/planning-position/PlanningPosition.model';
import { DayOfWeek } from '@/utils/datetime.util';

import { PublishNotificationTypes } from '@/page/planning/scheduler-calendar/types';
import { PlanningTag } from '@/domain/planning-tag/PlanningTag.model';
import { Location } from '@/domain/location/Location.model';
import { ShiftRecurringRule } from '@/domain/shift-rule/ShiftRule.model';
import { ShiftType } from '@/domain/shift-type/ShiftType.model';
import { UnitType } from '@/domain/date/Date.model';

export enum ShiftStatus {
    SHIFT_DRAFT = 'SHIFT_DRAFT',
    SHIFT_PUBLISHED = 'SHIFT_PUBLISHED',
    LEAVE_PENDING = 'LEAVE_PENDING',
    LEAVE_APPROVED = 'LEAVE_APPROVED',
    LEAVE_DECLINED = 'LEAVE_DECLINED',
}

export type WorkingTimeWarning = {
    rule: WorkingTimeRule;
    limit?: number;
};

export enum WorkingTimeRule {
    MAXIMUM_HOURS_PER_DAY_EXCEEDED = 'MAXIMUM_HOURS_PER_DAY_EXCEEDED',
    MINIMUM_BREAK_PER_DAY_REQUIRED = 'MINIMUM_BREAK_PER_DAY_REQUIRED',
    MINIMUM_REST_BETWEEN_TWO_DAY_REQUIRED = 'MINIMUM_REST_BETWEEN_TWO_DAY_REQUIRED',
    EMPLOYEE_MISSING = 'EMPLOYEE_MISSING',
}

export enum ShiftConflictType {
    CONFLICT_LEAVE = 'CONFLICT_LEAVE',
    CONFLICT_SHIFT = 'CONFLICT_SHIFT',
}

export type Shift = {
    id: string;
    resourceId: number;
    startDate: Date;
    endDate: Date;
    assignee: Employee;
    area: Area;
    planningPosition?: PlanningPosition;
    status: ShiftStatus;
    shiftNote: string;
    shiftType: ShiftType;
    conflicts: ShiftConflictType[];
    warnings: WorkingTimeWarning[];
    totalConflictCount: number;
    leaveId: number;
    leaveTypeId: number;
    leaveStartDate: LocalDate;
    leaveEndDate: LocalDate;
    leavePercentage: number;
    leaveUnitType?: UnitType;
    color: string;
    breakTime: number;
    duration: number;
    locationName: string;
    recurringRule: ShiftRecurringRule;
    createdAt: Date;
    createdBy: Employee;
    updatedAt: Date;
    updatedBy: Employee;
    publishedAt: Date;
    publishedBy: Employee;
};

export enum ShiftSearchRequestSort {
    FIRST_NAME = 'firstName',
    LAST_NAME = 'lastName',
    PLANNING_POSITION = 'planningPosition',
}

export type ShiftSearchRequest = {
    rangeDates: Range[];
    employeeIds?: number[];
    statuses?: ShiftStatus[];
    sort?: ShiftSearchRequestSort;
    sortDirection?: 'ASC' | 'DESC';
    planningPositionIds?: number[];
    employeePlanningPositionIds?: number[];
    areaIds?: number[];
    jobIds?: number[];
    planningTagIds?: number[];
    departmentIds?: number[];
    locationIds?: number[];
    simulation?: boolean;
};

export type LeaveShiftSearchRequest = {
    startDate: LocalDate;
    endDate: LocalDate;
    employeeIds?: number[];
    leaveTypeIds?: number[];
    locationIds?: number[];
    sort?: ShiftSearchRequestSort;
    sortDirection?: 'ASC' | 'DESC';
    offset?: number;
    limit?: number;
};

export type ShiftReleaseRequest = {
    rangeDates: Range[];
    employeeId: number;
};

export type Range = {
    start: Date;
    end: Date;
};

export enum NonWorkingType {
    PUBLIC_HOLIDAY = 'PUBLIC_HOLIDAY',
    COMPANY_HOLIDAY = 'COMPANY_HOLIDAY',
    DAY_OFF = 'DAY_OFF',
}

// Employee Shifts
export type NonWorkingShift = {
    reason: string;
    startDate: Date;
    endDate: Date;
    fullDay: boolean;
    type: NonWorkingType;
};

export type AvailableArea = {
    locationId: number;
    id: number;
    areaName: string;
    locationName: string;
};

export type EmployeeShift = {
    availableEmployees: AvailableEmployeeByDate;
    assignee: Employee;
    // shifts // leaves // recurringShifts
    shifts: Shift[];
    nonWorkingShifts: NonWorkingShift[];
    availableAreas: AvailableArea[];
    totalAvailableTime: number;
    totalPlannedTime: number;
    totalConflictCount: number;
    totalWarningCount: number;
    totalMissingCount: number;
};

// Update Shift Request
export type ShiftUpdateRequest = {
    startDate: Date;
    endDate: Date;
    employeeId?: number;
    shiftTypeId?: number;
    areaId?: number;
    planningPositionId?: number;
    shiftNote?: string;
    color?: string;
    breakTime?: number;
};

// New Shift Request
export type NewShift = {
    shiftTypeId?: number;
    shiftNote?: string;
    color?: string;
    breakTime?: number;
};

export type ShiftCreationRequest = {
    startDate: Date;
    endDate: Date;
    employeeIds: number[];
    areaId?: number;
    planningPositionId?: number;
    newShift: NewShift;
    shiftTemplateId?: number;
    simulation?: boolean;
};

export type AvailableEmployee = {
    id: number;
    firstName: string;
    lastName: string;
    displayName?: string;
    planningPositionName?: string;
    planningPositionId?: number;
    availableTime: number;
    usedTime: number;
};

export type AvailableEmployeeByDate = Record<string, AvailableEmployee[]>;

// Shift Areas
export type AreaShift = {
    area: Area;
    shifts: Shift[];
    totalPlannedTime: number;
    nonWorkingShifts: NonWorkingShift[];
    availableEmployees: AvailableEmployeeByDate;
    totalConflictCount: number;
    totalWarningCount: number;
    totalMissingCount: number;
};

export type ShiftPublishRequest = {
    startDate: string;
    endDate: string;
    notification: PublishNotificationTypes;
    employeeIds: number[];
    areaIds: number[];
};

export type TagShiftCoverage = {
    tag: PlanningTag;
    location: Location;
    shiftCoverages: DailyShiftCoverage[];
};

export type DailyShiftCoverage = {
    date: Date;
    count: number;
    shifts: Shift[];
    coverage: ShiftCoverage;
};

export type ShiftCoverage = {
    id: number;
    tag: PlanningTag;
    location: Location;
    requiredAmount: number;
    repeatEveryXWeek: number;
    repeatOn: DayOfWeek[];
    startDate: LocalDate;
    endDate: LocalDate;
    startTime: LocalTime;
    endTime: LocalTime;
    createdAt: string;
    createdBy: Employee;
    updatedAt: string;
    updatedBy: Employee;
};

export type ShiftCoverageSearchRequest = {
    startDate: LocalDate;
    endDate: LocalDate;
    limit?: number;
    offset?: number;
    sort?: string;
    sortDirection?: string;
    locationIds?: number[];
    planningTagIds?: number[];
};

export type ShiftCoverageCreationRequest = {
    tagId: number;
    locationId: number;
    startDate: LocalDate;
    endDate: LocalDate;
    repeatEveryXWeek: number;
    repeatOn: DayOfWeek[];
    startTime: string;
    endTime: string;
    requiredAmount: number;
};

export type ShiftCoverageUpdateRequest = ShiftCoverageCreationRequest;
