import { getFieldDefinitionTranslation } from '@/components/ag-grid-wrapper/column-types/columnTypes';
import { AsyncSelectFilter, DateFilter, SelectFilter } from '@/components/filters-bar/FiltersBar';
import { getDepartments } from '@/domain/department/Department.service';
import { searchEmployees } from '@/domain/employee/Employee.service';
import { getJobs } from '@/domain/job/Job.service';
import { LeaveType } from '@/domain/leave-type/LeaveType.model';
import { getLocations } from '@/domain/location/Location.service';
import {
    formatInDefaultDate,
    formatToLocalDate,
    getCurrentLocalDate,
    getEndOfYear,
    getStartOfMonth,
    getStartOfYear,
    MONTHS,
    setMonth,
    toDate,
} from '@/utils/datetime.util';
import { getLabelTranslation } from '@/utils/language.util';
import { useTranslation } from 'react-i18next';
import { UnitType } from '@/domain/date/Date.model';
import { getUnitTypeTranslationKey } from '@/domain/date/Date.service';
import { TimesheetSetting } from '@/domain/timesheet-setting/TimesheetSetting.model';
import { format } from 'date-fns';
import { activeMonth } from '@/page/employee-timesheet/timesheets-history/TimesheetsHistory.util';

export type LeaveBalanceFilter = SelectFilter | AsyncSelectFilter | DateFilter;

export const useLeaveBalancePageFilters = (
    leaveTypes: LeaveType[],
    defaultLeaveType: LeaveType,
    timesheetSettings: TimesheetSetting[],
    defaultTimesheetSetting: TimesheetSetting,
    haveDifferentCycleStartMonth: boolean,
): {
    filters: LeaveBalanceFilter[];
} => {
    const { t } = useTranslation();

    const filters: LeaveBalanceFilter[] = [
        {
            filterName: t('balance_page.filters.cycle'),
            type: 'select',
            selectMode: 'SYNC',
            value: [
                {
                    label: getFormatedMonth(defaultTimesheetSetting.cycleStartMonth),
                    value: defaultTimesheetSetting.cycleStartMonth,
                },
            ],
            //only unique months should be displayed
            options: Array.from(new Set(timesheetSettings?.map(timesheetSetting => timesheetSetting.cycleStartMonth))).map(month => ({
                label: getFormatedMonth(month),
                value: month,
            })),
            key: 'startCycleMonth',
            rule: 'EQUALS',
            availableRules: [],
            clearable: false,
            hide: !haveDifferentCycleStartMonth,
        },
        {
            key: 'endCycle',
            filterName: formatInDefaultDate(getStartOfYear()),
            type: 'date',
            value: getEndOfYear(),
            dateType: 'MORE_THAN',
            availableRules: ['MORE_THAN'],
            clearable: false,
        },
        {
            filterName: t('balance_page.filters.Leave_type'),
            type: 'select',
            selectMode: 'SYNC',
            options: leaveTypes?.map(leaveType => ({
                label: getLabelTranslation(leaveType.name),
                value: leaveType.id,
            })),
            value: [{ label: getLabelTranslation(defaultLeaveType.name), value: defaultLeaveType.id }],
            key: 'leaveTypeId',
            rule: 'EQUALS',
            availableRules: [],
            clearable: false,
        },
        {
            filterName: t('balance_page.filters.display_in'),
            type: 'select',
            selectMode: 'SYNC',
            options: Object.values(UnitType)
                .filter(unit => unit !== UnitType.BOTH)
                .map(unit => ({
                    label: t(getUnitTypeTranslationKey(unit)),
                    value: unit,
                })),
            value: [{ label: t(getUnitTypeTranslationKey(UnitType.DAYS)), value: UnitType.DAYS }],
            key: 'displayInUnitType',
            rule: 'EQUALS',
            availableRules: [],
            clearable: false,
            hide: defaultLeaveType.displayUnitType !== UnitType.BOTH,
        },
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_LOCATION' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                return getLocations().then(locations => {
                    return locations?.map(location => ({
                        label: location.name,
                        value: location.id,
                    }));
                });
            },
            key: 'locationIds',
            rule: 'EQUALS',
            availableRules: [],
        },
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_DEPARTMENT' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                return getDepartments().then(departments => {
                    return departments?.map(department => ({
                        label: getLabelTranslation(department.name),
                        value: department.id,
                    }));
                });
            },
            key: 'departmentIds',
            rule: 'EQUALS',
            availableRules: [],
        },
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_JOB' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                return getJobs().then(jobs => {
                    return jobs?.map(job => ({
                        label: getLabelTranslation(job.name),
                        value: job.id,
                    }));
                });
            },
            key: 'jobIds',
            rule: 'EQUALS',
            availableRules: [],
        },
        {
            key: 'managerIds',
            filterName: getFieldDefinitionTranslation({ fieldType: 'EMPLOYMENT_MANAGER' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                const employees = await searchEmployees();
                return employees?.map(employee => ({
                    label: employee.displayName,
                    value: employee.id,
                }));
            },
            rule: 'EQUALS',
            availableRules: [],
        },
    ];

    return {
        filters,
    };
};

const getFormatedMonth = (month: MONTHS): string => {
    const date = toDate(getStartOfMonth(getCurrentLocalDate()));
    const startDate = formatToLocalDate(setMonth(date, activeMonth(month)));

    return format(startDate, 'MMM');
};
