import { Stack, useMediaQuery, useTheme } from '@mui/material';
import { FC, ReactNode, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { canViewAtLeastOneProfileInfoTab } from '@/domain/permission/Permission.service';
import { useAppSelector } from '@/stores/store';
import { getProfileTabByLocation, getProfileTabItems } from '@/page/employee-profile/employeeProfile.util';

interface EmployeeProfileLayoutProps {
    tabs: ReactNode;
    content: ReactNode;
    profile: ReactNode;
    mobileProfile: ReactNode;
}

export const EmployeeProfileLayout: FC<EmployeeProfileLayoutProps> = ({ tabs, content, profile, mobileProfile }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const policies = useAppSelector(state => state.currentEmployee.grantedPolicies);
    const realm = useAppSelector(state => state.ui.currentRealm);
    const currentEmployee = useAppSelector(state => state.currentEmployee.employee);

    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const employeeId = params?.employeeId ? Number(params.employeeId) : currentEmployee?.id;
    const isCurrentEmployee = currentEmployee?.id === employeeId;
    // Redirect to the first valid tab if the requested tab is not valid
    useEffect(() => {
        const profileTabsItems = employeeId && realm ? getProfileTabItems(employeeId, realm.realmFeatures, policies) : [];

        const requestedTab = getProfileTabByLocation(location);
        const isTabValid = profileTabsItems.some(item => item.path === requestedTab);

        if (!isTabValid) {
            const firstValidTab = profileTabsItems[0];
            // Redirect to the first valid tab
            // if no valid tab is found, tabs will be hidden. See the condition below canSeeAtLeastOneTab
            if (firstValidTab) {
                const employeePath = employeeId ? `${employeeId}/` : '';
                navigate(`/profile/${employeePath}${firstValidTab.path}`, { replace: true });
            }
        }
    }, [employeeId, location, navigate, policies, realm]);

    if (!realm || !employeeId) {
        return;
    }

    const PROFILE_WIDTH = 320;
    const canSeeAtLeastOneTab = canViewAtLeastOneProfileInfoTab(realm.realmFeatures ?? [], policies, employeeId);
    const showProfileCard = !isMobile || !canSeeAtLeastOneTab;
    const showMobileProfileCard = isMobile && !isCurrentEmployee;

    return (
        <Stack
            flex='1'
            direction={isMobile ? 'column' : 'row'}
            gap={2}
            // This is to disable the scroll from the main layout on desktop
            // overflow is set to hidden and it works because the parent direct has overflowY: 'auto'
            overflow={isMobile ? undefined : 'hidden'}
        >
            {showProfileCard && (
                // the key is to force the component to re-render when the employeeId changes
                <Stack
                    key={params?.employeeId}
                    minWidth={PROFILE_WIDTH}
                    maxWidth={canSeeAtLeastOneTab ? PROFILE_WIDTH : undefined}
                    flexGrow={canSeeAtLeastOneTab ? 0 : 1}
                >
                    {profile}
                </Stack>
            )}
            {canSeeAtLeastOneTab && (
                /* we need to set the width so that we can have horizontal scroll in the employee leaves page */
                <Stack flex='1' gap={2} width={isMobile ? '100%' : `calc(100% - ${PROFILE_WIDTH}px - 16px)`}>
                    {!isMobile && tabs}
                    {showMobileProfileCard && mobileProfile}

                    <Stack
                        flex={1}
                        sx={{
                            overflowY: 'auto',
                            '&::-webkit-scrollbar': { display: 'none' },
                        }}
                    >
                        {content}
                    </Stack>
                </Stack>
            )}
        </Stack>
    );
};
