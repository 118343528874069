import * as yup from 'yup';
import { isRatingCommentRequired } from '@/page/review/employee-review-feedback-form/EmployeeReviewFeedbackForm.util';
import { ReviewRatingScale } from '@/domain/review-rating-scale/ReviewRatingScale.model';
import { t } from 'i18next';
import { getLabelFormSchema } from '@/domain/label/Label.schema';

export const ratingItemSchema = yup.object().shape({
    score: yup.number().default(undefined),
    label: yup.string().default(undefined),
    description: yup.string().default(undefined),
    commentRequired: yup.boolean().default(undefined),
    name: yup.string().default(undefined),
});
export type RatingItemFormType = yup.InferType<typeof ratingItemSchema>;

export const ratingItemsSchema = yup.array().of(ratingItemSchema).default([]).required();

export const ratingSchema = yup
    .object()
    .shape({
        items: ratingItemsSchema,
    })
    .default(undefined)
    .notRequired();
export type RatingFormType = yup.InferType<typeof ratingSchema>;

export const employeeReviewFeedbackSectionTypeSchema = yup
    .string()
    .required()
    .oneOf(['SECTION'] as const);

export const employeeReviewFeedbackSectionSchema = yup.object().shape({
    id: yup.number().required(),
    order: yup.number().required(),
    title: getLabelFormSchema(),
    type: employeeReviewFeedbackSectionTypeSchema,
});
export type EmployeeReviewFeedbackSectionFormType = yup.InferType<typeof employeeReviewFeedbackSectionSchema>;

export const categorySchema = yup.object().shape({
    id: yup.number().required(),
    name: yup.string(),
});
export type categoryFormType = yup.InferType<typeof categorySchema>;

const levelSchema = yup.object().shape({
    id: yup.number().required(),
    description: yup.string(),
    score: yup.number().required(),
    commentRequired: yup.boolean(),
    name: yup.string(),
});
export type LevelFormType = yup.InferType<typeof levelSchema>;

export const skillSchema = yup.object().shape({
    id: yup.number().required(),
    name: yup.string(),
    description: yup.string(),
    levels: yup.array().of(levelSchema).required(),
});

export type SkillFormType = yup.InferType<typeof skillSchema>;

export const employeeReviewFeedbackSkillSchema = yup.object().shape({
    score: yup.number().default(undefined),
    comment: yup.string().default(''),
    category: categorySchema,
    skill: skillSchema.required(),
});
export type EmployeeReviewFeedbackSkillFormType = yup.InferType<typeof employeeReviewFeedbackSkillSchema>;

export const employeeReviewFeedbackSkillsTypeSchema = yup
    .string()
    .required()
    .oneOf(['SKILLS'] as const);

export const employeeReviewFeedbackSkillsSchema = yup.object().shape({
    id: yup.number().required(),
    order: yup.number().required(),
    type: employeeReviewFeedbackSkillsTypeSchema,
    rating: ratingSchema,
    skills: yup.array().of(employeeReviewFeedbackSkillSchema).required(),
    minSkills: yup.number().default(undefined),
    maxSkills: yup.number().default(undefined),
    allSkillsRequired: yup.boolean().required(),
});
export type EmployeeReviewFeedbackSkillsFormType = yup.InferType<typeof employeeReviewFeedbackSkillsSchema>;

export const employeeReviewFeedbackObjectiveSchema = yup.object().shape({
    id: yup.number().required(),
    order: yup.number().required(),
    type: yup
        .string()
        .required()
        .oneOf(['OBJECTIVES'] as const),
});
export type EmployeeReviewFeedbackObjectiveFormType = yup.InferType<typeof employeeReviewFeedbackObjectiveSchema>;

export const employeeReviewFeedbackQuestionScoreSchema = yup.number().when(['required', 'rating'], {
    is: (required: boolean, rating: ReviewRatingScale) => {
        return required && rating && rating.items.length > 0;
    },
    then: schema => schema.required(),
    otherwise: schema => schema.nullable(),
});

export const employeeReviewFeedbackQuestionCommentSchema = yup
    .string()
    .trim()
    .default('')
    .when(['required', 'score', 'rating'], {
        is: (required: boolean, score: number, rating: RatingFormType) => {
            return (!score && required) || (score && rating && isRatingCommentRequired(rating, score));
        },
        then: schema => schema.required(),
        otherwise: schema => schema,
    });

export const employeeReviewFeedbackQuestionTypeSchema = yup.string().required().oneOf(['QUESTION', 'PRIVATE_EMPLOYEE_QUESTION', 'PRIVATE_MANAGER_QUESTION']);

export const employeeReviewFeedbackQuestionSchema = yup.object().shape({
    id: yup.number().required(),
    order: yup.number().required(),
    score: employeeReviewFeedbackQuestionScoreSchema,
    comment: employeeReviewFeedbackQuestionCommentSchema,
    rating: ratingSchema,
    required: yup.boolean().required(),
    title: getLabelFormSchema(),
    instruction: getLabelFormSchema().default(undefined),
    description: yup.string(),
    type: employeeReviewFeedbackQuestionTypeSchema,
});
export type EmployeeReviewFeedbackQuestionFormType = yup.InferType<typeof employeeReviewFeedbackQuestionSchema>;

export const employeeReviewFeedbackQuestionSkillTypeSchema = yup
    .string()
    .required()
    .oneOf(['SKILL'] as const);
export const employeeReviewFeedbackSkillQuestionSchema = yup.object().shape({
    id: yup.number().required(),
    order: yup.number().required(),
    required: yup.boolean().required(),
    score: employeeReviewFeedbackQuestionScoreSchema,
    rating: ratingSchema,
    comment: employeeReviewFeedbackQuestionCommentSchema,
    title: yup.string(),
    instruction: yup.string().default(undefined),
    description: yup.string(),
    type: employeeReviewFeedbackQuestionSkillTypeSchema,
    skillId: yup.number(),
});
export type EmployeeReviewFeedbackSkillQuestionFormType = yup.InferType<typeof employeeReviewFeedbackSkillQuestionSchema>;

const validateSkills = (skills: EmployeeReviewFeedbackSkillFormType[], minSkills: number | undefined, allSkillsRequired: boolean): boolean => {
    const skillsRatedNumber = skills.filter(skill => skill.score && skill.score > 0).length;
    if (allSkillsRequired) {
        return skillsRatedNumber === skills.length;
    }
    return !minSkills || skillsRatedNumber >= minSkills;
};

const employeeReviewFeedbackItemSchema = yup.lazy(item => {
    const { type } = item;
    switch (type) {
        case 'SECTION':
            return employeeReviewFeedbackSectionSchema;
        case 'SKILL':
            return employeeReviewFeedbackSkillQuestionSchema;
        case 'OBJECTIVES':
            return employeeReviewFeedbackObjectiveSchema;
        case 'SKILLS':
            // Skills test validation is done here because it's not the same test in summary skills test validation
            // and summary skills tests validation is using employeeReviewFeedbackSkillsSchema
            return employeeReviewFeedbackSkillsSchema.test({
                name: 'skills-validation',
                test: function (value) {
                    const { skills, minSkills, allSkillsRequired } = value;
                    return validateSkills(skills, minSkills, allSkillsRequired);
                },
                message: function ({ value }) {
                    const { minSkills, maxSkills, allSkillsRequired } = value;
                    if (allSkillsRequired) {
                        return t('reviews.write_feedback.all_skills_required');
                    }
                    if (minSkills === maxSkills) {
                        return t('reviews.write_feedback.review_skills_error_min_equal_max', { value: minSkills });
                    }
                    return t('reviews.write_feedback.review_skills_error', {
                        min: minSkills,
                        max: maxSkills,
                    });
                },
            });
        case 'QUESTION':
        case 'PRIVATE_EMPLOYEE_QUESTION':
        case 'PRIVATE_MANAGER_QUESTION':
        default:
            return employeeReviewFeedbackQuestionSchema;
    }
});
export type EmployeeReviewFeedbackItemForm = yup.InferType<typeof employeeReviewFeedbackItemSchema>;

const employeeReviewFeedbackItemsSchema = yup.array().of(employeeReviewFeedbackItemSchema).required();
export type EmployeeReviewFeedbackItemsType = yup.InferType<typeof employeeReviewFeedbackItemsSchema>;

export const employeeReviewFeedbackFormSchema = yup.object().shape({
    employeeReviewFeedbackItems: employeeReviewFeedbackItemsSchema,
});
export type EmployeeReviewFeedbackFormType = yup.InferType<typeof employeeReviewFeedbackFormSchema>;
