import { FC, PropsWithChildren } from 'react';
import { Paper, Stack } from '@mui/material';
import { StepperBreadCrumb } from '@/components/stepper-breadcrumb/StepperBreadcrumb';
import { useDrawer } from '@/hooks/layout/Drawer.hook';

export type StepWorkflow = {
    stepName: string;
    stepTranslationKey: string;
};
type StepperFormProps = {
    steps: StepWorkflow[];
    currentStep: StepWorkflow;
};

/**
 * The StepperWorkflow component is a wrapper component that allows you to create a workflow with multiple steps on multiple pages.
 * @param steps is an array of Step objects that define the steps in the stepper.
 * @param currentStep is an object that defines the current step in the stepper.
 * @param children is an optional prop that allows you to add custom buttons or components to the stepper.
 */
export const StepperWorkflow: FC<PropsWithChildren<StepperFormProps>> = ({ steps, currentStep, children }) => {
    const { drawerWidth } = useDrawer();

    return (
        <Paper square component='footer' sx={{ mx: -2, position: 'fixed', bottom: 0, width: `calc(100vw - ${drawerWidth}px)` }}>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} p={2}>
                <StepperBreadCrumb steps={steps} currentStep={currentStep} />
                <Stack gap={1} direction={'row'}>
                    {children}
                </Stack>
            </Stack>
        </Paper>
    );
};
