import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { StateHandler } from '@/components/state-handler/StateHandler';
import { TabsConfig, TabsContainer } from '@/components/tabs-container/TabsContainer';
import { canViewCompanyDocuments, canViewEmployeeDocuments } from '@/domain/permission/Permission.service';
import { useGetCompanyFolders, useGetEmployeeFolders } from '@/hooks/document/Document.hook';
import { useAppSelector } from '@/stores/store';
import { ListItemButton, Paper, Stack, Typography } from '@mui/material';
import List from '@mui/material/List';
import { Link } from 'react-router-dom';
import { Folder01Icon } from 'hugeicons-react';
import { getLabelTranslation, useUserLanguage } from '@/utils/language.util';

export const AllDocumentsPage: FC = () => {
    const { t } = useTranslation();
    const policies = useAppSelector(state => state.currentEmployee.grantedPolicies);
    const currentEmployee = useAppSelector(state => state.currentEmployee.employee);
    const userLanguage = useUserLanguage();
    const realm = useAppSelector(state => state.ui.currentRealm);

    if (!realm) {
        throw new Error('Realm is not defined');
    }

    if (!currentEmployee?.id) {
        throw new Error('Current employee is not defined');
    }

    const employeeDocumentsEnabled = canViewEmployeeDocuments(realm.realmFeatures, policies, currentEmployee.id);
    const companyDocumentsEnabled = canViewCompanyDocuments(realm.realmFeatures, policies);

    const [activeTab, setActiveTab] = useState<string>(employeeDocumentsEnabled ? 'myDocuments' : 'companyDocuments');

    const {
        data: employeeFolders = [],
        isLoading: isLoadingEmployeeFolders,
        isError: isErrorEmployeeFolders,
        error: errorEmployeeFolders,
    } = useGetEmployeeFolders(currentEmployee?.id);

    const {
        data: companyFolders = [],
        isLoading: isLoadingCompanyFolders,
        isError: isErrorCompanyFolders,
        error: errorCompanyFolders,
    } = useGetCompanyFolders();

    const TabConfig: TabsConfig = {
        tabs: [
            {
                name: t('my_documents.my_documents'),
                index: 'myDocuments',
                isEnabled: employeeDocumentsEnabled,
            },
            {
                name: t('my_documents.company_documents'),
                index: 'companyDocuments',
                isEnabled: companyDocumentsEnabled,
            },
        ],
    };

    const renderFolder = (folderName: string, folderId: number, employeeId?: number) => {
        const folderNameEncoded = encodeURIComponent(folderName);
        const url = employeeId
            ? `/documents/all-documents/${folderNameEncoded}/${folderId}/${employeeId}`
            : `/documents/all-documents/${folderNameEncoded}/${folderId}`;
        return (
            <ListItemButton key={folderId} component={Link} to={url} title={folderName} aria-label={folderName} sx={{ p: 2.5 }}>
                <Stack direction='row' gap={1} flex={1} alignItems='center' justifyContent='space-between'>
                    <Stack direction='row' gap={1} alignItems='center'>
                        <Folder01Icon size={20} />
                        <Typography variant='body2bold'>{folderName}</Typography>
                    </Stack>
                </Stack>
            </ListItemButton>
        );
    };
    return (
        <Stack gap={2} flex={1}>
            <Paper>
                <TabsContainer activeTab={activeTab} tabConfig={TabConfig} onChangeTab={(tab: string) => setActiveTab(tab)} />
            </Paper>
            {activeTab === 'myDocuments' && (
                <StateHandler
                    isLoading={isLoadingEmployeeFolders}
                    isError={isErrorEmployeeFolders}
                    error={errorEmployeeFolders}
                    isEmpty={!employeeFolders?.length}
                >
                    <Stack component={Paper} flex={1}>
                        <List component='nav' aria-label='myDocuments'>
                            {employeeFolders?.map(item => renderFolder(getLabelTranslation(item.folder.name, userLanguage), item.folder.id, item.employee?.id))}
                        </List>
                    </Stack>
                </StateHandler>
            )}

            {activeTab === 'companyDocuments' && (
                <StateHandler isLoading={isLoadingCompanyFolders} isError={isErrorCompanyFolders} error={errorCompanyFolders} isEmpty={!companyFolders?.length}>
                    <Stack component={Paper} flex={1}>
                        <List component='nav' aria-label='companyDocuments'>
                            {companyFolders?.map(item => renderFolder(getLabelTranslation(item.name, userLanguage), item.id))}
                        </List>
                    </Stack>
                </StateHandler>
            )}
        </Stack>
    );
};
