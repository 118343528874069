import { StateHandler } from '@/components/state-handler/StateHandler';
import { createRole, deleteRole, updateRole } from '@/domain/role/Role.service';
import { Role, RoleCreationMutation, RoleUpdateMutation } from '@/domain/role/Role.model';
import { useGetRoles } from '@/hooks/role/Role.hook';
import { RoleFormValues, RoleSettingsDialog } from '@/page/setting/role/RoleSettingsDialog';
import { handleError } from '@/utils/api.util';
import { ICellRendererParams, RowClickedEvent } from '@ag-grid-community/core';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CompanySettingsLayout } from '../CompanySettingsLayout';
import { ConfigType } from '../types';
import { EmployeeFieldMoreButton } from '@/page/employee-profile/employee-profile-info/EmployeeFieldMoreButton/EmployeeFieldMoreButton';

export const RoleSettingsPage: FC = () => {
    const { t } = useTranslation();
    const [sectionDialogMode, setSectionDialogMode] = useState<'create' | 'edit'>('create');
    const [roleDialogOpen, setRoleDialogOpen] = useState<boolean>(false);
    const [activeRole, setActiveRole] = useState<Role>();

    const { data: roles = [], isLoading: isRolesLoading, isError: isRolesError, refetch: refetchRoles, error } = useGetRoles();

    const handleDelete = async (params: ICellRendererParams<Role>) => {
        if (!params.data?.id) {
            return;
        }
        try {
            await deleteRole(params.data.id);
            await refetchRoles();
        } catch (e) {
            handleError(e);
        }
    };

    const getEmployeeFieldMoreButton = (params: ICellRendererParams<Role>) => (
        <EmployeeFieldMoreButton
            approveEnabled={false}
            declineEnabled={false}
            disabled={false}
            deleteEnabled={true}
            editDisabled={true}
            cancelEnabled={false}
            onDeleteClicked={() => handleDelete(params)}
        />
    );

    const config: ConfigType<Role> = {
        type: 'table',
        isOnRowClickActive: true,
        header: {
            primaryButtonCaption: t('general.create'),
            primaryButtonAction: () => {
                setSectionDialogMode('create');
                setRoleDialogOpen(true);
            },
        },
        table: {
            agGridProps: {
                onRowClicked: (params: RowClickedEvent<Role>) => {
                    setSectionDialogMode('edit');
                    setActiveRole(params.data);
                    setRoleDialogOpen(true);
                },
            },
            columns: [
                {
                    field: 'name',
                    headerName: 'Name',
                },
                {
                    type: 'actionMenu',
                    cellRenderer: getEmployeeFieldMoreButton,
                },
            ],
        },
    };

    const onSaveRole = async (roleForm: RoleFormValues, id: number | undefined) => {
        setRoleDialogOpen(false);
        setActiveRole(undefined);

        const mutation: RoleCreationMutation | RoleUpdateMutation = {
            name: roleForm.name,
        };

        if (sectionDialogMode === 'create') {
            try {
                await createRole(mutation);
                await refetchRoles();
            } catch (error) {
                handleError(error);
            }
        } else {
            try {
                if (id) {
                    await updateRole(id, mutation);
                    await refetchRoles();
                }
            } catch (error) {
                handleError(error);
            }
        }
    };

    const onCloseTagDialog = () => {
        setRoleDialogOpen(false);
        setActiveRole(undefined);
    };

    return (
        <StateHandler isLoading={isRolesLoading} isError={isRolesError} error={error}>
            <CompanySettingsLayout options={config} data={roles} />
            {roleDialogOpen && <RoleSettingsDialog open={roleDialogOpen} handleSave={onSaveRole} closeDialog={onCloseTagDialog} activeRole={activeRole} />}
        </StateHandler>
    );
};
