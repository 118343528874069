import { LeaveRequest } from '@/domain/leave-request/LeaveRequest.model';
import { EmployeeLeaveRequestList } from '@/page/leave/EmployeeLeaveRequestList';
import { compareAsc, compareDesc, isFutureDate, isTodayDate } from '@/utils/datetime.util';
import { Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

type UpcomingLeavesProps = {
    employeeId: number;
    leaveRequests: LeaveRequest[];
    onChange: () => void;
};

export const EmployeeLeaves: FC<UpcomingLeavesProps> = ({ employeeId, leaveRequests, onChange }) => {
    const { t } = useTranslation();

    const upcomingPredicate = (leaveRequest: LeaveRequest) => {
        return !leaveRequest.endDate || isFutureDate(leaveRequest.endDate) || isTodayDate(leaveRequest.endDate);
    };
    const upcomingLeaves = leaveRequests.filter(upcomingPredicate);
    const pastLeaves = leaveRequests.filter(leaveRequest => !upcomingPredicate(leaveRequest));

    return (
        <Stack direction='column' gap={2}>
            {upcomingLeaves?.length > 0 && (
                <Stack gap={2}>
                    <Typography variant='h1'>{t('my_leaves.upcoming_leaves')}</Typography>
                    <EmployeeLeaveRequestList leaveRequests={upcomingLeaves} employeeId={employeeId} compareDatesByAge={compareAsc} onChange={onChange} />
                </Stack>
            )}
            {pastLeaves?.length > 0 && (
                <Stack gap={2}>
                    <Typography variant='h1'>{t('my_leaves.past_leaves')}</Typography>
                    <EmployeeLeaveRequestList leaveRequests={pastLeaves} employeeId={employeeId} compareDatesByAge={compareDesc} onChange={onChange} />
                </Stack>
            )}
        </Stack>
    );
};
