import { Box, Drawer, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '@/stores/store';
import { getProfileTabItems } from '@/page/employee-profile/employeeProfile.util';

type Props = {
    employeeId: number;
    open: boolean;
    onClose: () => void;
};
export const MobileProfileTabsNavigation: FC<Props> = ({ employeeId, open, onClose }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const policies = useAppSelector(state => state.currentEmployee.grantedPolicies);
    const realm = useAppSelector(state => state.ui.currentRealm);

    if (!realm) {
        throw new Error('Realm not defined');
    }

    const menuItems = getProfileTabItems(employeeId, realm.realmFeatures, policies);

    return (
        <Drawer anchor={'bottom'} open={open} onClose={onClose}>
            <Box role='presentation' onClick={onClose} onKeyDown={onClose}>
                <List>
                    {menuItems.map(item => (
                        <ListItem key={item.label} disablePadding>
                            <ListItemButton
                                onClick={event => {
                                    event.stopPropagation();
                                    onClose();
                                    navigate(`/profile/${employeeId}/${item.path}`);
                                }}
                            >
                                <ListItemText primary={t(item.label)} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Box>
        </Drawer>
    );
};
