import { DialogContainer } from '@/components/dialog-container/DialogContainer';
import { FieldLocalDate } from '@/components/form/field-date/FieldDate';
import { FieldText } from '@/components/form/field-text/FieldText';
import {
    EmployeePayrollLockCreationMutation,
    EmployeePayrollLockCreationValidation,
    EmployeePayrollLockPreview,
} from '@/domain/employee-payroll-lock/EmployeePayrollLock.model';
import { checkEmployeePayrollLockCreationFeasibility, createEmployeePayrollLock } from '@/domain/employee-payroll-lock/EmployeePayrollLock.service';
import { handleError } from '@/utils/api.util';
import { endOfDay, getCurrentLocalDate, getLocalDateMaxTestConfig, getLocalDateTestConfig, getTomorrow } from '@/utils/datetime.util';

import { showSnackbar } from '@/utils/snackbar.util';
import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Button, FormControlLabel, Stack, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

type LockTimesheetDialogProps = {
    onClose: () => void;
    onSave: () => void;
    employeeIds: number[];
};

export const LockTimesheetDialog: FC<LockTimesheetDialogProps> = ({ onClose, onSave, employeeIds }) => {
    const { t } = useTranslation();

    const dialogSaveButton = (
        <Button type='submit' form={'lockTimesheetDialogForm'}>
            {t('general.save')}
        </Button>
    );

    return (
        <DialogContainer open={true} onClose={onClose} onCancel={onClose} primaryButton={dialogSaveButton} title={t('timesheets.lock_dialog.title')}>
            <LockTimesheetDialogForm employeeIds={employeeIds} onSave={onSave} />
        </DialogContainer>
    );
};

type LockTimesheetDialogFormProps = {
    employeeIds: number[];
    onSave: () => void;
};

type LockTimesheetDialogFormValues = {
    lockedAt: LocalDate;
    comment?: string;
};

export const LockTimesheetDialogForm: FC<LockTimesheetDialogFormProps> = ({ employeeIds, onSave }) => {
    const { t } = useTranslation();
    const [employeePayrollLockErrors, setEmployeePayrollLockErrors] = useState<EmployeePayrollLockPreview[]>([]);

    const schema = yup.object().shape({
        lockedAt: yup
            .string<LocalDate>()
            .required()
            .test(getLocalDateTestConfig())
            .test(getLocalDateMaxTestConfig(getCurrentLocalDate(), t('timesheets.lock_dialog.max_date_error'))),
        comment: yup.string(),
    });
    const { control, handleSubmit } = useForm<LockTimesheetDialogFormValues>({
        resolver: yupResolver(schema),
        defaultValues: {
            comment: '',
            lockedAt: undefined,
        },
    });

    const handleOnSave = async (lockTimesheetDialogForm: LockTimesheetDialogFormValues) => {
        try {
            setEmployeePayrollLockErrors([]);
            const lockedAtEndOfDay = endOfDay(lockTimesheetDialogForm.lockedAt);
            const search: EmployeePayrollLockCreationValidation = {
                employeeIds: employeeIds,
                lockedAt: lockedAtEndOfDay,
            };
            const errors = await checkEmployeePayrollLockCreationFeasibility(search);
            //Before we create the lock we should check if there is not any pending timesheet, leave or payment
            if (errors.length > 0) {
                setEmployeePayrollLockErrors(errors);
            } else {
                const creationMutation: EmployeePayrollLockCreationMutation = {
                    ...lockTimesheetDialogForm,
                    employeeIds,
                    lockedAt: lockedAtEndOfDay,
                };
                await createEmployeeLock(creationMutation);
            }
        } catch (error) {
            handleError(error);
        }
    };

    const createEmployeeLock = async (creationMutation: EmployeePayrollLockCreationMutation) => {
        try {
            await createEmployeePayrollLock(creationMutation);

            showSnackbar(t('timesheets.lock_dialog.lock_created_message'), 'success');

            onSave();
        } catch (error) {
            handleError(error);
        }
    };

    const totalPendingTimesheets = employeePayrollLockErrors.reduce((acc, curr) => acc + curr.numberOfPendingTimesheets, 0);
    const totalPendingLeaves = employeePayrollLockErrors.reduce((acc, curr) => acc + curr.numberOfPendingLeaves, 0);
    const totalPendingPayments = employeePayrollLockErrors.reduce((acc, curr) => acc + curr.numberOfPendingPayments, 0);
    const totalOfPreviousLocks = employeePayrollLockErrors.reduce((acc, curr) => acc + (curr.previousLock ? 1 : 0), 0);

    return (
        <Stack direction='column' spacing={2} component={'form'} id={'lockTimesheetDialogForm'} onSubmit={handleSubmit(handleOnSave, console.error)}>
            <FormControlLabel
                label={t('timesheets.lock_dialog.effective_date_field_title')}
                control={<FieldLocalDate name='lockedAt' control={control} maxDate={getTomorrow()} />}
            />
            <FormControlLabel label={t('timesheets.lock_dialog.comment_field_title')} control={<FieldText name='comment' control={control} fullWidth />} />
            {employeePayrollLockErrors.length > 0 && (
                <Alert severity='error' elevation={0} sx={{ alignItems: 'center' }}>
                    <Typography variant='body2'>{t('timesheets.lock_dialog.lock_errors')}</Typography>
                    {!!totalPendingTimesheets && (
                        <Typography variant='body2'>
                            {t('timesheets.lock_dialog.lock_errors_timesheet', {
                                numberOfTimesheets: totalPendingTimesheets,
                            })}
                        </Typography>
                    )}
                    {!!totalPendingLeaves && (
                        <Typography variant='body2'>
                            {t('timesheets.lock_dialog.lock_errors_leave', {
                                numberOfLeaves: totalPendingLeaves,
                            })}
                        </Typography>
                    )}
                    {!!totalPendingPayments && (
                        <Typography variant='body2'>
                            {t('timesheets.lock_dialog.lock_errors_payment', {
                                numberOfPayments: totalPendingPayments,
                            })}
                        </Typography>
                    )}
                    {employeePayrollLockErrors.length === 1 && !!employeePayrollLockErrors[0].previousLock && (
                        <Typography variant='body2'>
                            {t('timesheets.lock_dialog.lock_errors_previous_lock', {
                                lockedAt: employeePayrollLockErrors[0].previousLock.lockedAt,
                            })}
                        </Typography>
                    )}
                    {employeePayrollLockErrors.length > 1 && totalOfPreviousLocks > 0 && (
                        <Typography variant='body2'>{t('timesheets.lock_dialog.lock_errors_previous_lock_multiple')}</Typography>
                    )}
                    {employeePayrollLockErrors.length > 1 && (
                        <Stack pt={2}>
                            <Typography variant='body2'>
                                {`${t('timesheets.lock_dialog.caused_by', { names: employeePayrollLockErrors.map(epl => epl.employee.displayName + ', ') })} `}
                            </Typography>
                        </Stack>
                    )}
                </Alert>
            )}
        </Stack>
    );
};
