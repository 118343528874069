import { IconButton, Menu, MenuItem } from '@mui/material';
import { FC, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { ReviewStatus } from '@/domain/review/Review.model';
import { MoreVerticalIcon } from '@/assets/icons/Icons';

type Props = {
    onUpdateReview: () => void;
    reviewStatus: ReviewStatus;
    onDelete: () => void;
    onReopen: () => void;
    onClose: () => void;
    disabled?: boolean;
};

export const ManageReviewsContextMenu: FC<Props> = ({ onUpdateReview, disabled = false, reviewStatus, onDelete, onClose, onReopen }) => {
    const [popupVisible, setPopupVisible] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<HTMLElement>();
    const { t } = useTranslation();

    return (
        <>
            <Menu
                open={popupVisible}
                id='user-menu'
                anchorEl={anchorEl}
                keepMounted
                onClose={() => {
                    setPopupVisible(false);
                }}
                anchorOrigin={{ vertical: 30, horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                {reviewStatus !== ReviewStatus.CLOSED && (
                    <MenuItem
                        dense={true}
                        onClick={event => {
                            event.stopPropagation();
                            setPopupVisible(false);
                            onUpdateReview();
                        }}
                    >
                        {t('reviews.manage_reviews.update_review')}
                    </MenuItem>
                )}

                {reviewStatus === ReviewStatus.DRAFT && (
                    <MenuItem
                        dense={true}
                        onClick={event => {
                            event.stopPropagation();
                            setPopupVisible(false);
                            onDelete();
                        }}
                    >
                        {t('general.delete')}
                    </MenuItem>
                )}
                {reviewStatus === ReviewStatus.STARTED && (
                    <MenuItem
                        dense={true}
                        onClick={event => {
                            event.stopPropagation();
                            setPopupVisible(false);
                            onClose();
                        }}
                    >
                        {t('general.close')}
                    </MenuItem>
                )}
                {reviewStatus === ReviewStatus.CLOSED && (
                    <MenuItem
                        dense={true}
                        onClick={event => {
                            event.stopPropagation();
                            setPopupVisible(false);
                            onReopen();
                        }}
                    >
                        {t('reviews.manage_reviews.reopen')}
                    </MenuItem>
                )}
            </Menu>
            <IconButton
                size='small'
                disabled={disabled}
                onClick={event => {
                    setAnchorEl(event.currentTarget);
                    event.preventDefault();
                    event.stopPropagation();
                    setPopupVisible(true);
                }}
            >
                <MoreVerticalIcon />
            </IconButton>
        </>
    );
};
