import { Employee } from '@/domain/employee/Employee.model';
import { canManagePendingTimesheets } from '@/domain/permission/Permission.service';
import { DailyTimesheetReport, DayTimesheet, TimesheetAction, TimesheetType } from '@/domain/timesheet/Timesheet.model';
import { TimesheetDialog } from '@/page/timesheet/timesheet-dialog/TimesheetDialog';
import { useAppSelector } from '@/stores/store';
import { Button } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const MissingTimesheetsButton: FC<{
    employee: Employee;
    dailyTimesheetReport: DailyTimesheetReport;
    onSuccess: () => void;
}> = ({ employee, dailyTimesheetReport, onSuccess }) => {
    const { t } = useTranslation();

    const [openTimesheetsDialog, setOpenTimesheetsDialog] = useState(false);
    const policies = useAppSelector(state => state.currentEmployee.grantedPolicies);

    // TODO : maybe create a service ?
    const missingTimesheetsDates =
        dailyTimesheetReport?.dayTimesheets
            .filter((dayTimesheet: DayTimesheet) => dayTimesheet?.timesheets?.some(timesheet => timesheet.type === TimesheetType.MISSING && !timesheet.lock))
            .map((dayTimesheet: DayTimesheet) => {
                return dayTimesheet.date;
            }) ?? [];

    const canDisplayOpenTimesheetDialog = canManagePendingTimesheets(policies, employee.id) && !!missingTimesheetsDates.length;

    if (!canDisplayOpenTimesheetDialog) {
        return;
    }

    const handleSave = () => {
        setOpenTimesheetsDialog(false);
        onSuccess();
    };

    return (
        <>
            <Button color='error' variant='text' size='small' onClick={() => setOpenTimesheetsDialog(true)}>
                {`${dailyTimesheetReport.missingCount} ${t('timesheets.missing_timesheets')}`}
            </Button>
            {openTimesheetsDialog && (
                <TimesheetDialog
                    defaultReferenceDate={missingTimesheetsDates[0]}
                    missingEntriesDates={missingTimesheetsDates}
                    mode={TimesheetAction.MISSING_TIMESHEET}
                    open={true}
                    employee={employee}
                    onClose={() => setOpenTimesheetsDialog(false)}
                    onSave={handleSave}
                />
            )}
        </>
    );
};
