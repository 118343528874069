import { Button, Stack } from '@mui/material';
import { AgGridWrapper, RogerColDef } from '@/components/ag-grid-wrapper/AgGridWrapper';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { showSnackbar } from '@/utils/snackbar.util';
import { handleError } from '@/utils/api.util';
import { EmployeeReview, UpdateEmployeeReviewMutation } from '@/domain/employee-review/EmployeeReview.model';
import { BasicMenu } from '@/components/basic-menu/BasicMenu';
import { getSetupStep, isPeerReviewAsked, isUpwardReviewAsked } from '@/domain/review/Review.service';
import { updateEmployeeReview } from '@/domain/employee-review/EmployeeReview.service';
import { Review } from '@/domain/review/Review.model';
import { useGetReview } from '@/hooks/review/Review.hook';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { StepperWorkflow } from '@/components/stepper-workflow/StepperWorkflow';
import { MoreVerticalIcon } from '@/assets/icons/Icons';
import { EmployeeReviewUpdateReviewersDialog } from '@/page/review/employee-review-update-reviewers-dialog/EmployeeReviewUpdateReviewersDialog';
import { EmployeeReviewPreviewDialog } from '@/page/review/employee-review-preview-dialog/EmployeeReviewPreviewDialog';

export const AddReviewersPreviewFormPage: FC = () => {
    const { reviewId } = useParams();
    const reviewIdNumber = isNaN(Number(reviewId)) ? undefined : Number(reviewId);
    const { data: review, isError: isReviewError, isLoading: isReviewLoading, refetch: refetchReview, error } = useGetReview(reviewIdNumber);

    return (
        <StateHandler isLoading={isReviewLoading} isError={isReviewError} error={error}>
            {review && <AddReviewersPreviewForm review={review} refetchReview={refetchReview} />}
        </StateHandler>
    );
};

type AddReviewersPreviewFormProps = {
    review: Review;
    refetchReview: () => Promise<void>;
};

export const AddReviewersPreviewForm: FC<AddReviewersPreviewFormProps> = ({ review, refetchReview }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { id: reviewId } = review;
    const employeeReviews = review?.employeeReviews ?? [];

    const [previewDialogOpen, setPreviewDialogOpen] = useState<boolean>(false);
    const [updateReviewersDialogOpen, setUpdateReviewersDialogOpen] = useState<boolean>(false);
    const [activeReviewData, setActiveReviewData] = useState<EmployeeReview>();

    const onUpdateReviewers = async (request: UpdateEmployeeReviewMutation, id: number) => {
        try {
            await updateEmployeeReview(id, request);
            refetchReview().catch(handleError);
            showSnackbar(t('reviews.messages.reviewers_updated'), 'success');
            setUpdateReviewersDialogOpen(false);
        } catch (error) {
            handleError(error);
        }
    };

    const contextMenuRenderer = ({ data }: { data: EmployeeReview | undefined }) => {
        const menuItems = [
            {
                title: t('reviews.employee_review.preview_template'),
                onClick: () => {
                    setActiveReviewData(data);
                    setPreviewDialogOpen(true);
                },
            },
            {
                title: t('reviews.employee_review.update_reviewers'),
                onClick: () => {
                    setActiveReviewData(data);
                    setUpdateReviewersDialogOpen(true);
                },
            },
        ];

        return <BasicMenu items={menuItems} endIcon={<MoreVerticalIcon />} />;
    };

    const tableOptions: RogerColDef<EmployeeReview>[] = [
        {
            field: 'employee',
            type: 'employee',
            headerName: t('general.employee'),
        },
        {
            headerName: t('reviews.employee_review.manager'),
            type: 'stackedAvatars',
            valueGetter: ({ data }) => data?.managers.flatMap(manager => manager.reviewer),
            cellRendererParams: () => ({
                cellNavDisabled: true,
            }),
        },
    ];

    if (isPeerReviewAsked(review)) {
        tableOptions.push({
            field: 'peerReviewers',
            headerName: t('reviews.employee_review.peer_reviewers'),
            type: 'stackedAvatars',
            valueGetter: ({ data }) => data?.peerReviewers.flatMap(peer => peer.reviewer),
        });
    }
    if (isUpwardReviewAsked(review)) {
        tableOptions.push({
            field: 'upwardReviewers',
            type: 'stackedAvatars',
            headerName: t('reviews.employee_review.upward_reviewers'),
            valueGetter: ({ data }) => data?.upwardReviewers.flatMap(upward => upward.reviewer),
        });
    }

    tableOptions.push({
        type: 'actionMenu',
        cellRenderer: contextMenuRenderer,
    });

    const steps = getSetupStep().steps;
    const setupStep = getSetupStep().getStep('REVIEWERS_AND_PREVIEW');

    return (
        <Stack justifyContent={'space-between'} flex={1} gap={2} mb={8.5}>
            <AgGridWrapper<EmployeeReview> rowData={employeeReviews} columnDefs={tableOptions} />
            {activeReviewData && (
                <EmployeeReviewPreviewDialog open={previewDialogOpen} activeReview={activeReviewData} onClose={() => setPreviewDialogOpen(false)} />
            )}

            {updateReviewersDialogOpen && activeReviewData && (
                <EmployeeReviewUpdateReviewersDialog
                    open={updateReviewersDialogOpen}
                    activeEmployeeReview={{ ...activeReviewData, review }}
                    onSave={onUpdateReviewers}
                    onClose={() => setUpdateReviewersDialogOpen(false)}
                />
            )}

            <StepperWorkflow steps={steps} currentStep={setupStep}>
                <Button onClick={() => navigate(`/reviews/manage-reviews/${reviewId}/setup`)} variant={'text'}>
                    {t('general.back')}
                </Button>
                <Button onClick={() => navigate(`/reviews/manage-reviews/${reviewId}/invitations`)} variant={'contained'}>
                    {t('reviews.wizard.next')}
                </Button>
            </StepperWorkflow>
        </Stack>
    );
};
