import { EmployeeReviewManagerSummary } from '@/domain/employee-review-feedback-summary/EmployeeReviewFeedbackSummary.model';
import { EmployeeReviewFeedback } from '@/domain/employee-review-feedback/EmployeeReviewFeedback.model';
import { EmployeeReview } from '@/domain/employee-review/EmployeeReview.model';
import { getCurrentPrincipalEmployment } from '@/domain/employment/Employment.service';
import { formatInDefaultDate, getCurrentLocalDate } from '@/utils/datetime.util';
import { getLabelTranslation, getUserLanguage } from '@/utils/language.util';
import { useTheme } from '@mui/material';
import { Document, Page, Text, View } from '@react-pdf/renderer';
import { FC, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

type EmployeeReviewFeedbackLayoutPDFProps = {
    employeeReviewFeedback: EmployeeReviewFeedback | EmployeeReviewManagerSummary;
    employeeReview: EmployeeReview;
};

export const EmployeeReviewFeedbackLayoutPDF: FC<PropsWithChildren<EmployeeReviewFeedbackLayoutPDFProps>> = ({
    employeeReviewFeedback,
    employeeReview,
    children,
}) => {
    const { t } = useTranslation();
    const userLanguage = getUserLanguage();
    const theme = useTheme();

    const employment = getCurrentPrincipalEmployment(employeeReviewFeedback?.employee);
    return (
        <Document>
            <Page
                size='A4'
                wrap
                style={{
                    padding: theme.spacing(2),
                    gap: theme.spacing(3),
                    fontSize: 10,
                }}
            >
                <View>
                    <Text
                        style={{
                            fontSize: 14,
                            fontWeight: 'bold',
                        }}
                    >
                        {getLabelTranslation(employeeReviewFeedback?.reviewName)}
                    </Text>
                    <Text
                        style={{
                            fontSize: 14,
                            fontWeight: 'bold',
                        }}
                    >
                        {employeeReviewFeedback?.employee?.displayName}
                    </Text>
                    <Text>{getLabelTranslation(employment?.job?.name, userLanguage)}</Text>
                    {!!employeeReview?.managers?.length && (
                        <View style={{ gap: theme.spacing(0.5), flexDirection: 'row' }}>
                            <Text>{t('reviews.review_summary.reviewers')}:</Text>
                            <View style={{ gap: theme.spacing(0.5) }}>
                                {(employeeReview.managers ?? []).map(manager => (
                                    <Text key={manager.id}>{manager.reviewer.displayName}</Text>
                                ))}
                            </View>
                        </View>
                    )}
                    <Text>{t('reviews.review_summary.exported_on', { date: formatInDefaultDate(getCurrentLocalDate()) })}</Text>
                </View>
                {children}
            </Page>
        </Document>
    );
};
