import { MobileNavigation } from '@/components/mobile-navigation/MobileNavigation';
import { Box, Drawer, Paper, Stack, Toolbar, useTheme } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

import { useDrawer } from '@/hooks/layout/Drawer.hook';
import { Header } from '@/page/layout/Header';
import { Sidebar } from '@/page/layout/Sidebar';
import { desktopVisible } from '@/theme/responsive';

export const Layout: FC<PropsWithChildren> = ({ children }) => {
    const theme = useTheme();
    const { drawerWidth } = useDrawer();

    return (
        <Box sx={{ display: 'flex', height: '100vh', width: '100vw' }} bgcolor='white'>
            <Header
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                    transition: theme.transitions.create(['margin', 'width']),
                }}
            />
            <Box
                component='nav'
                sx={{
                    ...desktopVisible,
                    width: { sm: drawerWidth },
                    transition: theme.transitions.create(['width']),
                    flexShrink: { sm: 0 },
                }}
            >
                <Drawer
                    variant='permanent'
                    open={true}
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            border: 'none',
                        },
                    }}
                >
                    <Sidebar />
                </Drawer>
            </Box>
            <Stack
                flex={1}
                sx={{
                    width: { xs: '100vw', sm: `calc(100vw - ${drawerWidth}px)` },
                }}
            >
                {/* This is used to align the content with the header */}
                <Toolbar variant={'dense'} />
                <Stack
                    component='main'
                    flex={1}
                    bgcolor={{ xs: theme.palette.background.default }}
                    borderRadius={{ sm: `${theme.shape.borderRadius}px 0 0 0` }}
                    sx={{
                        overflowY: 'auto',
                        '&::-webkit-scrollbar': {
                            display: 'none',
                        },
                    }}
                    p={2}
                >
                    {children}
                    {/* This is used to align the content with the mobile navigation */}
                    <Toolbar sx={{ display: { sm: 'none' } }} />
                </Stack>
            </Stack>
            <Paper
                sx={{
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    right: 0,
                }}
            >
                <MobileNavigation />
            </Paper>
        </Box>
    );
};
