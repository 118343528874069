import { FC, useState } from 'react';
import { BubbleMenu, Editor } from '@tiptap/react';
import { Button, IconButton, Paper, Stack, SxProps, TextField, Theme } from '@mui/material';
import { DialogContainer } from '@/components/dialog-container/DialogContainer';
import { useTranslation } from 'react-i18next';
import { LeftToRightListBulletIcon, LeftToRightListNumberIcon, Link02Icon, TextBoldIcon, TextItalicIcon } from 'hugeicons-react';

type Props = {
    editor: Editor;
    error: boolean;
    isFocused: boolean;
};

export const EditorMenuBar: FC<Props> = ({ editor, error, isFocused }) => {
    if (!editor) {
        return <></>;
    }

    const menuBar: SxProps<Theme> = {
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        // this margin is to avoid the element to move when the border is added
        margin: isFocused ? '-1px' : 0,
        border: (theme: Theme) => {
            if (isFocused) {
                if (error) {
                    return `2px solid ${theme.palette.error.main}`;
                }
                return `2px solid ${theme.palette.primary.main}`;
            }
            if (error) {
                return `1px solid ${theme.palette.error.main}`;
            }
            return `1px solid ${theme.palette.grey[300]}`;
        },
        borderBottom: 0,
    };

    const control: SxProps<Theme> = {
        opacity: 0.5,
        transition: 'opacity .1s linear',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&.active, &:hover': {
            opacity: 1,
        },
    };
    return (
        <Stack alignItems='center' direction={'row'} sx={menuBar}>
            <IconButton
                onClick={() => {
                    editor.chain().focus().toggleBold().run();
                }}
                sx={control}
                className={editor.isActive('bold') ? 'active' : undefined}
                size='small'
            >
                <TextBoldIcon size={20} />
            </IconButton>

            <IconButton
                onClick={() => {
                    editor.chain().focus().toggleItalic().run();
                }}
                sx={control}
                className={editor.isActive('italic') ? 'active' : undefined}
                size='small'
            >
                <TextItalicIcon size={20} />
            </IconButton>
            <IconButton
                onClick={() => {
                    editor.chain().focus().toggleOrderedList().run();
                }}
                sx={control}
                className={editor.isActive('orderedList') ? 'active' : undefined}
                size='small'
            >
                <LeftToRightListNumberIcon size={20} />
            </IconButton>

            <IconButton
                onClick={() => {
                    editor.chain().focus().toggleBulletList().run();
                }}
                sx={control}
                className={editor.isActive('bulletList') ? 'active' : undefined}
                size='small'
            >
                <LeftToRightListBulletIcon size={20} />
            </IconButton>

            <HyperlinkModal editor={editor} />
        </Stack>
    );
};

type HyperlinkProps = {
    editor: Editor;
};

export const HyperlinkModal: FC<HyperlinkProps> = ({ editor }) => {
    const [showModal, setShowModal] = useState(false);
    const [linkInput, setLinkInput] = useState('');
    const { t } = useTranslation();

    const setLink = (linkInput: string, editor: Editor) => {
        if (linkInput) {
            editor.chain().focus().extendMarkRange('link').setLink({ href: linkInput }).run();
        }
        setLinkInput('');
        setShowModal(false);
    };

    const removeLink = (editor: Editor) => {
        editor.chain().focus().extendMarkRange('link').unsetLink().run();
        setLinkInput('');
        setShowModal(false);
    };

    const onLinkClick = (editor: Editor) => {
        const link = editor.getAttributes('link').href;
        setLinkInput(link);
        setShowModal(true);
    };

    const focusFrom = editor.state?.selection?.from;
    const focusTo = editor.state?.selection?.to;
    const isTextHighlighted = focusFrom !== focusTo;

    return (
        <>
            <IconButton onClick={() => onLinkClick(editor)} size='small' disabled={!isTextHighlighted}>
                <Link02Icon size={20} />
            </IconButton>

            {showModal && (
                <DialogContainer
                    onClose={() => setShowModal(false)}
                    open={true}
                    onSave={() => setLink(linkInput, editor)}
                    title={t('rich_text_editor.edit_link')}
                    saveButtonText={t('rich_text_editor.save_link')}
                    onCancel={() => removeLink(editor)}
                    cancelButtonText={t('rich_text_editor.remove_link')}
                    sx={{ zIndex: 10000 }}
                >
                    <TextField value={linkInput} onChange={event => setLinkInput(event.target.value)} sx={{ width: '100%' }} />
                </DialogContainer>
            )}

            <BubbleMenu tippyOptions={{ duration: 150 }} editor={editor} shouldShow={({ editor }) => editor.isActive('link')}>
                <Paper>
                    <Button size={'small'} variant={'outlined'} onClick={() => onLinkClick(editor)} sx={{ marginRight: 0.25 }}>
                        {t('rich_text_editor.edit_link')}
                    </Button>
                    <Button size={'small'} variant={'outlined'} onClick={() => removeLink(editor)}>
                        {t('rich_text_editor.remove_link')}
                    </Button>
                </Paper>
            </BubbleMenu>
        </>
    );
};
