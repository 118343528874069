import { StateHandler } from '@/components/state-handler/StateHandler';
import { useGetFolders } from '@/hooks/document/Document.hook';
import { useGetEmployees } from '@/hooks/employee/Employee.hook';
import { useGetPermissionGroup } from '@/page/setting/permission/Permission.hook';
import { PermissionSettingForm } from '@/page/setting/permission/permission-setting-form/PermissionSettingForm';
import { handleError } from '@/utils/api.util';
import { showSnackbar } from '@/utils/snackbar.util';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { createPermissionGroup, deletePermissionGroup, updatePermissionGroup } from '@/domain/permission-group/PermissionGroup.service';
import { PermissionGroupMutation } from '@/domain/permission-group/PermissionGroup.model';
import { ConfirmDialog } from '@/components/confirmation-dialog/ConfirmDialog';
import { useGetSectionDefinitions } from '@/hooks/section-definition/SectionDefinition.hook';

export const PermissionsSettingPage: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    const permissionGroupId = Number(params.permissionId) || undefined;
    const isEdit = !!permissionGroupId;

    const {
        data: permissionGroup,
        isLoading: isLoadingPermissionGroup,
        isError: isPermissionGroupError,
        error: permissionGroupError,
    } = useGetPermissionGroup(permissionGroupId);
    const { data: allEmployees = [], isLoading: isLoadingEmployees, isError: isEmployeesError, error: employeesError } = useGetEmployees();
    const {
        data: sectionDefinitions = [],
        isLoading: isLoadingSectionDefinitions,
        isError: isSectionDefinitionsError,
        error: sectionDefinitionsError,
    } = useGetSectionDefinitions();
    const { data: folders = [], isLoading: isLoadingFolders, isError: isFoldersError, error: foldersError } = useGetFolders();

    const isLoading = isLoadingEmployees || isLoadingSectionDefinitions || isLoadingFolders || isLoadingPermissionGroup;
    const isError = isEmployeesError || isSectionDefinitionsError || isFoldersError || isPermissionGroupError;
    const error = employeesError || sectionDefinitionsError || foldersError || permissionGroupError;

    const [dialogToDeleteGroupIsOpen, setDialogToDeleteGroupIsOpen] = useState(false);

    const handleSaveForm = async (data: PermissionGroupMutation) => {
        const successMessage = isEdit
            ? 'permissions_setting_page.messages.permission_group_updated'
            : 'permissions_setting_page.messages.permission_group_created';

        const getAction = () => {
            if (permissionGroupId) {
                return updatePermissionGroup(data, permissionGroupId);
            }
            return createPermissionGroup(data);
        };
        try {
            await getAction();
            showSnackbar(t(successMessage), 'success');
            navigate(-1);
        } catch (error) {
            handleError(error);
        }
    };

    const handleDelete = () => {
        if (permissionGroupId) {
            setDialogToDeleteGroupIsOpen(true);
        } else {
            navigate('/settings/permissions');
        }
    };

    const handleConfirmDelete = (groupId: number) => {
        deletePermissionGroup(groupId)
            .then(() => {
                showSnackbar(t('permissions_setting_page.messages.permission_group_deleted'), 'success');
                navigate(-1);
            })
            .catch(handleError);
    };

    return (
        <StateHandler isLoading={isLoading} isError={isError} error={error}>
            <PermissionSettingForm
                permissionGroup={permissionGroup}
                allEmployees={allEmployees}
                sectionDefinitions={sectionDefinitions}
                folders={folders}
                onSave={handleSaveForm}
                onDelete={handleDelete}
            />
            {permissionGroupId && (
                <ConfirmDialog
                    open={dialogToDeleteGroupIsOpen}
                    title={t('permissions_setting_page.are_you_sure_delete_permission')}
                    content={t('general.action_irreversible')}
                    onConfirm={() => handleConfirmDelete(permissionGroupId)}
                    onClose={() => setDialogToDeleteGroupIsOpen(false)}
                    // Override default confirm button props
                    confirmButtonProps={{
                        children: t('permissions_setting_page.delete_permission'),
                        color: 'error',
                    }}
                />
            )}
        </StateHandler>
    );
};
