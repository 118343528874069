import { TreeSelect, TreeSelectionProps } from '@/components/tree-select/TreeSelect';
import { Controller, ControllerProps, ControllerRenderProps, FieldPath, FieldValues } from 'react-hook-form';

type TreeSelectRootProps = Pick<
    TreeSelectionProps,
    'itemsData' | 'loading' | 'onChange' | 'variant' | 'autoFocus' | 'fullWidth' | 'placeholder' | 'disabled' | 'slotProps'
>;

export type FieldTreeSelectProps<TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>> = {
    textFieldProps?: RemoveFromType<RemoveFromType<TreeSelectionProps, TreeSelectRootProps>, ControllerRenderProps>;
} & TreeSelectRootProps &
    Omit<ControllerProps<TFieldValues, TName>, 'render'>;

export const FieldTreeSelect = <TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>(
    props: FieldTreeSelectProps<TFieldValues, TName>,
): JSX.Element => {
    const { itemsData, loading, onChange, variant, autoFocus, fullWidth, placeholder, disabled, slotProps, textFieldProps, ...controllerProps } = props;

    return (
        <Controller
            {...controllerProps}
            render={({ field, fieldState: { error } }) => (
                <TreeSelect
                    itemsData={itemsData}
                    loading={loading}
                    variant={variant}
                    autoFocus={autoFocus}
                    fullWidth={fullWidth}
                    placeholder={placeholder}
                    disabled={disabled}
                    slotProps={slotProps}
                    {...textFieldProps}
                    {...field}
                    onChange={newItem => {
                        field.onChange(newItem);
                        onChange?.(newItem);
                    }}
                    error={!!error}
                    helperText={error?.message}
                />
            )}
        />
    );
};
