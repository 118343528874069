import { StateHandler } from '@/components/state-handler/StateHandler';
import { LocationMutation } from '@/domain/location/Location.model';
import { createLocation, updateLocation } from '@/domain/location/Location.service';
import { useGetLocation } from '@/page/setting/location/Location.hook';
import { LocationSettingForm } from '@/page/setting/location/location-setting-form/LocationSettingForm';
import { handleError } from '@/utils/api.util';
import { showSnackbar } from '@/utils/snackbar.util';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

export const LocationSettingPage: FC = () => {
    const { t } = useTranslation();
    const params = useParams();
    const navigate = useNavigate();

    const legalUnitId = Number(params.legalUnitId) || undefined;
    const locationId = Number(params.locationId) || undefined;

    const { data: location, isLoading: isLoadingLocation, isError: isErrorLocation, error: errorLocation } = useGetLocation(locationId);

    if (!legalUnitId) {
        throw new Error('Legal unit id is required');
    }

    const handleSave = async (data: LocationSettingForm) => {
        // Remove id generated by react-hook-form
        const areas = data.areas.map(({ areaId, id: _id, ...restArea }) => ({
            ...restArea,
            id: areaId,
        }));
        const payload: LocationMutation = {
            ...data,
            country: data?.country?.value,
            areas: areas,
            closingTime: data.closingTime ?? undefined,
            openingTime: data.openingTime ?? undefined,
            legalUnitId,
        };
        try {
            if (!locationId) {
                await createLocation(payload);
                showSnackbar(t('locations_page.messages.new_location_added'), 'success');
            } else {
                await updateLocation(locationId, payload);
                showSnackbar(t('locations_page.messages.location_updated'), 'success');
            }
            goToLocations();
        } catch (error) {
            handleError(error);
        }
    };

    const goToLocations = () => {
        navigate(`/settings/organization/legal-units/${legalUnitId}`);
    };

    return (
        <StateHandler isLoading={isLoadingLocation} isError={isErrorLocation} error={errorLocation}>
            <LocationSettingForm location={location} onSave={handleSave} />
        </StateHandler>
    );
};
