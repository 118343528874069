import { IconButton, ListItemText, MenuItem, Paper, PaperProps, Select, Stack, Typography } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import { Label } from '@/domain/label/Label.model';
import {
    getSurveyCategoryTranslationKey,
    SURVEY_QUESTION_CATEGORIES,
    SurveyQuestion,
    SurveyQuestionCategory,
    SurveyQuestionItem,
    SurveyQuestionType,
} from '@/domain/survey/Survey.model';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SurveyMultipleChoice } from './SurveyMultipleChoice';
import { SurveyLickertScale } from './SurveyLickertScale';
import { SurveySingleChoice } from './SurveySingleChoice';
import { TranslatableLabelInput } from '@/components/translatable-label-input/TranslatableLabelInput';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { SurveyOpinionScale } from './SurveyOpinionScale';
import { UserLanguage } from '@/utils/language.util';
import { Copy01Icon, Delete02Icon } from 'hugeicons-react';

type SurveyTemplateQuestionPageProps = {
    question: SurveyQuestion;
    mode: 'create' | 'edit';
    isTranslationMode: boolean;
    onChangeQuestionType: (data: SurveyQuestionType, index: number) => void;
    index: number;
    onChangeQuestion: (label: Label) => void;
    onQuestionItemAdded: (item: SurveyQuestionItem) => void;
    onQuestionScaleItemAdded: (item: SurveyQuestionItem, scaleOptionLength: number) => void;
    onChangeQuestionItemTitle: (title: Label, index: number) => void;
    onChangeQuestionItemImage: (imageUrl: string, index: number) => void;
    onChangeQuestionItemScore: (score: number, index: number) => void;
    onDeleteQuestionItem: (index: number, questionType: SurveyQuestionType) => void;
    duplicateQuestion: () => void;
    // If not provided, the delete button will be disabled
    deleteQuestion: (() => void) | undefined;
    onSelectCategory: (category: SurveyQuestionCategory) => void;
    initOpinionScale: () => void;
    translationLanguage: UserLanguage;
} & PaperProps;

export const SurveyTemplateQuestionPage: FC<SurveyTemplateQuestionPageProps> = ({
    question,
    isTranslationMode,
    onChangeQuestionType,
    index,
    onChangeQuestion,
    onQuestionItemAdded,
    onChangeQuestionItemTitle,
    onChangeQuestionItemImage,
    onDeleteQuestionItem,
    deleteQuestion,
    onSelectCategory,
    onQuestionScaleItemAdded,
    duplicateQuestion,
    initOpinionScale,
    translationLanguage,
    ...rest
}) => {
    const { t } = useTranslation();

    const renderQuestionItems = (question: SurveyQuestion) => {
        switch (question.type) {
            case SurveyQuestionType.SINGLE_CHOICE:
                return (
                    <SurveySingleChoice
                        isTranslationMode={isTranslationMode}
                        question={question}
                        onQuestionItemAdded={onQuestionItemAdded}
                        onChangeQuestionItemTitle={onChangeQuestionItemTitle}
                        onChangeQuestionItemImage={onChangeQuestionItemImage}
                        onDeleteQuestionItem={onDeleteQuestionItem}
                        translationLanguage={translationLanguage}
                    />
                );
            case SurveyQuestionType.MULTI_CHOICE:
                return (
                    <SurveyMultipleChoice
                        question={question}
                        isTranslationMode={isTranslationMode}
                        onQuestionItemAdded={onQuestionItemAdded}
                        onChangeQuestionItemTitle={onChangeQuestionItemTitle}
                        onChangeQuestionItemImage={onChangeQuestionItemImage}
                        onDeleteQuestionItem={onDeleteQuestionItem}
                        translationLanguage={translationLanguage}
                    />
                );
            case SurveyQuestionType.LICKERT_SCALE:
                return (
                    <SurveyLickertScale
                        isTranslationMode={isTranslationMode}
                        question={question}
                        onQuestionScaleItemAdded={onQuestionScaleItemAdded}
                        onChangeQuestionItemTitle={onChangeQuestionItemTitle}
                        onChangeQuestionItemImage={onChangeQuestionItemImage}
                        onDeleteQuestionItem={onDeleteQuestionItem}
                        translationLanguage={translationLanguage}
                    />
                );
            case SurveyQuestionType.OPINION_SCALE:
            case SurveyQuestionType.NPS:
                return (
                    <SurveyOpinionScale
                        isTranslationMode={isTranslationMode}
                        question={question}
                        onQuestionScaleItemAdded={onQuestionScaleItemAdded}
                        onChangeQuestionItemTitle={onChangeQuestionItemTitle}
                        onChangeQuestionItemImage={onChangeQuestionItemImage}
                        initOpinionScale={initOpinionScale}
                    />
                );
            default:
                return undefined;
        }
    };

    return (
        <Paper elevation={1} {...rest}>
            <Stack p={2} gap={2}>
                {!isTranslationMode && (
                    <Stack justifyContent={'space-between'} direction={'row'} alignItems={'center'} gap={2}>
                        <Stack direction={'row'} gap={2} alignItems={'center'}>
                            <Typography variant='body1bold'>
                                {t('survey_templates_page.question')} {index + 1}
                            </Typography>

                            {!isTranslationMode && (
                                <ToggleButtonGroup
                                    value={question.type}
                                    exclusive
                                    onChange={(_, newAlignment) => onChangeQuestionType(newAlignment, index)}
                                    size='small'
                                >
                                    <ToggleButton value={SurveyQuestionType.TEXT}>{t('survey_templates_page.text')}</ToggleButton>
                                    <ToggleButton value={SurveyQuestionType.SINGLE_CHOICE}>{t('survey_templates_page.single_choice')}</ToggleButton>
                                    <ToggleButton value={SurveyQuestionType.MULTI_CHOICE}>{t('survey_templates_page.multiple_choice')}</ToggleButton>
                                    <ToggleButton value={SurveyQuestionType.LICKERT_SCALE}>{t('survey_templates_page.likert_scale')}</ToggleButton>
                                    <ToggleButton value={SurveyQuestionType.NPS}>{t('survey_templates_page.nps')}</ToggleButton>
                                    <ToggleButton value={SurveyQuestionType.OPINION_SCALE}>{t('survey_templates_page.opinion_scale')}</ToggleButton>
                                    <ToggleButton value={SurveyQuestionType.SECTION}>{t('survey_templates_page.section')}</ToggleButton>
                                </ToggleButtonGroup>
                            )}
                        </Stack>

                        <Stack direction={'row'} justifyContent={'flex-end'}>
                            <IconButton onClick={duplicateQuestion}>
                                <Copy01Icon />
                            </IconButton>
                            <IconButton onClick={deleteQuestion} disabled={!deleteQuestion}>
                                <Delete02Icon />
                            </IconButton>
                        </Stack>
                    </Stack>
                )}
                <Stack direction='row' gap={2}>
                    <Stack gap={0.5} flex={1}>
                        <Typography variant={'body2'}>{t('survey_templates_page.what_would_you_like_to_ask')}</Typography>
                        {/*todo: implement the error helper text message*/}
                        <TranslatableLabelInput
                            fullWidth
                            InputProps={{ multiline: true, minRows: 1 }}
                            value={question.question}
                            onChange={(value: Label) => {
                                onChangeQuestion(value);
                            }}
                            translationLanguage={translationLanguage}
                        />
                    </Stack>

                    {/* Todo: remove this Section behavior, because it's not a type of section, it's a styling use case instead, create an "Add Section" button in the same place than "Add question" Button */}
                    {question.type !== SurveyQuestionType.SECTION && !isTranslationMode && (
                        <Stack gap={0.5}>
                            <Typography variant={'body2'}>{t('survey_templates_page.category')}</Typography>

                            <Select
                                value={question?.category}
                                size='small'
                                sx={{ minWidth: '232px', height: '37px' }}
                                defaultValue={''}
                                onChange={event => onSelectCategory(event.target.value as SurveyQuestionCategory)}
                            >
                                {SURVEY_QUESTION_CATEGORIES.map(category => (
                                    <MenuItem key={category} value={category}>
                                        <ListItemText primary={t(getSurveyCategoryTranslationKey(category))} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </Stack>
                    )}
                </Stack>
                {renderQuestionItems(question)}
            </Stack>
        </Paper>
    );
};
