import { FieldNumber } from '@/components/form/field-number/FieldNumber';
import { FieldSwitch } from '@/components/form/field-switch/FieldSwitch';
import { RealmFeaturesType } from '@/domain/realm/Realm.model';
import { TimesheetMode, TimesheetSetting, TimesheetSettingMutation } from '@/domain/timesheet-setting/TimesheetSetting.model';
import { ContentContainer } from '@/page/layout/ContentContainer';
import { Footer, FooterActions, FooterActionsProps } from '@/page/layout/Footer';
import { TimeManagementConfiguration } from '@/page/setting/time-management/time-management-setting-form/TimeManagementConfiguration';
import { TimeManagementTimeClock } from '@/page/setting/time-management/time-management-setting-form/TimeManagementTimeClock';
import { getTimesheetSettingSchema } from '@/page/setting/time-management/time-management-setting-form/TimesheetSettingForm.schema';
import { DayOfWeek, MONTHS } from '@/utils/datetime.util';
import { yupResolver } from '@hookform/resolvers/yup';
import { Collapse, FormControlLabel, formHelperTextClasses, Paper, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useRealmFeatureEnabled } from '@/hooks/realm/useRealmFeatureEnabled';

export type TimesheetSettingForm = TimesheetSettingMutation & {
    isBonusEnabled: boolean;
    isPaidBreaksEnabled: boolean;
    isForceBreakToBeTakenEnabled: boolean;
};

type TimeManagementSettingFormProps = {
    timesheetSetting?: TimesheetSetting;
    isEdit: boolean;
    onSave: (timesheetSettingForm: TimesheetSettingForm) => void;
    onDelete: () => void;
};

const MIDNIGHT_TIME = '01:00';

export const TimeManagementSettingForm: FC<TimeManagementSettingFormProps> = ({ timesheetSetting, isEdit, onSave, onDelete }) => {
    const { t } = useTranslation();

    const schema = getTimesheetSettingSchema();

    const formMethods = useForm<TimesheetSettingForm>({
        resolver: yupResolver(schema),
        defaultValues: isEdit ? getTimesheetSettingWithDefaultValues(isEdit, timesheetSetting) : getDefaultValues(isEdit, timesheetSetting),
    });

    const {
        handleSubmit,
        formState: { isDirty },
        watch,
    } = formMethods;

    const getFooterButtons = (): FooterActionsProps['actions'] => {
        const footerButtons: FooterActionsProps['actions'] = [
            {
                onClick: () => {
                    handleSubmit(onSave, console.error)();
                },
                disabled: !isDirty,
                children: isEdit ? t('general.update') : t('general.create'),
                variant: 'contained',
            },
        ];

        if (isEdit) {
            footerButtons.unshift({
                onClick: () => onDelete(),
                children: t('general.delete'),
                color: 'error',
                variant: 'contained',
            });
        }

        return footerButtons;
    };

    const footerActions = getFooterButtons();

    const canDisplayTimeclock = useRealmFeatureEnabled(RealmFeaturesType.CLOCK_IN_OUT);

    const timesheetMode = watch('timesheetMode');
    const isSimplified = timesheetMode === TimesheetMode.SIMPLIFIED;

    return (
        <>
            <FormProvider {...formMethods}>
                <Stack component={ContentContainer} width='100%' flex={1} gap={2}>
                    <Stack gap={2}>
                        <TimeManagementConfiguration />
                        <TimeManagementTimeClock />
                        {!isSimplified && canDisplayTimeclock && <TimeManagementPlanning />}
                    </Stack>
                </Stack>
            </FormProvider>
            <Footer>
                <FooterActions actions={footerActions} />
            </Footer>
        </>
    );
};

const TimeManagementPlanning: FC = () => {
    const { t } = useTranslation();
    const { control, watch } = useFormContext<TimesheetSettingForm>();
    //same value as the BE to not allow the employee to adjust like crazy
    const MAX_VALUE_FOR_MINUTES_TO_ADJUST = 240;

    return (
        <Stack component={Paper} p={2} gap={2}>
            <Typography variant={'h1'}>{t('time_management_settings_page.time_management_configuration.planning')}</Typography>

            <Stack direction={'row'} gap={1} alignItems={'center'}>
                <FormControlLabel
                    label={t('time_management_settings_page.time_management_configuration.force_shift_start_time_on_clock_in')}
                    labelPlacement='end'
                    control={<FieldSwitch name='forceShiftStartTimeOnClockIn' control={control} />}
                    sx={{ mr: 0 }}
                />

                <Collapse in={watch('forceShiftStartTimeOnClockIn')}>
                    <Stack direction={'row'} gap={1} alignItems={'baseline'}>
                        <FieldNumber
                            name='forceShiftStartTimeBeforeInMinutes'
                            control={control}
                            min={0}
                            max={MAX_VALUE_FOR_MINUTES_TO_ADJUST}
                            step={5}
                            sx={{
                                width: '8em',
                                [`& .${formHelperTextClasses.root}`]: {
                                    whiteSpace: 'nowrap',
                                },
                            }}
                        />
                        <Typography variant={'body1'}>
                            {t('time_management_settings_page.time_management_configuration.force_shift_start_time_on_clock_in_minutes')}
                        </Typography>
                    </Stack>
                </Collapse>
            </Stack>
            <Stack direction={'row'} gap={1} alignItems={'center'}>
                <FormControlLabel
                    label={t('time_management_settings_page.time_management_configuration.force_shift_end_time_on_clock_out')}
                    labelPlacement='end'
                    control={<FieldSwitch name='forceShiftEndTimeOnClockOut' control={control} />}
                    sx={{ mr: 0 }}
                />

                <Collapse in={watch('forceShiftEndTimeOnClockOut')}>
                    <Stack direction={'row'} gap={1} alignItems={'baseline'}>
                        <FieldNumber
                            name='forceShiftEndTimeAfterInMinutes'
                            control={control}
                            min={0}
                            max={MAX_VALUE_FOR_MINUTES_TO_ADJUST}
                            step={5}
                            sx={{
                                width: '8em',
                                [`& .${formHelperTextClasses.root}`]: {
                                    whiteSpace: 'nowrap',
                                },
                            }}
                        />
                        <Typography variant={'body1'}>
                            {t('time_management_settings_page.time_management_configuration.force_shift_start_time_on_clock_in_minutes')}
                        </Typography>
                    </Stack>
                </Collapse>
            </Stack>
        </Stack>
    );
};

const areBonusValuesEqualsToDefaults = (isEdit: boolean, timesheetSetting?: TimesheetSetting) => {
    return (
        !isEdit ||
        ((!timesheetSetting?.nightBonusPercentage || timesheetSetting?.nightBonusPercentage === 0) &&
            (!timesheetSetting?.saturdayBonusPercentage || timesheetSetting?.saturdayBonusPercentage === 0) &&
            (!timesheetSetting?.sundayBonusPercentage || timesheetSetting?.sundayBonusPercentage === 0))
    );
};

const getDefaultValues = (isEdit: boolean, timesheetSetting?: TimesheetSetting) => {
    return {
        name: '',
        timesheetMode: TimesheetMode.NORMAL,
        autofillTimesheet: false,
        includeShiftsIntoTimesheets: false,
        isBonusEnabled: !areBonusValuesEqualsToDefaults(isEdit, timesheetSetting),
        nightBonusPercentage: 0,
        nightBonusStartTime: '23:00',
        nightBonusEndTime: '06:00',
        saturdayBonusPercentage: 0,
        saturdayBonusStartTime: MIDNIGHT_TIME,
        saturdayBonusEndTime: MIDNIGHT_TIME,
        saturdayFromDayOfWeek: DayOfWeek.SATURDAY,
        saturdayToDayOfWeek: DayOfWeek.SUNDAY,
        sundayBonusPercentage: 0,
        sundayBonusStartTime: MIDNIGHT_TIME,
        sundayBonusEndTime: MIDNIGHT_TIME,
        sundayFromDayOfWeek: DayOfWeek.SUNDAY,
        sundayToDayOfWeek: DayOfWeek.MONDAY,
        mandatoryComment: false,
        cycleStartMonth: MONTHS.JANUARY,
        breakDisplayEnabled: true,
        mobileClockInOut: false,
        allowClockInOutOutsideWorkHours: false,
        allowClockInOutOnSundayAndPublicHolidays: false,
        forceBreakClockInOut: false,
        forceSmallBreakDurationInMinutes: 0,
        forceSmallBreakAfterXHours: 0,
        forceBigBreakDurationInMinutes: 0,
        forceBigBreakAfterXHours: 0,
        forceBreakToBeTakenFrom: '12:00',
        forceBreakToBeTakenTo: '14:00',
        isForceBreakToBeTakenEnabled: false,
        paidBreaksSundayPublicHolidays: false,
        paidBreaksSundayPublicHolidaysMaximumDurationInMinutes: 0,
        paidBreaksSaturday: false,
        paidBreaksSaturdayMaximumDurationInMinutes: 0,
        isPaidBreaksEnabled: false,
        rule1PaidBreaksFrom: '8:00',
        rule1PaidBreaksTo: '12:00',
        rule1PaidBreaksMaximumDurationInMinutes: 0,
        rule2PaidBreaksFrom: '14:00',
        rule2PaidBreaksTo: '19:00',
        rule2PaidBreaksMaximumDurationInMinutes: 0,
        forceShiftStartTimeOnClockIn: false,
        forceShiftStartTimeBeforeInMinutes: 0,
        forceShiftEndTimeOnClockOut: false,
        forceShiftEndTimeAfterInMinutes: 0,
        considerMissingTimesheetsAsUnpaidLeave: false,
        maximumWeeklyAdditionalWorkingTime: 45,
    };
};

const getTimesheetSettingRuleWithDefaultValues = (
    timesheetSetting: TimesheetSetting | undefined,
    defaultValues: TimesheetSettingForm,
): Partial<TimesheetSettingForm> => {
    const rules = {
        rule1PaidBreaksFrom: timesheetSetting?.rule1PaidBreaksFrom ?? defaultValues.rule1PaidBreaksFrom,
        rule1PaidBreaksTo: timesheetSetting?.rule1PaidBreaksTo ?? defaultValues.rule1PaidBreaksTo,
        rule1PaidBreaksMaximumDurationInMinutes:
            timesheetSetting?.rule1PaidBreaksMaximumDurationInMinutes ?? defaultValues.rule1PaidBreaksMaximumDurationInMinutes,
        rule2PaidBreaksFrom: timesheetSetting?.rule2PaidBreaksFrom ?? defaultValues.rule2PaidBreaksFrom,
        rule2PaidBreaksTo: timesheetSetting?.rule2PaidBreaksTo ?? defaultValues.rule2PaidBreaksTo,
        rule2PaidBreaksMaximumDurationInMinutes:
            timesheetSetting?.rule2PaidBreaksMaximumDurationInMinutes ?? defaultValues.rule1PaidBreaksMaximumDurationInMinutes,
    };

    return {
        ...rules,
        isPaidBreaksEnabled: rules?.rule1PaidBreaksMaximumDurationInMinutes > 0 || rules?.rule2PaidBreaksMaximumDurationInMinutes > 0,
    };
};

const getTimesheetSettingWithDefaultValues = (isEdit: boolean, timesheetSetting?: TimesheetSetting) => {
    const defaultValues = getDefaultValues(isEdit, timesheetSetting);
    const timesheetSettingValues = {
        ...timesheetSetting,
        isBonusEnabled: !areBonusValuesEqualsToDefaults(isEdit, timesheetSetting),
        nightBonusStartTime: timesheetSetting?.nightBonusStartTime ?? defaultValues.nightBonusStartTime,
        nightBonusEndTime: timesheetSetting?.nightBonusEndTime ?? defaultValues.nightBonusEndTime,
        saturdayBonusStartTime: timesheetSetting?.saturdayBonusStartTime ?? defaultValues.saturdayBonusStartTime,
        saturdayBonusEndTime: timesheetSetting?.saturdayBonusEndTime ?? defaultValues.saturdayBonusEndTime,
        saturdayFromDayOfWeek: timesheetSetting?.saturdayFromDayOfWeek ?? defaultValues.saturdayFromDayOfWeek,
        saturdayToDayOfWeek: timesheetSetting?.saturdayToDayOfWeek ?? defaultValues.saturdayToDayOfWeek,
        sundayBonusStartTime: timesheetSetting?.sundayBonusStartTime ?? defaultValues.sundayBonusStartTime,
        sundayBonusEndTime: timesheetSetting?.sundayBonusEndTime ?? defaultValues.sundayBonusEndTime,
        sundayFromDayOfWeek: timesheetSetting?.sundayFromDayOfWeek ?? defaultValues.sundayFromDayOfWeek,
        sundayToDayOfWeek: timesheetSetting?.sundayToDayOfWeek ?? defaultValues.sundayToDayOfWeek,
        isForceBreakToBeTakenEnabled: !!timesheetSetting?.forceBreakToBeTakenFrom && !!timesheetSetting?.forceBreakToBeTakenTo,
        forceBreakToBeTakenFrom: timesheetSetting?.forceBreakToBeTakenFrom ?? defaultValues.forceBreakToBeTakenFrom,
        forceBreakToBeTakenTo: timesheetSetting?.forceBreakToBeTakenTo ?? defaultValues.forceBreakToBeTakenTo,
        maximumWeeklyAdditionalWorkingTime:
            timesheetSetting?.maximumWeeklyAdditionalWorkingTime !== undefined
                ? timesheetSetting?.maximumWeeklyAdditionalWorkingTime / 60
                : defaultValues.maximumWeeklyAdditionalWorkingTime,
    };

    const timesheetSettingsRules = getTimesheetSettingRuleWithDefaultValues(timesheetSetting, defaultValues);
    //join the two objects together to form the timesheetSettingForm
    return {
        ...timesheetSettingValues,
        ...timesheetSettingsRules,
    };
};
