import { Label } from '@/domain/label/Label.model';
import { Survey, SurveyAnswer, SurveyAnswerMutation, SurveyQuestion } from '@/domain/survey/Survey.model';
import {
    createSurveyAnswer,
    getUserSurvey,
    isLickertScale,
    isNPS,
    isOpinionScale,
    isRenderAsMultipleChoice,
    isRenderAsSection,
    isRenderAsSingleChoice,
    isRenderAsText,
    updateSurveyAnswer,
} from '@/domain/survey/Survey.service';
import { handleError } from '@/utils/api.util';
import { UserLanguage } from '@/utils/language.util';
import Grid from '@mui/material/Grid';
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { EmployeeSurveyFooter } from './EmployeeSurveyFooter';
import { EmployeeSurveyHeader } from './EmployeeSurveyHeader';
import { SurveyAnswerMultiChoice } from './SurveyAnswerMultiChoice';
import { SurveyAnswerScale } from './SurveyAnswerScale';
import { SurveyAnswerSingleChoice } from './SurveyAnswerSingleChoice';
import { SurveyAnswerText } from './SurveyAnswerText';
import { SurveyCompleted } from './SurveyCompleted';
import { SurveyQuestionsAnswered } from './SurveyQuestionsAnswered';
import { SurveySection } from './SurveySection';
import { SurveyTokenError } from './SurveyTokenError';
import { WelcomePage } from './Welcome';
import { SurveyTemplate } from '@/domain/survey-template/Survey-template.model';

type Props = {
    token: string | undefined;
    selectedLanguage: UserLanguage;
    editingSurveyTemplate?: SurveyTemplate;
    onLanguageChange: (language: UserLanguage) => void;
};

export const EmployeeSurveyStepQuestions: FC<Props> = ({ token, selectedLanguage, editingSurveyTemplate, onLanguageChange }) => {
    const dispatch = useDispatch();
    const { surveyId } = useParams();
    const [activeStep, setActiveStep] = useState<number>(0);
    const [survey, setSurvey] = useState<Survey>();
    const [questions, setQuestions] = useState<SurveyQuestion[]>([]);
    const [answers, setAnswers] = useState<SurveyAnswer[]>([]);
    const [totalAnswers, setTotalAnswers] = useState<number>(0);
    const likertScaleLength = 5;

    // Specific useEffect when Preview Mode with editingSurveyTemplate props
    useEffect(() => {
        if (editingSurveyTemplate) {
            const allAnswers: SurveyAnswer[] = editingSurveyTemplate.questions.map(q => {
                return {
                    id: 0,
                    surveyId: editingSurveyTemplate.id,
                    surveyQuestionId: q.id,
                    anonymous: false,
                    skipped: false,
                    textAnswer: '',
                    items: [],
                    comment: '',
                };
            });
            setQuestions(editingSurveyTemplate.questions);
            setTotalAnswers(editingSurveyTemplate.questions.length);
            setActiveStep(0);
            setAnswers(allAnswers);
        }
    }, [editingSurveyTemplate]);

    // Specific useEffect when open survey with token via email
    useEffect(() => {
        if (token) {
            getUserSurvey(token)
                .then(data => {
                    const survey = data.survey;
                    const remainingQuestions = data.remainingQuestions;
                    setSurvey(survey);
                    setQuestions(remainingQuestions);
                    setTotalAnswers(data.questionCount);
                    if (remainingQuestions.length !== data.questionCount) {
                        setActiveStep(1);
                    }
                    const allAnswers: SurveyAnswer[] = remainingQuestions.map(q => {
                        return {
                            id: 0,
                            surveyId: survey.id,
                            surveyQuestionId: q.id,
                            anonymous: false,
                            skipped: false,
                            textAnswer: '',
                            items: [],
                            comment: '',
                        };
                    });
                    setAnswers(allAnswers);
                })
                .catch(error => {
                    handleError(error);
                });
        }
    }, [token, dispatch, surveyId]);
    const handleSaveAnswer = (mutation: SurveyAnswerMutation, id?: number) => {
        if (!token) {
            return;
        }
        if (id) {
            updateSurveyAnswer(id, mutation, token).then(data => {
                answers[activeStep - 1] = data;
                setAnswers([...answers]);
            });
        } else {
            createSurveyAnswer(mutation, token).then(data => {
                answers[activeStep - 1] = data;
                setAnswers([...answers]);
            });
        }
    };

    const saveAnswer = (skipped: boolean, activeStep: number) => {
        if (!answers) {
            return;
        }
        if (activeStep > 0) {
            const answer = answers[activeStep - 1];

            if (!answer) {
                return;
            }

            if (skipped) {
                answer.skipped = true;

                const skippedMutation: SurveyAnswerMutation = {
                    surveyQuestionId: answer.surveyQuestionId,
                    textAnswer: '',
                    anonymous: answer.anonymous,
                    skipped: true,
                    answerSurveyQuestionItemIds: [],
                    comment: '',
                };

                handleSaveAnswer(skippedMutation, answer.id);
            } else {
                const mutation: SurveyAnswerMutation = {
                    surveyQuestionId: answer.surveyQuestionId,
                    textAnswer: answer.textAnswer ?? '',
                    anonymous: answer.anonymous,
                    skipped: false,
                    answerSurveyQuestionItemIds: answer.items.map(i => i.id),
                    comment: answer.comment,
                };

                answer.skipped = false;
                handleSaveAnswer(mutation, answer.id);
            }
        }
    };

    const handleSubmit = (skipped: boolean) => {
        if (!(!token && activeStep === questions.length)) {
            setActiveStep(activeStep + 1);
            saveAnswer(skipped, activeStep);
        }
    };

    if (!survey && !!token) {
        return <SurveyTokenError />;
    }

    if (!questions) {
        return <></>;
    }

    const onTextAnswerUpdated = (answerIndex: number, content: string, isAnonymous: boolean, question: SurveyQuestion) => {
        if (!answers) {
            return;
        }
        answers[answerIndex].textAnswer = content;
        answers[answerIndex].anonymous = isAnonymous;
        answers[answerIndex].surveyQuestionId = question.id;
        setAnswers([...answers]);
    };

    const onSingleChoiceAnswerUpdated = (answerIndex: number, selected: number, question: SurveyQuestion) => {
        if (!answers) {
            return;
        }
        const selectedOption = answers[answerIndex].items;
        selectedOption?.splice(0, 1);
        if (selectedOption) {
            selectedOption.push({
                label: undefined as unknown as Label,
                order: 0,
                score: 0,
                imageUrl: '',
                id: selected,
            });
        }
        answers[answerIndex].items = selectedOption;
        answers[answerIndex].surveyQuestionId = question.id;
        setAnswers([...answers]);
        handleSubmit(false);
    };

    const onMultiChoiceAnsweredUpdated = (answerIndex: number, checked: boolean, selected: number, question: SurveyQuestion) => {
        if (!answers) {
            return;
        }
        const selectedOption = answers[answerIndex].items;
        if (checked) {
            if (selectedOption) {
                selectedOption.push({
                    label: undefined as unknown as Label,

                    order: 0,
                    score: 0,
                    imageUrl: '',
                    id: selected,
                });
            }
        } else {
            if (!selectedOption) {
                return;
            }
            const selectedOptionIndex = selectedOption.findIndex(o => o.id === selected);
            selectedOption?.splice(selectedOptionIndex, 1);
        }

        answers[answerIndex].items = selectedOption;
        answers[answerIndex].surveyQuestionId = question.id;
        setAnswers([...answers]);
    };

    const onScaleAnswerUpdated = (answerIndex: number, selected: number, question: SurveyQuestion) => {
        if (!answers) {
            return;
        }
        const selectedOption = answers[answerIndex].items;
        selectedOption?.splice(0, 1);
        if (selectedOption) {
            selectedOption.push({
                label: undefined as unknown as Label,
                order: 0,
                score: 0,
                imageUrl: '',
                id: selected,
            });
        }
        answers[answerIndex].items = selectedOption;
        answers[answerIndex].surveyQuestionId = question.id;

        setAnswers([...answers]);
        if (question.items.length <= 5) {
            handleSubmit(false);
        }
    };

    const handleBack = () => {
        setActiveStep(currentStep => currentStep - 1);
    };
    const renderFooterOrCompletedPage = () => {
        if (survey && activeStep === questions.length + 1) {
            return <SurveyCompleted />;
        } else if (questions.length) {
            return (
                <EmployeeSurveyFooter
                    activeStep={activeStep}
                    onSubmit={(skipped: boolean) => handleSubmit(skipped)}
                    onBack={() => handleBack()}
                    questionCount={totalAnswers}
                    onLanguageChange={onLanguageChange}
                    token={token ?? ''}
                    isPreviewMode={!token}
                    answers={answers}
                    questions={questions}
                    survey={survey}
                    selectedLanguage={selectedLanguage}
                />
            );
        }
    };

    const showActiveQuestion = (index: number, question: SurveyQuestion) => {
        if (activeStep === index + 1) {
            if (isRenderAsText(question.type)) {
                return (
                    <SurveyAnswerText
                        key={index}
                        question={question}
                        answer={answers[index]}
                        selectedLanguage={selectedLanguage}
                        onSubmit={() => handleSubmit(false)}
                        onUpdate={(content: string, isAnonymous: boolean) => {
                            onTextAnswerUpdated(index, content, isAnonymous, question);
                        }}
                    />
                );
            } else if (isRenderAsSection(question.type)) {
                return <SurveySection key={index} question={question} selectedLanguage={selectedLanguage} />;
            } else if (isRenderAsSingleChoice(question.type)) {
                return (
                    <SurveyAnswerSingleChoice
                        key={index}
                        question={question}
                        answer={answers[index]}
                        selectedLanguage={selectedLanguage}
                        onSelect={(selected: number) => {
                            onSingleChoiceAnswerUpdated(index, selected, question);
                        }}
                    />
                );
            } else if (isRenderAsMultipleChoice(question.type)) {
                return (
                    <SurveyAnswerMultiChoice
                        key={index}
                        question={question}
                        answer={answers[index]}
                        selectedLanguage={selectedLanguage}
                        onSelect={(selected: number, checked: boolean) => {
                            onMultiChoiceAnsweredUpdated(index, checked, selected, question);
                        }}
                    />
                );
            } else if (isLickertScale(question.type) || isOpinionScale(question.type) || isNPS(question.type)) {
                return question.items.length > likertScaleLength ? (
                    <SurveyAnswerScale
                        key={index}
                        selectedLanguage={selectedLanguage}
                        question={question}
                        answer={answers[index]}
                        onSelect={(selected: number) => {
                            onScaleAnswerUpdated(index, selected, question);
                        }}
                    />
                ) : (
                    <SurveyAnswerSingleChoice
                        key={index}
                        question={question}
                        answer={answers[index]}
                        selectedLanguage={selectedLanguage}
                        onSelect={(selected: number) => {
                            onScaleAnswerUpdated(index, selected, question);
                        }}
                    />
                );
            }
        }
    };

    const renderQuestions = () => {
        if (!questions.length) {
            return <SurveyQuestionsAnswered />;
        } else {
            return questions.map((question: SurveyQuestion, index: number) => showActiveQuestion(index, question));
        }
    };
    return (
        <Grid
            item
            sx={theme => ({
                paddingBottom: 100,
                [theme.breakpoints.down('md')]: {
                    background: '#fff',
                    minHeight: '100vh',
                },
            })}
        >
            {questions.length ? <EmployeeSurveyHeader /> : undefined}
            {questions.length && activeStep === 0 ? (
                <WelcomePage isPreviewMode={!token} selectedLanguage={selectedLanguage} survey={survey} surveyTemplate={editingSurveyTemplate} />
            ) : (
                renderQuestions()
            )}
            {renderFooterOrCompletedPage()}
        </Grid>
    );
};
