import { ClockInOut } from '@/components/clock-in-out/ClockInOut';
import { EmployeeSearch } from '@/components/employee-search/EmployeeSearch';
import { UserAccess } from '@/page/layout/UserAccess';
import { AppBar, AppBarProps, Stack, Toolbar } from '@mui/material';
import { FC } from 'react';

import { canClockInOut, canViewOtherEmployeeBasicInfo } from '@/domain/permission/Permission.service';
import { hasRealmFeatureEnabled } from '@/domain/realm/Realm.service';
import { HeaderBackButton } from '@/page/layout/HeaderBackButton';
import { useGetTimesheetSettingById } from '@/page/setting/time-management/TimesheetSettings.hook';
import { useAppSelector } from '@/stores/store';
import { desktopVisible } from '@/theme/responsive';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { RealmFeaturesType } from '@/domain/realm/Realm.model';
import { isMobileDeviceFromUserAgent } from '@/utils/device.util';

export const Header: FC<AppBarProps> = ({ ...rest }) => {
    const { t } = useTranslation();
    const grantedPolicies = useAppSelector(state => state.currentEmployee.grantedPolicies);
    const currentEmployeeId = useAppSelector(state => state.currentEmployee.employee?.id);
    const params = useParams();
    const profileEmployeeId = Number(params.employeeId) || undefined;
    const currentTimesheetSettingId = useAppSelector(state => state.currentEmployee.employee?.currentWorkingPattern?.timesheetSetting?.id);
    const realm = useAppSelector(state => state.ui.currentRealm);
    const breadCrumb = useAppSelector(state => state.ui.breadCrumb);

    const { data: timesheetSetting } = useGetTimesheetSettingById(currentTimesheetSettingId);

    if (!currentEmployeeId) {
        return;
    }

    const canViewAtLeastOneEmployee = canViewOtherEmployeeBasicInfo(grantedPolicies, currentEmployeeId);

    const getHeaderTitle = (): string => {
        const page = breadCrumb?.[breadCrumb.length - 1];
        if (page?.label) {
            return page?.label;
        }
        return page?.nameKey ? t(page?.nameKey) : '';
    };

    const clockInOutEnabled = () => {
        if (!realm || !currentEmployeeId) {
            return false;
        }
        return (
            canClockInOut(grantedPolicies, currentEmployeeId) &&
            hasRealmFeatureEnabled(realm.realmFeatures, RealmFeaturesType.CLOCK_IN_OUT) &&
            canClockInOutMobile()
        );
    };

    function canClockInOutMobile(): boolean {
        if (!timesheetSetting) {
            return false;
        }
        return isMobileDeviceFromUserAgent() ? timesheetSetting?.mobileClockInOut : true;
    }

    return (
        <AppBar
            // header is white
            color='inherit'
            elevation={0}
            {...rest}
        >
            <Toolbar variant={'dense'}>
                <Stack direction='row' justifyContent='space-between' alignItems='center' flex='1'>
                    <HeaderBackButton title={getHeaderTitle()} />

                    <Stack direction='row' alignItems='center' spacing={2}>
                        {clockInOutEnabled() && <ClockInOut currentEmployeeId={currentEmployeeId} />}
                        <Stack direction='row' alignItems='center' spacing={2} sx={desktopVisible}>
                            {canViewAtLeastOneEmployee && <EmployeeSearch employeeId={profileEmployeeId} />}
                            <UserAccess />
                        </Stack>
                    </Stack>
                </Stack>
            </Toolbar>
        </AppBar>
    );
};
