import { departmentAPI } from '@/api/department/Department.api';
import {
    Department,
    DepartmentCreationMutation,
    DepartmentNode,
    DepartmentNodeOrganization,
    DepartmentNodesSearchRequest,
    DepartmentUpdateMutation,
} from '@/domain/department/Department.model';
import { employeeAPI } from '@/api/employee/Employee.api';
import { Employee, EmployeeAvatar } from '@/domain/employee/Employee.model';

export function getDepartments(): Promise<Department[]> {
    return departmentAPI.getDepartments();
}

export const getDepartmentNodes = (search: DepartmentNodesSearchRequest = {}): Promise<DepartmentNode[]> => {
    return departmentAPI.getDepartmentNodes(search);
};

export const createDepartment = (mutation: DepartmentCreationMutation): Promise<Department> => {
    return departmentAPI.createDepartment(mutation);
};

export const updateDepartment = (departmentId: number, mutation: DepartmentUpdateMutation): Promise<Department> => {
    return departmentAPI.updateDepartment(departmentId, mutation);
};

export const deleteDepartment = async (departmentId: number): Promise<void> => {
    await departmentAPI.deleteDepartment(departmentId);
};

export const getDepartmentNodeOrganizations = async (search: DepartmentNodesSearchRequest = {}): Promise<DepartmentNodeOrganization[]> => {
    const [departmentNodes, employees] = await Promise.all([departmentAPI.getDepartmentNodes(search), employeeAPI.searchEmployees()]);

    return buildDepartmentNodeOrganizations(departmentNodes, employees);
};

const buildDepartmentNodeOrganizations = (nodes: DepartmentNode[], employees: Employee[]): DepartmentNodeOrganization[] => {
    const employeesMapByDepartment = employees
        .filter(employee => getCurrentDepartments(employee).length > 0) // Ensure the employee has departments
        .flatMap(employee => getCurrentDepartments(employee).map(department => ({ department, employee })))
        .reduce((map, { department, employee }) => {
            if (!map.has(department.id)) {
                map.set(department.id, new Set<EmployeeAvatar>());
            }
            map.get(department.id)?.add(employee);
            return map;
        }, new Map<number, Set<EmployeeAvatar>>());

    return mapEmployeesToDepartmentNodeOrganizations(nodes, employeesMapByDepartment);
};

const mapEmployeesToDepartmentNodeOrganizations = (
    nodes: DepartmentNode[],
    employeesMapByDepartment: Map<number, Set<EmployeeAvatar>>,
): DepartmentNodeOrganization[] => {
    return nodes.map(node => {
        const employees = Array.from(employeesMapByDepartment.get(node.id) || []);
        const children = mapEmployeesToDepartmentNodeOrganizations(node.children, employeesMapByDepartment);
        return { ...node, employees, children };
    });
};

const getCurrentDepartments = (employee: Employee): Department[] => {
    return employee.currentEmployments.map(employment => employment.department);
};
