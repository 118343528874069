import { RecursiveValue } from '@/components/tree-select/TreeSelect';

export const findItemById = (items: RecursiveValue[], id: number): RecursiveValue | undefined => {
    for (const item of items) {
        if (item.id === id) {
            return item;
        }
        if (item.children.length > 0) {
            const found = findItemById(item.children, id);
            if (found) {
                return found;
            }
        }
    }
};

export const findParents = (items: RecursiveValue[], id: number): RecursiveValue[] => {
    return items.reduce<RecursiveValue[]>((acc, currentValue) => {
        if (currentValue.children.some(child => child.id === id)) {
            return [currentValue, ...acc];
        }
        if (currentValue.children.length > 0) {
            const found = findParents(currentValue.children, id);
            if (found.length > 0) {
                return [currentValue, ...found];
            }
        }
        return acc;
    }, []);
};
