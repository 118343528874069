import { AgGridWrapper, RogerColDef } from '@/components/ag-grid-wrapper/AgGridWrapper';
import { getTableHeight } from '@/components/ag-grid-wrapper/AgGridWrapper.util';
import { getFieldDefinitionTranslation } from '@/components/ag-grid-wrapper/column-types/columnTypes';
import { DialogWrapper, DialogWrapperProps } from '@/components/dialog-wrapper/DialogWrapper';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { ContractType, Employment, EmploymentCreateReason } from '@/domain/employment/Employment.model';
import { deleteContract } from '@/domain/employment/Employment.service';
import { useGetContracts } from '@/hooks/employment/Employment.hook';
import { EmploymentDates } from '@/page/employee-profile/employee-profile-info/EmploymentSection/EmploymentsHistoryDialog';
import { formatInDefaultDate, isBeforeDate } from '@/utils/datetime.util';
import { DialogContent, Stack } from '@mui/material';
import { t } from 'i18next';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ICellRendererParams } from '@ag-grid-community/core';
import { BasicMenu, BasicMenuItem } from '@/components/basic-menu/BasicMenu';
import { MoreVerticalIcon } from 'hugeicons-react';
import { ModifyContractDialog } from '@/page/employee-profile/employee-profile-info/EmployeeContractSection/ModifyContractDialog';
import { handleError } from '@/utils/api.util';

type ContractsHistoryDialogProps = {
    employeeId: number;
    onContractChange?: () => void;
} & DialogWrapperProps;
export const ContractsHistoryDialog: FC<ContractsHistoryDialogProps> = ({ employeeId, onContractChange, ...restDialog }) => {
    const { t } = useTranslation();
    const [contractToUpdate, setContractToUpdate] = useState<Employment>();

    const {
        data: contracts = [],
        isLoading: isContractsLoading,
        isError: isContractsError,
        error: contractsError,
        refetch: refetchContracts,
    } = useGetContracts({ employeeId: employeeId });

    const sortedContracts = [...contracts].sort((a, b) => (isBeforeDate(a.startDate, b.startDate) ? 1 : -1));
    const tableHeight = getTableHeight({ rowsLength: contracts.length, disableFooter: true });

    const onMutationSuccess = async () => {
        await refetchContracts();
        onContractChange?.();
    };

    const handleContractUpdated = () => {
        setContractToUpdate(undefined);
        onMutationSuccess().catch(handleError);
    };

    const cellActionRenderer = (params: ICellRendererParams<Employment>) => {
        const contract = params.data;
        if (!contract) {
            return;
        }

        const handleDeleteContract = async (contract: Employment) => {
            try {
                await deleteContract(contract.id);
                await onMutationSuccess();
            } catch (error) {
                handleError(error);
            }
        };

        const deleteActionItem: BasicMenuItem = {
            title: t('general.delete'),
            onClick: () => {
                handleDeleteContract(contract).catch(handleError);
            },
            confirmationRequired: true,
        };

        // only able to delete rehired contracts
        const isDeletable = contract.employmentCreateReason === EmploymentCreateReason.REHIRED;

        return (
            <BasicMenu
                items={[
                    {
                        title: t('general.edit'),
                        onClick: () => {
                            setContractToUpdate(contract);
                        },
                    },
                    ...(isDeletable ? [deleteActionItem] : []),
                ]}
                endIcon={<MoreVerticalIcon />}
            />
        );
    };
    const actionMenu = {
        type: 'actionMenu',
        cellRenderer: cellActionRenderer,
    };

    return (
        <DialogWrapper header={t('employee.contract.history.title')} maxWidth='lg' {...restDialog}>
            <DialogContent sx={{ pb: 2 }}>
                <Stack width='100%' minHeight={100} height={tableHeight}>
                    <StateHandler isLoading={isContractsLoading} isError={isContractsError} error={contractsError} isEmpty={!contracts.length}>
                        <AgGridWrapper rowData={sortedContracts} columnDefs={getContractColumnDefs(actionMenu)} compact statusBar={undefined} />
                    </StateHandler>
                </Stack>
                {contractToUpdate && (
                    <ModifyContractDialog
                        open={true}
                        onClose={() => setContractToUpdate(undefined)}
                        onSave={handleContractUpdated}
                        contract={contractToUpdate}
                        existingContracts={contracts}
                    />
                )}
            </DialogContent>
        </DialogWrapper>
    );
};

const getContractColumnDefs = (actionMenu: RogerColDef<Employment>): RogerColDef<Employment>[] => {
    return [
        {
            field: 'startDate',
            headerName: t('employee.contract.history.effective_date'),
            valueFormatter: ({ value }: { value: Date }) => formatInDefaultDate(value),
            cellRenderer: ({ data }) => (data ? <EmploymentDates employment={data} /> : ''),
            cellClass: ['display-flex'],
        },
        {
            field: 'contractType',
            headerName: getFieldDefinitionTranslation({ fieldType: 'EMPLOYMENT_CONTRACT_TYPE' }),
            valueFormatter: ({ value }: { value: ContractType }) => t('employee.employment.contract_type', { context: value }),
        },
        {
            field: 'probationEndDate',
            headerName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_PROBATION_END_DATE' }),
            type: 'date',
        },
        {
            field: 'endDate',
            headerName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_CONTRACT_END_DATE' }),
            type: 'date',
        },
        {
            field: 'terminationNoticeDate',
            headerName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_TERMINATION_NOTICE_DATE' }),
            type: 'date',
        },
        {
            field: 'terminationLastDayAtWork',
            headerName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_TERMINATION_LAST_DAY' }),
            type: 'date',
        },
        {
            field: 'terminationType',
            headerName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_TERMINATION_TYPE' }),
            valueFormatter: ({ value }) => (value ? t('employee.employment.termination_type.enum', { context: value }) : ''),
        },
        {
            field: 'terminationReason',
            headerName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_TERMINATION_REASON' }),
            valueFormatter: ({ value }) => (value ? t('employee.employment.termination_reason.enum', { context: value }) : ''),
        },
        {
            field: 'terminationComment',
            headerName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_TERMINATION_COMMENT' }),
        },
        actionMenu,
    ];
};
