import { WeeklyWorkingTime } from '@/domain/weekly-working-time/WeeklyWorkingTime.model';
import { WorkingPatternTemplate } from '@/domain/working-pattern-template/WorkingPatternTemplate.model';
import { TimesheetSetting } from '@/domain/timesheet-setting/TimesheetSetting.model';
import { DayOfWeek } from '@/utils/datetime.util';

import { DayPeriod } from '../date/Date.model';
import { Calendar } from '@/domain/calendar/Calendar.model';

export type EmployeeWorkingPattern = {
    id: number;
    employeeId: number;
    startDate: LocalDate;
    endDate: LocalDate;
    type: EmployeeWorkingPatternType;
    rate?: number;
    weeklyWorkingTime?: WeeklyWorkingTime;
    workingPatternTemplate?: WorkingPatternTemplate;
    workingDays: EmployeeWorkingDay[];
    timesheetSetting: TimesheetSetting;
    averageDailyWorkingTime: number;
    calendar: Calendar;
};

export enum EmployeeWorkingPatternType {
    FIXED = 'FIXED',
    RATE = 'RATE',
    TEMPLATE = 'TEMPLATE',
    HOURLY = 'HOURLY',
}

export type EmployeeWorkingDay = WorkingDay & {
    id: number;
};

export type EmployeeWorkingPatternTemplateCreationMutation = {
    employeeId: number;
    startDate: LocalDate;
    type: EmployeeWorkingPatternType;
    rate?: number;
    weeklyWorkingTimeId?: number;
    workingPatternTemplateId?: number;
    workingDays?: WorkingDay[];
    timesheetSettingId?: number;
    calendarId: number;
};

export type EmployeeWorkingPatternUpdateMutation = Omit<EmployeeWorkingPatternTemplateCreationMutation, 'employeeId'>;

export type EmployeeWorkingPatternSearchRequest = {
    employeeIds: number[];
};

export type EmployeeWorkingPatternTemplate = {
    id: number;
    employeeId: number;
    startDate: LocalDate;
    endDate: LocalDate;
    type: EmployeeWorkingPatternType;
    weeklyWorkingTime: WeeklyWorkingTime;
    workingPatternTemplate: WorkingPatternTemplate;
    workingDays: EmployeeWorkingDay[];
    averageDailyWorkingTime: number;
    totalWeeklyWorkingTimeInMinutes: number;
    totalWeeklyWorkingDays: number;
};

export type WorkingDay = {
    dayOfWeek: DayOfWeek;
    amountWorked: number;
    dayPeriod: DayPeriod;
};
