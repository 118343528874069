import { UnitType } from '@/domain/date/Date.model';
import { LeaveTransaction } from '@/domain/leave-transaction/LeaveTransaction.model';
import { AllowanceType, LeaveTransactionType, LeaveType } from '@/domain/leave-type/LeaveType.model';
import { Paper, Stack } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AgGridWrapper } from '@/components/ag-grid-wrapper/AgGridWrapper';
import { useAgGridWrapper } from '@/components/ag-grid-wrapper/useAgGridWrapper';
import { RequestStatusChip } from '@/components/request-status-chip/RequestStatusChip';
import { EmployeeWorkingPattern } from '@/domain/employee-working-pattern/EmployeeWorkingPattern.model';
import { EmployeePolicy } from '@/domain/employee/Employee.model';
import { LeaveCorrection, LeaveCorrectionCreateMutation, LeaveCorrectionUpdateMutation } from '@/domain/leave-correction/LeaveCorrection.model';
import { leaveCorrectionService } from '@/domain/leave-correction/LeaveCorrection.service';
import { LeaveRequest } from '@/domain/leave-request/LeaveRequest.model';
import {
    canDeclineLeaveRequest,
    canLeaveRequestBeApproved,
    convertLeavesMinutesToUnit,
    deleteLeaveRequest,
    getLeaveRequestDurationFormatted,
    getLeaveRequestPeriodAsString,
    isLeaveTypeHours,
    isLeaveTypeMedical,
} from '@/domain/leave-request/LeaveRequest.service';
import { getAllowanceText } from '@/domain/leave-type/LeaveType.service';
import { canCorrectEmployeeBalance, canEditLeaveRequest, hasConfigureLeavePolicy } from '@/domain/permission/Permission.service';
import { getEmployeeWorkingPatternTitle } from '@/domain/working-pattern-template/WorkingPatternTemplate.service';
import { EmployeeFieldMoreButton } from '@/page/employee-profile/employee-profile-info/EmployeeFieldMoreButton/EmployeeFieldMoreButton';
import { LeaveCorrectionDialog, LeaveCorrectionValues } from '@/page/leave/leave-correction-dialog/LeaveCorrectionDialog';
import { LeaveRequestDialog } from '@/page/leave/leave-request-dialog/LeaveRequestDialog';
import { handleError } from '@/utils/api.util';
import { compareAsc, formatDate, formatInDefaultDate, subYears } from '@/utils/datetime.util';
import { showSnackbar } from '@/utils/snackbar.util';
import { ColDef, ICellRendererParams } from '@ag-grid-community/core';

type Props = {
    employeeId: number;
    workingPattern?: EmployeeWorkingPattern;
    leaveType: LeaveType;
    transactions: LeaveTransaction[];
    // TODO: [RP-2300] 3 grid with a lot of different columns use the same component.We must split it into 3 components
    historyType: string;
    onApproveClicked?: (leaveRequest: LeaveRequest) => void;
    onDeclineClicked?: (leaveRequest: LeaveRequest) => void;
    onCancelClicked?: (leaveRequest: LeaveRequest) => void;
    onDeleteClicked?: (id: number) => void;
    approveEnabled?: boolean;
    declineEnabled?: boolean;
    policies?: EmployeePolicy[];
    loadHistory: () => void;
    displayUnitType: UnitType;
};

export const LeaveTransactionsTable: FC<Props> = ({
    employeeId,
    workingPattern,
    leaveType,
    transactions,
    historyType,
    onApproveClicked,
    onDeclineClicked,
    onCancelClicked,
    approveEnabled,
    declineEnabled,
    policies,
    onDeleteClicked,
    loadHistory,
    displayUnitType,
}) => {
    const { t } = useTranslation();

    const agGridWrapper = useAgGridWrapper<LeaveTransaction>();

    const [leaveRequestToUpdate, setLeaveRequestToUpdate] = useState<LeaveRequest>();
    const [leaveCorrection, setLeaveCorrection] = useState<LeaveCorrection>();

    useEffect(() => {
        agGridWrapper.gridRef.current?.api?.refreshCells();
    }, [agGridWrapper.gridRef, transactions]);

    const isLeaveRequestCanBeCancelled = (leaveRequest: LeaveRequest): boolean => {
        return leaveRequest.requestStatus !== 'CANCELLED' && leaveRequest.requestStatus !== 'DECLINED';
    };

    const getFormattedLeaveTransactionAmount = (leaveTransaction: LeaveTransaction, useAbsoluteValue = false) => {
        const convertedTime = convertLeavesMinutesToUnit({
            input: displayUnitType === UnitType.DAYS ? leaveTransaction.amountInDays : leaveTransaction.amountInMinutes,
            outputUnit: displayUnitType,
            roundingType: leaveType.roundingType,
            useAbsoluteValue: useAbsoluteValue,
        });

        return t('duration.formatDuration', {
            duration: convertedTime,
            unit: displayUnitType,
        });
    };

    const getAmountWordingByType = (historyType: string) => {
        switch (historyType) {
            case 'daysGranted':
                return t('domain.leave_request.amount');
            case 'adjustments':
                return t('domain.leave_request.amount');
            case 'requests':
                return t('domain.leave_request.amount_used');
            default:
                return '';
        }
    };

    const handleLeaveRequestDelete = (requestId: number | undefined) => {
        if (!requestId) {
            return;
        }
        deleteLeaveRequest(requestId).then(loadHistory).catch(handleError);
    };

    // @JessyBAER - it's weird to have two different renderers for the same thing
    const cellActionNotAdjustmentRenderer = ({ data }: ICellRendererParams<LeaveTransaction>) => (
        <EmployeeFieldMoreButton
            editDisabled={policies && !canCorrectEmployeeBalance(policies, employeeId) && !workingPattern}
            onEditClicked={() => {
                if (!data) {
                    return;
                }
                setLeaveCorrection(data.leaveCorrection);
            }}
            deleteEnabled={policies && canCorrectEmployeeBalance(policies, employeeId)}
            onDeleteClicked={() => {
                if (!data?.leaveCorrection?.id) {
                    return;
                }
                onDeleteClicked?.(data.leaveCorrection.id);
            }}
            approveEnabled={false}
            declineEnabled={false}
            cancelEnabled={false}
        />
    );

    const cellActionNotRequestRenderer = ({ data }: ICellRendererParams<LeaveTransaction>) => {
        if (!data?.leaveRequest) {
            return;
        }
        return (
            <EmployeeFieldMoreButton
                onEditClicked={() => {
                    setLeaveRequestToUpdate(data?.leaveRequest);
                }}
                onDeleteClicked={() => handleLeaveRequestDelete(data?.leaveRequest?.id)}
                onApproveClicked={() => {
                    if (!data?.leaveRequest) {
                        return;
                    }
                    onApproveClicked?.(data.leaveRequest);
                }}
                onDeclineClicked={() => {
                    if (!data?.leaveRequest) {
                        return;
                    }
                    onDeclineClicked?.(data.leaveRequest);
                }}
                onCancelClicked={() => {
                    if (!data?.leaveRequest) {
                        return;
                    }
                    onCancelClicked?.(data.leaveRequest);
                }}
                approveEnabled={approveEnabled && canLeaveRequestBeApproved(data.leaveRequest)}
                declineEnabled={declineEnabled && canDeclineLeaveRequest(data.leaveRequest)}
                deleteEnabled={policies && data?.leaveRequest?.requestStatus === 'CANCELLED' && hasConfigureLeavePolicy(policies)}
                cancelEnabled={isLeaveRequestCanBeCancelled(data?.leaveRequest)}
                editDisabled={policies && !canEditLeaveRequest(data?.leaveRequest?.requestStatus, policies, employeeId)}
            />
        );
    };

    const columnDefs: ColDef<LeaveTransaction>[] = [
        {
            field: 'transactionStartDate',
            headerName: t('my_leaves_history.table_headers.applied_on'),
            valueFormatter: data => {
                if (!data?.data) {
                    return '';
                }
                return `${formatInDefaultDate(data.data.transactionStartDate)} ${
                    data.data.leaveTransactionType !== LeaveTransactionType.CYCLE_CARRYOVER
                        ? `- ${data.data.transactionEndDate && formatInDefaultDate(data.data.transactionEndDate)}`
                        : ''
                }`;
            },

            hide: historyType !== 'daysGranted',
            width: 200,
        },
        {
            field: 'amountInMinutes',
            headerName: getAmountWordingByType(historyType),
            valueFormatter: ({ data }) => {
                if (!data) {
                    return '';
                }
                return getFormattedLeaveTransactionAmount(data);
            },
            hide: historyType !== 'daysGranted',
        },
        {
            headerName: t('my_leaves_history.table_headers.note'),
            valueFormatter: ({ data }) => {
                if (!data) {
                    return '';
                }
                const isCycleCarryover = data.leaveTransactionType === LeaveTransactionType.CYCLE_CARRYOVER;
                const carryoverText = `${t('my_leaves_history.carryover')}: ${formatDate(subYears(data.transactionStartDate, 1), 'yyyy')}`;

                if (isCycleCarryover) {
                    return carryoverText;
                }
                const policyNameText = `${t('my_leaves_history.table_headers.policy_name')} ${data.policy?.name}`;
                const allowanceText = getAllowanceText(data.policy, data.policy?.leaveType?.unitType ?? leaveType?.unitType, leaveType.roundingType);
                const workingPatternTitle = getEmployeeWorkingPatternTitle(data?.workingPattern, t);

                return `${policyNameText}: ${allowanceText} / ${workingPatternTitle}`;
            },
            hide: historyType !== 'daysGranted',
            flex: 1,
            maxWidth: 500,
        },
        {
            field: 'transactionStartDate',
            headerName: t('my_leaves_history.table_headers.effective_date'),
            type: 'date',
            hide: historyType !== 'adjustments',
        },
        {
            field: 'amountInMinutes',
            headerName: getAmountWordingByType(historyType),
            valueFormatter: ({ data }) => {
                if (!data) {
                    return '';
                }
                return getFormattedLeaveTransactionAmount(data, false);
            },
            hide: historyType !== 'adjustments',
        },
        {
            field: 'comment',
            headerName: t('my_leaves_history.table_headers.comment'),
            hide: historyType !== 'adjustments',
        },
        {
            field: 'createdBy.displayName',
            headerName: t('my_leaves_history.table_headers.adjustedBy'),
            hide: historyType !== 'adjustments',
        },
        {
            field: 'createdAt',
            headerName: t('my_leaves_history.table_headers.adjusted_on'),
            type: 'date',
            hide: historyType !== 'adjustments',
        },
        {
            type: 'actionMenu',
            cellRenderer: cellActionNotAdjustmentRenderer,
            hide: historyType !== 'adjustments',
        },
        {
            field: 'leaveRequest.requestStatus',
            headerName: t('my_leaves_history.table_headers.status'),
            cellRenderer: RequestStatusChip,
            cellRendererParams: ({ value }: { value: string }) => ({ status: value }),
            hide: historyType !== 'requests',
        },
        {
            field: 'leaveRequest',
            headerName: t('my_leaves_history.table_headers.period'),
            sortable: true,
            comparator: (a: LeaveRequest, b: LeaveRequest) => compareAsc(a?.startDate, b.startDate),
            valueFormatter: ({ data }) => (data?.leaveRequest ? getLeaveRequestPeriodAsString(data.leaveRequest) : ''),
            hide: historyType !== 'requests',
            width: 400,
        },
        {
            field: 'amountInMinutes',
            valueFormatter: ({ data }) => {
                if (!data) {
                    return '';
                }
                return getFormattedLeaveTransactionAmount(data);
            },
            headerName: getAmountWordingByType(historyType),
            hide: !(historyType === 'requests' && leaveType.allowanceType === AllowanceType.UNLIMITED && !isLeaveTypeHours(leaveType.unitType)),
        },
        {
            field: 'amountInMinutes',
            valueFormatter: ({ data }) => {
                if (!data) {
                    return '';
                }
                return getFormattedLeaveTransactionAmount(data);
            },
            headerName: getAmountWordingByType(historyType),
            hide: historyType !== 'requests' || !isLeaveTypeHours(leaveType.unitType),
        },
        {
            field: 'amountInMinutes',
            valueFormatter: ({ data }) => {
                if (!data) {
                    return '';
                }
                return getFormattedLeaveTransactionAmount(data);
            },
            headerName: getAmountWordingByType(historyType),
            hide: !(historyType === 'requests' && leaveType.allowanceType !== AllowanceType.UNLIMITED && !isLeaveTypeHours(leaveType.unitType)),
        },
        {
            field: 'leaveRequest.durationInMinutes',
            valueFormatter: ({ data }) => {
                if (!data?.leaveRequest) {
                    return '';
                }
                return getLeaveRequestDurationFormatted(data.leaveRequest);
            },
            headerName: t('general.duration'),
            hide: historyType !== 'requests' || !isLeaveTypeMedical(leaveType.leaveActivityType),
        },
        {
            field: 'leaveRequest.leavePercentage',
            valueFormatter: ({ value }: { value: number }) => {
                if (!value) {
                    return '';
                }
                return `${value}%`;
            },
            headerName: t('general.incapacity'),
            hide: historyType !== 'requests' || !isLeaveTypeMedical(leaveType.leaveActivityType),
        },
        {
            field: 'comment',
            headerName: t('my_leaves_history.table_headers.comment'),
            hide: historyType !== 'requests',
        },
        {
            field: 'createdBy.displayName',
            headerName: t('my_leaves_history.table_headers.requestedBy'),
            hide: historyType !== 'requests',
        },
        {
            field: 'createdAt',
            type: 'date',
            headerName: t('my_leaves_history.table_headers.requested_on'),
            hide: historyType !== 'requests',
        },
        {
            type: 'actionMenu',
            cellRenderer: cellActionNotRequestRenderer,
            hide: historyType !== 'requests',
        },
        {
            field: 'leaveRequest.statusUpdatedBy.displayName',
            headerName: t('my_leaves_history.table_headers.status_updated_by'),
            hide: historyType !== 'requests',
        },
        {
            field: 'leaveRequest.statusUpdatedAt',
            type: 'date',
            headerName: t('my_leaves_history.table_headers.status_updated_on'),
            hide: historyType !== 'requests',
        },
    ];

    const handleLeaveCorrectionCreate = async (values: LeaveCorrectionValues, employeeId: number) => {
        const { leaveType, ...rest } = values;
        const mutation: LeaveCorrectionCreateMutation = {
            ...rest,
            employeeId: employeeId,
            leaveTypeId: leaveType.id,
        };
        try {
            await leaveCorrectionService.createLeaveCorrection(mutation);
            setLeaveCorrection(undefined);
            showSnackbar(t('add_correction_dialog.messages.correction_added'), 'success');
        } catch (error) {
            handleError(error);
        }
    };

    const handleLeaveCorrectionUpdate = async (id: number, values: LeaveCorrectionValues) => {
        const { leaveType: _, ...rest } = values;
        const mutation: LeaveCorrectionUpdateMutation = rest;
        try {
            await leaveCorrectionService.updateLeaveCorrection(id, mutation);
            showSnackbar(t('add_correction_dialog.messages.correction_updated'), 'success');
            loadHistory();
            setLeaveCorrection(undefined);
        } catch (error) {
            handleError(error);
        }
    };

    const handleLeaveCorrectionDialogSave = (values: LeaveCorrectionValues) => {
        if (leaveCorrection?.id) {
            handleLeaveCorrectionUpdate(leaveCorrection.id, values).catch(handleError);
        } else {
            handleLeaveCorrectionCreate(values, transactions[0]?.employeeId).catch(handleError);
        }
    };

    return (
        <Stack component={Paper} flex={1} width='100%'>
            <AgGridWrapper<LeaveTransaction> rowData={transactions} columnDefs={columnDefs} initRef={agGridWrapper.setGridRef} />

            {leaveRequestToUpdate && (
                <LeaveRequestDialog
                    open={true}
                    onClose={() => setLeaveRequestToUpdate(undefined)}
                    onSave={() => setLeaveRequestToUpdate(undefined)}
                    employeeId={leaveRequestToUpdate.employee.id}
                    leaveRequestId={leaveRequestToUpdate.id}
                />
            )}
            {leaveCorrection && workingPattern && (
                <LeaveCorrectionDialog
                    open={true}
                    onSave={handleLeaveCorrectionDialogSave}
                    onClose={() => setLeaveCorrection(undefined)}
                    leaveCorrection={leaveCorrection}
                    workingPattern={workingPattern}
                />
            )}
        </Stack>
    );
};
