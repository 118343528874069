import { ChipProps } from '@mui/material';
import { Employee } from '@/domain/employee/Employee.model';

import { ObjectiveCompletionStatus } from '@/domain/objective-completion-status/ObjectiveCompletionStatus.model';
import { Department } from '@/domain/department/Department.model';
import { Label } from '@/domain/label/Label.model';

export type ObjectiveCategory = {
    id: number;
    name: Label;
    order: number;
};

export enum ObjectiveStatus {
    DELAYED = 'DELAYED',
    AT_RISK = 'AT_RISK',
    ON_TRACK = 'ON_TRACK',
}

export type Objective = {
    id: number;
    name: string;
    weight: number;
    description: string;
    assignee: Employee;
    parent: Objective | undefined;
    department: Department | undefined;
    dueDate: LocalDate;
    completedAt?: Date;
    completionStatus?: ObjectiveCompletionStatus;
    completionComment?: string;
    category: ObjectiveCategory | undefined;
    lastStatusUpdate: ObjectiveStatusUpdate | undefined;
    statusUpdates: ObjectiveStatusUpdate[];
    objectiveType: ObjectiveType;
    archived: boolean;
};

export type ObjectiveStatusUpdate = {
    id: number;
    status?: ObjectiveStatus;
    createdAt: Date;
    createdBy: Employee | undefined;
    updatedAt: Date;
    comment: string;
};

export const OBJECTIVES_STATUSES: {
    value: ObjectiveStatus;
    labelKey: string;
    color: ChipProps['color'];
}[] = [
    {
        value: ObjectiveStatus.AT_RISK,
        labelKey: 'objectives.status.at_risk',
        color: 'warning',
    },
    {
        value: ObjectiveStatus.DELAYED,
        labelKey: 'objectives.status.off_track',
        color: 'error',
    },
    {
        value: ObjectiveStatus.ON_TRACK,
        labelKey: 'objectives.status.on_track',
        color: 'success',
    },
];

export type ObjectiveCreateMutation = {
    name: string;
    description: string;
    weight: number;
    objectiveCategoryId?: number;
    departmentId?: number;
    assigneeIds: number[];
    dueDate: LocalDate;
    parentId?: number;
};

export type ObjectiveUpdateMutation = Omit<ObjectiveCreateMutation, 'assigneeIds'> & {
    assigneeId: number;
};

export type ObjectiveCloseMutation = {
    completionStatusId: number;
    completionComment?: string;
};

export type ObjectiveStatusCreateMutation = {
    status?: ObjectiveStatus;
    comment: string;
};

export type ObjectiveStatusUpdateMutation = ObjectiveStatusCreateMutation;

export const OBJECTIVE_TYPE_TYPES = ['INDIVIDUAL', 'DEPARTMENT'] as const;
export type ObjectiveType = (typeof OBJECTIVE_TYPE_TYPES)[number];

export type ObjectiveSearch = {
    name?: string;
    statusCompletionIds?: number[];
    employeeIds?: number[];
    objectiveType?: ObjectiveType;
    includeArchived?: boolean;
};

export type CompletionScaleUpdateMutation = {
    name: string;
    order: number;
};
