import { AgGridWrapper, RogerColDef } from '@/components/ag-grid-wrapper/AgGridWrapper';
import { useAgGridWrapper } from '@/components/ag-grid-wrapper/useAgGridWrapper';
import { DatatableAdditionalAction } from '@/components/datatable-additional-action/DatatableAdditionalAction';
import { DateRangePicker } from '@/components/date-range-picker/DateRangePicker';
import { useDateRangeStorage } from '@/components/date-range-picker/DateRangePicker.hook';
import { getDateRange } from '@/components/date-range-picker/DateRangePicker.util';
import { FiltersBar } from '@/components/filters-bar/FiltersBar';
import { useFiltersStorage } from '@/components/filters-bar/useFiltersStorage';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { canViewTimesheets } from '@/domain/permission/Permission.service';
import { DailyTimesheetReport } from '@/domain/timesheet/Timesheet.model';
import { ManageTimesheetPendingTableFiltersEnum, useManageTimesheetPendingTableFilters } from '@/hooks/timesheet/ManageTimesheetPendingTableFilters.hook';
import { useGetEmployeeTimesheets } from '@/hooks/timesheet/Timesheet.hook';
import { DurationUnit } from '@/i18n/i18n';
import { MissingCount } from '@/page/timesheet/missing-count/MissingCount';
import { useAppSelector } from '@/stores/store';
import { formatDate, formatInDefaultDate, isBeforeDate, isValidDate, toDate } from '@/utils/datetime.util';
import { Button, Paper, Stack, Typography, useTheme } from '@mui/material';
import { enGB } from 'date-fns/locale';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const TimesheetsMonthlySummaryPage: FC = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { gridRef, setGridRef, quickFilter } = useAgGridWrapper<DailyTimesheetReport>();

    const policies = useAppSelector(state => state.currentEmployee.grantedPolicies);
    const realm = useAppSelector(state => state.ui.currentRealm);
    const currentEmployee = useAppSelector(state => state.currentEmployee.employee);

    // date range and view type
    const { dateRangeViewType, dateRange, onDateRangeChange } = useDateRangeStorage({
        storageKey: 'monthlyTimesheetPage',
    });

    const { filters: availableFilters } = useManageTimesheetPendingTableFilters();

    const [filters, setFilters] = useFiltersStorage('timesheet-filters', availableFilters);

    const onBtnExport = () =>
        gridRef.current?.api?.exportDataAsExcel({
            allColumns: true,
        });

    const goToEmployeeHistoryPage = (employeeId: number) => {
        const year = toDate(dateRange[0]).getFullYear();
        const month = formatDate(dateRange[0], 'MMMM', {
            locale: enGB,
        }).toUpperCase();
        navigate(`/profile/${employeeId}/timesheets/history/${year}/${month}`);
    };
    const getFilterIds = (filterKey: string): number[] => {
        const filter = filters.find(filter => filter.key === filterKey);
        return filter?.value?.map(option => option.value as number) || [];
    };

    // It's important to wait for the filters to be loaded before enabling the search, this is to avoid multiple requests to the server
    const isFiltersLoaded = !!filters.length;
    const isValidRange = isValidDate(dateRange[0]) && !!dateRange[1] && isValidDate(dateRange[1]) && isBeforeDate(dateRange[0], dateRange[1]);

    const canRenderMonthlyTimesheetSummary =
        !!realm?.realmFeatures && !!currentEmployee?.id && canViewTimesheets(realm?.realmFeatures, policies, currentEmployee.id);
    const searchEnabled = isFiltersLoaded && isValidRange && canRenderMonthlyTimesheetSummary;
    const {
        data: pendingTimesheets = [],
        isError,
        error,
        isFetching = true,
    } = useGetEmployeeTimesheets(
        {
            startDate: dateRange[0],
            endDate: dateRange[1],
            locationIds: getFilterIds(ManageTimesheetPendingTableFiltersEnum.LOCATION),
            jobIds: getFilterIds(ManageTimesheetPendingTableFiltersEnum.JOB),
            departmentIds: getFilterIds(ManageTimesheetPendingTableFiltersEnum.DEPARTMENT),
            managerIds: getFilterIds(ManageTimesheetPendingTableFiltersEnum.MANAGER),
        },
        {
            enabled: searchEnabled,
        },
    );

    const missingCountRenderer = ({ value }: { value: number }) => <MissingCount missingCount={value} />;

    const pendingCountRenderer = ({ value }: { value: number }) => {
        return value ? (
            <Typography variant='body1' color={theme.palette.warning.main}>
                {value} {t('timesheets.pending')}
            </Typography>
        ) : (
            '-'
        );
    };

    const columnDefs: RogerColDef<DailyTimesheetReport>[] = [
        {
            field: 'employee.email',
            headerName: 'Email',
            hide: true,
        },
        {
            field: 'employee',
            type: 'employee',
            headerName: t('general.employee'),
        },
        {
            field: 'totalWorkedCount',
            headerName: t('timesheets.table_headers.totalWorkedCount'),
            type: 'minutesToHours',
        },
        {
            colId: 'totalLeaveCount',
            headerName: t('timesheets.table_headers.totalLeaveCount'),
            valueGetter: ({ data }) => {
                if (!data) {
                    return 0;
                }
                const minutes = data?.totalLeaveCount + data?.totalPublicHolidayCount + data?.totalFutureLeaveCount;
                return minutes / 60;
            },
            valueFormatter: ({ value }) =>
                value
                    ? t('duration.formatDuration', {
                          duration: value,
                          unit: DurationUnit.HOURS,
                      })
                    : '-',
        },
        {
            field: 'totalContractCount',
            headerName: t('timesheets.table_headers.totalContractCount'),
            valueGetter: ({ data }) => {
                return ((data?.totalContractCount ?? 0) + (data?.totalPublicHolidayCount ?? 0)) / 60;
            },
            valueFormatter: ({ value }) =>
                t('duration.formatDuration', {
                    duration: value ?? 0,
                    unit: DurationUnit.HOURS,
                }),
        },
        {
            field: 'totalDifference',
            headerName: t('timesheets.table_headers.totalDifference'),
            type: 'minutesToHours',
        },
        {
            field: 'totalBonusCount',
            headerName: t('timesheets.table_headers.totalBonusCount'),
            type: 'minutesToHours',
        },
        {
            colId: 'totalCompensationCount',
            headerName: t('timesheets.table_headers.totalCompensation'),
            valueGetter: ({ data }) => {
                if (!data) {
                    return 0;
                }
                const minutes = -((data?.totalCompensationCount ?? 0) + (data?.totalFutureCompensationCount ?? 0));
                return minutes / 60;
            },
            valueFormatter: ({ value }) =>
                value
                    ? t('duration.formatDuration', {
                          duration: value,
                          unit: DurationUnit.HOURS,
                      })
                    : '-',
        },
        {
            field: 'totalPaymentCount',
            headerName: t('timesheets.payments'),
            type: 'minutesToHours',
        },
        {
            field: 'totalAdjustmentCount',
            headerName: t('timesheets.adjustments'),
            type: 'minutesToHours',
        },
        {
            field: 'pendingCount',
            headerName: t('timesheets.table_headers.approvals'),
            cellRenderer: pendingCountRenderer,
            cellClass: ['display-flex'],
        },
        {
            field: 'missingCount',
            headerName: t('timesheets.table_headers.submissions'),
            valueGetter: ({ data }) => (data?.missingCount ? data.missingCount : 0),
            cellRenderer: missingCountRenderer,
            cellClass: ['display-flex'],
        },
        {
            // Column for exporting the month end date,
            // we need to add a column that is not visible in the table
            colId: 'exportMonthEndDate',
            headerName: t('timesheets.table_headers.exportMonthEndDate'),
            hide: true,
            valueGetter: () => (dateRange?.length === 2 && dateRange[1] ? formatInDefaultDate(dateRange[1]) : ''),
        },
        {
            field: 'employee.employeeCode',
            headerName: t('payroll.id'),
            hide: true,
        },
    ];

    return (
        <Stack gap={2} flex={1}>
            <Stack component={Paper} p={1} alignItems='center' direction='row' justifyContent='space-between'>
                <Stack direction='row' alignItems='flex-start' gap={1.5} flexWrap={'wrap'}>
                    <Stack direction='row' gap={0.5}>
                        <DateRangePicker
                            dates={dateRange}
                            onDatesChanged={onDateRangeChange}
                            defaultViewType={dateRangeViewType}
                            availableViews={['MONTH', 'RANGE']}
                        />
                        <Button
                            variant={'outlined'}
                            onClick={() => {
                                onDateRangeChange(getDateRange(dateRangeViewType), dateRangeViewType);
                            }}
                        >
                            {t('planning.today')}
                        </Button>
                    </Stack>
                    <FiltersBar filters={filters} onFiltersChange={setFilters} flex={1} />
                </Stack>
                <DatatableAdditionalAction quickFilter={quickFilter} onBtnExport={onBtnExport} disabled={isFetching} />
            </Stack>
            <StateHandler isLoading={isFetching} isError={isError} error={error}>
                <Stack component={Paper} flex={1}>
                    <AgGridWrapper<DailyTimesheetReport>
                        rowData={pendingTimesheets}
                        initRef={setGridRef}
                        onRowClicked={data => {
                            if (data?.data?.employee?.id) {
                                goToEmployeeHistoryPage(data.data.employee.id);
                            }
                        }}
                        columnDefs={columnDefs}
                    />
                </Stack>
            </StateHandler>
        </Stack>
    );
};
