import { yupResolver } from '@hookform/resolvers/yup';
import { Button, DialogActions, DialogContent, FormControlLabel, Stack, TextField } from '@mui/material';
import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { DialogWrapper } from '@/components/dialog-wrapper/DialogWrapper';
import { JobFamily } from '@/domain/job-family/JobFamily.model';

type Props = {
    open: boolean;
    onSave: (data: JobFamilyFormValues) => void;
    closeDialog: () => void;
    jobFamily: JobFamily | undefined;
};

const jobFamilySchema = yup.object().shape({
    name: yup.string().trim().required(),
});

export type JobFamilyFormValues = yup.InferType<typeof jobFamilySchema>;

export const JobFamilySettingsDialog: FC<Props> = ({ open, onSave, closeDialog, jobFamily }) => {
    const { t } = useTranslation();

    const { control, handleSubmit } = useForm<JobFamilyFormValues>({
        resolver: yupResolver(jobFamilySchema),
        defaultValues: {
            name: jobFamily?.name ?? '',
        },
    });

    const handleOnSave = (data: JobFamilyFormValues) => {
        onSave(data);
    };
    const onClose = () => {
        closeDialog();
    };

    const title = jobFamily ? t('job_family_setting_page.dialog.edit_title') : t('job_family_setting_page.dialog.add_title');

    return (
        <DialogWrapper header={title} open={open} onClose={onClose}>
            <Stack component={DialogContent}>
                <Controller
                    name='name'
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <FormControlLabel
                            aria-label={'job-family-name'}
                            label={t('job_family_setting_page.dialog.name_input')}
                            control={<TextField fullWidth {...field} error={!!error} helperText={error?.message} />}
                        />
                    )}
                />
            </Stack>
            <DialogActions>
                <Button onClick={handleSubmit(handleOnSave, console.error)} variant='contained'>
                    {t('general.confirm')}
                </Button>
            </DialogActions>
        </DialogWrapper>
    );
};
