import { DialogWrapper } from '@/components/dialog-wrapper/DialogWrapper';
import { DialogContainerProps } from '@/components/dialog-container/DialogContainer';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, DialogActions, DialogContent, FormControlLabel, Stack, TextField } from '@mui/material';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

type CloseEmployeeReviewFormValues = {
    comment: string;
};
export type CloseEmployeeReviewDialogProps = Omit<DialogContainerProps, 'onSave'> & {
    onSave: (values: CloseEmployeeReviewFormValues) => void;
};

export const CloseEmployeeReviewDialog: FC<CloseEmployeeReviewDialogProps> = ({ onSave, ...rest }) => {
    const { t } = useTranslation();

    const schema = yup.object().shape({
        comment: yup.string().default(''),
    });

    const { handleSubmit, register } = useForm<CloseEmployeeReviewFormValues>({
        resolver: yupResolver(schema),
        defaultValues: { comment: '' },
    });

    return (
        <DialogWrapper header={t('reviews.manage_reviews.close_review')} {...rest}>
            <Stack component={DialogContent}>
                <FormControlLabel
                    label={t('general.comment')}
                    labelPlacement='top'
                    control={<TextField fullWidth InputProps={{ multiline: true, minRows: 4 }} {...register('comment')} />}
                />
            </Stack>
            <DialogActions>
                <Button onClick={handleSubmit(onSave, console.error)} variant='contained'>
                    {t('general.confirm')}
                </Button>
            </DialogActions>
        </DialogWrapper>
    );
};
