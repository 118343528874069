import { FormControlLabel, Stack, TextField } from '@mui/material';
import { FC, useEffect } from 'react';
import { DialogContainer } from '@/components/dialog-container/DialogContainer';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { PlanningTag } from '@/domain/planning-tag/PlanningTag.model';

type Props = {
    open: boolean;
    handleSave: (data: TagForm, id?: number) => void;
    closeDialog: () => void;
    activeTagData?: PlanningTag;
    mode: 'edit' | 'create';
};

export interface TagForm {
    name: string;
}

export const PlanningTagSettingsDialog: FC<Props> = ({ open, handleSave, closeDialog, activeTagData, mode }) => {
    const { t } = useTranslation();
    const schema = yup.object().shape({
        name: yup.string().trim().required(),
    });
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            name: '',
        },
    });

    useEffect(() => {
        if (mode === 'edit') {
            reset({ name: activeTagData?.name });
        } else {
            reset();
        }
    }, [activeTagData, reset, mode]);

    const onSave = (data: TagForm) => {
        handleSave(data, activeTagData?.id);
        reset();
    };
    const onClose = () => {
        reset();
        closeDialog();
    };
    return (
        <DialogContainer
            open={open}
            onClose={onClose}
            onSave={handleSubmit(onSave, console.error)}
            title={mode === 'create' ? t('planning_setting_page.add_tag') : t('planning_setting_page.edit_tag')}
        >
            <Stack>
                <FormControlLabel
                    label={t('planning_setting_page.tag_name')}
                    labelPlacement='top'
                    control={<TextField fullWidth error={!!errors.name} helperText={errors.name?.message} {...register('name')} />}
                />
            </Stack>
        </DialogContainer>
    );
};
