import { FC } from 'react';
import { EmployeeReviewFeedbackFormType } from '@/page/review/employee-review-summary-feedback-form/EmployeeReviewSummaryFeedbackForm.schema';
import { Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { EmployeeAvatar } from '@/components/employee-avatar/EmployeeAvatar';
import { EmployeeReviewStarsIcons } from '@/page/review/employee-review-stars-icons/EmployeeReviewStarsIcons';
import { EmployeeReviewFeedbackSkillFormType, LevelFormType } from '@/page/review/employee-review-feedback-form/EmployeeReviewFeedbackFormPage.schema';

type EmployeeReviewSkillFeedbackFromPreparationProps = {
    employeeReviewFeedbackForm: EmployeeReviewFeedbackFormType;
    skillAndCategory: EmployeeReviewFeedbackSkillFormType;
};

export const EmployeeReviewSkillFeedbackFromPreparation: FC<EmployeeReviewSkillFeedbackFromPreparationProps> = ({
    employeeReviewFeedbackForm,
    skillAndCategory,
}) => {
    const theme = useTheme();

    if (!employeeReviewFeedbackForm || (!employeeReviewFeedbackForm.score && !employeeReviewFeedbackForm.comment)) {
        return <></>;
    }

    const getScoreLevelName = (score: number | undefined, levels: LevelFormType[] | undefined): string => {
        if (!levels || !score) {
            return '';
        }
        const level = levels?.find(level => level.score === score);
        return level?.name ?? '';
    };

    return (
        <Stack gap={0.25}>
            <Stack direction={'row'} alignItems={'center'} gap={1}>
                <EmployeeAvatar employeeAvatar={employeeReviewFeedbackForm.reviewer} />
                {!!employeeReviewFeedbackForm.score && (
                    <Stack p={0.5} px={1} width={240} borderColor={theme.palette.text.primary} border={'1px solid'} borderRadius={1}>
                        {/*todo: fix ellipsis not working properly*/}
                        <Tooltip title={employeeReviewFeedbackForm.comment} sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                            <Stack direction={'row'} alignItems={'center'} gap={1}>
                                <EmployeeReviewStarsIcons score={employeeReviewFeedbackForm.score} htmlColor={theme.palette.text.primary} />
                                <Typography color={theme.palette.text.primary} variant='body1' flexWrap={'nowrap'} whiteSpace={'nowrap'}>
                                    {getScoreLevelName(employeeReviewFeedbackForm.score, skillAndCategory.skill.levels)}
                                </Typography>
                            </Stack>
                        </Tooltip>
                    </Stack>
                )}
            </Stack>

            <Typography variant={'body2'}> {employeeReviewFeedbackForm.comment}</Typography>
        </Stack>
    );
};
